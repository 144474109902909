.theme-color-1 {
    --theme-color: #ff7f00;
    --theme-color-rgb: 217, 159, 70
}

.theme-color-2 {
    --theme-color: rgba(255, 127, 0, 1);
    --theme-color-rgb: 11, 175, 154
}
.w-200{
    width: 200px !important;
}
.theme-color-3 {
    --theme-color: rgba(255, 127, 0, 1);
    --theme-color-rgb: 35, 150, 152
}

.theme-color-4 {
    --theme-color: #6262a6;
    --theme-color-rgb: 35, 150, 152
}

.theme-color-5 {
    --theme-color: #417394;
    --theme-color-rgb: 65, 115, 148
}

:root {
    --theme-color: #ff7f00;
    --secondary-color: #4a5568;
    --theme-color-rgb: 13, 164, 135;
    --theme-color1: rgba(255, 127, 0, 1);
    --theme-color1-rgb: 14, 148, 122;
    --theme-color2: linear-gradient(90.56deg, var(--theme-color1) 8.46%, var(--theme-color) 62.97%)
}

@-webkit-keyframes scaleUpDown {

    0%,
    100% {
        -webkit-transform: scaleY(1) scaleX(1);
        transform: scaleY(1) scaleX(1)
    }

    50%,
    90% {
        -webkit-transform: scaleY(1.1);
        transform: scaleY(1.1)
    }

    75% {
        -webkit-transform: scaleY(0.95);
        transform: scaleY(0.95)
    }

    80% {
        -webkit-transform: scaleX(0.95);
        transform: scaleX(0.95)
    }
}

@keyframes scaleUpDown {

    0%,
    100% {
        -webkit-transform: scaleY(1) scaleX(1);
        transform: scaleY(1) scaleX(1)
    }

    50%,
    90% {
        -webkit-transform: scaleY(1.1);
        transform: scaleY(1.1)
    }

    75% {
        -webkit-transform: scaleY(0.95);
        transform: scaleY(0.95)
    }

    80% {
        -webkit-transform: scaleX(0.95);
        transform: scaleX(0.95)
    }
}

@-webkit-keyframes shake {

    0%,
    100% {
        -webkit-transform: skewX(0) scale(1);
        transform: skewX(0) scale(1)
    }

    50% {
        -webkit-transform: skewX(5deg) scale(0.9);
        transform: skewX(5deg) scale(0.9)
    }
}

@keyframes shake {

    0%,
    100% {
        -webkit-transform: skewX(0) scale(1);
        transform: skewX(0) scale(1)
    }

    50% {
        -webkit-transform: skewX(5deg) scale(0.9);
        transform: skewX(5deg) scale(0.9)
    }
}

@-webkit-keyframes particleUp {
    0% {
        opacity: 0
    }

    20% {
        opacity: 1
    }

    80% {
        opacity: 1
    }

    100% {
        opacity: 0;
        top: -100%;
        -webkit-transform: scale(0.5);
        transform: scale(0.5)
    }
}

@keyframes particleUp {
    0% {
        opacity: 0
    }

    20% {
        opacity: 1
    }

    80% {
        opacity: 1
    }

    100% {
        opacity: 0;
        top: -100%;
        -webkit-transform: scale(0.5);
        transform: scale(0.5)
    }
}

@-webkit-keyframes shape {
    0% {
        background-position: 100% 0
    }

    50% {
        background-position: 50% 50%
    }

    100% {
        background-position: 0 100%
    }
}

@keyframes shape {
    0% {
        background-position: 100% 0
    }

    50% {
        background-position: 50% 50%
    }

    100% {
        background-position: 0 100%
    }
}

@-webkit-keyframes rounded {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rounded {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes move {
    0% {
        -webkit-transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
        transform: scale(1) rotate(0deg) translate3d(0, 0, 1px)
    }

    30% {
        opacity: 1
    }

    100% {
        z-index: 10;
        -webkit-transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
        transform: scale(0) rotate(360deg) translate3d(0, 0, 1px)
    }
}

@keyframes move {
    0% {
        -webkit-transform: scale(1) rotate(0deg) translate3d(0, 0, 1px);
        transform: scale(1) rotate(0deg) translate3d(0, 0, 1px)
    }

    30% {
        opacity: 1
    }

    100% {
        z-index: 10;
        -webkit-transform: scale(0) rotate(360deg) translate3d(0, 0, 1px);
        transform: scale(0) rotate(360deg) translate3d(0, 0, 1px)
    }
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }
}

@-webkit-keyframes flash {
    0% {
        opacity: 0.4;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out
    }

    100% {
        opacity: 1;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out
    }
}

@keyframes flash {
    0% {
        opacity: 0.4;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out
    }

    100% {
        opacity: 1;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out
    }
}

@keyframes shake {
    0% {
        -webkit-transform: translate(3px, 0);
        transform: translate(3px, 0)
    }

    50% {
        -webkit-transform: translate(-3px, 0);
        transform: translate(-3px, 0)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@-webkit-keyframes grow {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes grow {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

.alert {
    font-size: 15px;
    letter-spacing: 0.3px;
    padding: 18px 24px
}

@media (max-width: 575px) {
    .alert {
        top: 0px !important;
        right: 0px !important;
        margin: 12px !important;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
    }
}

.alert .alert-success {
    background: #39da8a !important;
    color: #fff !important;
    border: none
}

.alert .alert-success .close {
    color: #fff;
    opacity: 1;
    top: -4px;
    text-shadow: none;
    font-weight: 400;
    font-size: 24px
}

.alert .btn-close {
    top: 50% !important;
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important
}

[dir="rtl"] .alert .btn-close {
    right: unset !important;
    left: 10px !important
}

[data-notify="icon"] {
    margin-right: 5px
}

[dir="rtl"] [data-notify="icon"] {
    margin-right: unset;
    margin-left: 5px
}

[data-notify="progressbar"] {
    width: 100%;
    height: 5px;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0
}

.progress-bar-info {
    background-color: #0c5460
}

.add-cart-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 10px;
    position: fixed;
    top: -90px;
    right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid #ececec;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 10px;
    -webkit-box-shadow: 0px 3px 5px rgba(34, 34, 34, 0.129);
    box-shadow: 0px 3px 5px rgba(34, 34, 34, 0.129);
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    z-index: 6
}

.add-cart-box.show {
    top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.add-cart-box .add-iamge {
    width: 50px;
    height: auto
}

.add-cart-box .btn-close {
    color: #fff;
    background-color: var(--theme-color);
    opacity: 1;
    background-image: none;
    position: absolute;
    top: -6px;
    right: -6px
}

.add-cart-box .add-contain h6 {
    font-weight: 600
}

.breadscrumb-section {
    background-color: #f8f8f8;
    position: relative;
    overflow: hidden
}

.breadscrumb-section .breadscrumb-contain {
    padding: calc(26px + (40 - 26) * ((100vw - 320px) / (1920 - 320))) 0;
    text-align: center;
    color: #222;
    font-family: "Public Sans", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 480px) {
    .breadscrumb-section .breadscrumb-contain {
        display: block
    }
}

.breadscrumb-section .breadscrumb-contain h2 {
    font-weight: 700;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0
}

@media (max-width: 480px) {
    .breadscrumb-section .breadscrumb-contain h2 {
        text-align: center;
        margin-bottom: 8px
    }
}

.breadscrumb-section .breadscrumb-contain .search-box-breadscrumb {
    position: relative;
    width: 70%;
    margin: 0 auto
}

@media (max-width: 575px) {
    .breadscrumb-section .breadscrumb-contain .search-box-breadscrumb {
        width: 90%
    }
}

@media (max-width: 360px) {
    .breadscrumb-section .breadscrumb-contain .search-box-breadscrumb {
        width: 100%
    }
}

.breadscrumb-section .breadscrumb-contain .search-box-breadscrumb input {
    width: 100%;
    border: none;
    border-radius: 6px;
    font-size: 15px
}

.breadscrumb-section .breadscrumb-contain .search-box-breadscrumb i {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.breadscrumb-section .breadscrumb-contain nav {
    margin-left: auto
}

[dir="rtl"] .breadscrumb-section .breadscrumb-contain nav {
    margin-left: unset;
    margin-right: auto
}

.breadscrumb-section .breadscrumb-contain nav .breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.breadscrumb-section .breadscrumb-contain nav .breadcrumb .breadcrumb-item {
    font-weight: 500
}

.breadscrumb-section .breadscrumb-contain nav .breadcrumb .breadcrumb-item i {
    color: #4a5568
}

.breadscrumb-section .breadscrumb-contain nav .breadcrumb .breadcrumb-item.active {
    color: #000;
    margin-top: 2px
}

.breadscrumb-section .breadscrumb-contain nav .breadcrumb .breadcrumb-item+.breadcrumb-item {
    position: relative
}

[dir="rtl"] .breadscrumb-section .breadscrumb-contain nav .breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
    padding-right: 8px
}

.breadscrumb-section .breadscrumb-contain nav .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    content: "\f105";
    color: #4a5568
}

.breadscrumb-section .breadscrumb-order {
    display: block
}

.breadscrumb-section .breadscrumb-order .order-box .order-image {
    width: calc(170px + (250 - 170) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin: 0 auto calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .checkmark {
    position: relative;
    padding: 30px;
    -webkit-animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
    animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
    display: inline-block;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    margin: -20px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .checkmark__check {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    fill: white
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .checkmark__background {
    fill: var(--theme-color);
    -webkit-animation: rotate 35s linear both infinite;
    animation: rotate 35s linear both infinite
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star {
    position: absolute;
    -webkit-animation: grow 3s infinite;
    animation: grow 3s infinite;
    fill: var(--theme-color);
    opacity: 0
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(1) {
    width: 12px;
    height: 12px;
    left: 12px;
    top: 16px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(2) {
    width: 18px;
    height: 18px;
    left: 168px;
    top: 84px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 32px;
    top: 162px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(4) {
    height: 20px;
    width: 20px;
    left: 82px;
    top: -12px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(5) {
    width: 14px;
    height: 14px;
    left: 125px;
    top: 162px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(6) {
    width: 10px;
    height: 10px;
    left: 16px;
    top: 16px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(1) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(2) {
    -webkit-animation-delay: 3s;
    animation-delay: 3s
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(3) {
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(4) {
    -webkit-animation-delay: 6s;
    animation-delay: 6s
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(5) {
    -webkit-animation-delay: 7.5s;
    animation-delay: 7.5s
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .star:nth-child(6) {
    -webkit-animation-delay: 9s;
    animation-delay: 9s
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .checkmark {
    position: relative;
    padding: 30px;
    -webkit-animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
    animation: checkmark 5m cubic-bezier(0.42, 0, 0.275, 1.155) both;
    display: inline-block;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    margin: -20px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .checkmark__check {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    fill: white
}

.breadscrumb-section .breadscrumb-order .order-box .order-image .checkmark__background {
    fill: var(--theme-color);
    -webkit-animation: rotate 35s linear both infinite;
    animation: rotate 35s linear both infinite
}

.breadscrumb-section .breadscrumb-order .order-box .order-image i {
    font-size: 50px;
    color: #4ead4e
}

.breadscrumb-section .breadscrumb-order .order-box .order-image h2 {
    margin-top: 10px;
    margin-bottom: 15px
}

.breadscrumb-section .breadscrumb-order .order-box .order-image p {
    font-size: 18px;
    text-transform: capitalize
}

.breadscrumb-section .breadscrumb-order .order-box .order-image.order-fail i {
    color: var(--theme-color)
}

.breadscrumb-section .breadscrumb-order .order-box .order-contain h3 {
    font-size: calc(21px + (24 - 21) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    margin-bottom: 6px
}

.breadscrumb-section .breadscrumb-order .order-box .order-contain h5 {
    margin-bottom: 8px;
    line-height: 1.4
}

.faq-breadscrumb {
    background-color: #f8f8f8;
    position: relative;
    overflow: hidden
}

.faq-breadscrumb .breadscrumb-contain {
    padding: calc(26px + (60 - 26) * ((100vw - 320px) / (1920 - 320))) 0;
    text-align: center;
    color: #222;
    display: block
}

.faq-breadscrumb .breadscrumb-contain h2 {
    font-size: calc(22px + (48 - 22) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    margin-top: -6px
}

.faq-breadscrumb .breadscrumb-contain p {
    margin: calc(11px + (16 - 11) * ((100vw - 320px) / (1920 - 320))) auto 0;
    color: #4a5568;
    width: 50%;
    line-height: 1.7;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 1660px) {
    .faq-breadscrumb .breadscrumb-contain p {
        width: 60%
    }
}

@media (max-width: 1366px) {
    .faq-breadscrumb .breadscrumb-contain p {
        width: 70%
    }
}

@media (max-width: 991px) {
    .faq-breadscrumb .breadscrumb-contain p {
        width: 90%
    }
}

@media (max-width: 767px) {
    .faq-breadscrumb .breadscrumb-contain p {
        width: 100%
    }
}

.faq-breadscrumb .breadscrumb-contain p span {
    display: inline
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag {
    position: relative;
    margin: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320))) auto 0;
    width: 50%
}

@media (max-width: 1660px) {
    .faq-breadscrumb .breadscrumb-contain .faq-form-tag {
        width: 60%
    }
}

@media (max-width: 1366px) {
    .faq-breadscrumb .breadscrumb-contain .faq-form-tag {
        width: 70%
    }
}

@media (max-width: 991px) {
    .faq-breadscrumb .breadscrumb-contain .faq-form-tag {
        width: 90%
    }
}

@media (max-width: 767px) {
    .faq-breadscrumb .breadscrumb-contain .faq-form-tag {
        width: 100%
    }
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group {
    background-color: #fff
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color);
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .form-control {
    padding-left: 0;
    border: none
}

[dir="rtl"] .faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .form-control {
    padding-left: unset;
    padding-right: 0
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .form-control:focus {
    background-color: transparent
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .faq-dropdown-menu li {
    display: block
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .faq-dropdown-button {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: var(--theme-color);
    z-index: 0
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .faq-dropdown-button::after {
    content: unset
}

.faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .faq-dropdown-button i {
    position: relative;
    padding: 0;
    margin-left: 5px;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .faq-breadscrumb .breadscrumb-contain .faq-form-tag .input-group .faq-dropdown-button i {
    margin-left: unset;
    margin-right: 5px
}

.vendore-breadscrumb-section {
    background-image: url("../../../public/assets/images/vendor-page/breadcrumb.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: calc(52px + (100 - 52) * ((100vw - 320px) / (1920 - 320))) 0px
}

.vendore-breadscrumb-section .breadscrumb-contain {
    width: 50%;
    margin: 0 auto
}

@media (max-width: 1366px) {
    .vendore-breadscrumb-section .breadscrumb-contain {
        width: 63%
    }
}

@media (max-width: 991px) {
    .vendore-breadscrumb-section .breadscrumb-contain {
        width: 85%
    }
}

@media (max-width: 767px) {
    .vendore-breadscrumb-section .breadscrumb-contain {
        width: 100%
    }
}

.vendore-breadscrumb-section .breadscrumb-contain h2 {
    font-size: calc(27px + (54 - 27) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    margin-bottom: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.vendore-breadscrumb-section .breadscrumb-contain form {
    position: relative
}

.vendore-breadscrumb-section .breadscrumb-contain form span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 16px
}

[dir="rtl"] .vendore-breadscrumb-section .breadscrumb-contain form span {
    left: unset;
    right: 16px
}

.vendore-breadscrumb-section .breadscrumb-contain form span i {
    font-size: calc(21px + (27 - 21) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color)
}

.vendore-breadscrumb-section .breadscrumb-contain form .form-control {
    padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(95px + (122 - 95) * ((100vw - 320px) / (1920 - 320))) calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(48px + (59 - 48) * ((100vw - 320px) / (1920 - 320)));
    border: none
}

[dir="rtl"] .vendore-breadscrumb-section .breadscrumb-contain form .form-control {
    padding-left: calc(95px + (122 - 95) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(48px + (59 - 48) * ((100vw - 320px) / (1920 - 320)))
}

.vendore-breadscrumb-section .breadscrumb-contain form button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
    padding: 0;
    font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
    width: calc(77px + (108 - 77) * ((100vw - 320px) / (1920 - 320)));
    height: calc(33px + (42 - 33) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .vendore-breadscrumb-section .breadscrumb-contain form button {
    right: unset;
    left: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)))
}


.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn:hover .icon {
    -webkit-transform: translateX(3px);
    transform: translateX(3px)
}

.btn.dark-button {
    background: linear-gradient(93.33deg, #222221 12.35%, #2F2F2D 99.38%) !important
}

.btn .icon {
    margin-left: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease
}

[dir="rtl"] .btn .icon {
    margin-left: unset;
    margin-right: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.btn .icon-2 {
    margin-left: 6px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease
}

[dir="rtl"] .btn .icon-2 {
    margin-left: unset;
    margin-right: 6px
}

.btn-furniture {
    border: 1px solid #4a5568;
    color: #4a5568;
    display: block
}

.btn-animation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    border-radius: 5px;
    font-weight: 600;
    background: transparent;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    background: #ff6b6b;
    background: -webkit-gradient(linear, left top, right top, from(#ff6b6b), to(#ff4f4f));
    background: linear-gradient(90deg, #ff6b6b 0%, #ff4f4f 100%);
    border: none;
    z-index: 0
}

.btn-animation i {
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease
}

.btn-animation::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background: #ff4f4f;
    background: -webkit-gradient(linear, left top, right top, from(#ff4f4f), to(#ff6b6b));
    background: linear-gradient(90deg, #ff4f4f 0%, #ff6b6b 100%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-animation:hover {
    color: #fff
}

.btn-animation:hover i {
    -webkit-transform: translateX(3px);
    transform: translateX(3px)
}

.btn-animation:hover::after {
    top: 0;
    height: 100%
}

.btn-md {
    padding: calc(8px + (11 - 8) * ((100vw - 320px) / (1920 - 320))) calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.btn-sm {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500
}

.btn-2 {
    padding: 9px 25px;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400
}

.btn-2:hover::after {
    top: 0;
    height: 100%
}

.btn-2-animation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: relative;
    background: rgba(255, 127, 0, 1);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 127, 0, 1)), to(#0e947a));
    background: linear-gradient(90deg, rgba(255, 127, 0, 1) 0%, #0e947a 100%);
    border: none;
    z-index: 0
}

.btn-2-animation::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background: rgba(255, 127, 0, 1);
    background: -webkit-gradient(linear, left top, right top, from(#0e947a), to(rgba(255, 127, 0, 1)));
    background: linear-gradient(90deg, #0e947a 0%, rgba(255, 127, 0, 1) 100%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-category {
    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320))) calc(16px + (27 - 16) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.8px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    border-radius: 50px
}

.cookie-bar-box {
    background: #fff;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    max-width: 370px;
    overflow: hidden;
    z-index: 2;
    -webkit-box-shadow: 0 0.25rem 0.5rem rgba(34, 34, 34, 0.05), 0 24px 35px rgba(34, 34, 34, 0.1);
    box-shadow: 0 0.25rem 0.5rem rgba(34, 34, 34, 0.05), 0 24px 35px rgba(34, 34, 34, 0.1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .cookie-bar-box {
    right: unset;
    left: 10px
}

@media (max-width: 480px) {
    .cookie-bar-box {
        max-width: 100%;
        right: 0;
        margin: 0 10px
    }

    [dir="rtl"] .cookie-bar-box {
        right: unset;
        left: 0
    }
}

.cookie-bar-box.hide {
    opacity: 0;
    bottom: -350px
}

.cookie-bar-box .cookie-box {
    padding: calc(13px + (30 - 13) * ((100vw - 320px) / (1920 - 320)));
    text-align: center
}

.cookie-bar-box .cookie-box .cookie-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.cookie-bar-box .cookie-box .cookie-image img {
    width: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin-right: 10px
}

[dir="rtl"] .cookie-bar-box .cookie-box .cookie-image img {
    margin-right: unset;
    margin-left: 10px
}

.cookie-bar-box .cookie-box .cookie-contain {
    margin-top: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.cookie-bar-box .cookie-box .cookie-contain h2 {
    margin-bottom: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700
}

.cookie-bar-box .cookie-box .cookie-contain h5 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin: 0 auto;
    line-height: 1.3
}

.cookie-bar-box .button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0
}

.cookie-bar-box .button-group button {
    white-space: nowrap;
    width: 100%;
    font-weight: 700;
    border-radius: 0
}

.cookie-bar-box .button-group button.privacy-button {
    background-color: #f8f8f8
}

.cookie-bar-box .button-group button.ok-button {
    background-color: var(--theme-color);
    color: #fff
}

.cookie-bar-box-2 {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #eadfd8;
    max-width: 400px;
    padding: 20px;
    border-radius: 20px;
    text-align: center
}

.cookie-bar-box-2 .cookie-bar-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    background-color: #ded4cd;
    border-radius: 100%;
    margin: 0 auto 13px
}

.cookie-bar-box-2 .cookie-bar-image i {
    font-size: 26px;
    height: auto;
    color: #2c3249
}

.cookie-bar-box-2 .cookie-bar-detail h4 {
    line-height: 1.5;
    color: #2c3249
}

.cookie-bar-box-2 .cookie-bar-detail .cookie-button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 10px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 12px
}

.cookie-bar-box-2 .cookie-bar-detail .cookie-button-group .allow-button {
    background-color: rgba(44, 50, 73, 0.16);
    padding: 11px 17px;
    border: 1px solid #2c3249;
    border-radius: 50px 15px 15px 50px;
    font-size: 14px;
    font-weight: 600
}

.cookie-bar-box-2 .cookie-bar-detail .cookie-button-group .decline-button {
    background-color: rgba(44, 50, 73, 0.16);
    padding: 11px 17px;
    border-radius: 15px 50px 50px 15px;
    font-size: 14px;
    font-weight: 600
}

.counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.counter .qty-left-minus,
.counter .qty-right-plus {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid #ececec;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 20px;
    line-height: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0
}

.counter .qty-left-minus i,
.counter .qty-right-plus i {
    font-size: 12px;
    margin-top: 3px
}

.counter .qty-left-minus:hover,
.counter .qty-right-plus:hover {
    color: #010200
}

.counter .qty-left-minus {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0
}

[dir="rtl"] .counter .qty-left-minus {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right: 1px solid #ececec;
    border-left: 0
}

.counter .qty-right-plus {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0
}

[dir="rtl"] .counter .qty-right-plus {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
    border-left: 1px solid #ececec
}

.counter input {
    width: 45px;
    height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid #ececec;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 0
}

[dir="rtl"] .counter input {
    text-align: center
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none
}

.newsletter-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 11px
}

.newsletter-form .form-control {
    background-color: transparent;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)))
}

.newsletter-form .form-control::-webkit-input-placeholder {
    color: #fff;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.newsletter-form .form-control::-moz-placeholder {
    color: #fff;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.newsletter-form .form-control:-ms-input-placeholder {
    color: #fff;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.newsletter-form .form-control::-ms-input-placeholder {
    color: #fff;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.newsletter-form .form-control::placeholder {
    color: #fff;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.normal-form .form-control {
    border: none
}

.normal-form .form-control:focus {
    background-color: #fff
}

.theme-form-check {
    cursor: pointer;
    -webkit-transition: background 0.2s ease;
    transition: background 0.2s ease
}

.theme-form-check input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    border-radius: 10px;
    background: none;
    border: 0;
    -webkit-box-shadow: inset 0 0 0 1.5px #9F9F9F;
    box-shadow: inset 0 0 0 1.5px #9F9F9F;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    margin: 0;
    -webkit-transition: -webkit-box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    transition: -webkit-box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25), -webkit-box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    pointer-events: none
}

.theme-form-check input:focus {
    outline: none
}

.theme-form-check input:checked {
    -webkit-box-shadow: inset 0 0 0 6px #6743ee;
    box-shadow: inset 0 0 0 6px #6743ee
}

.theme-form-check input label {
    vertical-align: middle;
    display: inline-block;
    line-height: 20px;
    padding: 0 8px
}

.theme-form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    opacity: .95;
    top: -5px;
    left: 5px;
    height: 31px;
    background-color: #fff;
    white-space: nowrap;
    opacity: 1
}

.theme-form-floating>.form-control:not(:-ms-input-placeholder)~label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    opacity: .95;
    top: -5px;
    left: 5px;
    height: 31px;
    background-color: #fff;
    white-space: nowrap;
    opacity: 1
}

.theme-form-floating>.form-control:focus~label,
.theme-form-floating>.form-control:not(:placeholder-shown)~label,
.theme-form-floating>.form-select~label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    opacity: .95;
    top: -5px;
    left: 5px;
    height: 31px;
    background-color: #fff;
    white-space: nowrap;
    opacity: 1
}

[dir="rtl"] .theme-form-floating>.form-control:not(:-moz-placeholder-shown)~label {
    right: 10px;
    left: unset
}

[dir="rtl"] .theme-form-floating>.form-control:not(:-ms-input-placeholder)~label {
    right: 10px;
    left: unset
}

[dir="rtl"] .theme-form-floating>.form-control:focus~label,
[dir="rtl"] .theme-form-floating>.form-control:not(:placeholder-shown)~label,
[dir="rtl"] .theme-form-floating>.form-select~label {
    right: 10px;
    left: unset
}

.theme-form-floating .form-control,
.theme-form-floating .form-select {
    height: calc(51px + (54 - 51) * ((100vw - 320px) / (1920 - 320)));
    line-height: normal
}

.theme-form-floating>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 17px;
    padding-bottom: 10px;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.theme-form-floating>.form-control:not(:-ms-input-placeholder) {
    padding-top: 17px;
    padding-bottom: 10px;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.theme-form-floating>.form-control:focus,
.theme-form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 17px;
    padding-bottom: 10px;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.theme-form-floating>.form-select {
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.theme-form-floating>.form-select:focus {
    border-color: var(--theme-color);
    outline: 0;
    -webkit-box-shadow: 0 0 0 4px transparent;
    box-shadow: 0 0 0 4px transparent
}

.theme-form-floating .form-control:focus {
    background-color: #fff;
    border: 1px solid #ced4da
}

.theme-form-floating label {
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    border: none
}

.theme-form-floating-2>.form-control:not(:-moz-placeholder-shown)~label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    opacity: .95;
    top: -5px;
    left: 10px;
    height: 31px;
    background-color: #fff;
    padding: 0 10px;
    opacity: 1
}

.theme-form-floating-2>.form-control:not(:-ms-input-placeholder)~label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    opacity: .95;
    top: -5px;
    left: 10px;
    height: 31px;
    background-color: #fff;
    padding: 0 10px;
    opacity: 1
}

.theme-form-floating-2>.form-control:focus~label,
.theme-form-floating-2>.form-control:not(:placeholder-shown)~label,
.theme-form-floating-2>.form-select~label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    opacity: .95;
    top: -5px;
    left: 10px;
    height: 31px;
    background-color: #fff;
    padding: 0 10px;
    opacity: 1
}

[dir="rtl"] .theme-form-floating-2>.form-control:not(:-moz-placeholder-shown)~label {
    left: unset;
    right: 10px
}

[dir="rtl"] .theme-form-floating-2>.form-control:not(:-ms-input-placeholder)~label {
    left: unset;
    right: 10px
}

[dir="rtl"] .theme-form-floating-2>.form-control:focus~label,
[dir="rtl"] .theme-form-floating-2>.form-control:not(:placeholder-shown)~label,
[dir="rtl"] .theme-form-floating-2>.form-select~label {
    left: unset;
    right: 10px
}

.theme-form-floating-2>.form-control:not(:-moz-placeholder-shown) {
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 500
}

.theme-form-floating-2>.form-control:not(:-ms-input-placeholder) {
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 500
}

.theme-form-floating-2>.form-control:focus,
.theme-form-floating-2>.form-control:not(:placeholder-shown) {
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 500
}

.theme-form-floating-2>.form-select {
    padding-top: 12px;
    padding-bottom: 10px;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.theme-form-floating-2>.form-select:focus {
    border-color: var(--theme-color);
    outline: 0;
    -webkit-box-shadow: 0 0 0 4px transparent;
    box-shadow: 0 0 0 4px transparent
}

.theme-form-floating-2 .form-control:focus {
    background-color: #fff;
    border: 1px solid #ced4da
}

.theme-form-floating-2>.form-control,
.theme-form-floating-2>.form-select {
    height: 46px
}

.theme-form-floating-2>label {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 9px;
    padding: 0;
    border: none;
    height: auto
}

[dir="rtl"] .theme-form-floating-2>label {
    left: unset;
    right: 9px
}

.theme-form-floating-2>.form-control {
    padding: 14px 10px
}

.theme-form-floating-2 label {
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568
}

.checkbox_animated {
    cursor: pointer;
    position: relative;
    margin-right: 16px;
    height: 100%
}

[dir="rtl"] .checkbox_animated {
    margin-right: unset;
    margin-left: 16px
}

.checkbox_animated:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 6px;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    left: 4px;
    top: 4px;
    z-index: 1;
    border: 2px solid var(--theme-color);
    border-top-style: none;
    border-right-style: none
}

[dir="rtl"] .checkbox_animated:before {
    left: unset;
    right: 4px
}

.checkbox_animated:after {
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    top: -2px;
    left: 0;
    background-color: #fff;
    border: 2px solid #ececec;
    cursor: pointer
}

[dir="rtl"] .checkbox_animated:after {
    left: unset;
    right: 0
}

.checkbox_animated:checked:before {
    -webkit-transform: rotate(-45deg) scale(1, 1);
    transform: rotate(-45deg) scale(1, 1)
}

.custom-form-check-2 {
    min-height: auto;
    margin-bottom: 0
}

.custom-form-check .form-check-input {
    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 0
}

[dir="rtl"] .custom-form-check .form-check-input {
    margin-right: -1.5em;
    margin-left: unset
}

.custom-form-check .form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.custom-form-check .form-check-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.form-control {
    font-size: 14px;
    padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.form-control.subscribe-input {
    border: none;
    border-radius: 0
}

[dir="rtl"] .form-control {
    text-align: right
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000
}

.form-select {
    font-size: 14px
}

.form-select.theme-form-select:focus {
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000
}

.input-group .btn {
    z-index: 1
}

.label-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.label-flex .discount {
    background-color: var(--theme-color);
    color: #fff;
    padding: 4px calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 4px
}

.label-flex .discount.sm-discount label {
    font-size: 12px
}

.label-flex .discount label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: -2px 0
}

.label-flex .wishlist {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    margin-left: auto
}

[dir="rtl"] .label-flex .wishlist {
    margin-left: unset;
    margin-right: auto
}

.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms
}

.blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0)
}

.fullpage-loader {
    width: 100vw;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: rgba(255,255,255,.6);
    backdrop-filter: blur(10px);
    z-index: 9999;
    opacity: 1;
    -webkit-transition: opacity .5s;
    transition: opacity .5s
}

.fullpage-loader span {
    width: 6vmin;
    height: 6vmin;
    background-color: var(--theme-color);
    border-radius: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    -webkit-animation-name: move;
    animation-name: move;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 0.8);
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.8);
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    top: calc(50% - 4vmin);
    left: 50%;
    -webkit-transform-origin: -4vmin center;
    transform-origin: -4vmin center
}

.fullpage-loader span:nth-child(1) {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
    opacity: 0
}

.fullpage-loader span:nth-child(2) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    opacity: 0
}

.fullpage-loader span:nth-child(3) {
    -webkit-animation-delay: -1.5s;
    animation-delay: -1.5s;
    opacity: 0
}

.fullpage-loader span:nth-child(4) {
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
    opacity: 0
}

.fullpage-loader span:nth-child(5) {
    -webkit-animation-delay: -2.5s;
    animation-delay: -2.5s;
    opacity: 0
}

.fullpage-loader span:nth-child(6) {
    -webkit-animation-delay: -3s;
    animation-delay: -3s;
    opacity: 0
}

.fullpage-loader--invisible {
    opacity: 0
}

.theme-modal .modal-dialog .modal-content {
    border: none
}

.theme-modal .modal-dialog .modal-content .modal-header {
    border-bottom: none;
    padding: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320))) calc(17px + (16 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.theme-modal .modal-dialog .modal-content .modal-header .modal-title {
    font-weight: 600;
    font-size: 20px;
    width: 95%;
    margin-top: -4px
}

@media (max-width: 575px) {
    .theme-modal .modal-dialog .modal-content .modal-header .modal-title {
        margin-top: 0
    }
}

.theme-modal .modal-dialog .modal-content .modal-header .btn-close {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: var(--theme-color);
    opacity: 1;
    background-image: none;
    border-radius: 4px;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    color: #fff;
    margin: 0;
    padding: 8px;
    z-index: 1
}

[dir="rtl"] .theme-modal .modal-dialog .modal-content .modal-header .btn-close {
    right: unset;
    left: -15px
}

@media (max-width: 575px) {
    .theme-modal .modal-dialog .modal-content .modal-header .btn-close {
        right: 13px;
        top: 13px
    }

    [dir="rtl"] .theme-modal .modal-dialog .modal-content .modal-header .btn-close {
        right: unset;
        left: 13px
    }
}

@media (max-width: 575px) and (max-width: 575px) {
    .theme-modal .modal-dialog .modal-content .modal-header .btn-close i {
        margin-top: -1px;
        display: block
    }
}

.theme-modal .modal-dialog .modal-content .modal-footer {
    border: none;
    padding: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320))) calc(17px + (16 - 17) * ((100vw - 320px) / (1920 - 320)));
    gap: 8px
}

.theme-modal .modal-dialog .modal-content .modal-footer button {
    margin: 0
}

.view-modal .modal-dialog .modal-content .modal-body {
    padding: calc(18px + (29 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .title-name {
    font-weight: 600;
    line-height: 1.3;
    text-transform: uppercase;
    margin-bottom: calc(7px + (3 - 7) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(17px + (25 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .price {
    font-size: 20px;
    color: #4a5568
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .brand-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: calc(13px + (19 - 13) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px dashed #ececec;
    padding-bottom: 14px
}

[dir="rtl"] .view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .brand-list {
    padding-right: 0
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .brand-list li {
    width: 100%
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .brand-list li .brand-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .brand-list li .brand-box h5 {
    width: 110px;
    font-size: 14px;
    color: #4a5568
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-size {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 15px;
    margin-top: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-size h4 {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    white-space: nowrap
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-size .select-form-size {
    width: auto
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .product-rating {
    border-bottom: 1px dashed #ececec;
    padding-bottom: 14px;
    margin-top: 14px
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .product-detail {
    margin-top: 12px
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .product-detail h4 {
    font-weight: 600;
    margin-bottom: 7px
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .product-detail p {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.4;
    margin: 0;
    color: #4a5568
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 17px
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight .select-form-wight {
    width: auto
}

@media (max-width: 480px) {
    .view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight .select-form-wight {
        width: 100%
    }
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight .stoke-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 6px
}

@media (max-width: 575px) {
    .view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight .stoke-box {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight .stoke-box .feather {
    width: 19px;
    height: auto;
    color: #61b33e
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight .stoke-box h6 {
    font-size: 16px;
    letter-spacing: 0.9px;
    color: #4a5568
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .select-wight .select-form-wight:focus {
    border-color: #ececec;
    -webkit-box-shadow: none;
    box-shadow: none
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .modal-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .modal-button .add-cart-button {
    background-color: #222;
    color: #fff;
    font-weight: 600
}

.view-modal .modal-dialog .modal-content .modal-body .right-sidebar-modal .modal-button .view-button {
    margin-left: 15px
}

.location-modal .modal-dialog .modal-content .modal-header {
    display: block;
    padding: calc(13px + (30 - 13) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: 0
}

.location-modal .modal-dialog .modal-content .modal-header p {
    margin-bottom: 0;
    line-height: 1.6
}

.location-modal .modal-dialog .modal-content .modal-body {
    padding: calc(13px + (30 - 13) * ((100vw - 320px) / (1920 - 320))) calc(13px + (30 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .search-input {
    position: relative
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .search-input .form-control {
    padding-left: 45px
}

[dir="rtl"] .location-modal .modal-dialog .modal-content .modal-body .location-list .search-input .form-control {
    padding-left: unset;
    padding-right: 45px
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .search-input i {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
    font-size: 17px;
    color: #4a5568
}

[dir="rtl"] .location-modal .modal-dialog .modal-content .modal-body .location-list .search-input i {
    left: unset;
    right: 0
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .disabled-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) calc(7px + (14 - 7) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid #ececec;
    margin-top: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .disabled-box h6 {
    color: #4a5568;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .disabled-box span {
    margin-left: auto;
    margin-right: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 10px;
    font-weight: 500;
    color: #9b9bb4;
    padding: 3px 10px;
    border-radius: 30px
}

[dir="rtl"] .location-modal .modal-dialog .modal-content .modal-body .location-list .disabled-box span {
    margin-right: auto;
    margin-left: unset
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li {
    display: block
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: calc(10px + (10 - 10) * ((100vw - 320px) / (1920 - 320))) calc(7px + (14 - 7) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 0
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: -1
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a:hover::after {
    opacity: .2
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a:hover h6 {
    color: var(--theme-color)
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a:hover span {
    border-color: transparent;
    background-color: var(--theme-color);
    color: #fff
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a h6 {
    color: #222;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a span {
    margin-left: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 10px;
    font-weight: 500;
    border: 1px solid #e2e2ec;
    color: #9b9bb4;
    padding: 3px 10px;
    border-radius: 30px
}

[dir="rtl"] .location-modal .modal-dialog .modal-content .modal-body .location-list .location-select li a span {
    margin-right: auto;
    margin-left: unset
}

.deal-modal .modal-dialog .modal-content .modal-header {
    padding: calc(13px + (30 - 13) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: 0
}

.deal-modal .modal-dialog .modal-content .modal-header p {
    margin-bottom: 0;
    line-height: 1.6
}

.deal-modal .modal-dialog .modal-content .modal-body {
    padding: calc(13px + (30 - 13) * ((100vw - 320px) / (1920 - 320))) calc(13px + (30 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box {
    height: 360px;
    overflow: auto
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(85, 85, 85, 0.14)
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
    border-radius: 50px
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(85, 85, 85, 0.5)
}

@media (max-width: 575px) {
    .deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box {
        height: auto
    }
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list {
    padding-right: 0
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li {
    width: 100%
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li.list-1 .deal-offer-contain::before {
    background-color: var(--theme-color)
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li.list-2 .deal-offer-contain::before {
    background-color: #ff4f4f
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li.list-3 .deal-offer-contain::before {
    background-color: #ffa53b
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 13px;
    position: relative;
    z-index: 0;
    padding: calc(11px + (16 - 11) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12px;
    overflow: hidden
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.15
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-image img {
    width: calc(69px + (80 - 69) * ((100vw - 320px) / (1920 - 320)));
    height: calc(69px + (80 - 69) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain {
    display: block;
    color: #222
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain a {
    color: #222
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain a:hover {
    color: #222
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain a h5 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain h5 {
    font-weight: 600
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain h6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 7px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-size: 15px;
    font-weight: 400;
    margin-top: 6px
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain h6 del {
    color: #ff4f4f;
    font-weight: 400;
    font-size: 13px
}

.deal-modal .modal-dialog .modal-content .modal-body .deal-offer-box .deal-offer-list li .deal-offer-contain .deal-contain h6 span {
    color: #4a5568;
    font-size: 13px;
    font-weight: 400
}

.remove-profile .modal-dialog .modal-content .modal-header {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-bottom: 0
}

.remove-profile .modal-dialog .modal-content .modal-body .remove-box p {
    text-align: center;
    line-height: 1.6;
    margin: 0;
    color: #4a5568
}

.pickup-offcanvas {
    border: none
}

.pickup-offcanvas .offcanvas-header {
    border-bottom: 1px solid #ececec;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.pickup-offcanvas .offcanvas-header .offcanvas-title {
    font-weight: 600
}

.pickup-offcanvas .offcanvas-header .offcanvas-title span {
    display: block;
    color: #4a5568;
    font-size: 14px;
    font-weight: 400;
    margin-top: 4px
}

.pickup-offcanvas .offcanvas-body .check-box {
    margin-top: 10px
}

.pickup-offcanvas .offcanvas-body .check-box h6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    color: #4a5568
}

.pickup-offcanvas .offcanvas-body .check-box h6 i {
    font-size: 20px
}

.pickup-offcanvas .offcanvas-body .address-box {
    margin-top: 18px
}

[dir="rtl"] .pickup-offcanvas .offcanvas-body .address-box ul {
    padding-right: 0
}

.pickup-offcanvas .offcanvas-body .address-box ul li {
    display: block;
    font-size: 13px;
    color: #4a5568
}

.categories-canvas {
    width: 320px
}

.categories-canvas .offcanvas-header {
    padding: 20px 24px;
    border-bottom: 1px solid #ececec;
    -webkit-box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1);
    box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1)
}

.categories-canvas .offcanvas-header .offcanvas-title {
    color: var(--theme-color);
    font-weight: 600
}

.categories-canvas .offcanvas-header .close-button {
    padding: 0;
    margin: -10px 0;
    font-size: 16px;
    background-image: none
}

.categories-canvas .offcanvas-body {
    padding: 20px 24px;
    margin-top: 13px
}

.categories-canvas .offcanvas-body .categories-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 25px
}

[dir="rtl"] .categories-canvas .offcanvas-body .categories-list ul {
    padding-left: unset;
    padding-right: 0
}

.categories-canvas .offcanvas-body .categories-list ul li {
    display: block;
    width: 100%
}

.categories-canvas .offcanvas-body .categories-list ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

.categories-canvas .offcanvas-body .categories-list ul li a:hover h6 {
    letter-spacing: 0.3px
}

.categories-canvas .offcanvas-body .categories-list ul li a:hover h6::before {
    width: 65px
}

.categories-canvas .offcanvas-body .categories-list ul li a h6 {
    font-size: 17px;
    font-weight: 500;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.categories-canvas .offcanvas-body .categories-list ul li a h6:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.categories-canvas .offcanvas-body .categories-list ul li a i {
    margin-left: auto
}

[dir="rtl"] .categories-canvas .offcanvas-body .categories-list ul li a i {
    margin-left: unset;
    margin-right: auto
}

.product-section-box {
    border-radius: 7px;
    border: 1px solid #dedede;
    overflow: hidden;
    margin-top: calc(12px + (48 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-box .custom-nav {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    border: none;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    border-bottom: 1px solid #ececec
}

.product-section-box .custom-nav .nav-item .nav-link {
    color: #222;
    padding: 10px 22px;
    font-size: 14px;
    letter-spacing: 0.6px;
    border: none;
    font-weight: 600;
    background-color: #fff;
    border-radius: 6px 6px 0 0 !important;
    position: relative;
    border-bottom: 3px solid transparent;
}
.product-section-box .custom-nav .nav-item .nav-link:hover, .product-section-box .custom-nav .nav-item .nav-link.active{
    color:var(--theme-color)
}
.product-section-box .custom-nav .nav-item .nav-link.active{
    border-color: var(--theme-color)
}

[dir="rtl"] .product-section-box .custom-nav .nav-item .nav-link::after {
    left: unset;
    right: 0
}

.product-section-box .custom-nav .nav-item .nav-link:focus,
.product-section-box .custom-nav .nav-item .nav-link:hover,
.product-section-box .custom-nav .nav-item .nav-link.active {
    background-color: #fff;

}


.product-section-box .offer-table {
    margin-bottom: 0;
    border: 1px solid #ececec;
    table-layout: fixed;
    display: inherit;
    border-radius: 6px
}

[dir="rtl"] .product-section-box .offer-table {
    right: unset;
    left: 10px
}

.product-section-box .offer-table tbody tr th,
.product-section-box .offer-table tbody tr td {
    padding: 12px 16px
}

.product-section-box .offer-table tbody tr th {
    width: 7%
}

.product-section-box .offer-table tbody tr td {
    vertical-align: middle
}

.product-section-box .offer-table tbody tr td.offer-name {
    width: 20%
}

.product-section-box .custom-tab {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-box .custom-tab .nav-desh {
    margin-bottom: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-box .custom-tab .nav-desh:last-of-type {
    margin-bottom: 0
}

.product-section-box .custom-tab .nav-desh .desh-title {
    margin-bottom: 8px
}

.product-section-box .custom-tab .nav-desh .desh-title h5 {
    font-weight: 700
}

.product-section-box .custom-tab .product-detail .detail-title h5 {
    font-weight: 600;
    color: #4a5568;
    font-size: 17px;
    margin-bottom: 10px
}

.product-section-box .custom-tab .product-detail .detail-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 4px
}

.product-section-box .custom-tab .product-detail .detail-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    color: #4a5568;
    width: 100%
}

.product-section-box .custom-tab .product-detail .detail-list li i {
    font-size: 6px;
    color: #4a5568;
    margin: 6px 6px 0 0
}

.product-section-box .custom-tab .tab-pane .product-description p {
    margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    line-height: 1.7;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-box .custom-tab .tab-pane .product-description p:last-child {
    margin-bottom: 0
}

.product-section-box .custom-tab .information-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 7px
}

.product-section-box .custom-tab .information-box ul li {
    color: #4a5568;
    width: 100%;
    display: list-item;
    list-style-type: circle;
    margin-left: 35px;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-box .custom-tab .review-box .review-title {
    margin-bottom: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-box .custom-tab .review-box .review-people .review-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 26px
}

[dir="rtl"] .product-section-box .custom-tab .review-box .review-people .review-list {
    padding-right: 0
}

.product-section-box .custom-tab .review-box .review-people .review-list li {
    display: block;
    width: 100%
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    padding: 20px 26px;
    background: #f8f8f8
}

@media (max-width: 480px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box {
        display: block
    }
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box:hover .reply a {
    opacity: 1
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-image {
    width: 70px;
    height: 70px
}

@media (max-width: 480px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-image {
        margin: 0 auto
    }
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 575px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-image img {
        border-radius: 8px
    }
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment {
    padding-left: 15px;
    width: calc(100% - 70px)
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment .name {
    display: block;
    font-weight: 600;
    font-size: 15px
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment .name:hover {
    color: var(--theme-color)
}

[dir="rtl"] .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment {
    padding-left: unset;
    padding-right: 15px
}

@media (max-width: 480px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment {
        padding: 0;
        width: 100%;
        margin-top: 16px
    }
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment .date-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment .date-time h6 {
    font-size: 13px;
    color: #777;
    margin-top: 1px
}

@media (max-width: 360px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment .date-time {
        display: block
    }

    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .people-comment .date-time .product-rating {
        margin-top: 8px
    }
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .reply {
    margin-top: 9px;
    line-height: 1.6;
    color: #4a5568;
    position: relative
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .reply p {
    width: 90%;
    margin: 0;
    line-height: 1.6
}

@media (max-width: 480px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .reply p {
        width: 100%
    }
}

.product-section-box .custom-tab .review-box .review-people .review-list li .people-box .reply a {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .reply a {
    right: unset;
    left: 0
}

@media (max-width: 575px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .reply a {
        opacity: 1
    }
}

@media (max-width: 480px) {
    .product-section-box .custom-tab .review-box .review-people .review-list li .people-box .reply a {
        position: relative;
        margin-left: 8px
    }
}

.product-section-box .custom-tab .review-box .rating-box {
    margin-top: 17px
}

.product-section-box .custom-tab .review-box .rating-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .product-section-box .custom-tab .review-box .rating-box ul {
    padding-right: 0
}

.product-section-box .custom-tab .review-box .rating-box ul li {
    width: 100%
}

.product-section-box .custom-tab .review-box .rating-box ul li .rating-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 14px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-section-box .custom-tab .review-box .rating-box ul li .rating-list h5 {
    white-space: nowrap
}

.product-section-box .custom-tab .review-box .rating-box ul li .rating-list .progress {
    width: 100%;
    border-radius: 3px
}

.product-section-box .custom-tab .review-box .rating-box ul li .rating-list .progress .progress-bar {
    background: var(--theme-color2)
}

.product-section-box .custom-tab .info-table {
    border: 1px solid #ececec;
    margin-bottom: 0
}

.product-section-box .custom-tab .info-table.table-striped tbody tr:nth-of-type(odd) td {
    background-color: #f8f8f8;
    color: #222
}

.product-section-box .custom-tab .info-table tbody tr th,
.product-section-box .custom-tab .info-table tbody tr td {
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    white-space: nowrap
}

.product-section-box .custom-tab .info-table tbody tr td {
    border-left: 1px solid #ececec
}

.custome-pagination {
    margin-top: calc(22px + (35 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.custome-pagination .pagination {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(6px + (13 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.custome-pagination .pagination .page-item {
    border-radius: 5px;
    overflow: hidden
}

.custome-pagination .pagination .page-item:not(:first-child) .page-link {
    margin: 0
}

.custome-pagination .pagination .page-item.active .page-link {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: #fff
}

.custome-pagination .pagination .page-item .page-link {
    color: #4a5568;
    border: 1px solid transparent
}

.custome-pagination .pagination .page-item .page-link:hover {
    border-radius: 5px;
    border: 1px solid var(--theme-color);
    background-color: unset
}

.custome-pagination .pagination .page-item .page-link:focus {
    color: var(--theme-color);
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}

.ratio_30 .bg-size:before {
    content: "";
    padding-top: 30%;
    display: block
}

.ratio_35 .bg-size:before {
    content: "";
    padding-top: 35%;
    display: block
}

.ratio_45 .bg-size:before {
    content: "";
    padding-top: 45.4%;
    display: block
}

.ratio_50 .bg-size:before {
    content: "";
    padding-top: 50%;
    display: block
}

.ratio_50_1 .bg-size:before {
    content: "";
    padding-top: 50%;
    display: block
}

@media (max-width: 1660px) {
    .ratio_50_1 .bg-size:before {
        padding-top: 57%
    }
}

@media (max-width: 1199px) {
    .ratio_50_1 .bg-size:before {
        padding-top: 80%
    }
}

@media (max-width: 991px) {
    .ratio_50_1 .bg-size:before {
        padding-top: 59%
    }
}

@media (max-width: 767px) {
    .ratio_50_1 .bg-size:before {
        padding-top: 68%
    }
}

@media (max-width: 575px) {
    .ratio_50_1 .bg-size:before {
        padding-top: 77%
    }
}

@media (max-width: 480px) {
    .ratio_50_1 .bg-size:before {
        padding-top: 84%
    }
}

.ratio_58 .bg-size:before {
    content: "";
    padding-top: 58%;
    display: block
}

.ratio_60 .bg-size:before {
    content: "";
    padding-top: 60%;
    display: block
}

.ratio_65 .bg-size:before {
    content: "";
    padding-top: 65%;
    display: block
}

.ratio_87 .bg-size:before {
    content: "";
    padding-top: 87%;
    display: block
}

.ratio_square .bg-size:before {
    padding-top: 100%;
    content: "";
    display: block
}

.ratio_110 .bg-size:before {
    padding-top: 110%;
    content: "";
    display: block
}

.ratio_125 .bg-size::before {
    content: "";
    padding-top: 125%;
    display: block
}

.ratio_148 .bg-size::before {
    content: "";
    padding-top: 148%;
    display: block
}

.ratio_156 .bg-size:before {
    content: "";
    padding-top: 156%;
    display: block
}

.ratio_medium .bg-size:before {
    content: "";
    padding-top: 134%;
    display: block
}

.ratio_180 .bg-size:before {
    content: "";
    padding-top: 180%;
    display: block
}

.ratio_209 .bg-size:before {
    content: "";
    padding-top: 209%;
    display: block
}

.ratio_148_1 .bg-size {
    content: "";
    padding-top: 148%;
    display: block
}

@media (max-width: 1199px) {
    .ratio_148_1 .bg-size {
        padding-top: 88%
    }
}

.b-bottom {
    background-position: bottom !important
}

.b-top {
    background-position: top !important
}

.b-left {
    background-position: left !important
}

.b-right {
    background-position: right !important
}

.product-wrapper .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)))
}

.product-wrapper .slick-dots li {
    width: auto;
    height: auto
}

.product-wrapper .slick-dots li button {
    width: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
    height: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 4px;
    padding: 0;
    margin: 0;
    background-color: rgba(35, 35, 35, 0.2);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-wrapper .slick-dots li button::before {
    display: none
}

.product-wrapper .slick-dots li.slick-active button {
    margin: 0;
    width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    background-color: var(--theme-color);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-wrapper .timer {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    text-align: center
}

.product-wrapper.slick-slider.slick-dotted {
    margin-bottom: -2px
}

.no-arrow .slick-arrow {
    display: none !important
}

.no-space .slick-list {
    margin: 0 !important
}

.no-space .slick-list .slick-slide>div,
.no-space .slick-list .slick-slide>ul {
    margin: 0 !important
}

[dir="rtl"] .no-space .slick-list .slick-slide>div,
[dir="rtl"] .no-space .slick-list .slick-slide>ul {
    direction: rtl
}

.category-slider .slick-list {
    margin: 0
}

.category-slider .slick-list .slick-slide {
    padding: 0 10px
}

[dir="rtl"] .category-slider .slick-list .slick-slide>div,
[dir="rtl"] .category-slider .slick-list .slick-slide>ul {
    direction: rtl
}

.category-slider.slick-dotted {
    margin-bottom: 0
}

.slick-slider .slick-list {
    margin: 0 -10px
}

@media (max-width: 575px) {
    .slick-slider .slick-list {
        margin: 0 -6px
    }
}

.slick-slider .slick-list .slick-slide>div,
.slick-slider .slick-list .slick-slide>ul {
    margin: 0 10px
}

[dir="rtl"] .slick-slider .slick-list .slick-slide>div,
[dir="rtl"] .slick-slider .slick-list .slick-slide>ul {
    direction: rtl
}

@media (max-width: 575px) {

    .slick-slider .slick-list .slick-slide>div,
    .slick-slider .slick-list .slick-slide>ul {
        margin: 0 6px
    }
}

.slick-slider.slick-dotted {
    margin-bottom: 0
}

.slick-top .slick-list .slick-track>div {
    margin: 3px 0
}

.left-slider .slick-list .slick-track .slick-slide img {
    -webkit-filter: grayscale(20%) blur(1px);
    filter: grayscale(20%) blur(1px);
    opacity: 0.8
}

.left-slider .slick-list .slick-track .slick-slide.slick-current img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: grayscale(0) blur(0);
    filter: grayscale(0) blur(0);
    opacity: 1;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.16);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.16)
}

.arrow-slider .slick-prev,
.arrow-slider .slick-next {
    width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 4px rgba(34, 34, 34, 0.14);
    box-shadow: 0px 1px 4px rgba(34, 34, 34, 0.14);
    position: absolute;
    top: calc(-28px + (-43 - -28) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.arrow-slider .slick-prev::before,
.arrow-slider .slick-next::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    color: var(--theme-color);
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.arrow-slider .slick-prev:hover,
.arrow-slider .slick-next:hover {
    background-color: var(--theme-color)
}

.arrow-slider .slick-prev:hover::before,
.arrow-slider .slick-next:hover::before {
    color: #fff
}

.arrow-slider .slick-prev {
    left: unset;
    right: 60px
}

[dir="rtl"] .arrow-slider .slick-prev {
    right: unset;
    left: 0
}

.arrow-slider .slick-prev::before {
    content: "\f104"
}

@media (max-width: 575px) {
    .arrow-slider .slick-prev {
        right: 45px
    }
}

.arrow-slider .slick-next {
    right: 5px
}

[dir="rtl"] .arrow-slider .slick-next {
    right: unset;
    left: 50px
}

.arrow-slider .slick-next::before {
    content: "\f105"
}

@media (max-width: 575px) {
    [dir="rtl"] .arrow-slider .slick-next {
        left: 40px
    }
}

.arrow-slider-2 .slick-prev,
.arrow-slider-2 .slick-next {
    top: -42px
}

@media (max-width: 1460px) {

    .arrow-slider-2 .slick-prev,
    .arrow-slider-2 .slick-next {
        top: -38px
    }
}

@media (max-width: 1199px) {

    .arrow-slider-2 .slick-prev,
    .arrow-slider-2 .slick-next {
        top: -37px
    }
}

@media (max-width: 767px) {

    .arrow-slider-2 .slick-prev,
    .arrow-slider-2 .slick-next {
        top: -33px
    }
}

@media (max-width: 480px) {

    .arrow-slider-2 .slick-prev,
    .arrow-slider-2 .slick-next {
        top: -31px
    }
}

@media (max-width: 360px) {

    .arrow-slider-2 .slick-prev,
    .arrow-slider-2 .slick-next {
        top: -29px
    }
}

.arrow-slider-2 .slick-prev {
    left: unset;
    right: 50px
}

[dir="rtl"] .arrow-slider-2 .slick-prev {
    right: unset;
    left: 0
}

.arrow-slider-2 .slick-prev::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    content: "\f053";
    color: #222;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.arrow-slider-2 .slick-prev:hover::before {
    color: var(--theme-color)
}

.arrow-slider-2 .slick-next {
    right: 5px
}

[dir="rtl"] .arrow-slider-2 .slick-next {
    right: unset;
    left: 30px
}

.arrow-slider-2 .slick-next::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    content: "\f054";
    color: #222
}

.arrow-slider-2 .slick-next:hover::before {
    color: var(--theme-color)
}

.img-slider img {
    display: initial
}

.product-arrow .slick-prev,
.product-arrow .slick-next {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
    height: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ececec;
    border-radius: 5px;
    z-index: 1;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.product-arrow .slick-prev::before,
.product-arrow .slick-next::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900
}

.product-arrow .slick-prev {
    left: 0
}

.product-arrow .slick-prev::before {
    color: #000;
    content: "\f104"
}

.product-arrow .slick-prev:hover,
.product-arrow .slick-prev:focus {
    background-color: var(--theme-color)
}

.product-arrow .slick-prev:hover::before,
.product-arrow .slick-prev:focus::before {
    color: #fff
}

.product-arrow .slick-next {
    right: 0
}

[dir="rtl"] .product-arrow .slick-next {
    right: unset;
    left: 91%
}

.product-arrow .slick-next:before {
    color: #000;
    content: "\f105"
}

.product-arrow .slick-next:hover,
.product-arrow .slick-next:focus {
    background-color: var(--theme-color)
}

.product-arrow .slick-next:hover::before,
.product-arrow .slick-next:focus::before {
    color: #fff
}

.product-box-arrow .slick-prev,
.product-box-arrow .slick-next {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
    height: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 50%;
    z-index: 1;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    color: #4a5568
}

.product-box-arrow .slick-prev::before,
.product-box-arrow .slick-next::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    color: #000;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-box-arrow .slick-prev:hover,
.product-box-arrow .slick-prev:focus,
.product-box-arrow .slick-next:hover,
.product-box-arrow .slick-next:focus {
    background-color: var(--theme-color)
}

.product-box-arrow .slick-prev:hover::before,
.product-box-arrow .slick-prev:focus::before,
.product-box-arrow .slick-next:hover::before,
.product-box-arrow .slick-next:focus::before {
    color: #fff
}

.product-box-arrow .slick-prev {
    left: -28px
}

@media (max-width: 575px) {
    .product-box-arrow .slick-prev {
        left: -8px
    }
}

.product-box-arrow .slick-prev::before {
    content: "\f104"
}

.product-box-arrow .slick-next {
    right: -28px
}

@media (max-width: 575px) {
    .product-box-arrow .slick-next {
        right: -8px
    }
}

.product-box-arrow .slick-next:before {
    content: "\f105"
}

.slick-height .slick-list .slick-track .slick-slide {
    height: auto
}

.tab-style-color {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    overflow-x: auto;
    overflow-y: hidden
}

@media (max-width: 1265px) {
    .tab-style-color {
        margin-top: 13px
    }
}

@media (max-width: 1265px) {
    .tab-style-color-2 {
        margin-top: 0
    }
}

@media (max-width: 850px) {
    .tab-style-color-2 {
        margin-top: 13px;
        padding-bottom: 11px
    }
}

.tab-style-color .nav-item .nav-link {
    border: none;
    color: #222;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    white-space: nowrap;
    background-color: #fff;
    line-height: 1;
    border: 1px solid var(--theme-color);
    padding: calc(9px + (14 - 9) * ((100vw - 320px) / (1920 - 320))) calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin: 0
}

.tab-style-color .nav-item .nav-link.active,
.tab-style-color .nav-item .nav-link:hover {
    background-color: var(--theme-color);
    color: #fff
}

.tab-style-color .nav-item .nav-link img {
    margin-right: 5px
}

[dir="rtl"] .tab-style-color .nav-item .nav-link img {
    margin-right: unset;
    margin-left: 5px
}

.theme-option {
    position: fixed;
    bottom: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    right: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    z-index: 1;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

[dir="rtl"] .theme-option {
    right: unset;
    left: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 767px) {
    .theme-option {
        bottom: calc(75px + (80 - 75) * ((100vw - 320px) / (1920 - 320)))
    }
}

.theme-option .back-to-top {
    background-color: var(--theme-color);
    padding: 0;
    border-radius: 5px;
    z-index: 1;
    margin-top: 12px
}

[dir="rtl"] .theme-option .back-to-top {
    right: unset;
    left: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.theme-option .back-to-top a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)))
}

.theme-option .back-to-top a i {
    color: #fff
}

.setting-box {
    background-color: var(--theme-color);
    padding: 0;
    border-radius: 5px;
    z-index: 1;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.setting-box .setting-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding: 0;
    color: #fff
}

.setting-box .theme-setting-2 {
    position: absolute;
    bottom: calc(40px + (46 - 40) * ((100vw - 320px) / (1920 - 320)));
    right: 12px;
    width: 360px;
    background-color: #fff;
    padding: 12px;
    border-radius: 8px 8px 0 8px;
    -webkit-box-shadow: 0 4px 8px rgba(34, 34, 34, 0.12);
    box-shadow: 0 4px 8px rgba(34, 34, 34, 0.12);
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .setting-box .theme-setting-2 {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left
}

[dir="rtl"] .setting-box .theme-setting-2 {
    right: unset;
    left: 12px;
    border-radius: 8px 8px 8px 0
}

@media (max-width: 360px) {
    .setting-box .theme-setting-2 {
        width: 275px
    }
}

.setting-box .theme-setting-2.active {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right
}

[dir="rtl"] .setting-box .theme-setting-2.active {
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left
}

.setting-box .theme-setting-2 .theme-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 22px;
    gap: calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .setting-box .theme-setting-2 .theme-box ul {
    padding-right: 0
}

.setting-box .theme-setting-2 .theme-box ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding-bottom: 14px;
    gap: 20px;
    border-bottom: 1px dashed #ececec
}

.setting-box .theme-setting-2 .theme-box ul li:last-child {
    padding-bottom: 0;
    border: none
}

.setting-box .theme-setting-2 .theme-box ul li.color-box {
    display: block;
    gap: 19px
}

.setting-box .theme-setting-2 .theme-box ul li .setting-name {
    margin-bottom: 0;
    width: 15%
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    width: 85%
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button button {
    width: 100%;
    background-color: var(--theme-color);
    color: #fff;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button button.outline {
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    background-color: #fff
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button button.rtl-outline {
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    background-color: #fff
}

[dir="rtl"] .setting-box .theme-setting-2 .theme-box ul li .theme-setting-button button.rtl-outline {
    color: #fff;
    border: 1px solid transparent;
    background-color: var(--theme-color)
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button button.rtl-unline {
    color: #fff;
    border: 1px solid transparent;
    background-color: var(--theme-color)
}

[dir="rtl"] .setting-box .theme-setting-2 .theme-box ul li .theme-setting-button button.rtl-unline {
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    background-color: #fff
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button.color-picker {
    padding: 0;
    border: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button.color-picker .form-control {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 15px;
    padding: 0;
    border: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button.color-picker .form-control .form-control-color {
    width: 38px;
    height: auto;
    padding: 0;
    font-size: 13px;
    border: none
}

[dir="rtl"] .setting-box .theme-setting-2 .theme-box ul li .theme-setting-button.color-picker .form-control .form-control-color {
    max-width: 100%
}

.setting-box .theme-setting-2 .theme-box ul li .theme-setting-button.color-picker .form-control .form-label {
    width: auto;
    font-size: 16px;
    color: #4a5568
}

.timer ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .timer ul {
    padding-left: unset;
    padding-right: 0
}

@media (max-width: 360px) {
    .timer ul {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (max-width: 575px) {
    .timer ul {
        margin-top: 12px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.timer ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    position: relative;
    background-color: #ececec;
    padding: 8px;
    border-radius: 5px
}

@media (max-width: 575px) {
    .timer ul li {
        width: 40px;
        height: 40px
    }
}

.timer ul li+li {
    margin-left: 15px
}

[dir="rtl"] .timer ul li+li {
    margin-left: unset;
    margin-right: 15px
}

.timer ul li+li::after {
    content: ":";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: bolder;
    left: -10px
}

[dir="rtl"] .timer ul li+li::after {
    left: unset;
    right: -10px
}

.timer ul li .counter h4 {
    font-weight: 500
}

@media (max-width: 575px) {
    .timer ul li .counter h4 {
        font-size: 15px
    }
}

.timer-2 {
    margin-left: 40px
}

@media (max-width: 575px) {
    .timer-2 {
        margin-left: 0
    }
}

.timer-2 ul li {
    width: 60px;
    height: 60px;
    color: #000;
    border: 1px solid #222;
    background-color: transparent
}

.timer-2 ul li.bg-color {
    background-color: var(--theme-color);
    color: #fff
}

.timer-2 ul li .counter {
    font-size: 20px;
    font-weight: 500
}

.timer-2 ul li+li::after {
    color: #4a5568
}

.timer-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 360px) {
    .timer-box ul {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (max-width: 575px) {
    .timer-box ul {
        margin-top: 12px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.timer-box ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    position: relative;
    background-color: #ececec;
    padding: 8px;
    border-radius: 5px
}

@media (max-width: 575px) {
    .timer-box ul li {
        width: 40px;
        height: 40px
    }
}

.timer-box ul li+li {
    margin-left: 15px
}

.timer-box ul li+li::after {
    content: ":";
    position: absolute;
    font-weight: bolder;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -10px
}

.timer-box ul li .counter h4 {
    font-weight: 500
}

@media (max-width: 575px) {
    .timer-box ul li .counter h4 {
        font-size: 15px
    }
}

.title-leaf {
    width: 140px;
    text-align: center;
    margin-top: 7px;
    position: relative;
    z-index: 0
}

.title-leaf::before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    left: 0;
    background-color: var(--theme-color);
    z-index: -1
}

.title-leaf svg {
    background-color: #fff;
    width: 45px;
    z-index: 1;
    fill: var(--theme-color)
}

.title-leaf svg.bg-gray {
    background-color: #f8f8f8
}

.title {
    margin-bottom: 15px;
    display: inline-block;
    position: relative
}
.product-box:hover{
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.category-banner{
    border-radius: 7px;
}
.title h2 {
    font-weight: 700;
    color: #222;
    position: relative;
    font-size: 22px
}

.title p {
    line-height: 1.5;
    margin: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))) 0 0;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568
}

.title-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 1265px) {
    .title-flex {
        display: block
    }
}

.title-flex .timing-box {
    display: inline-block
}

.title-flex .timing-box .timing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ff4f4f;
    color: #fff;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(9px + (11 - 9) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px
}

@media (max-width: 1265px) {
    .title-flex .timing-box .timing {
        margin-top: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)))
    }
}

.title-flex .timing-box .timing i {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    margin-right: 9px
}

.title-flex .timing-box .timing .feather {
    width: 17px;
    height: 17px;
    margin-right: 9px
}

[dir="rtl"] .title-flex .timing-box .timing .feather {
    margin-left: 9px;
    margin-right: unset
}

.title-flex .timing-box .timing .name {
    font-weight: 600;
    margin-right: 8px;
    line-height: 1
}

[dir="rtl"] .title-flex .timing-box .timing .name {
    margin-left: 8px;
    margin-right: unset
}

.title-flex .timing-box .timing .time ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .title-flex .timing-box .timing .time ul {
    padding-left: unset;
    padding-right: 0
}

.title-flex .timing-box .timing .time ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    line-height: 1
}

.title-flex .timing-box .timing .time ul li+li {
    margin-left: 15px
}

[dir="rtl"] .title-flex .timing-box .timing .time ul li+li {
    margin-left: unset;
    margin-right: 15px
}

.title-flex .timing-box .timing .time ul li+li::after {
    content: ":";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: bolder;
    left: -10px
}

[dir="rtl"] .title-flex .timing-box .timing .time ul li+li::after {
    left: unset;
    right: -10px
}

.title-flex .timing-box .timing .time ul li .counter h6 {
    font-weight: 600
}

.title .product-tabs {
    border: none;
    position: absolute;
    top: 0;
    right: 0
}

.title .product-tabs .nav-item {
    position: relative
}

.title .product-tabs .nav-item .nav-link {
    border: none;
    border-radius: unset;
    color: #4a5568;
    position: relative;
    font-weight: 400;
    font-size: 16px
}

.title .product-tabs .nav-item .nav-link.active {
    font-weight: 500;
    color: var(--theme-color)
}

.title .product-tabs .nav-item .nav-link.active::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: 0;
    left: 17px;
    border-radius: 50px;
    background-color: var(--theme-color)
}

.title .product-tabs .nav-item .nav-link.active::after {
    content: "";
    position: absolute;
    width: 4px;
    height: 2px;
    bottom: 0;
    left: 75px;
    border-radius: 50px;
    background-color: var(--theme-color)
}

.title .product-tabs .nav-item+.nav-item {
    margin-left: 10px
}

.title .product-tabs .nav-item+.nav-item::before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    top: 9px;
    left: -11px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

.title-border {
    border-bottom: 1px solid #ececec;
    padding-bottom: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-title h5 {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 10px;
    letter-spacing: 0.5px
}

.vendor-title p {
    margin-bottom: 0;
    font-size: 15px;
    color: #4a5568;
    line-height: 1.6
}

.seller-title h2 {
    font-weight: 700;
    font-size: 37px;
    margin-bottom: 23px;
    letter-spacing: 0.5px;
    text-transform: uppercase
}

.seller-title p {
    margin-bottom: 0;
    font-size: 15px;
    color: #4a5568;
    line-height: 1.6
}

.title-flex-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 850px) {
    .title-flex-2 {
        display: block
    }
}

.title-flex-2 .timing-box {
    display: inline-block
}

.title-flex-2 .timing-box .timing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ff4f4f;
    color: #fff;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(9px + (11 - 9) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px
}

@media (max-width: 850px) {
    .title-flex-2 .timing-box .timing {
        margin-top: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)))
    }
}

.title-flex-2 .timing-box .timing i {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    margin-right: 9px
}

.title-flex-2 .timing-box .timing .feather {
    width: 17px;
    height: 17px;
    margin-right: 9px
}

[dir="rtl"] .title-flex-2 .timing-box .timing .feather {
    margin-left: 9px;
    margin-right: unset
}

.title-flex-2 .timing-box .timing .name {
    font-weight: 600;
    margin-right: 8px;
    line-height: 1
}

[dir="rtl"] .title-flex-2 .timing-box .timing .name {
    margin-left: 8px;
    margin-right: unset
}

.tooltip {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear
}

.tooltip .tooltip-arrow::before {
    border-top-color: var(--theme-color)
}

.tooltip .tooltip-inner {
    font-weight: 500;
    background-color: var(--theme-color);
    color: #fff
}

.col-custome-3 {
    width: 22%
}

@media (max-width: 1399px) {
    .col-custome-3 {
        width: 25%
    }
}

@media (max-width: 1199px) {
    .col-custome-3 {
        width: 30%
    }
}

@media (max-width: 991px) {
    .col-custome-3 {
        width: 0
    }
}

.col-custome-9 {
    width: 78%
}

@media (max-width: 1399px) {
    .col-custome-9 {
        width: 75%
    }
}

@media (max-width: 1199px) {
    .col-custome-9 {
        width: 70%
    }
}

@media (max-width: 991px) {
    .col-custome-9 {
        width: 100%
    }
}

.fw-300 {
    font-weight: 300
}

.w-60 {
    width: 60% !important
}

.section-small-space {
    padding: calc(18px + (42 - 18) * ((100vw - 320px) / (1920 - 320))) 0
}

.section-big-space {
    padding: calc(40px + (160 - 40) * ((100vw - 320px) / (1920 - 320))) 0
}

.section-lg-space {
    padding: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320))) 0
}

section,
.section-t-space {
    padding: 20px 0 !important
}

.section-b-space {
    padding-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)))
}

.container-fluid-lg {
    padding:0 calc(12px + (300 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.container-fluid-md {
    padding: 0 calc(12px + (50 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.container-fluid-xs {
    padding: 0 calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.section-small-space {
    padding: 30px 0
}
.hr-a {
    position:relative;
    margin-top: 40px;
}
.hr-a hr{
    background-color:rgba(0,0,0,0.5);
}
.hr-a a{
    position:absolute;
    right: 0;
    font-size: 14px;
    padding-left: 15px;
    background:#fff;
    top:  -10px;
}
.p-top-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

[dir="rtl"] .p-top-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.p-top-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.p-top-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.p-center-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

[dir="rtl"] .p-center-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.p-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.p-center-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

[dir="rtl"] .p-center-right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.p-bottom-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

[dir="rtl"] .p-bottom-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.p-bottom-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.p-bottom-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.p-sticky {
    position: sticky;
    top: 10px
}

.ls-expanded {
    letter-spacing: 12px;
    margin-right: -12px
}

.ls-resize-expanded {
    letter-spacing: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.g-8 {
    margin: -8px
}

.g-8>div {
    padding: 8px
}

.icon-width {
    width: 35px;
    height: 35px
}
ol, ul, li{
    padding: 0;
    margin: 0;
    list-style-type: none
}
.rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 360px) {
    .rating {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

[dir="rtl"] .rating {
    padding-left: unset;
    padding-right: 0
}

.rating li {
    line-height: 0
}

.rating li+li {
    margin-left: 2px
}

[dir="rtl"] .rating li+li {
    margin-left: unset;
    margin-right: 2px
}

.rating li .feather {
    width: 14px;
    height: 14px;
    stroke: #ffb321
}

.rating li .feather.fill {
    fill: #ffb321
}

.product-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-rating span {
    color: #4a5568;
    font-size: 12px;
    margin-left: 5px
}

[dir="rtl"] .product-rating span {
    margin-left: unset;
    margin-right: 5px
}

.mend-auto {
    margin-right: auto !important;
    text-align: left
}

.mstart-auto {
    margin-left: auto !important;
    text-align: left
}

.fw-500 {
    font-weight: 500
}

.text-theme {
    color: var(--theme-color)
}

.text-title {
    color: #222 !important
}

.text-content {
    color: #4a5568
}

.text-yellow {
    color: #ffb321 !important
}

.text-danger {
    color: #ff4f4f !important
}

.bg-gray {
    background-color: #f8f8f8 !important
}

.bg-theme {
    background-color: var(--theme-color)
}

.bg-gradient-color {
    background: linear-gradient(149.8deg, #f8f8fb 17.21%, #f3f5f9 79.21%)
}

.orange-color {
    color: #ffbc5d !important
}


.w-58 {
    width: 58% !important
}

.colorpick-eyedropper-input-trigger {
    display: none
}

.hover-effect:hover {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s
}
ol, ul{
    margin: 0 !important;
    padding: 0;
    padding-left: 0 !important;
}
body {
    font-family: "Public Sans", sans-serif;
    position: relative;
    font-size: 14px;
    color: #222;
    margin: 0;
    background-color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding-right: 0 !important;
    overflow-x: hidden;
}

body ::-moz-selection {
    color: #fff;
    background-color: var(--theme-color)
}

body ::selection {
    color: #fff;
    background-color: var(--theme-color)
}

.bg-overlay {
    width: 100vw;
    height: 100vh;
    background-color: #222;
    position: fixed;
    z-index: 9;
    top: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s
}

.bg-overlay.show {
    visibility: visible;
    opacity: 0.5
}


span {
    display: inline-block
}

.theme-color {
    color: var(--theme-color) !important
}

.theme-bg-color {
    background: var(--theme-color) !important
}

.banner-contain {
    border-radius: 5px;
    overflow: hidden;
    position: relative
}

[dir="rtl"] .banner-contain .banner-details {
    text-align: left
}

.banner-contain .banner-details.banner-b-space {
    padding: calc(27px + (45 - 27) * ((100vw - 320px) / (1920 - 320))) calc(20px + (140 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.banner-contain .banner-details .banner-box {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(30px + (39 - 30) * ((100vw - 320px) / (1920 - 320))) calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 0px 60px 60px 0px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    -webkit-box-shadow: 0px 11px 10px rgba(74, 85, 104, 0.06);
    box-shadow: 0px 11px 10px rgba(74, 85, 104, 0.06)
}

.banner-contain .banner-details .banner-box h5 {
    margin: calc(4px + (6 - 4) * ((100vw - 320px) / (1920 - 320))) 0;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.banner-contain .banner-details .banner-button {
    position: absolute;
    bottom: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    left: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #fff;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.banner-contain .banner-details h2 {
    font-size: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.banner-contain .banner-details h2.banner-title {
    font-size: calc(31px + (50 - 31) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700
}

.banner-contain .banner-details h3 {
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-top: -7px
}

.banner-contain .banner-details h4 {
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6
}

.banner-contain .banner-details .coupon-code {
    padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) calc(9px + (15 - 9) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--theme-color-rgb), 0.4);
    border: 2px dashed rgba(255, 255, 255, 0.5);
    display: inline-block;
    font-weight: 500;
    margin-top: 13px
}

.banner-contain .banner-details .coupon-code-white {
    background-color: rgba(255, 255, 255, 0.2)
}

.banner-contain .banner-details .coupon-code.code-2 {
    background-color: rgba(153, 105, 31, 0.5)
}

.banner-contain .banner-details p {
    color: #4a5568;
    line-height: 1.5
}

[dir="rtl"] .banner-contain .banner-details p {
    margin-right: auto
}

.banner-contain .banner-details p.banner-text {
    width: 62%
}

@media (max-width: 575px) {
    .banner-contain .banner-details p.banner-text {
        width: 80%
    }
}

@media (max-width: 480px) {
    .banner-contain .banner-details p.banner-text {
        width: 100%
    }
}

.banner-contain .banner-details .banner-timing {
    width: 100%;
    height: 210px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(181.97deg, rgba(0, 0, 0, 0.3) 1.66%, rgba(0, 0, 0, 0) 95.27%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    text-align: center;
    color: #fff
}

.banner-contain .banner-details .banner-timing .time {
    margin-top: 25px
}

.banner-contain .banner-details .banner-timing .time ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.banner-contain .banner-details .banner-timing .time ul li .counter {
    width: 55px;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px dashed #fff
}

.banner-contain .banner-details .banner-timing .time ul li .counter h5 {
    font-weight: 600;
    margin-bottom: 4px
}

.banner-contain-2 {
    position: relative;
    border-radius: 5px;
    overflow: hidden
}

.banner-contain-2 .banner-detail {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: calc(14px + (25 - 14) * ((100vw - 320px) / (1920 - 320))) calc(19px + (25 - 19) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .banner-contain-2 .banner-detail {
    left: unset;
    right: 0;
    text-align: left
}

.banner-contain-2 .banner-detail .banner-detail-box {
    position: relative;
    padding: 7px 0
}

.banner-contain-2 .banner-detail .banner-detail-box::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: -10px;
    background-color: var(--theme-color)
}

.banner-contain-2 .banner-detail .banner-detail-box-2 {
    padding: 7px 0 7px 17px
}

.banner-contain-2 .banner-detail .banner-detail-box-2::before {
    width: 5px;
    border-radius: 50px;
    left: 0
}

[dir="rtl"] .banner-contain-2 .banner-detail .banner-detail-box-2::before {
    left: 0;
    right: unset
}

.banner-contain-2 .banner-detail h3 {
    font-size: calc(27px + (34 - 27) * ((100vw - 320px) / (1920 - 320)))
}

.banner-contain-2 .banner-detail h3 .brand-name {
    font-weight: bold;
    background-color: var(--theme-color);
    color: #fff;
    padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    z-index: 0
}

.banner-contain-2 .banner-detail h3 .brand-name::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(3px + (7 - 3) * ((100vw - 320px) / (1920 - 320)));
    left: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
    border: calc(1px + (2 - 1) * ((100vw - 320px) / (1920 - 320))) solid #222;
    z-index: -1
}

.banner-contain-2 .banner-detail h2 {
    font-size: calc(42px + (59 - 42) * ((100vw - 1199px) / (1920 - 1199)));
    font-weight: 700
}

.banner-contain-2 .banner-detail h4 {
    font-weight: 600;
    font-size: calc(17px + (21 - 17) * ((100vw - 320px) / (1920 - 320)));
    color: #222
}

.banner-contain-2 .banner-detail-2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: calc(14px + (25 - 14) * ((100vw - 320px) / (1920 - 320))) calc(19px + (25 - 19) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .banner-contain-2 .banner-detail-2 {
    left: unset;
    right: 0
}

.banner-contain-2 .banner-detail-2>div {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(24.21%, rgba(255, 255, 255, 0.2)), color-stop(75.22%, rgba(255, 255, 255, 0.2)));
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 24.21%, rgba(255, 255, 255, 0.2) 75.22%);
    padding: 22px;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px)
}

.banner-contain-2 .banner-detail-2 h3 {
    font-size: calc(27px + (36 - 27) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #fff
}

.banner-contain-2 .banner-detail-2 h2 {
    font-size: calc(42px + (59 - 42) * ((100vw - 1199px) / (1920 - 1199)));
    font-weight: 700
}

.banner-contain-2 .banner-detail-2 button {
    background-color: var(--theme-color);
    color: #fff;
    margin: 9px auto 0
}

.banner-contain-3 {
    border-radius: 10px;
    overflow: hidden;
    position: relative
}

.banner-contain-3 .flower-pot {
    position: absolute;
    bottom: 0;
    right: -59px
}

@media (max-width: 1199px) {
    .banner-contain-3 .flower-pot {
        display: none
    }
}

.banner-contain-3 .social-image {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.banner-contain-3 .delivery-bike {
    text-align: center
}

.banner-contain-3 .delivery-bike img {
    margin-top: -16px
}

.banner-contain-3.banner-contain-bg {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: block
}

.banner-contain-3 .banner-detail-2 h2 {
    font-size: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px;
    text-transform: none
}

.banner-contain-3 .banner-detail-2 h3 {
    font-size: 35px
}

.banner-contain-3 .banner-detail-2 h4 {
    font-size: 40px
}

.banner-contain-3 .banner-minus-position {
    margin-top: -13px
}

@media (max-width: 575px) {
    .banner-contain-3 .banner-minus-position {
        margin-top: 0
    }
}

@media (max-width: 1660px) {
    .banner-contain-3 .banner-detail-deliver {
        width: 60% !important;
        margin-left: auto
    }
}

@media (max-width: 575px) {
    .banner-contain-3 .banner-detail-deliver {
        width: 100% !important;
        background: #eaf5f5
    }
}

.banner-contain-3 .banner-detail {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.banner-contain-3 .banner-detail.banner-half-width {
    width: 50%
}

.banner-contain-3 .banner-detail.banner-p-sm {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))) !important
}

.banner-contain-3 .banner-detail h2 {
    font-size: calc(25px + (45 - 25) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 3px
}

.banner-contain-3 .banner-detail h2.sale-name {
    font-size: calc(25px + (38 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.banner-contain-3 .banner-detail h3 {
    font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px;
    line-height: 1.4
}

.banner-contain-3 .banner-detail h3.banner-contain {
    font-size: 33px;
    font-weight: bold;
    margin-bottom: 0
}

@media (max-width: 1500px) {
    .banner-contain-3 .banner-detail h3.banner-contain {
        font-size: 20px
    }
}

.banner-contain-3 .banner-detail h4 {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 5px;
    line-height: 1.4
}

.banner-contain-3 .banner-detail h4.furniture-title {
    position: relative;
    display: inline-block
}

.banner-contain-3 .banner-detail h4.furniture-title img {
    position: absolute;
    top: -26px;
    right: -185px
}

@media (max-width: 1199px) {
    .banner-contain-3 .banner-detail h4.furniture-title img {
        display: none
    }
}

@media (max-width: 1500px) {
    .banner-contain-3 .banner-detail h4 .delivery-contain {
        font-size: 16px
    }
}

.banner-contain-3 .banner-detail h5 {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px
}

.banner-contain-3 .banner-detail h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.banner-contain-3 .banner-detail p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
    line-height: 1.5;
    color: #4a5568;
    letter-spacing: 0.8px
}

.banner-contain-3 .banner-detail .banner-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px
}

[dir="rtl"] .banner-contain-3 .banner-detail .banner-list {
    padding-right: 0
}

.banner-contain-3 .banner-detail .banner-list li {
    display: block;
    width: 100%
}

.banner-contain-3 .banner-detail .banner-list li .delivery-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 11px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.banner-contain-3 .banner-detail .banner-list li .delivery-box .check-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: #6bbe66;
    color: #fff;
    border-radius: 100%;
    font-size: 11px
}

@media (max-width: 1500px) {
    .banner-contain-3 .banner-detail .banner-list li .delivery-box .check-contain h5 {
        font-size: 14px
    }
}

.banner-contain-3 .banner-detail .shop-now-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    color: #fff;
    font-size: 15px
}

.banner-contain-3 .banner-detail .shop-now-button i {
    font-size: 14px;
    margin-left: 8px;
    margin-top: -2px
}

[dir="rtl"] .banner-contain-3 .banner-detail .shop-now-button i {
    margin-left: unset;
    margin-right: 8px
}

.offer-banner {
    border-radius: 15px;
    overflow: hidden;
    position: relative
}

.offer-banner:hover .banner-detail {
    border-radius: 5px
}

.offer-banner:hover .banner-detail::after {
    opacity: 1
}

.offer-banner .banner-detail {
    position: absolute;
    top: calc(9px + (18 - 9) * ((100vw - 320px) / (1920 - 320)));
    left: calc(9px + (18 - 9) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) calc(21px + (26 - 21) * ((100vw - 320px) / (1920 - 320))) calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320))) calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px 30px 30px 5px;
    -webkit-box-shadow: 0px 3px 4px rgba(34, 34, 34, 0.14);
    box-shadow: 0px 3px 4px rgba(34, 34, 34, 0.14);
    z-index: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.95)
}

.offer-banner .banner-detail h5 {
    letter-spacing: 0.6px;
    margin-bottom: 3px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.offer-banner .banner-detail h6 {
    letter-spacing: 1.7px;
    color: #4a5568
}

.offer-banner .offer-box {
    position: absolute;
    bottom: calc(7px + (18 - 7) * ((100vw - 320px) / (1920 - 320)));
    right: calc(7px + (18 - 7) * ((100vw - 320px) / (1920 - 320)));
    background-color: var(--theme-color);
    border-radius: 50px
}

.offer-banner .offer-box a {
    color: #fff;
    padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320))) calc(15px + (21 - 15) * ((100vw - 320px) / (1920 - 320)));
    display: block
}

.offer-banner .offer-box a span {
    letter-spacing: 1.5px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.bank-section .bank-offer {
    border-radius: calc(9px + (20 - 9) * ((100vw - 320px) / (1920 - 320)));
    overflow: hidden
}

.bank-section .bank-offer:hover .bank-footer-1 .bank-coupon::after {
    top: 0;
    height: 100%
}

.bank-section .bank-offer:hover .bank-footer-2 .bank-coupon::after {
    top: 0;
    height: 100%
}

.bank-section .bank-offer:hover .bank-footer-3 .bank-coupon::after {
    top: 0;
    height: 100%
}

.bank-section .bank-offer .bank-header {
    background: linear-gradient(76.68deg, #e6e6e6 -9.39%, #fafafa 78.76%, #f0f0f0 101.45%, #fff 112.78%);
    padding: calc(21px + (35 - 21) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.bank-section .bank-offer .bank-header .bank-left .bank-image {
    width: 120px;
    height: auto;
    margin-bottom: 12px
}

.bank-section .bank-offer .bank-header .bank-left .bank-name h2 {
    color: #bb0032;
    font-weight: 700;
    margin-bottom: 7px;
    font-size: calc(22px + (31 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.bank-section .bank-offer .bank-header .bank-left .bank-name h2.bank-offer-2 {
    color: #239bf0
}

.bank-section .bank-offer .bank-header .bank-left .bank-name h2.bank-offer-3 {
    color: #e73718
}

.bank-section .bank-offer .bank-header .bank-left .bank-name h5 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.bank-section .bank-offer .bank-header .bank-left .bank-name h5.valid {
    margin-top: calc(22px + (69 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.bank-section .bank-offer .bank-footer {
    padding: calc(7px + (17 - 7) * ((100vw - 320px) / (1920 - 320))) calc(13px + (26 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.bank-section .bank-offer .bank-footer-1 {
    background: linear-gradient(85.8deg, #bb0032 -4.64%, #ff584d 109.73%, #ff8c75 131.73%)
}

.bank-section .bank-offer .bank-footer-1 .bank-coupon {
    background: linear-gradient(85.8deg, #ff584d -4.64%, rgba(187, 0, 50, 0.1) 131.73%)
}

.bank-section .bank-offer .bank-footer-1 .bank-coupon::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #ff584d;
    background: linear-gradient(85.8deg, rgba(187, 0, 50, 0.1) 131.73%, #ff584d -4.64%)
}

.bank-section .bank-offer .bank-footer-2 {
    background: -webkit-gradient(linear, left top, right top, color-stop(15.92%, #2395e8), color-stop(49.57%, #4eb5ff));
    background: linear-gradient(90deg, #2395e8 15.92%, #4eb5ff 49.57%)
}

.bank-section .bank-offer .bank-footer-2 .bank-coupon {
    background: -webkit-gradient(linear, left top, right top, color-stop(15.92%, #2395e8), color-stop(49.57%, #4eb5ff));
    background: linear-gradient(90deg, #2395e8 15.92%, #4eb5ff 49.57%)
}

.bank-section .bank-offer .bank-footer-2 .bank-coupon::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #4eb5ff;
    background: -webkit-gradient(linear, left top, right top, color-stop(15.92%, #4eb5ff), color-stop(49.57%, #2395e8));
    background: linear-gradient(90deg, #4eb5ff 15.92%, #2395e8 49.57%)
}

.bank-section .bank-offer .bank-footer-3 {
    background: linear-gradient(85.8deg, #ffb321 -4.64%, #ff584d 109.73%, #ff8c75 131.73%)
}

.bank-section .bank-offer .bank-footer-3 .bank-coupon {
    background: linear-gradient(85.8deg, #ffb321 -4.64%, #ff584d 109.73%, #ff8c75 131.73%)
}

.bank-section .bank-offer .bank-footer-3 .bank-coupon::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: #ffb321;
    background: -webkit-gradient(linear, right top, left top, color-stop(-4.64%, rgba(255, 140, 117, 0)), color-stop(109.73, rgba(255, 88, 77, 0.1)), color-stop(131.73%, #ffb321));
    background: linear-gradient(-90deg, rgba(255, 140, 117, 0) -4.64%, rgba(255, 88, 77, 0.1) 109.73, #ffb321 131.73%)
}

.bank-section .bank-offer .bank-footer h4 {
    font-weight: 500;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.bank-section .bank-offer .bank-footer h4 input {
    pointer-events: none;
    background-color: transparent;
    color: #fff;
    border: none;
    font-weight: 600;
    width: 60%
}

.bank-section .bank-offer .bank-footer .bank-coupon {
    margin-left: auto;
    color: #fff;
    font-weight: 600;
    padding: 6px 21px;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 0
}

[dir="rtl"] .bank-section .bank-offer .bank-footer .bank-coupon {
    margin-left: unset;
    margin-right: auto
}

.shop-banner {
    width: 52% !important
}

@media (max-width: 575px) {
    .shop-banner {
        width: 100% !important
    }
}

.shop-banner>div {
    padding: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
    background: var(--theme-color);
    color: #fff;
    border-radius: 9px
}

.shop-banner>div h2 {
    font-size: calc(21px + (37 - 21) * ((100vw - 320px) / (1920 - 320))) !important;
    font-weight: 700 !important;
    line-height: 1.2
}

.shop-banner>div h3 {
    font-size: calc(19px + (30 - 19) * ((100vw - 320px) / (1920 - 320))) !important;
    margin-top: 18px;
    margin-bottom: -2px
}

@media (max-width: 575px) {
    .banner-small.ratio_65 .bg-size:before {
        padding-top: 53%
    }
}

.banner-furniture>.row {
    direction: ltr
}

@media (min-width: 1400px) {
    .banner-furniture>.row>div {
        margin-left: 16.6666666667%
    }
}

@media (min-width: 1400px) {
    .banner-furniture>.row>div {
        margin-left: 8.3333333333%
    }
}

.blog-box:hover .blog-box-image a {
    -webkit-transform: scale(1.12) rotate(3deg);
    transform: scale(1.12) rotate(3deg);
    overflow: hidden
}

.blog-box:hover .blog-detail h5 {
    color: var(--theme-color)
}

.blog-box .blog-box-image {
    overflow: hidden;
    border-radius: 5px
}

.blog-box .blog-box-image .blog-image {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-box .blog-detail {
    margin-top: 15px;
    display: block
}

.blog-box .blog-detail.blog-contain {
    background-color: #ff4f4f
}

.blog-box .blog-detail h6 {
    color: #4a5568;
    margin-bottom: 3px;
    font-weight: 600
}

.blog-box .blog-detail h5 {
    color: #222;
    line-height: 1.5;
    font-weight: 600;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .blog-box {
    padding-bottom: 1px;
    border-radius: 10px;
    border: 1px solid #ececec;
    overflow: hidden;
    position: relative
}

.blog-section .blog-box:hover .blog-box-image a {
    -webkit-transform: scale(1.12) rotate(3deg);
    transform: scale(1.12) rotate(3deg);
    overflow: hidden
}

.blog-section .blog-box:hover .blog-detail label {
    color: #fff
}

.blog-section .blog-box:hover .blog-detail label::before {
    opacity: 1
}

.blog-section .blog-box .blog-box-image {
    overflow: hidden;
    border-radius: 0
}

.blog-section .blog-box .blog-box-image a {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .blog-box .blog-image {
    position: relative
}

.blog-section .blog-box .blog-image label {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 5px;
    border: none;
    padding: 8px 10px;
    font-size: 12px;
    letter-spacing: 0.9px;
    -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2)
}

.blog-section .blog-box .blog-detail {
    padding: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 0
}

.blog-section .blog-box .blog-detail h2 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.4
}

.blog-section .blog-box .blog-detail label {
    font-size: 12px;
    padding: 7px 11px;
    border-radius: 50px;
    color: var(--theme-color);
    margin-bottom: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    overflow: hidden;
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .blog-box .blog-detail label::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: .1;
    z-index: -1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .blog-box .blog-detail a {
    color: #222;
    display: block
}

.blog-section .blog-box .blog-detail h3 {
    line-height: 1.4;
    margin-bottom: 5px;
    font-weight: 600
}

.blog-section .blog-box .blog-detail h5 {
    font-size: 15px;
    margin-top: 10px;
    font-weight: 500;
    color: #4a5568
}

.blog-section .blog-box .blog-detail .blog-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 15px;
    gap: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.blog-section .blog-box .blog-detail .blog-list span {
    color: #4a5568;
    margin-bottom: -5px;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.blog-section .blog-box .blog-detail .blog-list .blog-social-icon {
    position: relative
}

.blog-section .blog-box .blog-detail .blog-list .blog-social-icon .icon {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0
}

.blog-section .blog-box .blog-detail .blog-list .blog-social-icon .icon li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .blog-box .blog-detail .blog-list .blog-social-icon .icon li .fab {
    font-size: 14px;
    color: #4a5568
}

.blog-section .blog-box .blog-detail .blog-list .blog-social-icon:hover .icon {
    opacity: 1
}

.category-box {
    background-color: #f8f8f8;
    margin: 0px auto;
    display: block;
    width: 90%;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    padding: 20px 10px;
    z-index: 0
}

.category-box.category-bg {
    background: linear-gradient(149.8deg, #f6f5f2 17.21%, #fbfaf9 79.21%)
}

.category-box::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../../../public/assets/images/vegetable/shape.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--theme-color);
    opacity: 0;
    z-index: -1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-box:hover {
    background-color: transparent
}

.category-box:hover::after {
    opacity: 1
}

.category-box:hover img {
    -webkit-filter: invert(1) brightness(100);
    filter: invert(1) brightness(100)
}

.category-box:hover h5 {
    color: #fff
}

.category-box-2::after {
    background-image: url(../../../public/assets/images/cake/bg.jpg)
}

.category-box img {
    width: calc(38px + (45 - 38) * ((100vw - 320px) / (1920 - 320)));
    height: calc(38px + (45 - 38) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain;
    margin: 0 auto
}

.category-box h5 {
    color: #222;
    margin-top: 12px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: 13px;
}

.shop-box .shop-category-box {
    padding: 20px 10px;
    background-color: #f8f8f8;
    display: block;
    text-align: center;
    border-radius: 0;
    margin: 0 !important;
    border-right: 1px solid #ececec
}

.shop-box .shop-category-box a {
    display: block;
    width: 100%
}

.shop-box .shop-category-box a .shop-category-image {
    width: 68px;
    height: 68px;
    display: inline-block;
    background-color: #f8f8f8;
    padding: 8px;
    border-radius: 7px
}

.shop-box .shop-category-box a .shop-category-image img {
    width: 100%;
    height: 100%
}

.shop-box .shop-category-box a .category-box-name {
    position: relative;
    width: 90%;
    margin: 10px auto 0;
    background-color: #f8f8f8;
    color: #222;
    border-radius: 4px
}

.shop-box .shop-category-box a .category-box-name h6 {
    color: #4a5568;
    font-size: 14px;
    font-weight: 500;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.deal-section .deal-box {
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.deal-section .deal-box:hover .category-image img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
}

@media (max-width: 575px) {
    .deal-section .deal-box {
        display: block
    }
}

.deal-section .deal-box .category-image {
    width: 60%
}

@media (max-width: 575px) {
    .deal-section .deal-box .category-image {
        width: 100%
    }
}

.deal-section .deal-box .category-image img {
    width: 80%;
    margin-left: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .deal-section .deal-box .category-image img {
    margin-left: unset;
    margin-right: auto
}

@media (max-width: 575px) {
    .deal-section .deal-box .category-image img {
        width: 60%;
        margin: 0 auto 30px
    }
}

.deal-section .deal-box .buy-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    right: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    background-color: var(--theme-color);
    padding: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px
}

[dir="rtl"] .deal-section .deal-box .buy-box {
    right: unset;
    left: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.deal-section .deal-box .buy-box .iconly-Buy {
    font-size: 24px
}

.deal-section .deal-box .deal-detail {
    width: 65%
}

@media (max-width: 575px) {
    .deal-section .deal-box .deal-detail {
        width: 100%
    }
}

.deal-section .deal-box .deal-detail .hot-deal {
    background-color: var(--theme-color);
    display: inline-block;
    padding: 3px 10px;
    border-radius: 50px;
    font-size: 11px;
    color: #fff;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
    text-transform: uppercase
}

.deal-section .deal-box .deal-detail .hot-deal span {
    line-height: 2.1
}

.deal-section .deal-box .deal-detail h5 {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin: 10px 0 8px;
    text-transform: capitalize;
    font-weight: 500
}

.deal-section .deal-box .deal-detail .price {
    color: var(--theme-color);
    font-weight: 500;
    margin-bottom: 10px
}

.deal-section .deal-box .deal-detail .price span {
    color: #4a5568;
    text-decoration: line-through;
    font-weight: 400;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease
}

.deal-section .deal-box .deal-detail .custom-progressbar {
    border-radius: 50px;
    height: 10px
}

.deal-section .deal-box .deal-detail .custom-progressbar .progress-bar {
    background: var(--theme-color2);
    border-radius: 50px
}

.deal-section .deal-box .deal-detail .item {
    color: #4a5568;
    margin-top: 18px
}

.deal-section .deal-box .deal-detail .item span {
    color: #222;
    font-weight: 500
}

.deal-section .deal-box .deal-detail .timer {
    margin-top: 15px
}

.deal-section .deal-box .deal-detail .timer ul li .counter {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.deal-section .deal-box .deal-detail .timer ul li .counter>div {
    line-height: 1
}

.deal-section .deal-box .offer {
    color: #4a5568;
    margin: 5px 0 12px
}

.category-section-2 .category-slider .shop-category-box {
    position: relative;
    padding-top: 14px
}

.category-section-2 .category-slider .shop-category-box:hover a::before {
    border-radius: 11px
}

.category-section-2 .category-slider .shop-category-box:hover a img {
    -webkit-transform: scale(1.04);
    transform: scale(1.04)
}

.category-section-2 .category-slider .shop-category-box a {
    display: block;
    position: relative
}

.category-section-2 .category-slider .shop-category-box a.circle-1::before {
    background-color: #fff9d8
}

.category-section-2 .category-slider .shop-category-box a.circle-2::before {
    background-color: #fff2ec
}

.category-section-2 .category-slider .shop-category-box a.circle-3::before {
    background-color: #fce9e9
}

.category-section-2 .category-slider .shop-category-box a.circle-4::before {
    background-color: #fcf1ff
}

.category-section-2 .category-slider .shop-category-box a::before {
    content: "";
    position: absolute;
    top: -14px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-section-2 .category-slider .shop-category-box a img {
    position: relative;
    width: calc(87px + (110 - 87) * ((100vw - 320px) / (1920 - 320)));
    margin: 0 auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-section-2 .category-slider .shop-category-box .category-name {
    position: relative;
    text-align: center;
    z-index: 0;
    margin: 16px auto 0
}

.category-section-2 .category-slider .shop-category-box .category-name h6 {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: 0 auto;
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.7px;
    line-height: 1.3;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-line-clamp: 3;
    margin-bottom: 0;
    font-weight: 500
}

.category-box-list {
    padding: 20px;
    border-radius: 15px;
    background-color: #f1f1f3;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 0
}

.category-box-list::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--theme-color-rgb), 0.15);
    z-index: -1;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-box-list:hover {
    background-color: #fff
}

.category-box-list:hover::after {
    opacity: 1
}

.category-box-list:hover .category-name h4 {
    font-weight: 700;
    color: var(--theme-color)
}

.category-box-list:hover .category-name h6 {
    color: #222
}

.category-box-list:hover .category-box-view .shop-button {
    opacity: 1;
    bottom: 36px
}

.category-box-list:hover a img {
    opacity: 0.7;
    -webkit-transform: scale(1.03);
    transform: scale(1.03)
}

.category-box-list .category-box-view .shop-button {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: var(--theme-color);
    font-size: 14px;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
    position: absolute;
    bottom: 36px;
}

.category-box-list .category-box-view .shop-button i {
    margin-left: 6px;
    font-size: 12px
}

.category-box-list .category-name {
    margin-bottom: 20px;
    color: #222;
    display: block
}

.category-box-list .category-name h4 {
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-box-list .category-name h6 {
    margin-top: 5px;
    color: #4a5568;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-box-list a {
    display: block;
    position: relative
}

.category-box-list a img {
    margin: 0 auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-section-4 .shop-category-box {
    text-align: center
}

.category-section-4 .shop-category-box img {
    width: 120px;
    height: 120px;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 25px;
    margin: 0 auto;
    border: 2px solid #fff
}

.category-section-4 .shop-category-box .category-name {
    margin-top: 14px
}

.category-section-4 .shop-category-box .category-name h6 {
    font-size: 16px;
    color: #fff;
    font-weight: 500
}

footer {
    background-color: #f8f8f8;
    position: relative;
    z-index: 0;
    border-top: 1px solid #dedede;
}


@media (max-width: 1199px) {
    footer::after {
        content: none
    }
}

footer:before {
    content: "";
    position: absolute;
    width: 30%;
    height: 100%;
    bottom: 0;
    right: 0;
    background-image: url(../../../public/assets/images/vegetable/footer-shape-2.png);
    background-repeat: no-repeat;
    z-index: -1
}

@media (max-width: 1199px) {
    footer:before {
        content: none
    }
}

@media (max-width: 767px) {
    footer .main-footer {
        border: none;
        padding-top: 0
    }
}

footer .main-footer .footer-theme .footer-theme-contain {
    margin-top: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-theme .footer-theme-contain p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    line-height: 27px;
    margin-bottom: 0
}

footer .main-footer .footer-theme .footer-theme-contain ul.address {
    margin-top: calc(17px + (30 - 17) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-theme .footer-theme-contain ul.address li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

footer .main-footer .footer-theme .footer-theme-contain ul.address li .feather {
    width: 18px;
    height: 18px;
    stroke: #4a5568
}

footer .main-footer .footer-theme .footer-theme-contain ul.address li p {
    margin: 1px 0 0 10px;
    color: #4a5568
}

[dir="rtl"] footer .main-footer .footer-theme .footer-theme-contain ul.address li p {
    margin: 1px 10px 0 0
}

footer .main-footer .footer-theme .footer-theme-contain ul li {
    display: block;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-theme .footer-theme-contain ul li a:hover {
    color: #4a5568
}

footer .main-footer .footer-theme .footer-theme-contain ul li+li {
    margin-top: calc(4px + (15 - 4) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-logo .theme-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 575px) {
    footer .main-footer .footer-logo .theme-logo {
        margin-bottom: 15px
    }
}

footer .main-footer .footer-logo .theme-logo a img {
    width: calc(120px + (160 - 120) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-logo .theme-logo h4 {
    margin-left: 10px
}

footer .main-footer .footer-logo .footer-logo-contain {
    margin: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320))) 0
}

footer .main-footer .footer-logo .footer-logo-contain p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    line-height: 27px;
    margin-bottom: 0;
    width: 88%
}

@media (max-width: 360px) {
    footer .main-footer .footer-logo .footer-logo-contain p {
        width: 100%
    }
}

footer .main-footer .footer-logo .footer-logo-contain .address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(7px + (30 - 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] footer .main-footer .footer-logo .footer-logo-contain .address {
    padding-left: unset;
    padding-right: 0
}

footer .main-footer .footer-logo .footer-logo-contain .address li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%
}

footer .main-footer .footer-logo .footer-logo-contain .address li:hover a {
    color: var(--theme-color)
}

footer .main-footer .footer-logo .footer-logo-contain .address li:hover .feather {
    stroke: var(--theme-color)
}

footer .main-footer .footer-logo .footer-logo-contain .address li .feather {
    width: 18px;
    height: 18px;
    stroke: #4a5568
}

footer .main-footer .footer-logo .footer-logo-contain .address li a {
    margin: 0 0 0 10px;
    color: #4a5568;
    line-height: 18px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] footer .main-footer .footer-logo .footer-logo-contain .address li a {
    margin-right: 10px;
    margin-left: unset
}

footer .main-footer .footer-title h4 {
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

@media (max-width: 575px) {
    footer .main-footer .footer-title h4 {
        font-weight: 500
    }
}

footer .main-footer .footer-contain {
    margin-top: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-contain p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    line-height: 27px;
    margin-bottom: 0
}

footer .main-footer .footer-contain ul.address {
    margin-top: calc(17px + (30 - 17) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-contain ul.address li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

footer .main-footer .footer-contain ul.address li .feather {
    width: 18px;
    height: 18px;
    stroke: #4a5568
}

footer .main-footer .footer-contain ul.address li p,
footer .main-footer .footer-contain ul.address li a {
    margin: 0 0 0 10px;
    color: #4a5568;
    line-height: 18px
}

[dir="rtl"] footer .main-footer .footer-contain ul.address li p,
[dir="rtl"] footer .main-footer .footer-contain ul.address li a {
    margin-right: 10px;
    margin-left: unset
}

footer .main-footer .footer-contain ul li {
    display: block;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-contain ul li a {
    display: inline-block;
    position: relative
}

footer .main-footer .footer-contain ul li a::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    left: 0;
    bottom: 0;
    background-color: var(--theme-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] footer .main-footer .footer-contain ul li a::before {
    left: unset;
    right: 0
}

[dir="rtl"] footer .main-footer .footer-contain ul li a::after {
    left: unset;
    right: -13px
}

footer .main-footer .footer-contain ul li a:hover {
    color: var(--theme-color);
}

[dir="rtl"] footer .main-footer .footer-contain ul li a:hover {
    margin-left: unset;
    margin-right: 13px
}

footer .main-footer .footer-contain ul li a:hover::before {
    width: 100%
}

footer .main-footer .footer-contain ul li a:hover::after {
    opacity: 1
}

footer .main-footer .footer-contain ul li+li {
    margin-top: calc(4px + (15 - 4) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .social-app::before {
    content: none
}

footer .main-footer .social-app ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

footer .main-footer .social-app ul li {
    display: inline-block
}

footer .main-footer .social-app ul li::before {
    content: none !important
}

footer .main-footer .social-app ul li a {
    display: block
}

footer .main-footer .social-app ul li a img {
    width: calc(114px + (140 - 114) * ((100vw - 320px) / (1920 - 320)))
}

footer .main-footer .footer-contact {
    margin-top: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] footer .main-footer .footer-contact {
    padding-left: unset;
    padding-right: 0
}

footer .main-footer .footer-contact ul li {
    display: block;
    margin-bottom: 30px;
    position: relative
}

footer .main-footer .footer-contact ul li:last-child {
    margin-bottom: 0
}

footer .main-footer .footer-contact ul li:last-child::before {
    content: none
}

footer .main-footer .footer-contact ul li.social-app ul li a {
    display: block
}

footer .main-footer .footer-contact ul li.social-app ul li a img {
    width: 139px
}



[dir="rtl"] footer .main-footer .footer-contact ul li::before {
    right: unset;
    left: 0
}

@media (max-width: 1460px) {
    footer .main-footer .footer-contact ul li::before {
        bottom: -20px
    }
}

@media (max-width: 991px) {
    footer .main-footer .footer-contact ul li::before {
        bottom: -18px
    }
}

@media (max-width: 767px) {
    footer .main-footer .footer-contact ul li::before {
        bottom: -16px
    }
}

@media (max-width: 360px) {
    footer .main-footer .footer-contact ul li::before {
        bottom: -13px
    }
}

footer .main-footer .footer-contact ul li .footer-number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 12px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

footer .main-footer .footer-contact ul li .footer-number .feather {
    width: 18px;
    height: 18px;
    stroke: #4a5568
}

footer .main-footer .footer-contact ul li .footer-number .contact-number h5 {
    margin-top: 6px;
    font-weight: 600;
    color: #222
}

footer .sub-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-top: 1px solid rgba(0,0,0,0.05)
}

@media (max-width: 991px) {
    footer .sub-footer {
        display: block;
        text-align: center
    }
}

@media (max-width: 767px) {
    footer .sub-footer {
        margin-bottom: 66px
    }
}

@media (max-width: 991px) {
    footer .sub-footer .payment {
        margin: 8px 0
    }
}

footer .sub-footer .social-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 991px) {
    footer .sub-footer .social-link {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

footer .sub-footer .social-link h6 {
    margin-right: 10px
}

[dir="rtl"] footer .sub-footer .social-link h6 {
    margin-left: 10px;
    margin-right: unset
}

footer .sub-footer .social-link ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

footer .sub-footer .social-link ul li:hover i {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px)
}

footer .sub-footer .social-link ul li a {
    display: block
}

footer .sub-footer .social-link ul li a i {
    color: #4a5568;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

footer .sub-footer .social-link ul li+li {
    margin-left: 8px
}

[dir="rtl"] footer .sub-footer .social-link ul li+li {
    margin-left: unset;
    margin-right: 8px
}

footer .sub-footer ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 6px
}

@media (max-width: 991px) {
    footer .sub-footer ul {
        display: block;
        text-align: center
    }
}

footer .sub-footer ul li {
    display: block
}

.footer-section-2 {
    background-color: #f8f8f8
}

.footer-section-2::before,
.footer-section-2::after {
    content: none
}

.footer-section-2 .download-image {
    margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.footer-section-2.footer-color-2 {
    background-color: #051616
}

.footer-section-2.footer-color-3 {
    background-color: #1d2328
}

.footer-section-2 .main-footer {
    padding-bottom: calc(18px + (50 - 18) * ((100vw - 320px) / (1920 - 320)));
    border-top: unset
}

.footer-section-2 .main-footer .foot-logo img {
    width: calc(120px + (160 - 120) * ((100vw - 320px) / (1920 - 320)))
}

.footer-section-2 .main-footer .information-text {
    margin-bottom: 0;
    margin-top: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    font-weight: 400;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.7
}

.footer-section-2 .main-footer .information-text-2 {
    color: #bfbfbf;
    margin-top: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.footer-section-2 .main-footer .social-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 6px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px
}

[dir="rtl"] .footer-section-2 .main-footer .social-icon {
    padding-left: unset;
    padding-right: 0
}

.footer-section-2 .main-footer .social-icon li {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.footer-section-2 .main-footer .social-icon li.light-bg::after {
    background-color: #fff;
    opacity: 0.1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.footer-section-2 .main-footer .social-icon li.light-bg:hover::after {
    opacity: 0.3
}

.footer-section-2 .main-footer .social-icon li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--theme-color);
    opacity: 0.06;
    border-radius: 50%;
    z-index: -1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.footer-section-2 .main-footer .social-icon li:hover::after {
    opacity: 1
}

.footer-section-2 .main-footer .social-icon li:hover i {
    color: #fff
}

.footer-section-2 .main-footer .social-icon li a {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #4a5568
}

.footer-section-2 .main-footer .social-icon li a i {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.footer-section-2 .main-footer .social-icon li a.footer-link-color {
    color: #fff
}

.footer-section-2 .main-footer .download-app {
    margin-top: 20px
}

.footer-section-2 .main-footer .download-app span {
    margin-bottom: 0;
    margin-top: 20px;
    color: #4a5568;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5
}

.footer-section-2 .main-footer .download-app img {
    width: 110px;
    margin-left: 8px
}

.footer-section-2 .main-footer .footer-title {
    margin-bottom: calc(13px + (36 - 13) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    position: relative
}

.footer-section-2 .main-footer .footer-list-light li a:hover {
    color: #fff
}

.footer-section-2 .main-footer .footer-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(7px + (16 - 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .footer-section-2 .main-footer .footer-list {
    padding-right: 0
}

.footer-section-2 .main-footer .footer-list li {
    display: block;
    width: 100%
}

@media (max-width: 575px) {
    .footer-section-2 .main-footer .footer-list li:last-child {
        margin-bottom: 16px
    }
}

.footer-section-2 .main-footer .footer-list li a {
    color: #4a5568;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px
}

.footer-section-2 .main-footer .footer-list li a i {
    margin-right: 10px
}

[dir="rtl"] .footer-section-2 .main-footer .footer-list li a i {
    margin-right: unset;
    margin-left: 10px
}

.footer-section-2 .main-footer .footer-list li a.light-text {
    color: #bfbfbf
}

.footer-section-2 .main-footer .footer-list li a:hover {
    color: #fff;
    padding-left: 14px
}

[dir="rtl"] .footer-section-2 .main-footer .footer-list li a:hover {
    padding-left: unset;
    padding-right: 14px
}

.footer-section-2 .main-footer .footer-list li a:hover::before {
    left: 0;
    opacity: 0.7
}

[dir="rtl"] .footer-section-2 .main-footer .footer-list li a:hover::before {
    left: unset;
    right: 0
}

.footer-section-2 .main-footer .footer-list li a:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -20px;
    border-radius: 100%;
    opacity: 0;
    background-color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .footer-section-2 .main-footer .footer-list li a:before {
    left: unset;
    right: -20px
}

.footer-section-2 .main-footer .footer-list li a.footer-contain-2::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    width: unset;
    height: unset;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    content: "\f105";
    left: 0;
    opacity: 0;
    background: none;
    color: var(--theme-color)
}

.footer-section-2 .main-footer .footer-list li a.footer-contain-2:hover {
    padding-left: 6px;
    color: var(--theme-color)
}

.footer-section-2 .main-footer .footer-list li a.footer-contain-2:hover::before {
    opacity: 1
}

[dir="rtl"] .footer-section-2 .main-footer .footer-address {
    padding-right: 0
}

.footer-section-2 .main-footer .footer-address li {
    margin-bottom: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.footer-section-2 .main-footer .footer-address li:last-child {
    margin-bottom: 0
}

.footer-section-2 .main-footer .footer-address li a {
    color: #4a5568;
    position: relative;
    font-size: 16px;
    display: block;
    width: 100%
}

.footer-section-2 .main-footer .footer-address li a.light-text {
    color: #bfbfbf
}

.footer-section-2 .main-footer .footer-address li a .inform-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.footer-section-2 .main-footer .footer-address li a .inform-box.flex-start-box {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.footer-section-2 .main-footer .footer-address li a .inform-box .feather {
    margin-right: 10px;
    width: 20px
}

[dir="rtl"] .footer-section-2 .main-footer .footer-address li a .inform-box .feather {
    margin-right: unset;
    margin-left: 10px
}

.footer-section-2 .main-footer .footer-address li a .inform-box p {
    margin: -3px 0 0;
    width: calc(100% + (64 - 100) * ((100vw - 320px) / (1920 - 320)));
    font-size: 16px;
    line-height: 30px
}

.footer-section-2 .sub-footer {
    border-top: 1px solid #ececec
}

.footer-section-2 .sub-footer-lite {
    border-top: 1px solid rgba(236, 236, 236, 0.2)
}

.footer-section-2 .sub-footer p {
    margin-bottom: 0;
    font-size: 15px;
    color: #4a5568;
    text-transform: capitalize
}

.footer-section-2 .sub-footer p.light-text {
    color: #bfbfbf
}

.footer-section-2 .sub-footer .payment-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

@media (max-width: 1199px) {
    .footer-section-2 .sub-footer .payment-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 10px
    }
}

.footer-section-2 .sub-footer .payment-box li {
    margin: 0 5px
}

.footer-section-2 .sub-footer .payment-box li img {
    width: 40px;
    margin-bottom: -6px;
    display: block
}

.footer-sm {
    margin-left: 320px;
    background-color: #fff
}

[dir="rtl"] .footer-sm {
    margin-left: unset;
    margin-right: 320px
}

@media (max-width: 1199px) {
    .footer-sm {
        margin-left: 0
    }

    [dir="rtl"] .footer-sm {
        margin-left: unset;
        margin-right: 0
    }
}

.footer-sm:after,
.footer-sm:before {
    display: none
}

.footer-sm .sub-footer {
    border-top: none;
    padding: 20px 0
}

.onhover-category-box {
    position: absolute;
    top: 0;
    left: 100%;
    background: #fff;
    -webkit-box-shadow: 0 0 8px #ddd;
    box-shadow: 0 0 8px #ddd;
    padding: 20px 25px;
    border-radius: 5px;
    width: 520px;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: auto auto;
    opacity: 0;
    visibility: hidden;
    margin-left: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .onhover-category-box {
    margin-left: unset;
    margin-right: 14px
}

@media (max-width: 1199px) {
    .onhover-category-box {
        grid-template-columns: auto;
        display: block;
        width: 100%
    }

    .onhover-category-box .list-2 {
        margin-top: 15px
    }
}

@media (max-width: 767px) {
    .onhover-category-box {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 12px 11px;
        margin-top: 7px;
        opacity: 1;
        visibility: visible;
        margin-left: 0
    }
}

.onhover-category-box .category-title-box {
    margin-bottom: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.onhover-category-box .category-title-box h5 {
    font-weight: 600
}

.onhover-category-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(4px + (11 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.onhover-category-box ul li {
    position: relative
}

.onhover-category-box ul li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #4a5568;
    border-radius: 100%
}

.onhover-category-box ul li a {
    margin-left: 13px
}

header.active .sticky-header {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    color: #222;
    -webkit-box-shadow: 0 8px 10px rgba(34, 34, 34, 0.05);
    box-shadow: 0 8px 10px rgba(34, 34, 34, 0.05);
    z-index: 9;
    padding: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320))) 0
}

header.active .sticky-header-2 {
    background-color: var(--theme-color);
    padding: 14px 0
}

header.active .sticky-header-3 {
    padding: 14px 0
}

header .timer-notification {
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative
}

@media (max-width: 1399px) {
    header .timer-notification {
        text-align: left
    }
}

header .timer-notification h6 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

header .timer-notification h6 a {
    text-decoration: underline;
    font-weight: 600;
    display: inline-block;
    margin-left: 5px
}

header .header-notification {
    position: relative
}

header .header-notification.remove {
    display: none
}

@media (max-width: 767px) {
    header .header-notification .notification-slider .timer-notification {
        width: 80%;
        margin: 0 auto
    }
}

header .header-notification .close-notification {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: 11px;
    padding: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 700
}

@media (max-width: 575px) {
    header .header-notification .close-notification {
        top: 10px;
        -webkit-transform: none;
        transform: none
    }
}

@media (max-width: 575px) {
    header .header-notification .close-notification span {
        display: none
    }
}

header .header-notification .close-notification i {
    margin-left: 5px;
    margin-top: 1px
}
.onhover-category-list{
    position:relative;
}
.onhover-category-list:hover .onhover-category-box {
    opacity: 1;
    visibility: visible;
    margin-left: 0;
}

[dir="rtl"] .onhover-category-list:hover .onhover-category-box {
    margin-left: unset;
    margin-right: 0
}

@media (max-width: 767px) {
    .onhover-category-list .list-1 {
        margin-bottom: 21px
    }
}

.onhover-category-list .onhover-category-box {
    position: absolute;
    top: 0;
    left: 100%;
    background: #fff;
    -webkit-box-shadow: 0 0 8px #ddd;
    box-shadow: 0 0 8px #ddd;
    padding: 20px 25px;
    border-radius: 5px;
    width: 520px;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: auto auto;
    opacity: 0;
    visibility: hidden;
    margin-left: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .onhover-category-list .onhover-category-box {
    margin-left: unset;
    margin-right: 14px
}

@media (max-width: 991px) {
    .onhover-category-list .onhover-category-box {
        grid-template-columns: auto;
        display: block;
        width: 100%
    }

    .onhover-category-list .onhover-category-box .list-2 {
        margin-top: 15px
    }
}

@media (max-width: 767px) {
    .onhover-category-list .onhover-category-box {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 12px 11px;
        margin-top: 7px;
        opacity: 1;
        visibility: visible;
        margin-left: 0
    }
}

.onhover-category-list .onhover-category-box .category-title-box {
    margin-bottom: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.onhover-category-list .onhover-category-box .category-title-box h5 {
    font-weight: 600
}

.onhover-category-list .onhover-category-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(4px + (11 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.onhover-category-list .onhover-category-box ul li {
    position: relative
}

.onhover-category-list .onhover-category-box ul li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #4a5568;
    border-radius: 100%
}

.onhover-category-list .onhover-category-box ul li a {
    margin-left: 13px
}

header .header-top {
    padding: 10px 0;
    background-color: var(--theme-color)
}

header .header-top-2 {
    background-color: #222
}

header .header-top .top-left-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

header .header-top .top-left-header i {
    font-size: 18px;
    margin-right: 5px
}

[dir="rtl"] header .header-top .top-left-header i {
    margin-right: unset;
    margin-left: 5px
}

header .header-top .top-left-header span {
    margin-top: 1px;
    font-weight: 500
}

header .header-top .about-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%
}

[dir="rtl"] header .header-top .about-list {
    padding-left: unset;
    padding-right: 0
}

header .header-top .about-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

header .header-top .about-list li i {
    font-size: 18px;
    margin-right: 5px
}

[dir="rtl"] header .header-top .about-list li i {
    margin-right: unset;
    margin-left: 5px
}

header .header-top .about-list li span {
    margin-top: 1px;
    font-weight: 500
}

header .header-top .about-list li+li {
    margin-left: 25px;
    position: relative
}

[dir="rtl"] header .header-top .about-list li+li {
    margin-left: unset;
    margin-right: 25px
}

header .header-top .about-list li+li::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 90%;
    top: 0;
    left: -13px;
    background-color: rgba(255, 255, 255, 0.3)
}

[dir="rtl"] header .header-top .about-list li+li::after {
    left: unset;
    right: -13px
}

header .header-top .about-list li .theme-form-select {
    padding: 0;
    background-color: transparent;
    color: #fff;
    border: none;
    background-image: none;
    position: relative
}

header .header-top .about-list li .theme-form-select .dropdown-toggle {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0 18px 0 0
}

header .header-top .about-list li .theme-form-select .dropdown-toggle img {
    width: 20px;
    margin-right: 10px
}

[dir="rtl"] header .header-top .about-list li .theme-form-select .dropdown-toggle img {
    margin-right: unset;
    margin-left: 10px
}

header .header-top .about-list li .theme-form-select .dropdown-toggle::after {
    content: none
}

header .header-top .about-list li .theme-form-select .dropdown-toggle:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

header .header-top .about-list li .theme-form-select .dropdown-toggle::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    color: #fff
}

header .header-top .about-list li .theme-form-select .dropdown-menu.sm-dropdown-menu {
    min-width: 100%
}

header .header-top .about-list li .theme-form-select .dropdown-menu li+li {
    margin-left: 0
}

[dir="rtl"] header .header-top .about-list li .theme-form-select .dropdown-menu li+li {
    margin-left: unset;
    margin-right: 0
}

header .header-top .about-list li .theme-form-select .dropdown-menu li .dropdown-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

header .header-top .about-list li .theme-form-select .dropdown-menu li .dropdown-item:hover,
header .header-top .about-list li .theme-form-select .dropdown-menu li .dropdown-item:focus {
    background-color: transparent
}

header .header-top .about-list li .theme-form-select .dropdown-menu li .dropdown-item.active,
header .header-top .about-list li .theme-form-select .dropdown-menu li .dropdown-item:active {
    color: #222;
    background-color: transparent
}

header .header-top .about-list li .theme-form-select .dropdown-menu li .dropdown-item img {
    width: 20px;
    margin-right: 10px
}

[dir="rtl"] header .header-top .about-list li .theme-form-select .dropdown-menu li .dropdown-item img {
    margin-right: unset;
    margin-left: 10px
}

header .nav-about {
    padding: 10px 0;
    background-color: var(--theme-color)
}

header .nav-about-2 {
    background-color: #222
}

header .top-nav {
    padding: 14px 0
}

@media (max-width: 767px) {
    header .top-nav {
        padding: 24px 0 0
    }
}

header .top-nav .navbar-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative
}

@media (max-width: 1199px) {
    header .top-nav .navbar-top {
        -webkit-box-pack: normal;
        -ms-flex-pack: normal;
        justify-content: normal
    }
}

@media (max-width: 767px) {
    header .top-nav .navbar-top {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

header .top-nav .navbar-top .web-logo img {
    width: calc(120px + (162 - 120) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    -o-object-fit: contain;
    object-fit: contain
}

@media (max-width: 767px) {
    header .top-nav .navbar-top .web-logo img {
        margin: 0 auto
    }
}

header .top-nav .navbar-top .middle-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 767px) {
    header .top-nav .navbar-top .middle-box {
        display: none
    }
}

header .top-nav .navbar-top .middle-box .location-box {
    border-radius: 5px 0 0 5px !important;
    border: 1px solid #ececec;
    border-right: 0 !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 4px 4px;
    padding-right: 20px;
    margin-right: 0px;
    position: relative
}

[dir="rtl"] header .top-nav .navbar-top .middle-box .location-box {
    margin-right: unset;
    margin-left: 13px;
    padding-right: 7px;
    padding-left: 20px
}

@media (max-width: 1199px) {
    header .top-nav .navbar-top .middle-box .location-box {
        margin-right: unset;
        margin-left: 13px
    }

    [dir="rtl"] header .top-nav .navbar-top .middle-box .location-box {
        margin-left: unset;
        margin-right: 13px
    }
}

@media (max-width: 1556px) {
    header .top-nav .navbar-top .middle-box .location-box {
        padding-right: 7px
    }

    [dir="rtl"] header .top-nav .navbar-top .middle-box .location-box {
        padding-right: 7px;
        padding-left: 7px
    }
}

@media (max-width: 767px) {
    header .top-nav .navbar-top .middle-box .location-box {
        display: none
    }
}

header .top-nav .navbar-top .middle-box .location-box button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0
}

header .top-nav .navbar-top .middle-box .location-box button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

header .top-nav .navbar-top .middle-box .location-box button .location-arrow {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: rgba(255, 127, 0, 1);
    background-color: rgba(255, 127, 0, 0.1);
    border-radius: 5px;
    margin-right: 12px
}

[dir="rtl"] header .top-nav .navbar-top .middle-box .location-box button .location-arrow {
    margin-right: unset;
    margin-left: 12px
}

@media (max-width: 1300px) {
    header .top-nav .navbar-top .middle-box .location-box button .location-arrow {
        margin-right: 0
    }

    [dir="rtl"] header .top-nav .navbar-top .middle-box .location-box button .location-arrow {
        margin-right: unset;
        margin-left: 0
    }
}

header .top-nav .navbar-top .middle-box .location-box button .location-arrow .feather {
    width: 16px;
    height: 16px
}

header .top-nav .navbar-top .middle-box .location-box button .locat-name {
    font-size: 15px;
    font-weight: 500;
    color: #333
}

@media (max-width: 1300px) {
    header .top-nav .navbar-top .middle-box .location-box button .locat-name {
        display: none
    }
}

header .top-nav .navbar-top .middle-box .location-box button i {
    color: #4a5568
}
header .top-nav .navbar-top .middle-box .location-box button i.fa-angle-down{
    margin-left: 15px;
    margin-right: -10px;
}
[dir="rtl"] header .top-nav .navbar-top .middle-box .location-box button i {
    margin-left: unset;
    margin-right: 17px
}

@media (max-width: 1300px) {
    header .top-nav .navbar-top .middle-box .location-box button i {
        display: none
    }
}

@media (max-width: 1199px) {
    header .top-nav .navbar-top .middle-box .search-box {
        display: none
    }
}

header .top-nav .navbar-top .middle-box .search-box .input-group ::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 400
}

header .top-nav .navbar-top .middle-box .search-box .input-group ::-moz-placeholder {
    font-size: 16px;
    font-weight: 400
}

header .top-nav .navbar-top .middle-box .search-box .input-group :-ms-input-placeholder {
    font-size: 16px;
    font-weight: 400
}

header .top-nav .navbar-top .middle-box .search-box .input-group ::-ms-input-placeholder {
    font-size: 16px;
    font-weight: 400
}

header .top-nav .navbar-top .middle-box .search-box .input-group ::placeholder {
    font-size: 16px;
    font-weight: 400
}

header .top-nav .navbar-top .middle-box .search-box .input-group .form-control {
    width: 277px;
    border-radius: 0;
    border-color: #ececec;
    height: 100%;
    margin-top:0px;
    font-size: 14px;
}
*:focus{
    box-shadow: none !important;
}
[dir="rtl"] header .top-nav .navbar-top .middle-box .search-box .input-group .form-control {
    border-radius: 0 5px 5px 0
}

@media (max-width: 1812px) {
    header .top-nav .navbar-top .middle-box .search-box .input-group .form-control {
        width: 466px
    }
}

header .top-nav .navbar-top .middle-box .search-box .input-group .form-control:focus {
    border-color: var(--theme-color);
    background-color: #fff
}
.text-left {
    text-align: left;
}

header .top-nav .navbar-top .middle-box .search-box .input-group button {
    background-color: #ff7f00;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #ff7f00
}

header .top-nav .navbar-top .middle-box .search-box .input-group button.bg-theme {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

header .top-nav .navbar-top .middle-box .search-box .input-group button.search-button-2 {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

[dir="rtl"] header .top-nav .navbar-top .middle-box .search-box .input-group button {
    border-radius: 5px 0 0 5px !important
}

header .top-nav .navbar-top .middle-box .search-box .input-group button .feather {
    width: 20px;
    height: 20px
}

@media (max-width: 1199px) {
    header .top-nav .navbar-top .rightside-box {
        margin-left: auto
    }

    [dir="rtl"] header .top-nav .navbar-top .rightside-box {
        margin-left: unset;
        margin-right: auto
    }
}

@media (max-width: 767px) {
    header .top-nav .navbar-top .rightside-box {
        margin-left: unset
    }

    [dir="rtl"] header .top-nav .navbar-top .rightside-box {
        margin-left: unset;
        margin-right: unset
    }
}

header .top-nav .navbar-top .rightside-box .right-side-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] header .top-nav .navbar-top .rightside-box .right-side-menu {
    padding-left: unset;
    padding-right: 0
}
.slider-9{
    overflow: hidden;
}
header .top-nav .navbar-top .rightside-box .right-side-menu .right-side {
    position: relative;
    padding-right: 0px !important
}

[dir="rtl"] header .top-nav .navbar-top .rightside-box .right-side-menu .right-side {
    padding-right: unset;
    padding-left: 32px
}

@media (max-width: 767px) {
    header .top-nav .navbar-top .rightside-box .right-side-menu .right-side {
        display: none
    }

    header .top-nav .navbar-top .rightside-box .right-side-menu .right-side:last-child {
        display: block
    }
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side:first-child {
    display: none
}

@media (max-width: 1199px) {
    header .top-nav .navbar-top .rightside-box .right-side-menu .right-side:first-child {
        display: block
    }
}

@media (max-width: 767px) {
    header .top-nav .navbar-top .rightside-box .right-side-menu .right-side:first-child {
        display: none
    }
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side:last-child {
    padding-right: 0
}

[dir="rtl"] header .top-nav .navbar-top .rightside-box .right-side-menu .right-side:last-child {
    padding-right: unset;
    padding-left: 0
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side:last-child::before {
    content: none
}



header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    margin-bottom: -14px;
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-icon .feather {
    color: #222;
    margin-right: 14px;
    stroke-width: 1.5
}

[dir="rtl"] header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-icon .feather {
    margin-right: unset;
    margin-left: 14px
}

@media (max-width: 1660px) {
    header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-icon .feather {
        margin-right: 0
    }

    [dir="rtl"] header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-icon .feather {
        margin-right: unset;
        margin-left: 0
    }
}

@media (max-width: 1660px) {
    header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-detail {
        display: none
    }
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-detail h6 {
    color: #4a5568;
    margin-bottom: 3px
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-detail h5 {
    font-weight: 500;
    color: #222
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .header-badge {
    padding-right: 9px
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .header-wishlist .feather {
    stroke-width: 1.5;
    color: #4a5568
}

@media (max-width: 1660px) {
    header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .header-wishlist .feather {
        color: #222
    }
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .header-wishlist:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .header-wishlist span {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ff7272;
    font-size: 12px;
    padding: 0;
    border-radius: 2px
}

[dir="rtl"] header .top-nav .navbar-top .rightside-box .right-side-menu .right-side .header-wishlist span {
    right: 0 !important
}

header .onhover-dropdown {
    position: relative
}

header .onhover-dropdown:hover .onhover-div {
    opacity: 1;
    visibility: visible
}

header .onhover-dropdown .onhover-div {
    position: absolute;
    top: 43px;
    right: 0px;
    background-color: #fff;
    z-index: 1001;
    width: 380px;
    border-radius: 10px;
    padding: 12px;
    -webkit-box-shadow: -1px 0 20px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    box-shadow: -1px 0 20px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    visibility: hidden;

}
.drop-contain{
    position:relative;
    width: 100%;
}
.product-box-contain{
    width: 100%;
}
.cart-list{
    max-height: 350px;
    overflow: hidden;
    overflow-y: scroll;
}
.cart-onhover-div{
    opacity: 1 !important;
    visibility: visible !important;
}
[dir="rtl"] header .onhover-dropdown .onhover-div {
    right: unset;
    left: 20px
}

header .onhover-dropdown .onhover-div-login {
    right: 0;
    width: 160px;
    padding: 20px
}

header .onhover-dropdown .onhover-div-login .user-box-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

header .onhover-dropdown .onhover-div-login .user-box-name li {
    display: block;
    width: 100%
}

header .onhover-dropdown .onhover-div-login .user-box-name li a {
    color: #222;
    display: block;
    position: relative
}

header .onhover-dropdown .onhover-div-login .user-box-name li a:hover::after {
    width: 40%
}

header .onhover-dropdown .onhover-div-login .user-box-name li a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 2px;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.3;
    border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: -1
}

header .onhover-dropdown .onhover-div .cart-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 7px;
    border-bottom: 1px solid #ececec;
    padding-bottom: calc(12px + (22 - 12) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] header .onhover-dropdown .onhover-div .cart-list {
    padding-right: 0
}

header .onhover-dropdown .onhover-div .cart-list li {
    margin: 0 !important
}

header .onhover-dropdown .onhover-div .cart-list li::after {
    content: none !important
}

header .onhover-dropdown .onhover-div .cart-list li .drop-cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

header .onhover-dropdown .onhover-div .cart-list li .drop-cart::after {
    content: none
}

header .onhover-dropdown .onhover-div .cart-list li .drop-cart .drop-image img {
    width: 87px;
    background: #f8f8f8;
    padding: 10px
}

header .onhover-dropdown .onhover-div .cart-list li .drop-cart .drop-contain h5 {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 5px;
    font-size: 13px;
    color: #444;
    font-weight: 500;
    padding-right: 20px;
}
header .onhover-dropdown .onhover-div .cart-list{
    position:relative;
}
header .onhover-dropdown .onhover-div .cart-list.loading{
    overflow: hidden !important
}
header .onhover-dropdown .onhover-div .cart-list .loader{
    height: 400px;
    width: 100%;
    background:rgba(255,255,255,.7);
    backdrop-filter: blur(10px);
    z-index: 1111;
    font-size: 33px;
    color:var(--theme-color);
    text-align: center;
    padding-top: calc(50% - 20px);
}
header .location-box button{
    border: 0 !important
}
header .onhover-dropdown .onhover-div .cart-list li .drop-cart .drop-contain h6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 5px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-size:12px
}

header .onhover-dropdown .onhover-div .cart-list li .drop-cart .drop-contain h6 span {
    margin-top: -2px;
    color: #4a5568
}

header .onhover-dropdown .onhover-div .cart-list li .drop-cart .drop-contain .close-button {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: transparent;
    border: none;
    padding: 0;
    color: #a0a0a0
}

[dir="rtl"] header .onhover-dropdown .onhover-div .cart-list li .drop-cart .drop-contain .close-button {
    right: unset;
    left: 0
}

header .onhover-dropdown .onhover-div .button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)))
}

header .onhover-dropdown .onhover-div .button-group .cart-button {
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    padding: 6px 10px
}

header .onhover-dropdown .onhover-div .button-group .cart-button:hover {
    background-color: var(--theme-color);
    color: #fff
}

header .onhover-dropdown .onhover-div .price-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)))
}

header .search-full {
    width: 0;
    height: 0;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

header .search-full .input-group {
    border: 1px solid #f0f3f8
}

header .search-full .input-group .input-group-text {
    background-color: transparent;
    border: none
}

header .search-full .input-group .input-group-text.close-search {
    cursor: pointer
}

header .search-full .input-group .input-group-text svg {
    height: 18px
}

header .search-full .input-group input {
    border: none
}

header .search-full.open {
    width: 100%;
    height: 100%;
    -webkit-animation: zoomIn 0.5s ease-in-out;
    animation: zoomIn 0.5s ease-in-out;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

header .search-full.show .search-suggestion {
    padding: 10px;
    border: 1px solid #f0f3f8;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: 500px;
    -webkit-transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99);
    transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99)
}

header .header-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

header .header-nav .header-nav-left {
    position: relative
}

header .header-nav .header-nav-left .dropdown-category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) calc(16px + (31 - 16) * ((100vw - 320px) / (1920 - 320)));
    background: var(--theme-color2);
    color: #fff;
    border-radius: 5px;
    border: none
}

@media (max-width: 767px) {
    header .header-nav .header-nav-left .dropdown-category {
        display: none
    }
}

header .header-nav .header-nav-left .dropdown-category-2 {
    background: linear-gradient(93.33deg, #222221 12.35%, #2f2f2d 99.38%)
}

header .header-nav .header-nav-left .dropdown-category .feather {
    font-size: 23px;
    margin-right: calc(9px + (16 - 9) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] header .header-nav .header-nav-left .dropdown-category .feather {
    margin-right: unset;
    margin-left: calc(9px + (16 - 9) * ((100vw - 320px) / (1920 - 320)))
}

header .header-nav .header-nav-left .dropdown-category span {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

header .header-nav .header-nav-left .dropdown-category .dropdown {
    display: inline-block
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown {
    position: unset
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown a {
    display: block;
    color: #fff;
    text-decoration: none;
    -webkit-transition: background 0.35s ease;
    transition: background 0.35s ease;
    font-family: "Public Sans", sans-serif;
    font-weight: 500;
    font-size: 18px
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown .fa {
    margin-top: 3px;
    margin-left: 10px;
    font-size: 14px;
    color: var(--theme-color)
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul {
    display: none;
    border: 1px solid #ececec;
    border-radius: 5px;
    position: absolute;
    top: 53px;
    width: 100%;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    z-index: 999
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul .show {
    display: block
}

@media (max-width: 1199px) {
    header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul {
        position: fixed;
        top: 0;
        left: 0;
        width: 320px
    }
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 12px 14px;
    border-bottom: 1px solid #ececec;
    cursor: pointer;
    -webkit-transition: background 0.35s ease;
    transition: background 0.35s ease;
    color: #222
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul li img {
    width: 25px;
    height: 25px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 6px
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul li:last-child {
    border-bottom: none
}

header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul li:focus,
header .header-nav .header-nav-left .dropdown-category .dropdown.custom-dropdown ul li:hover {
    background-color: #ececec
}

header .header-nav .header-nav-left:hover .category-dropdown {
    opacity: 1;
    visibility: visible;
    top: 63px
}

header .header-nav .header-nav-left .category-dropdown {
    position: absolute;
    top: 72px;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px #ddd;
    box-shadow: 0 0 8px #ddd;
    z-index: 2;
    width: 300px;
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 767px) {
    header .header-nav .header-nav-left .category-dropdown {
        width: calc(300px + (320 - 300) * ((100vw - 320px) / (1920 - 320)));
        height: 100vh;
        position: fixed;
        top: 0;
        left: -320px;
        border-radius: 0;
        z-index: 10;
        padding: 0;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    [dir="rtl"] header .header-nav .header-nav-left .category-dropdown {
        left: unset;
        right: -320px
    }

    header .header-nav .header-nav-left .category-dropdown.show {
        left: 0
    }

    [dir="rtl"] header .header-nav .header-nav-left .category-dropdown.show {
        left: unset;
        right: 0
    }

    header .header-nav .header-nav-left .category-dropdown:hover {
        opacity: 1;
        visibility: visible;
        top: 0
    }
}

header .header-nav .header-nav-left .category-dropdown .category-title {
    display: none
}

@media (max-width: 767px) {
    header .header-nav .header-nav-left .category-dropdown .category-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 20px 24px;
        border-bottom: 1px solid #ececec;
        -webkit-box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1);
        box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1)
    }
}

header .header-nav .header-nav-left .category-dropdown .category-title h5 {
    color: var(--theme-color);
    font-weight: 600
}

.category-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
}

.category-list li {
    display: block;
    width: 100%
}

.category-list li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

.category-list li a:hover h6 {
    letter-spacing: 0.3px
}

.category-list li a:hover h6::before {
    width: 65px
}

.category-list li a img {
    width: 23px;
    height: 23px;
    -o-object-fit: contain;
    object-fit: contain
}

.category-list li a h6 {
    font-size: 17px;
    font-weight: 500;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-list li a h6:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-list li a .according-menu {
    margin-left: auto
}

[dir="rtl"] .category-list li a .according-menu {
    margin-left: unset;
    margin-right: auto
}

.category-list li a i {
    margin-left: auto
}

[dir="rtl"] .category-list li a i {
    margin-left: unset;
    margin-right: auto
}

@media (max-width: 767px) {
    .category-list li a i {
        display: none
    }
}

@media (max-width: 767px) {
    header .header-nav .header-nav-right {
        display: none
    }
}

header .header-nav .header-nav-right .deal-button {
    color: var(--theme-color);
    padding: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320))) calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    z-index: 0
}

header .header-nav .header-nav-right .deal-button::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.1;
    z-index: -1
}

header .header-nav .header-nav-right .deal-button .feather {
    margin-right: 10px;
    stroke-width: 1.5px
}

[dir="rtl"] header .header-nav .header-nav-right .deal-button .feather {
    margin-right: unset;
    margin-left: 10px
}

@media (max-width: 1366px) {
    header .header-nav .header-nav-right .deal-button .feather {
        margin-right: 0
    }

    [dir="rtl"] header .header-nav .header-nav-right .deal-button .feather {
        margin-right: unset;
        margin-left: 0
    }
}

header .header-nav .header-nav-right .deal-button span {
    font-size: 16px;
    font-weight: 600
}

@media (max-width: 1366px) {
    header .header-nav .header-nav-right .deal-button span {
        display: none
    }
}

header.header-compact .top-nav .navbar-top {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset
}

header.header-compact .top-nav .navbar-top .rightside-box {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] header.header-compact .top-nav .navbar-top .rightside-box {
    margin-left: unset;
    margin-right: auto
}

header.header-compact .location-box {
    padding-right: 10px
}

header.bg-theme .navbar-light .navbar-nav .nav-link {
    color: #fff !important
}

header.bg-theme .navbar-light .navbar-nav .nav-link:hover {
    color: #fff !important
}

header.bg-theme .top-nav .navbar-top .rightside-box .right-side-menu .right-side:before {
    background-color: rgba(255, 255, 255, 0.5)
}

header.bg-theme .top-nav .navbar-top .rightside-box .right-side-menu .right-side .header-wishlist .feather {
    color: #fff
}

header.bg-theme .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-detail h5 {
    color: #fff
}

header.bg-theme .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-detail h6 {
    color: rgba(255, 255, 255, 0.7)
}

header.bg-theme .top-nav .navbar-top .rightside-box .right-side-menu .right-side .delivery-login-box .delivery-icon .feather {
    color: #fff
}

header.bg-theme .location-box {
    border-color: rgba(255, 255, 255, 0.5)
}

header.bg-theme .location-box button i {
    color: #fff
}

header.bg-theme .location-box button .locat-name {
    color: #fff
}

header.bg-theme .location-box button .location-arrow {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.24)
}

header.header-absolute {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 1
}

header.header-absolute.bg-theme {
    background-color: transparent
}

@media (min-width: 1200px) {
    [dir="rtl"] header.header-absolute .navbar-expand-xl .dropdown:hover .dropdown-menu-2 {
        -webkit-transform: translateX(-50%) translateY(0);
        transform: translateX(-50%) translateY(0)
    }
}
header{
    box-shadow: 0 0 25px rgba(0,0,0,.2);
    z-index: 11;
    position:relative;
}
header.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 320px);
    z-index: 1;
    background-color: #fff;
    margin-left: 320px;
    border-bottom: 1px solid #ececec
}

[dir="rtl"] header.fixed-header {
    left: unset;
    right: 0;
    margin-left: unset;
    margin-right: 320px
}

@media (max-width: 1199px) {
    header.fixed-header {
        width: 100%;
        margin-left: 0
    }

    [dir="rtl"] header.fixed-header {
        margin-left: unset;
        margin-right: 0
    }
}

header.fixed-header .top-nav {
    padding: 18px 0
}

header .location-box {
    border-radius: 5px;
    border: 1px solid #ececec;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 7px;
    padding-right: 20px;
    margin-right: 13px;
    position: relative
}
header .cart-box{
    margin-right: 0 !important;
    padding: 3px !important;
    margin-bottom:-20px;
}
[dir="rtl"] header .location-box {
    margin-right: unset;
    margin-left: 13px;
    padding-right: 7px;
    padding-left: 20px
}
header .cart-box button .locat-name{
    line-height: 17px;
    font-size: 14px;
}
header .cart-box button .locat-name small{
    font-size: 12px;
}

@media (max-width: 1199px) {
    header .location-box {
        margin-right: unset;
        margin-left: 13px
    }

    [dir="rtl"] header .location-box {
        margin-left: unset;
        margin-right: 13px
    }
}

@media (max-width: 1556px) {
    header .location-box {
        padding-right: 7px
    }

    [dir="rtl"] header .location-box {
        padding-right: 7px;
        padding-left: 7px
    }
}

@media (max-width: 767px) {
    header .location-box {
        display: none
    }
}

header .location-box button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0
}

header .location-box button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

header .location-box button .location-arrow {
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: rgba(255, 127, 0, 1);
    background:rgba(255, 127, 0, 0.1);
    border-radius: 5px;
    margin-right: 12px
}

[dir="rtl"] header .location-box button .location-arrow {
    margin-right: unset;
    margin-left: 12px
}

@media (max-width: 1300px) {
    header .location-box button .location-arrow {
        margin-right: 0
    }

    [dir="rtl"] header .location-box button .location-arrow {
        margin-right: unset;
        margin-left: 0
    }
}

header .location-box button .location-arrow .feather {
    width: 16px;
    height: 16px
}
img{
    max-width: 100% !important;
}
.bg-theme{
    background-color: var(--theme-color) !important
}
.addcart-button{
    height: 36px;
    border-radius: 50%;
}
header .location-box button .locat-name {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-color)
}
.cart-badge{
    background:rgba(255, 127, 0, 0.1);
    color:rgb(255, 127, 0);
    font-size: 12px !important;
    margin-right: -3px;
    border-radius: 3px 0 0 3px;
    margin-top: 2px;
}
.home-section-2{
    padding: 30px 0 !important;
}
.home-section-2 img{
    border-radius: 7px;
}
@media (max-width: 1300px) {
    header .location-box button .locat-name {
        display: none
    }
}

header .location-box button i {
    color: #4a5568
}

[dir="rtl"] header .location-box button i {
    margin-left: unset;
    margin-right: 17px
}

@media (max-width: 1300px) {
    header .location-box button i {
        display: none
    }
}

.mobile-menu {
    position: fixed;
    bottom: 0;
    padding: 10px;
    border-radius: 20px 20px 0 0;
    width: 100%;
    background-color: var(--theme-color1);
    z-index: 2;
    overflow: hidden;
    -webkit-box-shadow: 0 -3px 10px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0 -3px 10px 0px rgba(0, 0, 0, 0.08)
}

[dir="rtl"] .mobile-menu {
    left: 0
}

.mobile-menu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 70%;
    margin: 0 auto
}

@media (max-width: 767px) {
    .mobile-menu ul {
        width: 80%
    }
    header{
        padding-bottom:25px !important;
    }
}
.cart-icona{
    width: 0
}
@media (max-width: 480px) {
    .mobile-menu ul {
        width: 95%
    }
    header.pb-0{
        padding-bottom:20px !important;
    }
    .home-section-2{
        padding: 0 !important
    }
    .home-section-2>.container-fluid-lg{
        padding: 0 !important
    }
    .home-section-2 img{
        border-radius: 0;
    }
    header .top-nav .navbar-top .web-logo img{
        width: 170px !important
    }
    .cart-icona{
        display: block;
    }
}

@media (max-width: 360px) {
    .mobile-menu ul {
        width: 100%
    }
    header{
        padding-bottom:25px !important;
    }
}

[dir="rtl"] .mobile-menu ul {
    padding: 0
}

.mobile-menu ul li {
    width: 100%;
    text-align: center
}

.mobile-menu ul li.active {
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    position: relative
}

.mobile-menu ul li.active::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg);
    background-color: #fff;
    border-radius: 100%
}

.mobile-menu ul li a {
    display: block
}

.mobile-menu ul li a img {
    display: block;
    margin: 0px auto
}

.mobile-menu ul li a.active,
.mobile-menu ul li a:hover {
    color: #222
}

@media (max-width: 360px) {
    .mobile-menu ul li a {
        font-size: 13px
    }
}

.mobile-menu ul li a .icli {
    display: inline-block;
    margin: 0 auto;
    font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #fff
}

.mobile-menu ul li a span {
    display: block;
    color: #fff
}

.header-2 .navbar-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 480px) {
    .header-2 .navbar-top {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.header-2 .navbar-top .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.header-2 .search-box {
    display: none
}

@media (max-width: 991px) {
    .header-2 .search-box {
        display: block;
        background-color: #fff;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #4a5568;
        text-align: right;
        margin-left: auto
    }

    .header-2 .search-box a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #000
    }

    .header-2 .search-box a .icli {
        font-size: 25px
    }
}

@media (max-width: 480px) {
    .header-2 .search-box {
        margin-left: unset
    }
}

.header-2 .dropdown-list.drop-state {
    margin-right: 20px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 1199px) {
    .header-2 .dropdown-list.drop-state {
        margin-right: 0
    }
}

@media (max-width: 991px) {
    .header-2 .dropdown-list {
        background-color: transparent;
        margin: 0
    }
}

.header-2 .dropdown-list .location-icon {
    margin-right: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.header-2 .dropdown-list .location-icon .iconly-Location {
    font-size: 25px
}

.header-2 .dropdown-list .dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.header-2 .dropdown-list .dropdown .dropdown-toggle {
    padding-left: 0;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.5px;
    position: relative;
    margin-top: 4px
}

.header-2 .dropdown-list .dropdown .dropdown-toggle::after {
    display: none
}

.header-2 .dropdown-list .dropdown .dropdown-toggle i {
    color: #000;
    margin-left: 5px
}

.header-2 .dropdown-list .dropdown .dropdown-menu {
    min-width: 100%
}

.header-2 .dropdown-list .dropdown .dropdown-menu li {
    display: block
}

.header-2 .form-select {
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 0;
    font-family: "Public Sans", sans-serif;
    color: #222
}

.header-2 .form-select:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.header-2 .top-nav {
    padding: 14px 0;
    border-bottom: 1px solid #ececec
}

@media (max-width: 767px) {
    .header-2 .top-nav {
        border-bottom: unset;
        padding-bottom: 0
    }
}

.header-2 .top-nav-color {
    background-color: #ffe03e
}

.header-2 .top-nav span {
    color: #222
}

.header-2 .top-nav span .icli {
    font-size: 25px
}

.header-2 .top-nav .nav-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color);
    font-weight: bolder;
    font-family: "Public Sans", sans-serif
}

.header-2 .top-nav .nav-logo span {
    color: #222
}

.header-2 .top-nav .search-full {
    width: 0;
    height: 0;
    background-color: #fff;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 360px) {
    .header-2 .top-nav .search-full {
        right: -5px
    }
}

.header-2 .top-nav .search-full .input-group {
    border: 1px solid #f0f3f8;
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14)
}

.header-2 .top-nav .search-full .input-group .input-group-text {
    background-color: #fff;
    border: none;
    color: #4a5568
}

.header-2 .top-nav .search-full .input-group .input-group-text.close-search {
    cursor: pointer
}

.header-2 .top-nav .search-full .input-group input {
    border: none;
    height: 50px
}

.header-2 .top-nav .search-full.open {
    width: 100%;
    -webkit-animation: zoomIn 0.5s ease-in-out;
    animation: zoomIn 0.5s ease-in-out;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 0 30px
}

@media (max-width: 575px) {
    .header-2 .top-nav .search-full.open {
        padding: 0 25px
    }
}

@media (max-width: 480px) {
    .header-2 .top-nav .search-full.open {
        padding: 0 15px
    }
}

@media (max-width: 360px) {
    .header-2 .top-nav .search-full.open {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        padding: 0;
        width: 95%
    }
}

.header-2 .top-nav .search-full.show .search-suggestion {
    padding: 10px;
    border: 1px solid #f0f3f8;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: 500px;
    -webkit-transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99);
    transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99)
}

.header-2 .top-nav .middle-box {
    display: inline-block;
    margin-left: calc(16px + (29 - 16) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .header-2 .top-nav .middle-box {
    margin-left: unset;
    margin-right: calc(16px + (29 - 16) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 1366px) {
    .header-2 .top-nav .middle-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-left: 25px;
        float: right
    }
}

@media (max-width: 1199px) {
    .header-2 .top-nav .middle-box {
        margin-left: 15px
    }
}

@media (max-width: 991px) {
    .header-2 .top-nav .middle-box {
        display: none
    }
}

.header-2 .top-nav .middle-box .center-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 991px) {
    .header-2 .top-nav .middle-box .center-box {
        display: none
    }
}

.header-2 .top-nav .middle-box .searchbar-box {
    position: relative
}

@media (max-width: 991px) {
    .header-2 .top-nav .middle-box .searchbar-box {
        display: none
    }
}

.header-2 .top-nav .middle-box .searchbar-box .search-button {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    padding: 0 19px;
    right: 0;
    height: 100%;
    cursor: pointer
}

[dir="rtl"] .header-2 .top-nav .middle-box .searchbar-box .search-button {
    right: unset;
    left: 0
}

.header-2 .top-nav .middle-box .searchbar-box .search-button .iconly-Search {
    font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568
}

.header-2 .top-nav .middle-box .searchbar-box input {
    width: 685px;
    height: 45px;
    padding: 8px 60px 8px 18px;
    color: #222;
    border-color: #d3d3d3;
    font-size: 16px
}

[dir="rtl"] .header-2 .top-nav .middle-box .searchbar-box input {
    padding: 8px 18px 8px 60px
}

@media (max-width: 1754px) {
    .header-2 .top-nav .middle-box .searchbar-box input {
        width: 630px
    }
}

@media (max-width: 1678px) {
    .header-2 .top-nav .middle-box .searchbar-box input {
        width: 580px
    }
}

@media (max-width: 1604px) {
    .header-2 .top-nav .middle-box .searchbar-box input {
        width: 529px
    }
}

@media (max-width: 1332px) {
    .header-2 .top-nav .middle-box .searchbar-box input {
        width: 483px
    }
}

@media (max-width: 1270px) {
    .header-2 .top-nav .middle-box .searchbar-box input {
        width: 436px
    }
}

@media (max-width: 1235px) {
    .header-2 .top-nav .middle-box .searchbar-box input {
        width: 415px
    }
}

.header-2 .top-nav .middle-box .searchbar-box input:focus {
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14)
}


.header-2 .top-nav .middle-box .location-box-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 20px;
    position: relative
}

[dir="rtl"] .header-2 .top-nav .middle-box .location-box-2 {
    margin-right: unset;
    margin-left: 20px
}

@media (max-width: 1199px) {
    .header-2 .top-nav .middle-box .location-box-2 {
        margin-right: unset;
        margin-left: 0
    }
}

@media (max-width: 767px) {
    .header-2 .top-nav .middle-box .location-box-2 {
        display: none
    }
}

.header-2 .top-nav .middle-box .location-box-2 button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0
}

.header-2 .top-nav .middle-box .location-box-2 button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.header-2 .top-nav .middle-box .location-box-2 button .icli {
    margin-right: 9px;
    font-size: 22px
}

[dir="rtl"] .header-2 .top-nav .middle-box .location-box-2 button .icli {
    margin-right: unset;
    margin-left: 9px
}

@media (max-width: 1556px) {
    .header-2 .top-nav .middle-box .location-box-2 button .icli {
        margin-right: 0
    }
}

.header-2 .top-nav .middle-box .location-box-2 button span {
    font-size: 16px
}

@media (max-width: 1556px) {
    .header-2 .top-nav .middle-box .location-box-2 button span {
        display: none
    }
}

.header-2 .top-nav .middle-box .location-box-2 button .down-arrow {
    margin-left: 10px
}

[dir="rtl"] .header-2 .top-nav .middle-box .location-box-2 button .down-arrow {
    margin-left: unset;
    margin-right: 10px
}

@media (max-width: 1556px) {
    .header-2 .top-nav .middle-box .location-box-2 button .down-arrow {
        display: none
    }
}

.header-2 .rightside-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto
}

[dir="rtl"] .header-2 .rightside-menu {
    margin-left: unset;
    margin-right: auto
}

.header-2 .rightside-menu .dropdown-dollar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 22px
}

@media (max-width: 991px) {
    .header-2 .rightside-menu .dropdown-dollar {
        display: none
    }
}

.header-2 .rightside-menu .dropdown-dollar .dropdown {
    position: relative;
    z-index: 1
}

.header-2 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 0
}

.header-2 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle i {
    margin-left: 8px
}

[dir="rtl"] .header-2 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle i {
    margin-left: unset;
    margin-right: 8px
}

.header-2 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle::after {
    display: none
}

.header-2 .rightside-menu .dropdown-dollar .dropdown+.dropdown {
    margin-left: 23px
}

[dir="rtl"] .header-2 .rightside-menu .dropdown-dollar .dropdown+.dropdown {
    margin-left: unset;
    margin-right: 23px
}

.header-2 .rightside-menu .dropdown-dollar .dropdown+.dropdown::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 25px;
    left: -10px;
    background-color: #ececec
}

[dir="rtl"] .header-2 .rightside-menu .dropdown-dollar .dropdown+.dropdown::after {
    left: unset;
    right: -10px
}

.header-2 .rightside-menu .dropdown-dollar .dropdown .dropdown-menu {
    min-width: 100%
}

.header-2 .rightside-menu .dropdown-dollar .dropdown .dropdown-menu li {
    display: block
}

.header-2 .rightside-menu .wishlist-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.header-2 .rightside-menu .wishlist-box .header-icon {
    width: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid #ececec;
    background-color: #fff;
    border-radius: 100%;
    padding: 10px;
    position: relative
}

.header-2 .rightside-menu .wishlist-box .header-icon+.header-icon {
    margin-left: 38px
}

.header-2 .rightside-menu .wishlist-box .header-icon+.header-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    left: -50%;
    background-color: #ececec
}

.header-2 .rightside-menu .wishlist-box .header-icon.swap-icon {
    margin-left: 0
}

.header-2 .rightside-menu .wishlist-box .header-icon.swap-icon::after {
    content: none
}

.header-2 .rightside-menu .wishlist-box .header-icon.bag-icon {
    display: block
}

@media (max-width: 480px) {
    .header-2 .rightside-menu .wishlist-box .header-icon.bag-icon {
        display: none
    }
}

.header-2 .rightside-menu .wishlist-box .header-icon.search-icon,
.header-2 .rightside-menu .wishlist-box .header-icon.user-icon {
    display: none
}

@media (max-width: 1199px) {

    .header-2 .rightside-menu .wishlist-box .header-icon.search-icon,
    .header-2 .rightside-menu .wishlist-box .header-icon.user-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .header-2 .rightside-menu .wishlist-box .header-icon.search-icon+.header-icon,
    .header-2 .rightside-menu .wishlist-box .header-icon.user-icon+.header-icon {
        margin-left: 38px
    }

    .header-2 .rightside-menu .wishlist-box .header-icon.search-icon+.header-icon::after,
    .header-2 .rightside-menu .wishlist-box .header-icon.user-icon+.header-icon::after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        left: -50%;
        background-color: #ececec
    }
}

.header-2 .rightside-menu .wishlist-box .header-icon .badge-number {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 0;
    background: var(--theme-color2);
    font-weight: 600;
    color: #fff;
    border-radius: 100%;
    font-size: 10px
}

.header-2 .rightside-menu .wishlist-box .header-icon .icli {
    color: #000;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.header-2 .rightside-menu .wishlist-box .header-icon .icli.iconly-Swap {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.header-2 .rightside-menu .option-list>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

[dir="rtl"] .header-2 .rightside-menu .option-list>ul {
    padding-right: 0
}

.header-2 .rightside-menu .option-list>ul>li {
    position: relative
}

.header-2 .rightside-menu .option-list>ul>li:nth-of-type(5n-3) {
    display: none
}

.header-2 .rightside-menu .option-list>ul>li:nth-of-type(5n-3)+li {
    margin-left: 0
}

.header-2 .rightside-menu .option-list>ul>li:nth-of-type(5n-3)+li::after {
    content: none
}

@media (max-width: 1199px) {
    .header-2 .rightside-menu .option-list>ul>li:nth-of-type(5n-3) {
        display: block
    }

    .header-2 .rightside-menu .option-list>ul>li:nth-of-type(5n-3)+li {
        margin-left: 22px
    }

    .header-2 .rightside-menu .option-list>ul>li:nth-of-type(5n-3)+li::after {
        content: ""
    }
}

@media (max-width: 767px) {
    .header-2 .rightside-menu .option-list>ul>li:not(:nth-last-of-type(5n-5)) {
        display: none
    }
}

.header-2 .rightside-menu .option-list>ul>li+li {
    margin-left: 37px
}

[dir="rtl"] .header-2 .rightside-menu .option-list>ul>li+li {
    margin-left: unset;
    margin-right: 37px
}

@media (max-width: 1460px) {
    .header-2 .rightside-menu .option-list>ul>li+li {
        margin-left: 22px
    }

    [dir="rtl"] .header-2 .rightside-menu .option-list>ul>li+li {
        margin-left: unset;
        margin-right: 22px
    }
}

.header-2 .rightside-menu .option-list>ul>li+li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    left: -20px;
    background-color: #ececec
}

[dir="rtl"] .header-2 .rightside-menu .option-list>ul>li+li::after {
    left: unset;
    right: -20px
}

@media (max-width: 1460px) {
    .header-2 .rightside-menu .option-list>ul>li+li::after {
        left: -13px
    }

    [dir="rtl"] .header-2 .rightside-menu .option-list>ul>li+li::after {
        left: unset;
        right: -13px
    }
}

.header-2 .rightside-menu .option-list>ul>li .header-icon {
    width: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid #ececec;
    background-color: #fff;
    border-radius: 100%;
    padding: 10px;
    position: relative
}

.header-2 .rightside-menu .option-list>ul>li .header-icon+.header-icon {
    margin-left: 38px
}

.header-2 .rightside-menu .option-list>ul>li .header-icon+.header-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    left: -50%;
    background-color: #ececec
}

.header-2 .rightside-menu .option-list>ul>li .header-icon.swap-icon {
    margin-left: 0
}

.header-2 .rightside-menu .option-list>ul>li .header-icon.swap-icon::after {
    content: none
}

@media (max-width: 480px) {
    .header-2 .rightside-menu .option-list>ul>li .header-icon.bag-icon {
        display: none
    }
}

.header-2 .rightside-menu .option-list>ul>li .header-icon.search-icon,
.header-2 .rightside-menu .option-list>ul>li .header-icon.user-icon {
    display: none
}

@media (max-width: 1199px) {

    .header-2 .rightside-menu .option-list>ul>li .header-icon.search-icon,
    .header-2 .rightside-menu .option-list>ul>li .header-icon.user-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .header-2 .rightside-menu .option-list>ul>li .header-icon.search-icon+.header-icon,
    .header-2 .rightside-menu .option-list>ul>li .header-icon.user-icon+.header-icon {
        margin-left: 38px
    }

    .header-2 .rightside-menu .option-list>ul>li .header-icon.search-icon+.header-icon::after,
    .header-2 .rightside-menu .option-list>ul>li .header-icon.user-icon+.header-icon::after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        left: -50%;
        background-color: #ececec
    }
}

.header-2 .rightside-menu .option-list>ul>li .header-icon .badge-number {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 0;
    background-color: var(--theme-color);
    font-weight: 600;
    color: #fff;
    border-radius: 100%;
    font-size: 10px
}

.header-2 .rightside-menu .option-list>ul>li .header-icon .icli {
    color: #000;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.header-2 .rightside-menu .option-list>ul>li .header-icon .icli.iconly-Swap {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.header-2 .rightside-menu .option-list-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.header-2 .rightside-menu .option-list-2 li {
    position: relative
}

.header-2 .rightside-menu .option-list-2 li .header-icon {
    width: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid #ececec;
    background-color: #fff;
    border-radius: 100%;
    padding: 10px;
    position: relative
}

.header-2 .rightside-menu .option-list-2 li .header-icon+.header-icon {
    margin-left: 38px
}

.header-2 .rightside-menu .option-list-2 li .header-icon+.header-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    left: -50%;
    background-color: #ececec
}

.header-2 .rightside-menu .option-list-2 li .header-icon .badge-number {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 0;
    background-color: var(--theme-color);
    font-weight: 600;
    color: #fff;
    border-radius: 100%;
    font-size: 10px
}

.header-2 .rightside-menu .option-list-2 li .header-icon .icli {
    color: #000;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.header-2 .rightside-menu .option-list-2 li .header-icon .icli.iconly-Swap {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.header-2 .rightside-menu .option-list-2 li .user-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.header-2 .rightside-menu .option-list-2 li .user-box:hover .user-name h4 {
    color: var(--theme-color)
}

@media (max-width: 1660px) {
    .header-2 .rightside-menu .option-list-2 li .user-box .user-name {
        display: none
    }
}

.header-2 .rightside-menu .option-list-2 li+li {
    margin-left: 37px
}

[dir="rtl"] .header-2 .rightside-menu .option-list-2 li+li {
    margin-left: unset;
    margin-right: 37px
}

@media (max-width: 1660px) {
    .header-2 .rightside-menu .option-list-2 li+li {
        margin-left: 22px
    }

    [dir="rtl"] .header-2 .rightside-menu .option-list-2 li+li {
        margin-left: unset;
        margin-right: 22px
    }
}

.header-2 .rightside-menu .option-list-2 li+li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    left: -20px;
    background-color: #ececec
}

[dir="rtl"] .header-2 .rightside-menu .option-list-2 li+li::after {
    left: unset;
    right: -20px
}

@media (max-width: 1660px) {
    .header-2 .rightside-menu .option-list-2 li+li::after {
        left: -13px
    }

    [dir="rtl"] .header-2 .rightside-menu .option-list-2 li+li::after {
        left: unset;
        right: -13px
    }
}

.header-2 .rightside-menu .mobile-app {
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    padding: 16px 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 18px
}

[dir="rtl"] .header-2 .rightside-menu .mobile-app {
    margin-left: unset;
    margin-right: 18px
}

.header-2 .rightside-menu .mobile-app .mobile-image {
    margin-right: 11px
}

[dir="rtl"] .header-2 .rightside-menu .mobile-app .mobile-image {
    margin-right: unset;
    margin-left: 11px
}

.header-2 .rightside-menu .mobile-app .mobile-image img {
    width: 15px;
    height: auto
}

.header-2 .rightside-menu .dropdown-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.header-2 .rightside-menu .dropdown-user .user-detail {
    margin-left: 15px
}

@media (max-width: 1366px) {
    .header-2 .rightside-menu .dropdown-user .user-detail {
        display: none
    }
}

.header-2 .rightside-menu .dropdown-user .user-detail h6 {
    color: #4a5568;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px
}

.header-2 .rightside-menu .dropdown-user .user-detail h5 {
    color: #222;
    font-size: 16px;
    margin-bottom: 0
}

.header-2 .right-nav {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto
}

[dir="rtl"] .header-2 .right-nav {
    margin-left: unset;
    margin-right: auto
}

@media (max-width: 1366px) {
    .header-2 .right-nav {
        display: none
    }
}

.header-2 .right-nav .nav-number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 1660px) {
    .header-2 .right-nav .nav-number {
        display: none
    }
}

.header-2 .right-nav .nav-number img {
    width: 32px;
    height: 32px
}

.header-2 .right-nav .nav-number span {
    font-size: calc(18px + (27 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 8px
}

[dir="rtl"] .header-2 .right-nav .nav-number span {
    margin-left: unset;
    margin-right: 8px
}

.header-2 .right-nav .fire-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 11px 38px;
    overflow: hidden
}

.header-2 .right-nav .fire-button span {
    color: #fff;
    margin-left: 7px;
    font-size: 16px;
    font-weight: 500
}

[dir="rtl"] .header-2 .right-nav .fire-button span {
    margin-left: unset;
    margin-right: 7px
}

.header-2 .right-nav .fire-button .fire {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: transparent;
    z-index: 1
}

.header-2 .right-nav .fire-button .fire img {
    margin-top: -11px
}

.header-2 .right-nav .fire-button .fire .fire-main {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation: scaleUpDown 3s ease-out;
    animation: scaleUpDown 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-2 .right-nav .fire-button .fire .fire-main .main-fire {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: radial-gradient(farthest-corner at 10px 0, #fff 0%, #ddd 95%);
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%
}

.header-2 .right-nav .fire-button .fire .fire-main .particle-fire {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 60%;
    left: 45%;
    background-color: #fff;
    border-radius: 50%;
    -webkit-animation: particleUp 2s ease-out 0;
    animation: particleUp 2s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-2 .right-nav .fire-button .fire .fire-right {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation: shake 2s ease-out 0;
    animation: shake 2s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-2 .right-nav .fire-button .fire .fire-right .main-fire {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 15%;
    right: -25%;
    background-color: #fff;
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%
}

.header-2 .right-nav .fire-button .fire .fire-right .main-fire .particle-fire {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 45%;
    left: 50%;
    background-color: #fff;
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 50%;
    -webkit-animation: particleUp 2s ease-out 0;
    animation: particleUp 2s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-2 .right-nav .fire-button .fire .fire-left {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation: shake 3s ease-out 0;
    animation: shake 3s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-2 .right-nav .fire-button .fire .fire-left .main-fire {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 15%;
    left: -20%;
    background-color: #fff;
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%
}

.header-2 .right-nav .fire-button .fire .fire-left .main-fire .particle-fire {
    width: 10%;
    height: 10%;
    position: absolute;
    top: 10%;
    left: 20%;
    background-color: #fff;
    border-radius: 50%;
    -webkit-animation: particleUp 3s infinite ease-out 0;
    animation: particleUp 3s infinite ease-out 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-2 .header-nav-left {
    position: relative
}

.header-2 .header-nav-left .dropdown-category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    padding: 9px calc(16px + (31 - 16) * ((100vw - 320px) / (1920 - 320))) 9px 0;
    position: relative
}

[dir="rtl"] .header-2 .header-nav-left .dropdown-category {
    padding: 9px 0 9px calc(16px + (31 - 16) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 767px) {
    .header-2 .header-nav-left .dropdown-category {
        display: none
    }
}

.header-2 .header-nav-left .dropdown-category::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #ececec
}

[dir="rtl"] .header-2 .header-nav-left .dropdown-category::after {
    right: unset;
    left: 0
}

.header-2 .header-nav-left .dropdown-category .icli {
    font-size: 23px;
    color: var(--theme-color);
    margin-right: 10px
}

[dir="rtl"] .header-2 .header-nav-left .dropdown-category .icli {
    margin-right: unset;
    margin-left: 10px
}

.header-2 .header-nav-left .dropdown-category span {
    font-size: 16px;
    margin-top: 4px
}

.header-2 .header-nav-left:hover .category-dropdown {
    opacity: 1;
    visibility: visible;
    top: 63px
}

.header-2 .header-nav-left .category-dropdown {
    position: absolute;
    top: 72px;
    left: 0;
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px #ddd;
    box-shadow: 0 0 8px #ddd;
    z-index: 2;
    width: 270px;
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 767px) {
    .header-2 .header-nav-left .category-dropdown {
        width: calc(300px + (320 - 300) * ((100vw - 320px) / (1920 - 320)));
        height: 100vh;
        position: fixed;
        top: 0;
        left: -320px;
        border-radius: 0;
        z-index: 10;
        padding: 0;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    [dir="rtl"] .header-2 .header-nav-left .category-dropdown {
        left: unset;
        right: -320px
    }

    .header-2 .header-nav-left .category-dropdown.show {
        left: 0
    }

    [dir="rtl"] .header-2 .header-nav-left .category-dropdown.show {
        left: unset;
        right: 0
    }

    .header-2 .header-nav-left .category-dropdown:hover {
        opacity: 1;
        visibility: visible;
        top: 0
    }
}

.header-2 .header-nav-left .category-dropdown .category-title {
    display: none
}

@media (max-width: 767px) {
    .header-2 .header-nav-left .category-dropdown .category-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 20px 24px;
        border-bottom: 1px solid #ececec;
        -webkit-box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1);
        box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1)
    }
}

.header-2 .header-nav-left .category-dropdown .category-title h5 {
    color: var(--theme-color);
    font-weight: 600
}

.header-2 .header-nav-left .category-dropdown .category-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 25px;
    padding: 20px 25px
}

.header-2 .header-nav-left .category-dropdown .category-list li {
    display: block;
    width: 100%
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name:hover h6 {
    letter-spacing: 0.3px
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name:hover h6::before {
    width: 65px
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name img {
    width: 22px;
    height: 22px;
    -o-object-fit: contain;
    object-fit: contain
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name h6 {
    font-size: 17px;
    font-weight: 500;
    position: relative;
    margin-left: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name h6:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name i {
    margin-left: auto
}

@media (max-width: 767px) {
    .header-2 .header-nav-left .category-dropdown .category-list li .category-name i {
        display: none
    }
}

[dir="rtl"] .header-2 .header-nav-left .category-dropdown .category-list li .category-name i {
    margin-left: unset;
    margin-right: auto
}

.header-2 .header-nav-left .category-dropdown .category-list li .category-name .according-menu {
    margin-left: auto
}

[dir="rtl"] .header-2 .header-nav-left .category-dropdown .category-list li .category-name .according-menu {
    margin-left: unset;
    margin-right: auto
}

.header-2 .header-nav-left .category-dropdown .category-list li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

.header-2 .header-nav-left .category-dropdown .category-list li a:hover h6 {
    letter-spacing: 0.3px
}

.header-2 .header-nav-left .category-dropdown .category-list li a:hover h6::before {
    width: 65px
}

.header-2 .header-nav-left .category-dropdown .category-list li a img {
    width: 22px;
    height: 22px;
    -o-object-fit: contain;
    object-fit: contain
}

.header-2 .header-nav-left .category-dropdown .category-list li a h6 {
    font-size: 17px;
    font-weight: 500;
    position: relative;
    margin-left: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.header-2 .header-nav-left .category-dropdown .category-list li a h6:before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.header-2 .header-nav-left .category-dropdown .category-list li a i {
    margin-left: auto
}

[dir="rtl"] .header-2 .header-nav-left .category-dropdown .category-list li a i {
    margin-left: unset;
    margin-right: auto
}

.header-2 .dropdown-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "Public Sans", sans-serif
}

@media (max-width: 767px) {
    .header-2 .dropdown-list {
        display: none
    }
}

.header-2 .dropdown-list li:last-child {
    margin-right: 0
}

.header-2 .dropdown-list li+li .dropdown {
    margin-left: 20px
}

.header-2 .dropdown-list li+li .dropdown::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    left: -12px;
    background-color: rgba(255, 255, 255, 0.4)
}

.header-2 .dropdown-list li:first-child::after {
    content: none
}

.header-2 .dropdown-list li .dropdown .dropdown-toggle {
    width: auto;
    display: block;
    background-color: transparent;
    border-radius: 5px;
    font-weight: 500;
    font-size: unset
}

.header-2 .dropdown-list li .dropdown .dropdown-toggle:after {
    content: none
}

.header-2 .dropdown-list li .dropdown .dropdown-toggle span {
    font-weight: 400
}

.header-2 .dropdown-list li .dropdown .dropdown-toggle .fa-chevron-down {
    margin-left: 10px
}

.header-2 .dropdown-list li .dropdown .dropdown-menu .dropdown-list {
    display: block
}

.header-2 .dropdown-list li .dropdown .dropdown-menu .dropdown-list+.dropdown-list {
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.header-2 .about-list {
    font-family: "Public Sans", sans-serif
}

@media (max-width: 991px) {
    .header-2 .about-list {
        display: none
    }
}

.header-2 .about-list li {
    position: relative;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 500
}

.header-2 .about-list li:first-child {
    margin-left: 0
}

.header-2 .about-list li:first-child::after {
    content: none
}

.header-2 .about-list li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
    left: -8px;
    background-color: rgba(255, 255, 255, 0.4)
}

.header-3 .navbar-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 480px) {
    .header-3 .navbar-top {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.header-3 .navbar-top .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.header-3 .navbar-top .support-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 9px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (max-width: 1432px) {
    .header-3 .navbar-top .support-box {
        display: none
    }
}

.header-3 .navbar-top .support-box .support-image img {
    width: 33px;
    height: auto
}

.header-3 .navbar-top .support-box .support-number {
    text-align: right
}

.header-3 .navbar-top .support-box .support-number h2 {
    font-size: 25px;
    color: #fff
}

.header-3 .navbar-top .support-box .support-number h4 {
    color: #fff;
    margin-top: 4px;
    font-weight: 500;
    font-size: 16px
}

.header-3 .search-box {
    display: none
}

@media (max-width: 991px) {
    .header-3 .search-box {
        display: block;
        background-color: #fff;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #4a5568;
        text-align: right;
        margin-left: auto
    }

    .header-3 .search-box a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: #000
    }

    .header-3 .search-box a .icli {
        font-size: 25px
    }
}

@media (max-width: 480px) {
    .header-3 .search-box {
        margin-left: unset
    }
}

.header-3 .dropdown-list.drop-state {
    margin-right: 20px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 1199px) {
    .header-3 .dropdown-list.drop-state {
        margin-right: 0
    }
}

@media (max-width: 991px) {
    .header-3 .dropdown-list {
        background-color: transparent;
        margin: 0
    }
}

.header-3 .dropdown-list .location-icon {
    margin-right: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.header-3 .dropdown-list .location-icon .iconly-Location {
    font-size: 25px
}

.header-3 .dropdown-list .dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.header-3 .dropdown-list .dropdown .dropdown-toggle {
    padding-left: 0;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.5px;
    position: relative;
    margin-top: 4px
}

.header-3 .dropdown-list .dropdown .dropdown-toggle::after {
    display: none
}

.header-3 .dropdown-list .dropdown .dropdown-toggle i {
    color: #000;
    margin-left: 5px
}

.header-3 .dropdown-list .dropdown .dropdown-menu {
    min-width: 100%
}

.header-3 .dropdown-list .dropdown .dropdown-menu li {
    display: block
}

.header-3 .form-select {
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 0;
    font-family: "Public Sans", sans-serif;
    color: #222
}

.header-3 .form-select:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.header-3 .top-nav {
    padding: 14px 0;
    background-color: var(--theme-color)
}

@media (max-width: 1199px) {
    .header-3 .top-nav {
        border-bottom: unset
    }
}

@media (max-width: 767px) {
    .header-3 .top-nav .navbar-top {
        -webkit-box-pack: normal;
        -ms-flex-pack: normal;
        justify-content: normal
    }
}

.header-3 .top-nav span {
    color: #222
}

.header-3 .top-nav span .icli {
    font-size: 25px;
    margin-top: 2px;
    color: #fff;
    -webkit-text-fill-color: #fff
}

.header-3 .top-nav .nav-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color);
    font-weight: bolder;
    font-family: "Public Sans", sans-serif
}

.header-3 .top-nav .nav-logo span {
    color: #222
}

.header-3 .top-nav .search-full {
    width: 0;
    height: 0;
    background-color: #fff;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 360px) {
    .header-3 .top-nav .search-full {
        right: -5px
    }
}

.header-3 .top-nav .search-full .input-group {
    border: 1px solid #f0f3f8;
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14)
}

.header-3 .top-nav .search-full .input-group .input-group-text {
    background-color: #fff;
    border: none;
    color: #4a5568
}

.header-3 .top-nav .search-full .input-group .input-group-text.close-search {
    cursor: pointer
}

.header-3 .top-nav .search-full .input-group input {
    border: none;
    height: 50px
}

.header-3 .top-nav .search-full.open {
    width: 100%;
    -webkit-animation: zoomIn 0.5s ease-in-out;
    animation: zoomIn 0.5s ease-in-out;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 0 30px
}

@media (max-width: 575px) {
    .header-3 .top-nav .search-full.open {
        padding: 0 25px
    }
}

@media (max-width: 480px) {
    .header-3 .top-nav .search-full.open {
        padding: 0 15px
    }
}

@media (max-width: 360px) {
    .header-3 .top-nav .search-full.open {
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        padding: 0;
        width: 95%
    }
}

.header-3 .top-nav .search-full.show .search-suggestion {
    padding: 10px;
    border: 1px solid #f0f3f8;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    max-height: 500px;
    -webkit-transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99);
    transition: max-height 1000ms cubic-bezier(0.27, 0.7, 0, 0.99)
}

.header-3 .top-nav .middle-box {
    display: inline-block;
    margin-left: calc(16px + (29 - 16) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .header-3 .top-nav .middle-box {
    margin-left: unset;
    margin-right: calc(16px + (29 - 16) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 1366px) {
    .header-3 .top-nav .middle-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-left: 25px;
        float: right
    }
}

@media (max-width: 1199px) {
    .header-3 .top-nav .middle-box {
        margin-left: 15px
    }
}

@media (max-width: 991px) {
    .header-3 .top-nav .middle-box {
        margin-left: auto
    }
}

.header-3 .top-nav .middle-box .center-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 767px) {
    .header-3 .top-nav .middle-box {
        display: block !important
    }
}

.header-3 .top-nav .middle-box .searchbar-box-2 {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 853px;
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    overflow: hidden
}

@media (max-width: 1742px) {
    .header-3 .top-nav .middle-box .searchbar-box-2 {
        width: 800px
    }
}

@media (max-width: 1668px) {
    .header-3 .top-nav .middle-box .searchbar-box-2 {
        width: 760px
    }
}

@media (max-width: 1610px) {
    .header-3 .top-nav .middle-box .searchbar-box-2 {
        width: 710px
    }
}

@media (max-width: 1544px) {
    .header-3 .top-nav .middle-box .searchbar-box-2 {
        width: 670px
    }
}

@media (max-width: 991px) {
    .header-3 .top-nav .middle-box .searchbar-box-2 {
        display: none
    }
}

.header-3 .top-nav .middle-box .searchbar-box-2 .search-button {
    background-color: #ffb803;
    font-weight: 500
}

.header-3 .top-nav .middle-box .searchbar-box-2 .search-icon {
    font-size: 27px;
    background-color: #fff;
    padding-left: 16px;
    padding-right: 5px
}

[dir="rtl"] .header-3 .top-nav .middle-box .searchbar-box-2 .search-icon {
    padding-left: 5px;
    padding-right: 16px
}

.header-3 .top-nav .middle-box .searchbar-box-2 .search-icon i {
    color: var(--theme-color)
}

.header-3 .top-nav .middle-box .searchbar-box-2 input {
    height: 55px;
    padding: 8px;
    color: #222;
    border: none;
    font-size: 16px;
    z-index: 0
}

.header-3 .top-nav .middle-box .searchbar-box-2 input:focus {
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14)
}

.header-3 .top-nav .middle-box .searchbar-box-2 input::-webkit-input-placeholder {
    color: #4a5568;
    font-size: 16px
}

.header-3 .top-nav .middle-box .searchbar-box-2 input::-moz-placeholder {
    color: #4a5568;
    font-size: 16px
}

.header-3 .top-nav .middle-box .searchbar-box-2 input:-ms-input-placeholder {
    color: #4a5568;
    font-size: 16px
}

.header-3 .top-nav .middle-box .searchbar-box-2 input::-ms-input-placeholder {
    color: #4a5568;
    font-size: 16px
}

.header-3 .top-nav .middle-box .searchbar-box-2 input::placeholder {
    color: #4a5568;
    font-size: 16px
}

.header-3 .top-nav .middle-box .location-box-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 20px;
    position: relative
}

[dir="rtl"] .header-3 .top-nav .middle-box .location-box-2 {
    margin-right: unset;
    margin-left: 20px
}

@media (max-width: 1199px) {
    .header-3 .top-nav .middle-box .location-box-2 {
        margin-right: unset;
        margin-left: 0
    }
}

.header-3 .top-nav .middle-box .location-box-2 button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0
}

.header-3 .top-nav .middle-box .location-box-2 button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.header-3 .top-nav .middle-box .location-box-2 button .icli {
    margin-right: 9px;
    font-size: 22px;
    color: #fff
}

[dir="rtl"] .header-3 .top-nav .middle-box .location-box-2 button .icli {
    margin-right: unset;
    margin-left: 9px
}

@media (max-width: 991px) {
    .header-3 .top-nav .middle-box .location-box-2 button .icli {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
        height: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
        margin-right: 0
    }
}

.header-3 .top-nav .middle-box .location-box-2 button span {
    font-size: 16px;
    color: #fff
}

@media (max-width: 991px) {
    .header-3 .top-nav .middle-box .location-box-2 button span {
        display: none
    }
}

.header-3 .top-nav .middle-box .location-box-2 button .down-arrow {
    margin-left: 10px;
    color: #fff
}

[dir="rtl"] .header-3 .top-nav .middle-box .location-box-2 button .down-arrow {
    margin-left: unset;
    margin-right: 10px
}

@media (max-width: 991px) {
    .header-3 .top-nav .middle-box .location-box-2 button .down-arrow {
        display: none
    }
}

.header-3 .rightside-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto
}

@media (max-width: 1432px) {
    .header-3 .rightside-menu.support-sidemenu {
        display: none
    }
}

[dir="rtl"] .header-3 .rightside-menu {
    margin-left: unset;
    margin-right: auto
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu {
        position: absolute;
        top: -49px;
        right: 0
    }

    [dir="rtl"] .header-3 .rightside-menu {
        right: unset;
        left: 0
    }
}

@media (max-width: 991px) {
    .header-3 .rightside-menu {
        top: -53px;
        right: 78px
    }
}

@media (max-width: 767px) {
    .header-3 .rightside-menu {
        top: -51px
    }
}

@media (max-width: 480px) {
    .header-3 .rightside-menu {
        top: -49px;
        right: 58px
    }
}

@media (max-width: 360px) {
    .header-3 .rightside-menu {
        right: 64px
    }
}

.header-3 .rightside-menu .dropdown-dollar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 22px
}

@media (max-width: 991px) {
    .header-3 .rightside-menu .dropdown-dollar {
        display: none
    }
}

.header-3 .rightside-menu .dropdown-dollar .dropdown {
    position: relative;
    z-index: 0
}

.header-3 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    position: relative;
    border: none;
    background-color: transparent;
    padding: 0
}

.header-3 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle i {
    margin-left: 8px
}

[dir="rtl"] .header-3 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle i {
    margin-left: unset;
    margin-right: 8px
}

.header-3 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle::after {
    display: none
}

.header-3 .rightside-menu .dropdown-dollar .dropdown+.dropdown {
    margin-left: 23px
}

[dir="rtl"] .header-3 .rightside-menu .dropdown-dollar .dropdown+.dropdown {
    margin-left: unset;
    margin-right: 23px
}

.header-3 .rightside-menu .dropdown-dollar .dropdown+.dropdown::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 25px;
    left: -10px;
    background-color: #ececec
}

[dir="rtl"] .header-3 .rightside-menu .dropdown-dollar .dropdown+.dropdown::after {
    left: unset;
    right: -10px
}

.header-3 .rightside-menu .dropdown-dollar .dropdown .dropdown-menu {
    min-width: 100%
}

.header-3 .rightside-menu .dropdown-dollar .dropdown .dropdown-menu li {
    display: block
}

.header-3 .rightside-menu .wishlist-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.header-3 .rightside-menu .wishlist-box .header-icon {
    width: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid #ececec;
    background-color: #fff;
    border-radius: 100%;
    padding: 10px;
    position: relative
}

.header-3 .rightside-menu .wishlist-box .header-icon+.header-icon {
    margin-left: 38px
}

.header-3 .rightside-menu .wishlist-box .header-icon+.header-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    left: -50%;
    background-color: #ececec
}

.header-3 .rightside-menu .wishlist-box .header-icon.swap-icon {
    margin-left: 0
}

.header-3 .rightside-menu .wishlist-box .header-icon.swap-icon::after {
    content: none
}

.header-3 .rightside-menu .wishlist-box .header-icon.bag-icon {
    display: block
}

@media (max-width: 480px) {
    .header-3 .rightside-menu .wishlist-box .header-icon.bag-icon {
        display: none
    }
}

.header-3 .rightside-menu .wishlist-box .header-icon.search-icon,
.header-3 .rightside-menu .wishlist-box .header-icon.user-icon {
    display: none
}

@media (max-width: 1199px) {

    .header-3 .rightside-menu .wishlist-box .header-icon.search-icon,
    .header-3 .rightside-menu .wishlist-box .header-icon.user-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .header-3 .rightside-menu .wishlist-box .header-icon.search-icon+.header-icon,
    .header-3 .rightside-menu .wishlist-box .header-icon.user-icon+.header-icon {
        margin-left: 38px
    }

    .header-3 .rightside-menu .wishlist-box .header-icon.search-icon+.header-icon::after,
    .header-3 .rightside-menu .wishlist-box .header-icon.user-icon+.header-icon::after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        left: -50%;
        background-color: #ececec
    }
}

.header-3 .rightside-menu .wishlist-box .header-icon .badge-number {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 0;
    background: var(--theme-color2);
    font-weight: 600;
    color: #fff;
    border-radius: 100%;
    font-size: 10px
}

.header-3 .rightside-menu .wishlist-box .header-icon .icli {
    color: #000;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.header-3 .rightside-menu .wishlist-box .header-icon .icli.iconly-Swap {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.header-3 .rightside-menu .option-list-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

[dir="rtl"] .header-3 .rightside-menu .option-list-2 {
    padding-right: 0
}

.header-3 .rightside-menu .option-list-2 li {
    position: relative
}

.header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4) {
    display: none
}

.header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4)+li {
    margin-left: 0
}

[dir="rtl"] .header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4)+li {
    margin-left: unset;
    margin-right: 0
}

.header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4)+li::after {
    content: none
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4) {
        display: block
    }

    .header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4)+li {
        margin-left: 22px
    }

    [dir="rtl"] .header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4)+li {
        margin-left: unset;
        margin-right: 22px
    }

    .header-3 .rightside-menu .option-list-2 li:nth-of-type(5n-4)+li::after {
        content: ""
    }
}

@media (max-width: 767px) {
    .header-3 .rightside-menu .option-list-2 li:not(:nth-last-of-type(5n-5)) {
        display: none
    }
}

.header-3 .rightside-menu .option-list-2 li .header-icon {
    width: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid #ececec;
    background-color: #fff;
    border-radius: 100%;
    padding: 10px;
    position: relative
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .option-list-2 li .header-icon {
        border: none;
        background-color: transparent
    }
}

.header-3 .rightside-menu .option-list-2 li .header-icon+.header-icon {
    margin-left: 38px
}

.header-3 .rightside-menu .option-list-2 li .header-icon+.header-icon::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    left: -50%;
    background-color: #ececec
}

.header-3 .rightside-menu .option-list-2 li .header-icon .badge-number {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 0;
    background-color: var(--theme-color);
    font-weight: 600;
    color: #fff;
    border-radius: 100%;
    font-size: 10px
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .option-list-2 li .header-icon .badge-light {
        background-color: #fff;
        color: var(--theme-color);
        top: -3px
    }
}

.header-3 .rightside-menu .option-list-2 li .header-icon .icli {
    color: #000;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.header-3 .rightside-menu .option-list-2 li .header-icon .icli.iconly-Swap {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .option-list-2 li .header-icon .icli {
        color: #fff
    }
}

.header-3 .rightside-menu .option-list-2 li+li {
    margin-left: 37px
}

[dir="rtl"] .header-3 .rightside-menu .option-list-2 li+li {
    margin-left: unset;
    margin-right: 37px
}

@media (max-width: 1660px) {
    .header-3 .rightside-menu .option-list-2 li+li {
        margin-left: 22px
    }

    [dir="rtl"] .header-3 .rightside-menu .option-list-2 li+li {
        margin-left: unset;
        margin-right: 22px
    }
}

.header-3 .rightside-menu .option-list-2 li+li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    left: -20px;
    background-color: #ececec
}

[dir="rtl"] .header-3 .rightside-menu .option-list-2 li+li::after {
    left: unset;
    right: -20px
}

@media (max-width: 1660px) {
    .header-3 .rightside-menu .option-list-2 li+li::after {
        left: -13px
    }

    [dir="rtl"] .header-3 .rightside-menu .option-list-2 li+li::after {
        left: unset;
        right: -13px
    }
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .option-list-2 li+li::after {
        background-color: rgba(255, 255, 255, 0.2)
    }
}

.header-3 .rightside-menu .user-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 37px;
    position: relative
}

[dir="rtl"] .header-3 .rightside-menu .user-box {
    margin-left: unset;
    margin-right: 37px
}

@media (max-width: 1660px) {
    .header-3 .rightside-menu .user-box {
        margin-left: 22px
    }

    [dir="rtl"] .header-3 .rightside-menu .user-box {
        margin-left: unset;
        margin-right: 22px
    }
}

@media (max-width: 767px) {
    .header-3 .rightside-menu .user-box {
        margin-left: 0
    }

    [dir="rtl"] .header-3 .rightside-menu .user-box {
        margin-left: unset;
        margin-right: 0
    }
}

.header-3 .rightside-menu .user-box::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    left: -20px;
    background-color: #ececec
}

[dir="rtl"] .header-3 .rightside-menu .user-box::after {
    left: unset;
    right: -20px
}

@media (max-width: 1660px) {
    .header-3 .rightside-menu .user-box::after {
        left: -13px
    }

    [dir="rtl"] .header-3 .rightside-menu .user-box::after {
        left: unset;
        right: -13px
    }
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .user-box::after {
        background-color: rgba(255, 255, 255, 0.2)
    }
}

@media (max-width: 767px) {
    .header-3 .rightside-menu .user-box::after {
        content: none
    }
}

.header-3 .rightside-menu .user-box .header-icon {
    width: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (46 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid #ececec;
    background-color: #fff;
    border-radius: 100%;
    padding: 10px;
    position: relative
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .user-box .header-icon {
        border: none;
        background-color: transparent
    }
}

.header-3 .rightside-menu .user-box .header-icon .badge-number {
    width: 18px;
    height: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: 0;
    background-color: var(--theme-color);
    font-weight: 600;
    color: #fff;
    border-radius: 100%;
    font-size: 10px
}

.header-3 .rightside-menu .user-box .header-icon .icli {
    color: #000;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 1199px) {
    .header-3 .rightside-menu .user-box .header-icon .icli {
        color: #fff
    }
}

.header-3 .rightside-menu .user-box:hover .user-name h4 {
    color: var(--theme-color)
}

@media (max-width: 1660px) {
    .header-3 .rightside-menu .user-box .user-name {
        display: none
    }
}

.header-3 .rightside-menu .mobile-app {
    position: relative;
    border-radius: 5px;
    padding: 16px 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 18px;
    color: var(--theme-color)
}

.header-3 .rightside-menu .mobile-app::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.1;
    z-index: -1
}

[dir="rtl"] .header-3 .rightside-menu .mobile-app {
    margin-left: unset;
    margin-right: 18px
}

.header-3 .rightside-menu .mobile-app .mobile-image {
    margin-right: 11px
}

[dir="rtl"] .header-3 .rightside-menu .mobile-app .mobile-image {
    margin-right: unset;
    margin-left: 11px
}

.header-3 .rightside-menu .mobile-app .mobile-image img {
    width: 15px;
    height: auto
}

.header-3 .rightside-menu .dropdown-user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.header-3 .rightside-menu .dropdown-user .user-detail {
    margin-left: 15px
}

@media (max-width: 1366px) {
    .header-3 .rightside-menu .dropdown-user .user-detail {
        display: none
    }
}

.header-3 .rightside-menu .dropdown-user .user-detail h6 {
    color: #4a5568;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px
}

.header-3 .rightside-menu .dropdown-user .user-detail h5 {
    color: #222;
    font-size: 16px;
    margin-bottom: 0
}

.header-3 .right-nav {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto
}

[dir="rtl"] .header-3 .right-nav {
    margin-left: unset;
    margin-right: auto
}

@media (max-width: 1366px) {
    .header-3 .right-nav {
        display: none
    }
}

.header-3 .right-nav .nav-number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 1660px) {
    .header-3 .right-nav .nav-number {
        display: none
    }
}

.header-3 .right-nav .nav-number img {
    width: 32px;
    height: 32px
}

.header-3 .right-nav .nav-number span {
    font-size: calc(18px + (27 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 8px
}

[dir="rtl"] .header-3 .right-nav .nav-number span {
    margin-left: unset;
    margin-right: 8px
}

.header-3 .right-nav .fire-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 11px 38px;
    overflow: hidden
}

.header-3 .right-nav .fire-button span {
    color: #fff;
    margin-left: 7px;
    font-size: 16px;
    font-weight: 500
}

[dir="rtl"] .header-3 .right-nav .fire-button span {
    margin-left: unset;
    margin-right: 7px
}

.header-3 .right-nav .fire-button .fire {
    width: 20px;
    height: 20px;
    position: relative;
    background-color: transparent;
    z-index: 1
}

.header-3 .right-nav .fire-button .fire .fire-main {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation: scaleUpDown 3s ease-out;
    animation: scaleUpDown 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-3 .right-nav .fire-button .fire .fire-main .main-fire {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: radial-gradient(farthest-corner at 10px 0, #fff 0%, #ddd 95%);
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%
}

.header-3 .right-nav .fire-button .fire .fire-main .particle-fire {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 60%;
    left: 45%;
    background-color: #fff;
    border-radius: 50%;
    -webkit-animation: particleUp 2s ease-out 0;
    animation: particleUp 2s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-3 .right-nav .fire-button .fire .fire-right {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation: shake 2s ease-out 0;
    animation: shake 2s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-3 .right-nav .fire-button .fire .fire-right .main-fire {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 15%;
    right: -25%;
    background-color: #fff;
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%
}

.header-3 .right-nav .fire-button .fire .fire-right .main-fire .particle-fire {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 45%;
    left: 50%;
    background-color: #fff;
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 50%;
    -webkit-animation: particleUp 2s ease-out 0;
    animation: particleUp 2s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-3 .right-nav .fire-button .fire .fire-left {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-animation: shake 3s ease-out 0;
    animation: shake 3s ease-out 0;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-3 .right-nav .fire-button .fire .fire-left .main-fire {
    width: 80%;
    height: 80%;
    position: absolute;
    top: 15%;
    left: -20%;
    background-color: #fff;
    -webkit-transform: scaleX(0.8) rotate(45deg);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%
}

.header-3 .right-nav .fire-button .fire .fire-left .main-fire .particle-fire {
    width: 10%;
    height: 10%;
    position: absolute;
    top: 10%;
    left: 20%;
    background-color: #fff;
    border-radius: 50%;
    -webkit-animation: particleUp 3s infinite ease-out 0;
    animation: particleUp 3s infinite ease-out 0;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.header-3 .dropdown-category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    padding: 9px calc(16px + (31 - 16) * ((100vw - 320px) / (1920 - 320))) 9px 0;
    position: relative
}

[dir="rtl"] .header-3 .dropdown-category {
    padding: 9px 0 9px calc(16px + (31 - 16) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 1199px) {
    .header-3 .dropdown-category {
        display: none
    }
}

.header-3 .dropdown-category::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #ececec
}

[dir="rtl"] .header-3 .dropdown-category::after {
    right: unset;
    left: 0
}

.header-3 .dropdown-category .icli {
    font-size: 23px;
    color: var(--theme-color);
    margin-right: 10px
}

[dir="rtl"] .header-3 .dropdown-category .icli {
    margin-right: unset;
    margin-left: 10px
}

.header-3 .dropdown-category span {
    font-size: 16px;
    margin-top: 4px
}

.header-3 .dropdown-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "Public Sans", sans-serif
}

@media (max-width: 767px) {
    .header-3 .dropdown-list {
        display: none
    }
}

.header-3 .dropdown-list li:last-child {
    margin-right: 0
}

.header-3 .dropdown-list li+li .dropdown {
    margin-left: 20px
}

.header-3 .dropdown-list li+li .dropdown::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    left: -12px;
    background-color: rgba(255, 255, 255, 0.4)
}

.header-3 .dropdown-list li:first-child::after {
    content: none
}

.header-3 .dropdown-list li .dropdown .dropdown-toggle {
    width: auto;
    display: block;
    background-color: transparent;
    border-radius: 5px;
    font-weight: 500;
    font-size: unset
}

.header-3 .dropdown-list li .dropdown .dropdown-toggle:after {
    content: none
}

.header-3 .dropdown-list li .dropdown .dropdown-toggle span {
    font-weight: 400
}

.header-3 .dropdown-list li .dropdown .dropdown-toggle .fa-chevron-down {
    margin-left: 10px
}

.header-3 .dropdown-list li .dropdown .dropdown-menu .dropdown-list {
    display: block
}

.header-3 .dropdown-list li .dropdown .dropdown-menu .dropdown-list+.dropdown-list {
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: nowrap
}

.header-3 .about-list {
    font-family: "Public Sans", sans-serif
}

@media (max-width: 991px) {
    .header-3 .about-list {
        display: none
    }
}

.header-3 .about-list li {
    position: relative;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 500
}

.header-3 .about-list li:first-child {
    margin-left: 0
}

.header-3 .about-list li:first-child::after {
    content: none
}

.header-3 .about-list li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
    left: -8px;
    background-color: rgba(255, 255, 255, 0.4)
}

.bg-effect {
    background-image: url("../../../public/assets/images/bg-body.png");
    background-repeat: no-repeat;
    background-position: center
}

.home-contain {
    overflow: hidden;
    position: relative;
    border-radius: 10px
}

.home-contain .home-detail {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.home-contain .home-detail.home-big-space {
    padding: calc(27px + (185 - 27) * ((100vw - 320px) / (1920 - 320))) 0 !important
}

@media (max-width: 767px) {
    .home-contain .home-detail.home-overlay {
        background-color: rgba(255, 255, 255, 0.529)
    }
}

.home-contain .home-detail>.home-top {
    margin-top: -100px
}

@media (max-width: 575px) {
    .home-contain .home-detail>.home-top {
        margin-top: -80px
    }
}

@media (max-width: 480px) {
    .home-contain .home-detail>.home-top {
        margin-top: 0
    }
}

.home-contain .home-detail.home-p-sm {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) !important
}

.home-contain .home-detail.home-p-medium {
    padding: 50px calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))) !important
}

.home-contain .home-detail .ls-expanded {
    letter-spacing: 2.2px
}

.home-contain .home-detail h1 {
    font-size: calc(16px + (42 - 16) * ((100vw - 320px) / (1920 - 320)));
    width: 80%;
    line-height: 1.4;
    margin-bottom: calc(3px + (10 - 3) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 480px) {
    .home-contain .home-detail h1 {
        width: 100%
    }
}

[dir="rtl"] .home-contain .home-detail h1 {
    margin-right: auto;
    text-align: left
}

.home-contain .home-detail h1.super-sale {
    font-size: 45px
}

.home-contain .home-detail h1.name-title {
    font-size: calc(25px + (42 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.home-contain .home-detail h1.home-name {
    font-size: 86px;
    font-weight: 700;
    position: relative;
    display: inline-block
}

.home-contain .home-detail h1.home-name::before {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    bottom: 0;
    background-color: #222
}

.home-contain .home-detail h1.furniture-heading span {
    position: relative
}

.home-contain .home-detail h1.furniture-heading span::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url(../../../public/assets/images/furniture/banner/line.png);
    width: 100%;
    height: 100%;
    top: 90%;
    background-repeat: no-repeat
}

.home-contain .home-detail h1.poster-1 {
    line-height: calc(23px + (68 - 23) * ((100vw - 320px) / (1920 - 320)))
}

.home-contain .home-detail h1.poster-2 {
    line-height: calc(29px + (63 - 29) * ((100vw - 320px) / (1920 - 320)))
}

.home-contain .home-detail h1 span.name {
    font-weight: 800;
    color: var(--theme-color);
    position: relative;
    z-index: 0
}

.home-contain .home-detail h1 span.name::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 10px;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.4;
    z-index: -1
}

@media (max-width: 767px) {
    .home-contain .home-detail h1 span.name::before {
        display: none
    }
}

@media (max-width: 1399px) {
    .home-contain .home-detail h1 span.name::before {
        content: none
    }
}

.home-contain .home-detail h1 span.name-2 {
    font-weight: 800;
    color: var(--theme-color)
}

.home-contain .home-detail h1 .daily {
    color: var(--theme-color);
    font-weight: 800
}

.home-contain .home-detail h1 .discount {
    font-size: 16px;
    color: #262834;
    font-weight: 400
}

.home-contain .home-detail h2 {
    font-size: calc(25px + (38 - 25) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 7px
}

[dir="rtl"] .home-contain .home-detail h2 {
    text-align: left
}

.home-contain .home-detail h2 span.discount {
    font-size: 16px;
    font-weight: 400
}

.home-contain .home-detail h2.home-name {
    font-size: calc(52px + (85 - 52) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700
}

.home-contain .home-detail h3 {
    font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

[dir="rtl"] .home-contain .home-detail h3 {
    text-align: left
}

.home-contain .home-detail h4 {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 5px
}

[dir="rtl"] .home-contain .home-detail h4 {
    text-align: left
}

.home-contain .home-detail h5 {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px
}

[dir="rtl"] .home-contain .home-detail h5 {
    text-align: left
}

.home-contain .home-detail h6 {
    font-size: 14px;
    letter-spacing: 0.1px;
    color: #4a5568
}

[dir="rtl"] .home-contain .home-detail h6 {
    text-align: left
}

@media (max-width: 360px) {
    .home-contain .home-detail h6 {
        margin-bottom: 5px
    }
}

.home-contain .home-detail h6.home-home {
    margin-bottom: 10px;
    font-weight: 500
}

.home-contain .home-detail h6 span {
    font-weight: 500;
    padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    background: #000;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 114, 114, 0.15)), color-stop(79%, rgba(255, 114, 114, 0)));
    background: linear-gradient(90deg, rgba(255, 114, 114, 0.15) 0%, rgba(255, 114, 114, 0) 79%);
    border-radius: 50px;
    color: #ff4f4f;
    margin-left: calc(2px + (9 - 2) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .home-contain .home-detail h6 span {
    margin-left: unset;
    margin-right: calc(2px + (9 - 2) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 360px) {
    .home-contain .home-detail h6 span {
        display: none
    }
}

.home-contain .home-detail p {
    margin-bottom: 0;
    color: #4a5568;
    letter-spacing: 0.8px;
    line-height: calc(19px + (27 - 19) * ((100vw - 320px) / (1920 - 320)));
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

[dir="rtl"] .home-contain .home-detail p {
    margin-right: auto;
    text-align: left
}

.home-contain .home-detail p.organic {
    width: 70%
}

.home-contain .home-detail .shop-button {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #222
}

[dir="rtl"] .home-contain .home-detail .shop-button {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.home-contain .home-detail .shop-button:hover i {
    -webkit-transform: translateX(4px);
    transform: translateX(4px)
}

.home-contain .home-detail .shop-button i {
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    margin-left: 8px
}

[dir="rtl"] .home-contain .home-detail .shop-button i {
    margin-left: unset;
    margin-right: 8px
}

.home-contain .home-detail .social-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 10px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 14px
}

.home-contain .home-detail .social-icon li:hover a {
    border-color: var(--theme-color);
    background-color: var(--theme-color);
    color: #fff
}

.home-contain .home-detail .social-icon li a {
    width: 28px;
    height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid #4a5568;
    color: #222
}


.home-section-2 .home-contain {
    border-radius: 10px;
    overflow: hidden;
    position: relative
}
.hover-theme-color:hover{
    color: var(--theme-color) !important;
}
.hover-bg-theme-color:hover{
    background-color: var(--theme-color) !important;
    color:#fff !important

}
.bg-theme-color{
    background-color: var(--theme-color) !important;
    color:#fff !important
}
.bg-theme-light{
    background-color:rgba(255,127,0,0.16) !important;
}
.hover-bg-light:hover{
    background-color:rgba(255,127,0,0.16) !important;
    color: var(--theme-color) !important;
}
.home-section-2 .home-contain .home-detail {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.home-section-2 .home-contain .home-detail.home-p-sm {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320))) !important
}

.home-section-2 .home-contain .home-detail h1 {
    font-size: calc(25px + (45 - 25) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 6px
}

.home-section-2 .home-contain .home-detail h1.sale-name {
    font-size: calc(25px + (38 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.home-section-2 .home-contain .home-detail h1.heding-2 {
    font-size: calc(22px + (50 - 22) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 13px;
    margin-top: 7px;
    width: 100%
}

.home-section-2 .home-contain .home-detail h2 {
    font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px
}

.home-section-2 .home-contain .home-detail h2.content-2 {
    font-size: calc(16px + (36 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px;
    font-weight: 500
}

.home-section-2 .home-contain .home-detail h3 {
    font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px
}

.home-section-2 .home-contain .home-detail h4 {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 5px
}

.home-section-2 .home-contain .home-detail h5 {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px
}

.home-section-2 .home-contain .home-detail h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)))
}

.home-section-2 .home-contain .home-detail p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
    line-height: 1.5;
    color: #4a5568;
    letter-spacing: 0.8px
}

.home-section-2 .home-contain .home-detail .shop-now-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    color: var(--theme-color);
    font-size: 15px
}

.home-section-2 .home-contain .home-detail .shop-now-button i {
    font-size: 17px;
    margin-left: 8px
}

.home-section-small .home-contain .home-detail.home-width {
    width: 50%
}

@media (max-width: 360px) {
    .home-section-small .home-contain .home-detail.home-width:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.25);
        z-index: -1
    }
}

@media (max-width: 991px) {
    .home-section-small .home-contain .home-detail.home-width {
        width: 60%
    }
}

@media (max-width: 360px) {
    .home-section-small .home-contain .home-detail.home-width {
        width: 100%
    }
}

.home-section-small .home-contain.home-small .home-detail {
    padding: calc(20px + (50 - 20) * ((100vw - 767px) / (1920 - 767))) calc(12px + (50 - 12) * ((100vw - 767px) / (1920 - 767)))
}

.home-section-small .home-contain.home-small .home-detail h4 {
    font-size: calc(14px + (22 - 14) * ((100vw - 767px) / (1920 - 767)))
}

.home-search {
    background-image: -webkit-gradient(linear, left bottom, right top, from(rgba(255, 127, 0, 1)), color-stop(#06a188), color-stop(#019e88), color-stop(#009a89), color-stop(#009789), color-stop(#009489), color-stop(#009089), color-stop(#008d89), color-stop(#008a89), color-stop(#008688), color-stop(#008387), to(#007f86));
    background-image: linear-gradient(to right top, rgba(255, 127, 0, 1), #06a188, #019e88, #009a89, #009789, #009489, #009089, #008d89, #008a89, #008688, #008387, #007f86);
    padding-top: 0
}

.home-search .home-search-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-top: 156px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding-bottom: 70px
}

.home-search .home-search-content>div {
    width: 100%
}

.home-search .home-search-content h1 {
    color: #fff;
    font-size: 42px;
    text-transform: inherit
}

.home-search .home-search-content h3 {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin-top: 12px
}

.home-search .home-search-content .search-box {
    margin-top: 35px;
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: auto
}

.home-search .home-search-content .search-box .btn {
    background-color: #fff
}

.home-search .home-search-content .search-box .feather-search {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
    color: #a3a3a3
}

.home-search .home-search-content .category-sec {
    margin-top: 80px
}

.home-search .home-search-content .category-sec.category-section-2 .category-slider .shop-category-box .category-name h6 {
    color: #fff
}

.custom-row .sidebar-col {
    width: 320px;
    border-right: 1px solid #ececec;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    overflow: auto;
    padding-top: 0;
    background-color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .custom-row .sidebar-col {
    right: 0;
    left: unset
}

@media (max-width: 1199px) {
    .custom-row .sidebar-col {
        border-right: unset;
        left: -350px;
        background-color: #fff;
        z-index: 10;
        height: 100%;
        width: calc(300px + (320 - 300) * ((100vw - 320px) / (1199 - 320)))
    }

    [dir="rtl"] .custom-row .sidebar-col {
        right: -350px;
        left: unset
    }

    .custom-row .sidebar-col.show {
        left: 0
    }

    [dir="rtl"] .custom-row .sidebar-col.show {
        right: 0;
        left: unset
    }
}

.custom-row .sidebar-col .category-menu {
    background-color: transparent
}

.custom-row .sidebar-col .category-menu .web-logo {
    display: block;
    margin-bottom: calc(34px + (40 - 34) * ((100vw - 320px) / (1920 - 320)))
}

.custom-row .sidebar-col .category-menu .web-logo img {
    width: calc(53% + (70 - 53) * ((100vw - 320px) / (1920 - 320)))
}

.custom-row .sidebar-col .category-menu ul {
    gap: calc(21px + (28 - 21) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: none
}

.custom-row .sidebar-col .category-menu ul li .category-list img {
    width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)))
}

.custom-row .sidebar-col .category-menu ul li .category-list h5 {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.custom-row .content-col {
    margin-left: 320px;
    padding-left: calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(77px + (113 - 77) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .custom-row .content-col {
    margin-left: unset;
    margin-right: 320px
}

@media (max-width: 1199px) {
    .custom-row .content-col {
        margin-left: 0
    }

    [dir="rtl"] .custom-row .content-col {
        margin-left: unset;
        margin-right: 0
    }
}

.home-search-full {
    height: 70vh
}

@media (max-width: 575px) {
    .home-search-full {
        height: 50vh
    }
}

.home-search-full .home-contain .home-detail {
    height: 70vh;
    padding: 0;
    padding-top: 64px
}

@media (max-width: 575px) {
    .home-search-full .home-contain .home-detail {
        height: 50vh
    }
}

@media (max-width: 767px) {
    .home-search-full .home-contain .home-detail {
        padding-top: 48px
    }
}

@media (max-width: 480px) {
    .home-search-full .home-contain .home-detail {
        padding: 48px 15px 0
    }
}

.home-search-full .home-contain .home-detail>div {
    width: 50%
}

@media (max-width: 991px) {
    .home-search-full .home-contain .home-detail>div {
        width: 67%
    }
}

@media (max-width: 575px) {
    .home-search-full .home-contain .home-detail>div {
        width: 100%
    }
}

.home-search-full .home-contain .home-detail h1 {
    font-size: calc(31px + (58 - 31) * ((100vw - 320px) / (1920 - 320)));
    text-transform: inherit;
    margin-left: auto;
    margin-right: auto;
    margin-top: -18px
}

[dir="rtl"] .home-search-full .home-contain .home-detail h1 {
    text-align: center
}

.home-search-full .home-contain .home-detail h3 {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    margin-top: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
    color: #4a5568;
    line-height: 1.4
}

[dir="rtl"] .home-search-full .home-contain .home-detail h3 {
    text-align: center
}

.home-search-full .home-contain .home-detail .search-box {
    margin-top: calc(23px + (35 - 23) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    width: 60%;
    margin-left: auto;
    margin-right: auto
}

@media (max-width: 1460px) {
    .home-search-full .home-contain .home-detail .search-box {
        width: 83%
    }
}

@media (max-width: 480px) {
    .home-search-full .home-contain .home-detail .search-box {
        width: 100%
    }
}

.home-search-full .home-contain .home-detail .search-box .btn {
    background-color: #fff
}

.home-search-full .home-contain .home-detail .search-box .feather-search {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
    color: #a3a3a3;
    width: calc(21px + (24 - 21) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

[dir="rtl"] .home-search-full .home-contain .home-detail .search-box .feather-search {
    right: unset;
    left: 20px
}

@media (max-width: 991px) {
    .home-section-ratio .ratio_180 .bg-size:before {
        padding-top: 128%
    }
}

@media (max-width: 991px) {
    .home-section-ratio .custom-ratio {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1
    }
}

.home-furniture .feature-detail h2 {
    font-size: calc(32px + (51 - 32) * ((100vw - 1200px) / (1920 - 1200)))
}

.home-furniture .feature-detail h3 {
    background-color: #FFA53B;
    font-size: calc(29px + (40 - 29) * ((100vw - 1200px) / (1920 - 1200)));
    color: #fff;
    padding: 4px calc(9px + (13 - 9) * ((100vw - 1200px) / (1920 - 1200)));
    font-weight: 700
}

.furniture-contain-2 .home-detail {
    width: calc(100% + (75 - 100) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 767px) {
    .furniture-contain-2 .home-detail::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
        z-index: -1
    }
}

.furniture-contain-2 .home-detail h1 {
    width: calc(100% + (75 - 100) * ((100vw - 320px) / (1920 - 320)))
}

.button-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: calc(41px + (50 - 41) * ((100vw - 320px) / (1920 - 320)));
    height: calc(41px + (50 - 41) * ((100vw - 320px) / (1920 - 320)));
    position: fixed;
    right: 0;
    border-radius: 7px 0 0 7px;
    background-color: var(--theme-color);
    z-index: 9
}

.button-item .item-btn i {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.item-section {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: fixed;
    right: -130px;
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 7px 0 0 7px;
    padding: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 9
}

.item-section.active {
    right: 0
}

.item-section .close-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0;
    color: #fff;
    border: none;
    background-color: transparent
}

.item-section h6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 5px;
    margin-top: 10px;
    font-weight: bold
}

.item-section h6 .icli {
    font-size: 22px
}

.item-section .items-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 10px 0
}

.item-section .items-image li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    font-weight: 600;
    font-size: 13px
}

.item-section .items-image li+li {
    margin-left: -10px
}

.item-section .items-image li img {
    width: 15px;
    height: 15px;
    -o-object-fit: contain;
    object-fit: contain
}

.item-section .item-button {
    background-color: #fff;
    color: var(--theme-color)
}

header .main-nav {
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 767px) {
    header .main-nav {
        padding: 0
    }
}

header .nav-left-align .navbar-nav .dropdown-menu-2 {
    left: 0;
    -webkit-transform: unset;
    transform: unset
}

[dir="rtl"] header .nav-left-align .navbar-nav .dropdown-menu-2 {
    left: unset;
    right: 0
}

header .icon-box {
    display: inline-block;
    margin-right: 10px
}

header .dropdown-header {
    display: block;
    padding: 8px 20px;
    margin-bottom: 0;
    font-size: 14px;
    color: var(--theme-color);
    white-space: nowrap
}

@media (max-width: 1199px) {
    header .dropdown-header {
        padding-left: 0
    }
}

header .navbar-nav {
    padding-top: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

[dir="rtl"] header .navbar-nav {
    padding-left: unset;
    padding-right: 0
}

header .navbar-nav .nav-item {
    margin-bottom: 0
}

@media (max-width: 1199px) {
    header .navbar-nav .nav-item .new-pages {
        -webkit-box-pack: unset !important;
        -ms-flex-pack: unset !important;
        justify-content: unset !important
    }
}

header .navbar-nav .nav-item .new-pages .new-dropdown {
    position: absolute;
    top: -7px;
    right: 3px;
    background-color: #ff4f4f;
    font-size: 10px;
    padding: 1px 4px;
    color: #fff;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px
}

[dir="rtl"] header .navbar-nav .nav-item .new-pages .new-dropdown {
    border-top-left-radius: unset;
    border-top-right-radius: 5px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: 5px
}

@media (max-width: 1199px) {
    header .navbar-nav .nav-item .new-pages .new-dropdown {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        margin-left: 9px;
        border-radius: 3px
    }

    [dir="rtl"] header .navbar-nav .nav-item .new-pages .new-dropdown {
        margin-left: unset;
        margin-right: 9px
    }
}

header .navbar-nav .nav-item:hover {
    color: var(--theme-color)
}

header .navbar-nav .dropdown-menu {
    min-width: 200px;
    margin: 0;
    margin-top: -8px;
    background-color: #fff;
    -webkit-box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.7), 5px 20px 40px 0 rgba(34, 34, 34, 0.4);
    box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.7), 5px 20px 40px 0 rgba(34, 34, 34, 0.4);
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
    position: static;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
    display: block !important;
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px)
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover {
    position: relative
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover>a {
    display: block;
    position: relative
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover>a .new-text i {
    font-size: 12px;
    margin-left: 4px;
    color: var(--theme-color)
}

[dir="rtl"] header .navbar-nav .dropdown-menu .sub-dropdown-hover>a .new-text i {
    margin-left: unset;
    margin-right: 4px
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover>a::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0
}

[dir="rtl"] header .navbar-nav .dropdown-menu .sub-dropdown-hover>a::before {
    right: unset;
    left: 0
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover:hover .sub-menu {
    left: 113%;
    visibility: visible;
    opacity: 1
}

@media (max-width: 1199px) {
    header .navbar-nav .dropdown-menu .sub-dropdown-hover:hover .sub-menu {
        left: unset
    }
}

[dir="rtl"] header .navbar-nav .dropdown-menu .sub-dropdown-hover:hover .sub-menu {
    left: unset;
    right: 113%
}

@media (max-width: 1199px) {
    [dir="rtl"] header .navbar-nav .dropdown-menu .sub-dropdown-hover:hover .sub-menu {
        left: unset;
        right: unset
    }
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover .sub-menu {
    position: absolute;
    top: -19px;
    left: 123%;
    min-width: 200px;
    -webkit-box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    background-color: #fff;
    border-color: transparent;
    padding: 20px;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] header .navbar-nav .dropdown-menu .sub-dropdown-hover .sub-menu {
    right: 123%;
    left: unset
}

@media (max-width: 1199px) {
    header .navbar-nav .dropdown-menu .sub-dropdown-hover .sub-menu {
        position: relative;
        top: unset;
        left: unset;
        opacity: 1;
        min-width: auto;
        z-index: 1;
        visibility: visible;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin: 5px 0 12px 29px;
        padding: 0
    }
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover .sub-menu li a {
    padding: 0;
    font-size: 14px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    color: #222;
    display: block
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover .sub-menu li a:hover::after {
    width: 40%
}

header .navbar-nav .dropdown-menu .sub-dropdown-hover .sub-menu li a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 2px;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.3;
    border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: -1
}

header .navbar-nav .dropdown-menu-2 {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-15px);
    transform: translateX(-50%) translateY(-15px);
    width: 52vw;
    min-width: unset;
    padding: 22px !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    overflow: hidden;
    position: relative;
    z-index: 0
}

header .navbar-nav .dropdown-menu-2::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../../public/assets/images/bg.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    z-index: -1
}

[dir="rtl"] header .navbar-nav .dropdown-menu-2::after {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
    filter: FlipH
}

@media (max-width: 1199px) {
    header .navbar-nav .dropdown-menu-2::after {
        content: none
    }
}

@media (max-width: 1400px) {
    header .navbar-nav .dropdown-menu-2 {
        background-position: 20px center
    }
}

@media (max-width: 1199px) {
    header .navbar-nav .dropdown-menu-2 {
        width: 100%;
        padding: 0 !important;
        left: 0;
        margin: 0
    }
}

header .navbar-nav .dropdown-menu .dropdown {
    margin-right: 20px;
    margin-left: 20px
}

header .navbar-nav .dropdown-menu .dropdown:last-child {
    margin-bottom: 12px
}

header .navbar-nav .dropdown-menu .dropdown .dropdown-toggle::after {
    display: none
}

header .navbar-nav .dropdown-menu .dropdown-menu {
    display: block;
    padding-right: 16px;
    padding-left: 16px
}

header .navbar-nav .dropdown-image {
    background-image: none
}

header .navbar-nav .dropdown-image::after {
    content: none
}

@media (max-width: 991px) {
    header .navbar-nav .dropdown-image {
        left: 0;
        margin-top: 0
    }
}

header .navbar-nav .dropdown-image .dropdown-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 15px
}

@media (max-width: 1199px) {
    header .navbar-nav .dropdown-image .dropdown-column {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px
    }
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item {
    margin: 0 !important;
    text-align: center
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item::after {
    content: none
}

@media (max-width: 1199px) {
    header .navbar-nav .dropdown-image .dropdown-column .dropdown-item {
        padding: 0
    }
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item:hover img {
    -webkit-box-shadow: 5px 20px 40px 0 rgba(34, 34, 34, 0.07);
    box-shadow: 5px 20px 40px 0 rgba(34, 34, 34, 0.07);
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px)
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item:hover span {
    color: var(--theme-color);
    font-weight: 600
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item:hover span::after {
    width: 100%
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item img {
    width: 100%;
    -webkit-box-shadow: 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    box-shadow: 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: block
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item span {
    display: inline-block;
    margin-top: 14px;
    text-align: center;
    font-size: calc(14px + (16 - 14) * ((100vw - 1200px) / (1920 - 1200)));
    margin-bottom: -6px;
    position: relative;
    z-index: 0
}

header .navbar-nav .dropdown-image .dropdown-column .dropdown-item span::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 2px;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.3;
    border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: -1
}

header .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

header .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 16px;
    font-size: calc(21px + 0.45vw);
    white-space: nowrap;
    display: inline-block;
    font-weight: 500
}

header .navbar-brand:hover {
    text-decoration: none
}

header .navbar-brand:focus {
    text-decoration: none
}

header .navbar.navbar-expand-xl .navbar-nav .nav-item+.nav-item {
    margin-left: calc(30px + (40 - 30) * ((100vw - 1200px) / (1920 - 1200)))
}

[dir="rtl"] header .navbar.navbar-expand-xl .navbar-nav .nav-item+.nav-item {
    margin-left: unset;
    margin-right: calc(30px + (40 - 30) * ((100vw - 1200px) / (1920 - 1200)))
}

@media (max-width: 1199px) {
    header .navbar.navbar-expand-xl .navbar-nav .nav-item+.nav-item {
        margin-left: 0
    }

    [dir="rtl"] header .navbar.navbar-expand-xl .navbar-nav .nav-item+.nav-item {
        margin-left: unset;
        margin-right: 0
    }
}

header .navbar.navbar-expand-xl .navbar-nav .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    position: relative
}

header .navbar.navbar-expand-xl .navbar-nav .nav-link::after {
    content: none
}

header .navbar.navbar-expand-xl .navbar-nav .nav-link .icli {
    margin-left: 10px
}

header .navbar.navbar-expand-xl .navbar-nav .nav-link .label-menu {
    position: absolute;
    top: -10px;
    left: 84%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 8px;
    display: inline-block;
    text-transform: uppercase;
    line-height: 16px;
    padding: 0 8px;
    font-weight: 600;
    color: #fff;
    background-color: var(--theme-color);
    border-radius: 50px
}

@media (max-width: 1199px) {
    header .navbar.navbar-expand-xl .navbar-nav .nav-link .label-menu {
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform: none;
        transform: none;
        margin-left: 10px;
        margin-right: auto;
        border-radius: 3px
    }
}

header .navbar.navbar-expand-xl .navbar-nav .nav-link .label-menu::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    display: inline-block;
    top: 100%;
    left: 10px;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--theme-color)
}

@media (max-width: 1199px) {
    header .navbar.navbar-expand-xl .navbar-nav .nav-link .label-menu::before {
        content: none
    }
}

[dir="rtl"] header .navbar.navbar-expand-xl .navbar-nav .nav-link .label-menu::before {
    left: unset;
    right: 10px
}

header .navbar.navbar-expand-xl .navbar-nav .nav-link::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    right: -12px
}

[dir="rtl"] header .navbar.navbar-expand-xl .navbar-nav .nav-link::before {
    right: unset;
    left: -12px
}

@media (max-width: 1199px) {
    header .navbar.navbar-expand-xl .navbar-nav .nav-link::before {
        right: 0
    }

    [dir="rtl"] header .navbar.navbar-expand-xl .navbar-nav .nav-link::before {
        right: unset;
        left: 0
    }
}

header .navbar.navbar-expand-xl .navbar-nav .nav-link-2::before {
    content: none
}

header .navbar-text {
    padding-top: 9px;
    padding-bottom: 9px
}

header .navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

header .navbar-toggler {
    background-color: transparent;
    -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
    transition: -webkit-box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out;
    transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    padding: 12px;
    font-size: 18px;
    margin-right: 10px;
    padding: 0
}

[dir="rtl"] header .navbar-toggler {
    margin-right: unset;
    margin-left: 10px
}

@media (max-width: 767px) {
    header .navbar-toggler {
        margin-right: 0
    }
}

header .navbar-toggler:focus-visible {
    outline: none
}

header .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

header .navbar-toggler-icon {
    width: auto;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 20px;
    color: #222;
    -webkit-text-fill-color: #222;
    -webkit-opacity: 1
}

@media (max-width: 767px) {
    header .navbar-toggler-icon {
        margin-right: 0
    }
}

header .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto
}

header .navbar-expand {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

header .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

header .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
    left: 26px;
    top: 30px;
    -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14)
}

header .navbar-expand .navbar-nav-scroll {
    overflow: visible
}

header .navbar-expand .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

header .navbar-expand .navbar-toggler {
    display: none
}

header .navbar-light .navbar-brand {
    color: #222
}

header .navbar-light .navbar-brand:hover {
    color: #222
}

header .navbar-light .navbar-brand:focus {
    color: #222
}

header .navbar-light .navbar-nav .nav-link {
    color: #222
}

header .navbar-light .navbar-nav .nav-link:hover {
    color: var(--theme-color)
}

header .navbar-light .navbar-nav .nav-link:focus {
    color: var(--theme-color)
}

header .navbar-light .navbar-nav .show>.nav-link {
    color: var(--theme-color)
}

header .navbar-light .navbar-nav .nav-link.active {
    color: var(--theme-color)
}

header .navbar-light .navbar-toggler {
    color: #4a5568;
    border-color: transparent
}

header .navbar-light .navbar-text {
    color: #4a5568
}

header .navbar-light .navbar-text a {
    color: var(--theme-color)
}

header .navbar-light .navbar-text a:hover {
    color: var(--theme-color)
}

header .navbar-light .navbar-text a:focus {
    color: var(--theme-color)
}

header .navbar {
    display: inline-block;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 15px;
    z-index: 1
}

[dir="rtl"] header .navbar {
    padding-left: unset;
    padding-right: 15px
}

header .navbar:not(.navbar-stuck) .navbar-stuck-btn {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(#222, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

header .navbar-shadow {
    -webkit-box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1);
    box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1)
}

header .navbar-floating {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

header .navbar-stuck .navbar-btn {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(#222, 0) !important;
    white-space: nowrap !important;
    border: 0 !important
}

header .navbar-stuck-logo {
    display: none
}

header .navbar-sticky.navbar-stuck {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-animation: navbar-show 0.25s;
    animation: navbar-show 0.25s;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1);
    box-shadow: 0 2px 10px -3px rgba(34, 34, 34, 0.1)
}

header .header-nav-middle {
    margin: auto !important
}

header .offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1080;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    -webkit-box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    will-change: transform, box-shadow;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-box-shadow 0.3s ease;
    transition: -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-box-shadow 0.3s ease;
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.3s ease;
    transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.3s ease, -webkit-transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-box-shadow 0.3s ease;
    visibility: visible !important
}

header .offcanvas:not(.offcanvas-end):not(.offcanvas-bottom) {
    top: 0;
    left: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

@media (max-width: 1199px) {
    header .offcanvas:not(.offcanvas-end):not(.offcanvas-bottom) {
        width: calc(300px + (320 - 300) * ((100vw - 1200px) / (1920 - 1200)));
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        z-index: 7
    }
}

header .offcanvas-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 20px 24px;
    z-index: 1
}

header .offcanvas-header .btn-close {
    padding: 0;
    margin: -10px 0;
    font-size: 16px
}

header .offcanvas-header h5 {
    color: var(--theme-color);
    font-weight: 600
}

header .offcanvas-title {
    margin-bottom: 0;
    line-height: initial
}

header .offcanvas-body {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 20px 24px;
    overflow-y: auto;
    height: 100%
}

header .offcanvas-body::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
    opacity: 0
}

header .offcanvas-body::-webkit-scrollbar-thumb {
    border-radius: 4px
}

header .offcanvas-body>.simplebar-track {
    display: block;
    background-color: transparent
}

header .offcanvas-body .simplebar-vertical {
    margin-right: 3px
}

header .offcanvas-start {
    top: 0;
    left: 0;
    width: 352px;
    border-right: 0 solid transparent;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%)
}

header .offcanvas-end {
    top: 0;
    right: 0;
    width: 352px;
    border-left: 0 solid transparent;
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
}

header .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 0 solid transparent;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
}

header .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 0 solid transparent;
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

header .offcanvas.show {
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04) !important;
    box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04) !important
}

header .offcanvas-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 20px 24px
}

header .sidebar-toggle {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    z-index: 1025
}

header .hot-deal-box {
    color: #e21719;
    font-weight: 600;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 12px
}

header .hot-deal-box img {
    margin-right: 7px
}

header .hot-deal-box span {
    font-size: 16px;
    font-weight: 500
}

@media (prefers-reduced-motion: reduce) {
    header .navbar-toggler {
        -webkit-transition: none;
        transition: none
    }

    header .offcanvas {
        -webkit-transition: none;
        transition: none
    }
}

@media (min-width: 1200px) {
    header .navbar-expand-xl.navbar .offcanvas-body {
        overflow: visible
    }

    header .navbar-expand-xl.navbar-sticky.navbar-stuck .navbar-floating-logo {
        display: none !important
    }

    header .navbar-expand-xl .navbar-stuck-logo {
        display: block
    }

    header .navbar-expand-xl .navbar-nav {
        position: relative;
        padding-top: 0
    }

    header .navbar-expand-xl .navbar-nav .nav-item {
        border: 0
    }

    header .navbar-expand-xl .navbar-nav .nav-item:hover>.nav-link:not(.disabled) {
        color: var(--theme-color)
    }

    header .navbar-expand-xl .navbar-nav .nav-item.active>.nav-link:not(.disabled) {
        color: var(--theme-color)
    }

    header .navbar-expand-xl .navbar-nav>.dropdown>.dropdown-toggle::after {
        display: none
    }

    header .navbar-expand-xl .dropdown-menu {
        margin-top: 0;
        -webkit-box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
        box-shadow: -1px 0 10px 0 rgba(34, 34, 34, 0.07), 5px 20px 40px 0 rgba(34, 34, 34, 0.04);
        background-color: #fff;
        border-color: #fff;
        padding: 20px;
        border-radius: 10px
    }

    header .navbar-expand-xl .dropdown-menu-left {
        left: 0;
        -webkit-transform: translateX(0%) translateY(-15px);
        transform: translateX(0%) translateY(-15px)
    }

    [dir="rtl"] header .navbar-expand-xl .dropdown-menu-left {
        left: unset;
        right: 0
    }

    header .navbar-expand-xl .dropdown-menu li {
        display: block;
        width: 100%
    }

    header .navbar-expand-xl .dropdown-menu li:first-child .dropdown-item {
        margin: 0;
        margin-top: -3px
    }

    header .navbar-expand-xl .dropdown-menu li:last-child .dropdown-item {
        margin-bottom: -4px
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-column {
        margin: 5px;
        position: relative
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-column:first-of-type {
        margin-left: 0
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-item {
        padding: 0;
        margin: 10px 0 0 0;
        font-size: 14px;
        position: relative;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: 0
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-item:hover::after {
        width: 40%
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-item:active {
        color: #000
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-item::after {
        content: "";
        position: absolute;
        width: 0;
        height: 4px;
        bottom: 2px;
        left: 0;
        background-color: var(--theme-color);
        opacity: 0.3;
        border-radius: 50px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        z-index: -1
    }

    [dir="rtl"] header .navbar-expand-xl .dropdown-menu .dropdown-item::after {
        left: unset;
        right: 0
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-item:focus {
        background-color: transparent
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-item:hover {
        background-color: transparent;
        letter-spacing: 0.03em
    }

    header .navbar-expand-xl .dropdown-menu .dropdown {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
        border-left: 0
    }

    header .navbar-expand-xl .dropdown-menu .dropdown .dropdown-toggle {
        position: relative;
        padding-right: 26px
    }

    [dir="rtl"] header .navbar-expand-xl .dropdown-menu .dropdown .dropdown-toggle {
        padding-right: 20px;
        padding-left: 26px
    }

    header .navbar-expand-xl .dropdown-menu .dropdown .dropdown-toggle::after {
        content: "";
        position: absolute;
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: unset;
        height: unset;
        display: block;
        right: 10px;
        border: none
    }

    [dir="rtl"] header .navbar-expand-xl .dropdown-menu .dropdown .dropdown-toggle::after {
        right: unset;
        left: 10px
    }

    header .navbar-expand-xl .dropdown-menu .dropdown:last-child {
        margin-bottom: 0
    }

    header .navbar-expand-xl .dropdown-menu .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        width: auto;
        margin-top: 0;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 0;
        padding-left: 0;
        border-radius: 5px;
        background-color: #fff
    }

    [dir="rtl"] header .navbar-expand-xl .dropdown-menu .dropdown-menu {
        left: unset;
        right: 100%
    }

    header .navbar-expand-xl .dropdown-menu.show {
        display: none
    }

    header .navbar-expand-xl .dropdown-menu.dropdown-menu-end {
        right: 0;
        left: auto
    }

    header .navbar-expand-xl .dropdown:hover>.dropdown-menu {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        display: block !important
    }

    header .navbar-expand-xl .dropdown:hover .dropdown-menu-2 {
        -webkit-transform: translateX(-50%) translateY(0);
        transform: translateX(-50%) translateY(0);
        display: block !important
    }

    [dir="rtl"] header .navbar-expand-xl .dropdown:hover .dropdown-menu-2 {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    header .navbar-expand-xl .dropdown:hover .dropdown-menu-center {
        -webkit-transform: translateX(0%) translateY(0);
        transform: translateX(0%) translateY(0)
    }

    header .navbar-expand-xl .dropdown:hover .dropdown-menu-left {
        -webkit-transform: translateX(0%) translateY(0);
        transform: translateX(0%) translateY(0)
    }

    header .navbar-expand-xl .dropdown-mega {
        position: static
    }

    header .navbar-expand-xl .dropdown-mega>.dropdown-menu {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    header .navbar-expand-xl .dropdown-mega:hover>.dropdown-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    header .navbar-expand-xl .dropdown-mega .dropdown-menu .dropdown-item {
        padding: 0;
        margin: 10px 0px 0 0
    }

    header .navbar-expand-xl .dropdown-mega .dropdown-column.dropdown-column-img {
        width: 200px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0
    }

    header .navbar-expand-xl .dropdown-mega .dropdown-column {
        margin-top: 5px
    }

    header .navbar-expand-xl .dropdown-mega .dropdown-column .dropdown-header {
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 14px
    }

    [dir="rtl"] header .navbar-expand-xl .dropdown-mega .dropdown-column .dropdown-header {
        padding: 0
    }

    header .offcanvas-expand {
        -webkit-transform: none !important;
        transform: none !important;
        z-index: 1031
    }

    header .offcanvas-expand.offcanvas-start {
        -webkit-transform: none !important;
        transform: none !important
    }

    header .offcanvas-expand.offcanvas-end {
        -webkit-transform: none !important;
        transform: none !important
    }

    header .offcanvas-expand.offcanvas-bottom {
        -webkit-transform: none !important;
        transform: none !important
    }

    header .offcanvas-collapse {
        display: block;
        position: static;
        top: auto !important;
        right: auto !important;
        bottom: auto !important;
        left: auto !important;
        width: auto !important;
        max-width: 100% !important;
        height: auto !important;
        -webkit-transform: none !important;
        transform: none !important;
        background-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    header .offcanvas-collapse .offcanvas-header {
        display: none
    }

    header .offcanvas-collapse .offcanvas-footer {
        display: none
    }

    header .offcanvas-collapse .offcanvas-body {
        padding: 0;
        overflow: initial
    }

    header .offcanvas-enabled-start {
        padding-left: 376px
    }

    header .offcanvas-enabled-end {
        padding-right: 376px;
        padding-left: 0
    }
}

header .navbar-expand-xl .offcanvas {
    -webkit-box-flex: 1
}

@media (max-width: 1199px) {
    header .navbar-expand-xl .offcanvas {
        z-index: 4;
        -webkit-box-flex: 2
    }
}

header .offcanvas-backdrop {
    z-index: 6
}

@media (max-width: 1199px) {
    header .offcanvas-collapse .offcanvas-body .navbar-nav {
        padding-top: 0
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .nav-item {
        border-top: 0
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        color: #4a5568;
        font-weight: 700
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link:hover {
        color: var(--theme-color);
        background-color: transparent
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link.active {
        color: var(--theme-color);
        background-color: transparent
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link.disabled {
        color: #4a5568
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .show>.nav-link {
        color: var(--theme-color)
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .active>.nav-link {
        color: var(--theme-color)
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu li {
        display: block;
        width: 100%
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item {
        color: #4a5568;
        font-size: 14px
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item:hover {
        color: var(--theme-color);
        background-color: transparent
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item.active {
        color: var(--theme-color);
        background-color: transparent
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item.disabled {
        color: #4a5568
    }

    header .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-header {
        font-size: 16px;
        font-weight: 600
    }

    header .navbar-nav .dropdown-menu {
        opacity: 1;
        visibility: visible;
        display: none !important;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    header .navbar-nav .dropdown-menu.show {
        display: block !important
    }
}

@media (max-width: 1199px) {
    .new-nav-item {
        -webkit-box-pack: unset !important;
        -ms-flex-pack: unset !important;
        justify-content: unset !important
    }
}

.new-nav-item .new-dropdown {
    position: absolute;
    top: -7px;
    right: 3px;
    background-color: #ff4f4f;
    font-size: 10px;
    padding: 1px 4px;
    color: #fff;
    font-weight: 600;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px
}

[dir="rtl"] .new-nav-item .new-dropdown {
    border-top-left-radius: unset;
    border-top-right-radius: 5px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: 5px
}

@media (max-width: 1199px) {
    .new-nav-item .new-dropdown {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        margin-left: 9px;
        border-radius: 3px
    }

    [dir="rtl"] .new-nav-item .new-dropdown {
        margin-left: unset;
        margin-right: 9px
    }
}

.newsletter-section .newsletter-box {
    border-radius: 10px;
    position: relative;
    overflow: hidden
}

.newsletter-section .newsletter-box-2:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("../../../public/assets/images/vegetable/newsletter/1.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    z-index: -1
}

[dir="rtl"] .newsletter-section .newsletter-box-2:after {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
    filter: FlipH
}

.newsletter-section .newsletter-box-3:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("../../../public/assets/images/vegetable/newsletter/2.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    z-index: -1
}

[dir="rtl"] .newsletter-section .newsletter-box-3:after {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
    filter: FlipH
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail h2 {
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail h5 {
    font-weight: 600;
    color: #ffbc5d;
    margin-bottom: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box {
    position: relative
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::-webkit-input-placeholder {
    font-size: 14px;
    color: #4a5568
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::-moz-placeholder {
    font-size: 14px;
    color: #4a5568
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box :-ms-input-placeholder {
    font-size: 14px;
    color: #4a5568
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::-ms-input-placeholder {
    font-size: 14px;
    color: #4a5568
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::placeholder {
    font-size: 14px;
    color: #4a5568
}

@media (max-width: 360px) {
    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::-webkit-input-placeholder {
        padding-left: 0
    }

    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::-moz-placeholder {
        padding-left: 0
    }

    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box :-ms-input-placeholder {
        padding-left: 0
    }

    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::-ms-input-placeholder {
        padding-left: 0
    }

    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box ::placeholder {
        padding-left: 0
    }
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box input {
    height: calc(41px + (52 - 41) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    border: none;
    padding-left: 45px;
    padding-right: 140px
}

@media (max-width: 575px) {
    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box input {
        padding-right: 42px
    }
}

[dir="rtl"] .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box input {
    text-align: left
}

@media (max-width: 360px) {
    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box input {
        padding-left: 12px
    }
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box .arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    padding: 8px;
    background-color: rgba(var(--theme-color-rgb), 0.1);
    font-size: 15px;
    left: 8px;
    -webkit-text-stroke: 1px var(--theme-color);
    color: transparent
}

@media (max-width: 360px) {
    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box .arrow {
        display: none
    }
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box .sub-btn {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #ff4f4f;
    position: absolute;
    right: 3px;
    border-radius: 3px;
    border: none;
    padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(8px + (21 - 8) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #fff;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box .sub-btn i {
    margin-left: 6px
}

[dir="rtl"] .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box .sub-btn i {
    margin-left: unset;
    margin-right: 6px
}

@media (max-width: 575px) {
    .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box .sub-btn i {
        margin-left: 0
    }

    [dir="rtl"] .newsletter-section .newsletter-box .newsletter-contain .newsletter-detail .input-box .sub-btn i {
        margin-left: unset;
        margin-right: 0
    }
}

.newsletter-section-2 .newsletter-box {
    border-radius: 10px;
    overflow: hidden;
    position: relative
}

.newsletter-section-2 .newsletter-box .newsletter-detail {
    width: 100%;
    height: 100%;
    padding: calc(20px + (130 - 20) * ((100vw - 320px) / (1920 - 320)));
    z-index: 1
}

.newsletter-section-2 .newsletter-box .newsletter-detail h2 {
    font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px
}

.newsletter-section-2 .newsletter-box .newsletter-detail h4 {
    margin-bottom: 8px;
    font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.3;
    font-weight: 300
}

.newsletter-section-2 .newsletter-box .newsletter-detail .download-app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (max-width: 767px) {
    .newsletter-section-2 .newsletter-box .newsletter-detail .download-app {
        display: block
    }
}

.newsletter-section-2 .newsletter-box .newsletter-detail .download-app h3 {
    margin-right: 15px;
    font-weight: 400
}

[dir="rtl"] .newsletter-section-2 .newsletter-box .newsletter-detail .download-app h3 {
    margin-right: unset;
    margin-left: 15px
}

@media (max-width: 767px) {
    .newsletter-section-2 .newsletter-box .newsletter-detail .download-app h3 {
        margin-bottom: 10px
    }
}

.newsletter-section-2 .newsletter-box .newsletter-detail .download-app .download-app-image {
    margin: 0 -3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.newsletter-section-2 .newsletter-box .newsletter-detail .download-app .download-app-image li {
    margin: 0 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.newsletter-section-2 .newsletter-box .shape-box {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    text-align: center
}

.newsletter-section-2 .newsletter-box .shape-box:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../../../public/assets/images/veg-3/shape/circle.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    -webkit-animation: rounded infinite 30s linear;
    animation: rounded infinite 30s linear;
    right: 0;
    top: 0;
    z-index: -1
}

.offer-box {
    position: relative;
    z-index: 0
}

.offer-box .offer-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%
}

@media (max-width: 991px) {
    .offer-box .offer-contain {
        display: block;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.5)
    }
}

.offer-box .offer-contain .offer-detail h2 {
    text-transform: none;
    line-height: calc(27px + (43 - 27) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400
}

.offer-box .offer-contain .offer-detail p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.offer-box .offer-contain .offer-timing {
    margin-left: 24px
}

[dir="rtl"] .offer-box .offer-contain .offer-timing {
    margin-left: unset;
    margin-right: 24px
}

@media (max-width: 360px) {
    .offer-box .offer-contain .offer-timing {
        margin-left: 0
    }

    [dir="rtl"] .offer-box .offer-contain .offer-timing {
        margin-left: unset;
        margin-right: 0
    }
}

[dir="rtl"] .offer-box .offer-contain .offer-timing .time ul {
    padding-right: 0
}

.offer-box .offer-contain .offer-timing .time ul li {
    position: relative
}

.offer-box .offer-contain .offer-timing .time ul li+li {
    margin-left: 16px
}

[dir="rtl"] .offer-box .offer-contain .offer-timing .time ul li+li {
    margin-left: unset;
    margin-right: 16px
}

.offer-box .offer-contain .offer-timing .time ul li+li::before {
    content: ":";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -12px;
    font-size: 20px
}

[dir="rtl"] .offer-box .offer-contain .offer-timing .time ul li+li::before {
    left: unset;
    right: -12px
}

.offer-box .offer-contain .offer-timing .time ul li .counter {
    background-color: #ff4f4f;
    color: #fff;
    border-radius: 5px
}

.offer-box .offer-contain .offer-timing .time ul li .counter div {
    width: calc(46px + (61 - 46) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (73 - 50) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.offer-section .offer-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-image: url(../../../public/assets/images/grocery/banner/10.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 6px
}

@media (max-width: 575px) {
    .offer-section .offer-box {
        display: block;
        text-align: center
    }
}

.offer-section .offer-box h2 {
    color: #fff;
    font-weight: 700;
    font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));
    margin-top: -4px;
    margin-bottom: -5px
}

@media (max-width: 575px) {
    .offer-section .offer-box h2 {
        line-height: 1.3
    }
}

.offer-section .offer-box h2 span {
    font-weight: 500;
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.add-to-cart-box {
    border-radius: 7px;
    position: relative;
    max-width: 250px
}
.cart-box{
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 50px;
    left: 0;
}
.add-to-cart-box .btn-add-cart {
    position: absolute;
    right: 0;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) 0;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 10px;
    color: #4a5568;
    border-radius: 50px;
    font-weight: 500
}
.product-box{
    position:relative;
    border: 1px solid #dedede !important;
    background:#fff !important;
}

.add-to-cart-box .btn-add-cart .add-icon {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(30px + (37 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (37 - 30) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    border-radius: 100%;
    color: #fff;
    position: absolute;
    right: 4px
}

.add-to-cart-box .btn-add-cart .add-icon.bg-light-orange {
    background-color: #f9f8f6
}

.add-to-cart-box .btn-add-cart .add-icon.bg-light-gray {
    background-color: #f8f8f8
}

@media (max-width: 480px) {
    .add-to-cart-box .btn-add-cart .add-icon {
        display: none
    }
}

.add-to-cart-box .btn-add-cart .add-icon i {
    font-size: 13px
}

.add-to-cart-box .qty-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 5px;
    margin: 0;
    display: none
}

.add-to-cart-box .qty-box.open {
    display: block
}
.product-active .qty-box, .qty-box-active{
    display: block !important;
}
.add-to-cart-box .qty-box .input-group {
    background-color: #f8f8f8;
    border-radius: 50px;
    padding: 4px;
    text-align: center;
    z-index: 0;
    bottom: -5px;
}

.add-to-cart-box .qty-box .input-group.theme-bg-white {
    background-color: #fff
}
.td-bg-white{
    --bs-table-bg-type:#fff !important
}
.add-to-cart-box .qty-box .input-group button {
    width: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320)));
    height: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    padding: 0;
    background-color: var(--theme-color);
    z-index: 0;
    border-radius: 100% !important
}

.add-to-cart-box .qty-box .input-group button i {
    font-size: 13px;
    color: #fff;
    margin-top: 1px
}

.add-to-cart-box .qty-box .input-group button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.add-to-cart-box .qty-box .input-group input {
    height: auto;
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: center;
    font-size: 14px;
    color: #4a5568
}

.add-to-cart-btn-2 .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: calc(30px + (37 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (37 - 30) * ((100vw - 320px) / (1920 - 320)));
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #f7f7f7;
    padding: 0;
    border-radius: 100%;
    color: var(--theme-color)
}

[dir="rtl"] .add-to-cart-btn-2 .btn {
    right: unset;
    left: 0
}

.add-to-cart-btn-2 .qty-box-2 {
    display: none;
    bottom: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    margin-left: auto;
    max-width: 190px
}

[dir="rtl"] .add-to-cart-btn-2 .qty-box-2 {
    left: unset;
    right: 0
}

.add-to-cart-btn-2 .qty-box-2.qty-box-3 {
    max-width: 203px
}

.add-to-cart-btn-2 .qty-box-2.open {
    display: block
}

.add-to-cart-btn-2 .qty-box-2 .input-group {
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: 4px;
    text-align: center;
    z-index: 0
}

.add-to-cart-btn-2 .qty-box-2 .input-group button {
    width: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320)));
    height: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    padding: 0;
    background-color: #fff;
    z-index: 0;
    border-radius: 3px !important
}

.add-to-cart-btn-2 .qty-box-2 .input-group button i {
    font-size: 13px;
    color: var(--theme-color);
    margin-top: 1px
}

.add-to-cart-btn-2 .qty-box-2 .input-group button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.add-to-cart-btn-2 .qty-box-2 .input-group input {
    height: auto;
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: center;
    font-size: 14px;
    color: #4a5568
}

.qty-box {
    margin-top: 10px;
    width: 100%;
    max-width: 250px
}

.qty-box .input-group {
    background-color: #f8f8f8;
    border-radius: 7px;
    padding: 4px;
    text-align: center;
    z-index: 0
}

.qty-box .input-group.theme-bg-white {
    background-color: #fff
}

.qty-box .input-group button {
    width: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320)));
    height: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    padding: 0;
    background-color: #fff;
    z-index: 0;
    border-radius: 4px !important
}

.qty-box .input-group button i {
    font-size: 13px;
    color: var(--theme-color);
    margin-top: 1px
}

.qty-box .input-group button:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.qty-box .input-group input {
    height: auto;
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: center;
    font-size: 14px;
    color: #4a5568
}

.deal-timer {
    margin: 11px 0
}

.deal-timer.product-deal-timer {
    width: 290px;
    margin-top: 24px
}

.deal-timer.product-deal-timer ul {
    padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
    border-radius: 8px;
    border: none
}

.deal-timer.product-deal-timer ul li+li::before {
    left: -25px
}

[dir="rtl"] .deal-timer.product-deal-timer ul li+li::before {
    left: unset;
    right: -25px
}

.deal-timer.product-deal-timer ul li .counter {
    text-align: center
}

.deal-timer.product-deal-timer ul li .counter>div {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.deal-timer .deal-title {
    font-weight: 600;
    margin-bottom: 7px;
    letter-spacing: 0.4px
}

.deal-timer ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px 12px;
    border: 1px dashed var(--theme-color);
    text-align: center
}

.deal-timer ul li {
    position: relative
}

.deal-timer ul li+li::before {
    content: ":";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -20px
}

[dir="rtl"] .deal-timer ul li+li::before {
    left: unset;
    right: -20px
}

.deal-timer ul li .counter>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.deal-timer ul li .counter>div h5 {
    font-weight: 600;
    margin-bottom: 4px
}

.deal-timer ul li .counter>div h6 {
    color: #4a5568;
    font-size: 13px
}

.category-menu {
    background-color: #f8f8f8;
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px
}

.category-menu-2 {
    background: linear-gradient(136.8deg, #f6f5f2 17.01%, #fdf7f0 93.97%)
}

.category-menu h3 {
    margin-bottom: 40px;
    font-weight: 600;
    position: relative;
    display: inline-block
}

.category-menu h3::before {
    content: "";
    position: absolute;
    width: 70%;
    height: 2px;
    bottom: -8px;
    left: 0;
    background-color: var(--theme-color)
}

[dir="rtl"] .category-menu h3::before {
    left: unset;
    right: 0
}

.category-menu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 23px;
    border-bottom: 1px dashed rgba(74, 85, 104, 0.5)
}

.category-menu ul.value-list {
    margin-top: 35px;
    border: none
}

.category-menu ul li {
    display: block;
    width: 100%
}

.category-menu ul li.pb-30 {
    margin-bottom: 30px
}

.category-menu ul li .category-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #222
}

.category-menu ul li .category-list:hover h5 a {
    letter-spacing: 0.5px
}

.category-menu ul li .category-list:hover h5 a:before {
    width: 70px
}

.category-menu ul li .category-list svg {
    width: 25px;
    height: 25px;
    stroke: #222
}

.category-menu ul li .category-list img {
    width: 25px;
    height: 25px;
    -o-object-fit: contain;
    object-fit: contain
}

.category-menu ul li .category-list h5 a {
    color: #222;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    position: relative;
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.category-menu ul li .category-list h5 a:hover {
    color: #222
}

.category-menu ul li .category-list h5 a::before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    border-radius: 50px;
    background-color: var(--theme-color);
    z-index: -1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .category-menu ul li .category-list h5 a::before {
    left: unset;
    right: 0
}

.category-menu ul li .category-list i {
    margin-left: auto
}

.category-menu.menu-xl {
    position: sticky;
    top: 130px
}

.category-menu.menu-xl ul {
    gap: 28px;
    border-bottom: none
}

.category-menu.menu-xl ul li .category-list img {
    width: 35px;
    height: 35px
}

.category-menu.menu-xl ul li .category-list h5 {
    font-size: 17px
}

.product-border {
    border: 1px solid rgba(74, 85, 104, 0.3);
    border-radius: 10px;
    padding: 0 14px
}

.product-border.border-row .slick-slider .slick-list {
    margin: 0 -14px
}

.product-border.border-row .row>div:last-child {
    border-top: 1px solid #ddd
}

.product-border .slider-5-1 .slick-slide>.row {
    border-right: 1px solid rgba(74, 85, 104, 0.3);
    margin-left: 0
}

[dir="rtl"] .product-border .slider-5-1 .slick-slide>.row {
    border-left: 1px solid rgba(74, 85, 104, 0.3);
    border-right: unset
}

.product-border>.product-box-slider>div>.row>div:nth-child(n+6) {
    border-top: 1px solid rgba(74, 85, 104, 0.3)
}

.product-border>.product-box-slider>div>.row>div:nth-child(5n+5) .product-box {
    border-right: none
}

[dir="rtl"] .product-border>.product-box-slider>div>.row>div:nth-child(5n+5) .product-box {
    border-left: none;
    border-right: unset
}

@media screen and (max-width: 616px) {
    .product-border>.product-box-slider>div>.row>div:nth-child(5n+2) .product-box {
        border-right: none
    }

    [dir="rtl"] .product-border>.product-box-slider>div>.row>div:nth-child(5n+2) .product-box {
        border-left: none;
        border-right: unset
    }
}

.product-box {
    padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
    border-right: 1px solid rgba(74, 85, 104, 0.3);
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden
}

.product-box:hover .product-image a img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.product-box:hover .product-image .product-option {
    bottom: 15px;
    opacity: 1
}

.product-box.product-box-bg {
    background: linear-gradient(149.8deg, #f6f5f2 17.21%, #fbfaf9 79.21%);
    border-radius: 5px;
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    height:309px;
}

.product-box.product-box-bg .qty-box .input-group {
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.04);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.04);
    border:1px solid var(--theme-color)
}

.product-box.product-box-bg+.product-box-bg {
    margin-top: 20px !important
}

@media (max-width: 575px) {
    .product-box.product-box-bg+.product-box-bg {
        margin-top: 10px !important
    }
}

.product-box.product-white-bg {
    background-color: #fff;
    border-radius: calc(9px + (16 - 9) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid #eee
}

.product-box.product-white-bg .qty-box .input-group {
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.04);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.04)
}

.product-box.product-white-bg:hover {
    -webkit-box-shadow: 0 15px 70px rgba(0, 0, 0, 0.07);
    box-shadow: 0 15px 70px rgba(0, 0, 0, 0.07)
}

.product-box .label-tag {
    position: absolute;
    top: 24px;
    left: 0;
    padding: 5px 13px;
    background-color: #ffba00;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    z-index: 1
}

.product-box .label-tag::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 16px;
    left: 44px;
    border-bottom: solid 15px #faba02;
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    z-index: 1
}

.product-box .label-tag::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    left: 44px;
    border-bottom: solid 15px #faba02;
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    z-index: 1
}

.product-box .label-tag span {
    font-weight: 700
}

.product-box .product-image {
    text-align: center;
    position: relative
}

@media (max-width: 480px) {
    .product-box .product-image {
        padding: 8px
    }
}

.product-box .product-image .product-option {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    bottom: -5px;
    background-color: #fff;
    width: 70%;
    padding: 10px 5px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    max-width: 320px
}

@media (max-width: 480px) {
    .product-box .product-image .product-option {
        display: none
    }
}

.product-box .product-image .product-option li {
    position: relative;
    width: 33.33%
}

.product-box .product-image .product-option li a {
    color: #222
}

.product-box .product-image .product-option li a .feather {
    width: 17px;
    height: auto;
    color: #4a5568
}

.product-box .product-image .product-option li+li:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #4a5568;
    opacity: 0.3
}

[dir="rtl"] .product-box .product-image .product-option li+li:after {
    left: unset;
    right: 0
}

.product-box .product-image img {
    width: 100%;
    height: 140px;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-box .product-detail a {
    color: #222;
    display: block
}

.product-box .product-detail a:hover {
    color: #222
}

.product-box .product-detail a h6.name {
    font-weight: 600;
    line-height: 22px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    margin-top:10px;
}

.product-box .product-detail a h6.name-2 {
    -webkit-line-clamp: 1
}

.product-box .product-detail .product-rating {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(4px + (7 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.product-box .product-detail .product-rating h6 {
    font-weight: 500
}

.product-box .progress {
    height: 6px;
    border-radius: 0;
    margin-top: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.product-box .stock {
    font-size: 14px;
    color: var(--theme-color);
    margin-left: auto
}

.product-box .sold {
    font-weight: 700;
    margin-top: -5px;
    line-height: 14px;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.product-box .sold .price {
    font-weight: 600;
    margin-top: 0
}

.product-box .sold del {
    font-weight: 400;
    margin-left: 6px;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.product-box .sold.weight {
    font-size: 13px;
    margin: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320))) 0;
    line-height: normal
}

.product-box .qty-box {
    max-width: 210px
}

.product-box .counter-box {
    display: block;
    position: relative
}

.product-box .counter-box h6 {
    margin-top: 0
}

.product-box .counter-box .addtocart_btn .add-button,
.product-box .counter-box .addtocart_btn .add_cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px 11px;
    background-color: var(--theme-color);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.product-box .counter-box .addtocart_btn .add-button span,
.product-box .counter-box .addtocart_btn .add_cart span {
    position: relative;
    left: unset;
    right: unset;
    bottom: unset;
    font-size: 15px;
    margin-right: 8px
}

@media (max-width: 480px) {

    .product-box .counter-box .addtocart_btn .add-button span,
    .product-box .counter-box .addtocart_btn .add_cart span {
        display: none
    }
}

.product-box .counter-box .addtocart_btn .qty-box {
    display: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 1;
    margin-top: 0;
    max-width: 155px
}

.product-box .counter-box .addtocart_btn .qty-box.open {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.product-box .counter-box .addtocart_btn .input-group {
    -webkit-box-shadow: 0 2px 4px 0 #dedede;
    box-shadow: 0 2px 4px 0 #dedede;
    border-radius: 2px;
    padding: 4px;
    text-align: center;
    z-index: 0;
    background-color: #fff
}

.product-box .counter-box .addtocart_btn .input-group .btn {
    padding: 7px 12px;
    font-size: 10px;
    line-height: 1;
    border-radius: 2px !important;
    background-color: #f8f8f8
}

.product-box .counter-box .addtocart_btn .input-group .btn i {
    font-size: 15px
}

.product-box .counter-box .addtocart_btn .input-group .form-control {
    padding: 2px;
    text-align: center;
    border-color: #f9f9f9
}

.product-box .price {
    margin-top: 16px;
    font-weight: 600
}

.product-list {
    border: 1px solid rgba(74, 85, 104, 0.3);
    border-radius: 5px;
    padding: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.product-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
    position: relative
}

.product-list li+li {
    margin-top: 50px
}

@media (max-width: 575px) {
    .product-list li+li {
        margin-top: 44px
    }
}

@media (max-width: 360px) {
    .product-list li+li {
        margin-top: 30px
    }
}

.product-list li+li:after {
    content: "";
    position: absolute;
    top: -28px;
    right: 0;
    border-bottom: 1px dashed rgba(74, 85, 104, 0.3);
    width: calc(100% - 103px)
}

[dir="rtl"] .product-list li+li:after {
    right: unset;
    left: 0
}

.product-list li .offer-product {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-list li .offer-product:hover .offer-image {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.product-list li .offer-product:hover .offer-detail a .name {
    color: var(--theme-color)
}

.product-list li .offer-product .offer-image {
    width: 80px
}

@media (max-width: 1660px) {
    .product-list li .offer-product .offer-image {
        width: 90px
    }
}

.product-list li .offer-product .offer-image img {
    width: 80px;
    height: 80px;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 1660px) {
    .product-list li .offer-product .offer-image img {
        width: 90px;
        height: 70px
    }
}

@media (max-width: 575px) {
    .product-list li .offer-product .offer-image img {
        width: 70px;
        height: 70px
    }
}

.product-list li .offer-product .offer-detail {
    width: calc(100% - 80px);
    height: 80px;
    padding-left: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .product-list li .offer-product .offer-detail {
    padding-left: unset;
    padding-right: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.product-list li .offer-product .offer-detail a {
    display: block;
    color: #222
}

.product-list li .offer-product .offer-detail a:hover {
    color: #222
}

.product-list li .offer-product .offer-detail a .name {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-weight: 600;
    margin-top: -1px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-list li .offer-product .offer-detail span {
    font-size: 14px;
    margin-top: 5px;
    color: #4a5568;
    font-weight: 400
}

.product-list li .offer-product .offer-detail .price {
    margin-top: 6px;
    font-weight: 600;
    margin-bottom: -2px
}

.product-category {
    padding: calc(19px + (36 - 19) * ((100vw - 320px) / (1920 - 320))) calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8
}

.product-category .product-category-list {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2
}

[dir="rtl"] .product-category .product-category-list {
    padding-left: unset;
    padding-right: 0
}

.product-category .product-category-list li {
    display: block;
    padding-left: 22px;
    font-size: 14px;
    color: #4a5568;
    position: relative;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

[dir="rtl"] .product-category .product-category-list li {
    padding-left: unset;
    padding-right: 22px
}

.product-category .product-category-list li::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    top: 50%;
    -webkit-transform: rotate(45deg) translateY(-50%);
    transform: rotate(45deg) translateY(-50%);
    left: 0;
    border: 1px solid #4a5568
}

[dir="rtl"] .product-category .product-category-list li::before {
    left: unset;
    right: 8px
}

.product-category .product-category-list li+li {
    margin-top: calc(14px + (26 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.product-category .product-category-list li:last-child a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-category .product-category-list li:last-child a:hover {
    color: var(--theme-color)
}

.product-category .product-category-list li:last-child a i {
    margin-left: 8px
}

.product-category .product-category-list li:last-child::before {
    display: none
}

.product-slider {
    padding-bottom: 5px
}

.product-slider:hover .product-slider-image img {
    -webkit-transform: scale(1.1) rotate(4deg);
    transform: scale(1.1) rotate(4deg)
}

.product-slider .product-slider-image {
    border-radius: 5px;
    overflow: hidden;
    display: block
}

.product-slider .product-slider-image img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-slider .product-slider-detail {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    width: 90%;
    margin: -50px auto 0;
    padding: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320))) calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    -webkit-box-shadow: 0px 4px 10px rgba(47, 88, 80, 0.05);
    box-shadow: 0px 4px 10px rgba(47, 88, 80, 0.05);
    background-color: rgba(255, 255, 255, 0.8)
}

.product-slider .product-slider-detail>div {
    position: relative
}

.product-slider .product-slider-detail h3 {
    font-weight: 600;
    margin-bottom: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.product-slider .product-slider-detail h5 {
    margin-bottom: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568
}

.product-slider .product-slider-detail h6 {
    margin-top: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.product-slider .product-slider-detail .product-button {
    position: absolute;
    bottom: 0;
    right: 0
}

[dir="rtl"] .product-slider .product-slider-detail .product-button {
    right: unset;
    left: 0
}

@media (max-width: 360px) {
    .product-slider .product-slider-detail .product-button {
        position: relative;
        margin-top: 10px;
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.top-selling-box .top-selling-title {
    border-bottom: 1px solid #e0e2e7;
    padding-bottom: calc(12px + (22 - 12) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(24px + (36 - 24) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.top-selling-box .top-selling-title::after {
    content: "";
    position: absolute;
    width: 120px;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color)
}

[dir="rtl"] .top-selling-box .top-selling-title::after {
    left: unset;
    right: 0
}

.top-selling-box .top-selling-title h3 {
    font-size: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.top-selling-box .top-selling-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden
}

.top-selling-box .top-selling-contain+.top-selling-contain {
    margin-top: calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.top-selling-box .top-selling-contain+.top-selling-contain:before {
    content: "";
    position: absolute;
    top: -15px;
    right: 0;
    width: calc(100% - 118px);
    border: 1px dashed rgba(74, 85, 104, 0.3)
}

[dir="rtl"] .top-selling-box .top-selling-contain+.top-selling-contain:before {
    right: unset;
    left: 0
}

.top-selling-box .top-selling-contain:hover .top-selling-detail h5 {
    color: var(--theme-color)
}

.top-selling-box .top-selling-contain:hover .top-selling-detail .product-option {
    top: 0;
    opacity: 1
}

.top-selling-box .top-selling-contain:hover .top-selling-image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.top-selling-box .top-selling-contain .top-selling-image {
    overflow: hidden;
    width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px
}

.top-selling-box .top-selling-contain .top-selling-image img {
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.top-selling-box .top-selling-contain .top-selling-detail {
    padding-left: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    width: calc(100% - calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320))));
    position: relative
}

.top-selling-box .top-selling-contain .top-selling-detail .product-option {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.top-selling-box .top-selling-contain .top-selling-detail .product-option li a {
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid var(--theme-color);
    position: relative;
    z-index: 0;
    overflow: hidden;
    color: var(--theme-color)
}

.top-selling-box .top-selling-contain .top-selling-detail .product-option li a:hover {
    color: var(--theme-color)
}

.top-selling-box .top-selling-contain .top-selling-detail .product-option li a:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.1
}

.top-selling-box .top-selling-contain .top-selling-detail .product-option li a .feather {
    width: 20px;
    height: auto;
    margin-top: 1px
}

[dir="rtl"] .top-selling-box .top-selling-contain .top-selling-detail {
    padding-left: unset;
    padding-right: 18px
}

.top-selling-box .top-selling-contain .top-selling-detail a {
    display: block;
    color: #222
}

.top-selling-box .top-selling-contain .top-selling-detail a:hover {
    color: #222
}

.top-selling-box .top-selling-contain .top-selling-detail a h5 {
    margin-bottom: calc(2px + (5 - 2) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.top-selling-box .top-selling-contain .top-selling-detail h6 {
    margin-top: calc(7px + (13 - 7) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: var(--theme-color)
}

.product-box-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-box-2:hover .product-image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.product-box-2:hover .product-detail h6 {
    color: var(--theme-color)
}

.product-box-2:hover .product-detail .product-option {
    opacity: 1
}

.product-box-2+.product-box-2 {
    margin-top: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) !important
}

.product-box-2 .product-image {
    border-radius: 8px;
    overflow: hidden
}

.product-box-2 .product-image img {
    width: calc(86px + (130 - 86) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-box-2 .product-detail {
    margin-left: 15px;
    width: calc(100% - 130px - 15px);
    position: relative;
    overflow: hidden
}

[dir="rtl"] .product-box-2 .product-detail {
    margin-left: unset;
    margin-right: 15px
}

.product-box-2 .product-detail-2 {
    padding-left: 0;
    padding-right: 15px;
    text-align: right
}

.product-box-2 .product-detail-2 .rating {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.product-box-2 .product-detail-2 .cart-icon {
    right: unset;
    left: 0
}

.product-box-2 .product-detail .product-option {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-box-2 .product-detail .product-option li a {
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid var(--theme-color);
    position: relative;
    z-index: 0;
    overflow: hidden
}

.product-box-2 .product-detail .product-option li a:hover {
    color: var(--theme-color)
}

.product-box-2 .product-detail .product-option li a:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.1
}

.product-box-2 .product-detail .product-option li a .feather {
    width: 20px;
    height: auto;
    margin-top: 1px
}

.product-box-2 .product-detail h6 {
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: calc(2px + (7 - 2) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    color: #222;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-box-2 .product-detail h3 {
    color: var(--theme-color);
    font-weight: 600;
    margin-bottom: 12px
}

.product-box-2 .product-detail h5 {
    font-weight: 500;
    margin-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)))
}

.product-box-2 .product-detail h5 del {
    font-size: 14px;
    margin-left: 2px;
    color: #ff4f4f;
    font-weight: 600
}

.product-box-2 .product-detail .cart-icon {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #f7f5f2;
    border-radius: 100%
}

.product-box-2 .product-detail .cart-icon svg {
    width: 20px;
    height: 20px;
    color: #4a5568;
    stroke-width: 1.5px
}

.middle-image {
    width: 100%;
    height: 100%
}

.middle-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.product-offcer-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.product-offcer-contain+.product-offcer-contain {
    margin-top: 50px
}

.product-offcer-contain+.product-offcer-contain::before {
    content: "";
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    border: 1px dashed #bfa378
}

.product-offcer-contain .product-offer-image img {
    width: 150px;
    height: 85px;
    -o-object-fit: contain;
    object-fit: contain
}

.product-offcer-contain .product-offer-details {
    padding-left: 16px;
    width: calc(100% - 150px);
    position: relative
}

.product-offcer-contain .product-offer-details h6 {
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 7px
}

.product-offcer-contain .product-offer-details h5 {
    font-weight: 500;
    margin-top: 10px
}

.product-offcer-contain .product-offer-details h5 del {
    font-size: 14px;
    margin-left: 2px;
    color: #ff4f4f;
    font-weight: 600
}

.product-offcer-contain .product-offer-details .cart-icon {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #f7f5f2;
    border-radius: 100%
}

.product-offcer-contain .product-offer-details .cart-icon .feather {
    width: 20px;
    height: 20px;
    color: #4a5568;
    stroke-width: 1.5px
}

.deal-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 575px) {
    .deal-contain {
        display: block
    }
}

.deal-contain .deal-image img {
    width: 250px;
    height: 250px;
    -o-object-fit: contain;
    object-fit: contain
}

@media (max-width: 575px) {
    .deal-contain .deal-image img {
        width: 100%;
        height: 100%
    }
}

.deal-contain .deal-detail {
    padding-left: 25px;
    width: calc(100% - 250px)
}

[dir="rtl"] .deal-contain .deal-detail {
    padding-left: unset;
    padding-right: 25px
}

@media (max-width: 575px) {
    .deal-contain .deal-detail {
        padding-left: 0;
        width: 100%;
        margin-top: 10px
    }
}

.deal-contain .deal-detail a {
    color: #222
}

.deal-contain .deal-detail a:hover {
    color: #222
}

.deal-contain .deal-detail .name {
    font-weight: 600;
    margin-bottom: calc(5px + (11 - 5) * ((100vw - 320px) / (1920 - 320)))
}

.deal-contain .deal-detail h4 {
    font-weight: 500;
    margin-top: calc(7px + (14 - 7) * ((100vw - 320px) / (1920 - 320)))
}

.deal-contain .deal-detail h4 del {
    font-size: 14px;
    margin-left: 2px;
    color: #ff4f4f;
    font-weight: 600
}

[dir="rtl"] .deal-contain .deal-detail h4 del {
    margin-left: unset;
    margin-right: 2px
}

[dir="rtl"] .deal-contain .deal-detail .option-icon {
    padding-left: unset;
    padding-right: 0
}

.deal-contain .deal-detail .option-icon li+li {
    margin-left: 8px
}

[dir="rtl"] .deal-contain .deal-detail .option-icon li+li {
    margin-left: unset;
    margin-right: 8px
}

.deal-contain .deal-detail .option-icon li a {
    width: calc(36px + (48 - 36) * ((100vw - 320px) / (1920 - 320)));
    height: calc(36px + (48 - 36) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    -webkit-box-shadow: 0px 5.57831px 13.9458px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 5.57831px 13.9458px rgba(0, 0, 0, 0.05);
    border-radius: 100%;
    color: #222
}

.deal-contain .deal-detail .option-icon li a svg {
    width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    height: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.product-list-section.list-style>div {
    width: 100%
}

.product-list-section.list-style>div .product-box-3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.product-list-section.list-style>div .product-box-3 .product-header {
    width: 180px
}

@media (max-width: 767px) {
    .product-list-section.list-style>div .product-box-3 .product-header {
        width: 100%
    }
}

@media (max-width: 767px) {
    .product-list-section.list-style>div .product-box-3 {
        display: block
    }
}

.product-list-section.list-style>div .product-box-3 .product-footer {
    padding-left: 20px;
    width: calc(100% - 180px)
}

.product-list-section.list-style>div .product-box-3 .product-footer .product-detail .span-name {
    display: none
}

[dir="rtl"] .product-list-section.list-style>div .product-box-3 .product-footer {
    padding-left: unset;
    padding-right: 20px
}

@media (max-width: 767px) {
    .product-list-section.list-style>div .product-box-3 .product-footer {
        padding: 0;
        width: 100%;
        margin-top: 20px
    }
}

.product-list-section.list-style>div .product-box-3 .product-footer .product-content {
    display: block;
    line-height: 1.5;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.product-list-section.list-style>div .product-box-3 .product-footer .product-detail .qty-box {
    width: unset
}

.product-box-3 {
    padding: calc(7px + (14 - 7) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    background-color: #f8f8f8;
    border-radius: 5px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out
}

.product-box-3.theme-bg-white {
    background-color: #fff
}

.product-box-3:hover {
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.08);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.08)
}

.product-box-3:hover .product-header .product-image .product-option {
    bottom: 10px;
    opacity: 1
}

.product-box-3:hover .product-header .product-image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.product-box-3 .product-header {
    position: relative
}

.product-box-3 .product-header .product-image {
    text-align: center;
    padding: 20px;
    display: block
}

@media (max-width: 480px) {
    .product-box-3 .product-header .product-image {
        padding: 8px
    }
}

.product-box-3 .product-header .product-image img {
    width: 100%;
    height: calc(100px + (140 - 100) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-box-3 .product-header .product-image .product-option {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    bottom: -5px;
    background-color: #fff;
    width: 100%;
    padding: 10px 5px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    max-width: 230px
}

@media (max-width: 480px) {
    .product-box-3 .product-header .product-image .product-option {
        display: none
    }
}

.product-box-3 .product-header .product-image .product-option li {
    position: relative;
    width: 33.33%
}

.product-box-3 .product-header .product-image .product-option li a {
    color: #222
}

.product-box-3 .product-header .product-image .product-option li a .feather {
    width: 17px;
    height: auto;
    color: #4a5568
}

.product-box-3 .product-header .product-image .product-option li+li:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #4a5568;
    opacity: 0.3
}

[dir="rtl"] .product-box-3 .product-header .product-image .product-option li+li:after {
    left: unset;
    right: 0
}

.product-box-3 .product-header .product-header-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
}

[dir="rtl"] .product-box-3 .product-header .product-header-top {
    left: unset;
    right: 0
}

.product-box-3 .product-header .product-header-top .label-new {
    position: relative;
    border-radius: 4px;
    padding: 3px 8px;
    color: #fff
}

.product-box-3 .product-header .product-header-top .label-new.bg-warning {
    background: -webkit-gradient(linear, right top, left top, color-stop(-14.53%, #ffbf65), color-stop(99.15%, #ffad39)) !important;
    background: linear-gradient(-90deg, #ffbf65 -14.53%, #ffad39 99.15%) !important
}

.product-box-3 .product-header .product-header-top .label-new.bg-theme {
    background: var(--theme-color2) !important
}

.product-box-3 .product-header .product-header-top .label-new label {
    font-size: 13px;
    margin-top: 1px;
    font-weight: 600
}

.product-box-3 .product-header .product-header-top .wishlist-button {
    position: relative;
    padding: 6px;
    margin-left: auto;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 3px rgba(34, 34, 34, 0.24);
    box-shadow: 0 3px 3px rgba(34, 34, 34, 0.24);
    border-radius: 100%;
    z-index: 0
}

[dir="rtl"] .product-box-3 .product-header .product-header-top .wishlist-button {
    margin-left: unset;
    margin-right: auto
}

.product-box-3 .product-header .product-header-top .wishlist-button .feather {
    width: 15px;
    height: 15px;
    color: #4a5568
}

.product-box-3 .product-header .product-header-top .close-button {
    position: relative;
    padding: 0;
    z-index: 0
}

.product-box-3 .product-header .product-header-top .close-button .feather {
    width: 20px;
    height: auto;
    color: #4a5568
}

.product-box-3 .product-footer .product-detail .span-name {
    font-size: 13px;
    color: #50607c;
    margin-bottom: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.product-box-3 .product-footer .product-detail .product-content {
    display: none
}

.product-box-3 .product-footer .product-detail a {
    color: #222
}

.product-box-3 .product-footer .product-detail a:hover {
    color: #222
}

.product-box-3 .product-footer .product-detail .name {
    font-weight: 500;
    line-height: calc(21px + (24 - 21) * ((100vw - 320px) / (1920 - 320)));
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.product-box-3 .product-footer .product-detail .unit {
    font-size: 13px;
    font-weight: 500;
    margin-top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    color: #777
}

.product-box-3 .product-footer .price {
    margin-top: 6px;
    font-weight: 600
}

.product-box-3 .product-footer .price del {
    margin-left: 4px;
    font-weight: 400;
    color: #777;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.product-box-3 .product-footer .price span {
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-3 .circle-box {
    position: relative
}

.product-section-3 .circle-box .shape-circle {
    position: absolute;
    top: 0;
    left: 0
}

.product-section-3 .circle-box .shape-circle img {
    width: 100%;
    height: auto;
    margin: 0;
    -webkit-animation: rounded 15s linear infinite;
    animation: rounded 15s linear infinite
}

.product-section-3 .circle-box .shape-text {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    color: #fff
}

.product-section-3 .circle-box .shape-text h6 {
    display: block;
    margin: 0;
    font-weight: 500
}

.product-section-3 .product-title {
    padding: 16px;
    text-align: center;
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 8px 8px 0 0;
    border: 2px solid transparent;
    background-size: contain;
    background-clip: border-box;
    background-position: 0 0;
    -webkit-animation: shape 15s linear infinite;
    animation: shape 15s linear infinite
}

.product-section-3 .product-title.product-warning {
    background-color: transparent;
    color: #222;
    margin: 0;
    border: none
}

.product-bg-image {
    background-image: url(../../../public/assets/images/grocery/bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%
}

.product-bg-image .product-box-4 {
    border: none;
    background-color: transparent
}

.product-bg-image .product-box-3:hover {
    -webkit-box-shadow: none;
    box-shadow: none
}

.product-box-4 {
    border: 1px solid #ececec;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    text-align: center
}

.product-box-4+.product-box-4 {
    margin-top: 20px !important
}

.product-box-4:hover .product-image img {
    -webkit-transform: scale(1.08);
    transform: scale(1.08)
}

.product-box-4:hover .product-image .option li {
    opacity: 1;
    -webkit-transform: translateY(10px);
    transform: translateY(10px)
}

.product-box-4:hover .product-image .option li:nth-child(1) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s
}

.product-box-4:hover .product-image .option li:nth-child(2) {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s
}

.product-box-4:hover .product-image .option li:nth-child(3) {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s
}

.product-box-4:hover .product-image .option li:nth-child(4) {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s
}

.product-box-4:hover .product-detail a .name {
    color: var(--theme-color) !important;
    font-weight: 600
}

.product-box-4-3 {
    border: 2px solid var(--theme-color);
    border-radius: 0 0 8px 8px
}

@media (max-width: 1660px) {
    .product-box-4-3 {
        height: 90%
    }
}

@media (max-width: 991px) {
    .product-box-4-3 {
        height: auto
    }
}

.product-box-4 .circle-box {
    position: relative
}

.product-box-4 .circle-box .shape-circle {
    position: absolute;
    top: 0;
    left: 0
}

.product-box-4 .circle-box .shape-circle img {
    width: 100%;
    height: auto;
    margin: 0;
    -webkit-animation: rounded 15s linear infinite;
    animation: rounded 15s linear infinite
}

.product-box-4 .circle-box .shape-text {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    color: #fff
}

.product-box-4 .circle-box .shape-text h6 {
    display: block;
    margin: 0;
    font-weight: 500
}

.product-box-4 .custom-progressbar {
    margin-top: 26px;
    border-radius: 50px;
    height: 10px
}

.product-box-4 .custom-progressbar .progress-bar {
    background-color: var(--theme-color)
}

.product-box-4 h5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 6px
}

.product-box-4 h5 span {
    margin-left: 3px
}

.product-box-4 .product-image {
    position: relative
}

.product-box-4 .product-image-2 img {
    margin: 20px 0 !important
}

@media (max-width: 991px) {
    .product-box-4 .product-image-2 img {
        margin: 0 !important
    }
}

.product-box-4 .product-image img {
    width: 70%;
    height: calc(80px + (140 - 80) * ((100vw - 320px) / (1920 - 320)));
    margin: 30px auto 20px;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 480px) {
    .product-box-4 .product-image img {
        width: 80%
    }
}

@media (max-width: 360px) {
    .product-box-4 .product-image img {
        width: 100%;
        margin: 20px 0 0
    }
}

.product-box-4 .product-image img.product-image {
    width: 65%;
    height: auto;
    -o-object-fit: unset;
    object-fit: unset;
    margin: 20px auto 0
}

.product-box-4 .product-image .option {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    margin: 0 -4px
}

[dir="rtl"] .product-box-4 .product-image .option {
    padding-right: 0
}

@media (max-width: 480px) {
    .product-box-4 .product-image .option {
        margin: 0 -1px
    }
}

.product-box-4 .product-image .option li {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
    margin: 0 4px;
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden
}

@media (max-width: 480px) {
    .product-box-4 .product-image .option li {
        width: 30px;
        height: 30px;
        margin: 0 1px
    }
}

.product-box-4 .product-image .option li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.1;
    z-index: -1
}

.product-box-4 .product-image .option li a {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.product-box-4 .product-image .option li a .icli {
    font-size: 20px;
    color: #000
}

@media (max-width: 480px) {
    .product-box-4 .product-image .option li a .icli {
        font-size: 15px
    }
}

.product-box-4 .product-image .option li a .icli.iconly-Swap {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.product-box-4 .product-detail {
    text-align: left;
    margin-top: 15px;
    position: relative
}

.product-box-4 .product-detail .addtocart_btn .add-button,
.product-box-4 .product-detail .addtocart_btn .add_cart {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.product-box-4 .product-detail .addtocart_btn .add-button span,
.product-box-4 .product-detail .addtocart_btn .add_cart span {
    position: absolute;
    left: 8px;
    right: 0;
    bottom: -21px;
    font-size: 12px
}

.product-box-4 .product-detail .addtocart_btn .qty-box {
    display: none;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 1;
    margin-top: 0
}

.product-box-4 .product-detail .addtocart_btn .qty-box.open {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.product-box-4 .product-detail .addtocart_btn .input-group {
    -webkit-box-shadow: 0 2px 4px 0 #dedede;
    box-shadow: 0 2px 4px 0 #dedede;
    border-radius: 2px;
    padding: 4px;
    text-align: center;
    z-index: 0;
    background-color: #fff
}

.product-box-4 .product-detail .addtocart_btn .input-group .btn {
    padding: 7px 12px;
    font-size: 10px;
    line-height: 1;
    border-radius: 2px !important;
    background-color: #f8f8f8
}

.product-box-4 .product-detail .addtocart_btn .input-group .btn i {
    font-size: 15px
}

.product-box-4 .product-detail .addtocart_btn .input-group .form-control {
    padding: 2px;
    text-align: center;
    border-color: #f9f9f9
}

.product-box-4 .product-detail a {
    color: #222
}

.product-box-4 .product-detail a .name {
    width: 70%;
    margin-top: 5px;
    text-transform: capitalize;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-align: left
}

[dir="rtl"] .product-box-4 .product-detail a .name {
    text-align: right
}

@media (max-width: 480px) {
    .product-box-4 .product-detail a .name {
        width: 100%
    }
}

.product-box-4 .product-detail .price {
    margin-top: 5px;
    font-weight: 500
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .price {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.product-box-4 .product-detail .price del {
    margin-left: 10px;
    color: #4a5568;
    font-weight: 400
}

[dir="rtl"] .product-box-4 .product-detail .price del {
    margin-left: unset;
    margin-right: 10px
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .price del {
        margin-left: 5px
    }

    [dir="rtl"] .product-box-4 .product-detail .price del {
        margin-left: unset;
        margin-right: 5px
    }
}

.product-box-4 .product-detail .buy-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px 11px;
    background-color: var(--theme-color);
    color: #fff
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .buy-button {
        position: relative;
        bottom: unset;
        right: unset;
        width: 100%;
        margin-top: 12px;
        color: var(--theme-color);
        border-radius: 5px;
        border: 1px solid var(--theme-color)
    }
}

[dir="rtl"] .product-box-4 .product-detail .buy-button {
    right: unset;
    left: 0
}

.product-box-4 .product-detail .buy-button i {
    font-size: 18px
}

.product-box-4 .product-detail .buy-button-2 {
    width: 53px
}

.product-box-4 .product-detail .sold-box {
    margin-top: 12px
}

.product-box-4 .product-detail .sold-box .progress {
    height: 8px;
    border-radius: 50px
}

.product-box-4 .product-detail .sold-box .progress .progress-bar {
    border-radius: 50px;
    background-color: var(--theme-color)
}

.product-box-4 .product-detail .sold-box h5 {
    margin-top: 6px
}

.product-box-4 .product-detail .sold-box h5 span {
    color: #000
}

.product-box-4 .product-detail .price-qty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .price-qty {
        display: block
    }
}

.product-box-4 .product-detail .price-qty .counter-number {
    display: inline-block
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .price-qty .counter-number {
        margin-bottom: 9px;
        width: 100%
    }
}

@media (max-width: 336px) {
    .product-box-4 .product-detail .price-qty .counter-number {
        display: block;
        width: 100%
    }
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .price-qty .counter-number .counter {
        width: 100%
    }
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .price-qty .counter-number .counter input {
        width: 100%
    }
}

@media (max-width: 480px) {

    .product-box-4 .product-detail .price-qty .counter-number .counter .qty-left-minus,
    .product-box-4 .product-detail .price-qty .counter-number .counter .qty-right-plus {
        width: 85px
    }
}

.product-box-4 .product-detail .price-qty .buy-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 6px 15px
}

[dir="rtl"] .product-box-4 .product-detail .price-qty .buy-button {
    right: unset;
    left: 0
}

@media (max-width: 480px) {
    .product-box-4 .product-detail .price-qty .buy-button {
        width: 100%;
        margin-top: 0
    }
}

.product-box-4 .product-detail .price-qty .buy-button .icli {
    font-size: 24px
}

.product-box-4 .product-detail .price-qty .buy-button i {
    font-size: 16px
}

.product-box-4 .product-detail .counter-number-box {
    position: absolute;
    bottom: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #e1b254;
    border-radius: 5px;
    background-color: rgba(225, 178, 84, 0.1);
    padding: 5px
}

.product-box-4 .product-detail .counter-number-box span {
    margin-right: 6px;
    position: relative
}

.product-box-4 .product-detail .counter-number-box span:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: -8px;
    background-color: #e1b254
}

.product-box-4 .product-detail .counter-number-box input {
    width: 27px;
    height: unset;
    border: none;
    background-color: transparent;
    position: relative
}

.product-box-4 .product-detail .counter-number-box .counter-arrow {
    background-color: #e1b254;
    color: #fff;
    padding: 3px 6px
}

.product-box-4 .product-detail .counter-number-box .counter-arrow .down,
.product-box-4 .product-detail .counter-number-box .counter-arrow .up {
    width: unset;
    height: unset;
    border: unset;
    font-size: unset;
    background-color: transparent;
    padding: 0;
    color: #fff
}

.product-box-4 .product-detail .counter-number-box .counter-arrow .down:hover,
.product-box-4 .product-detail .counter-number-box .counter-arrow .up:hover {
    color: #fff
}

.product-box-5 .product-image {
    position: relative
}

.product-box-5 .product-image .bg-size {
    border-radius: 10px
}

.product-box-5 .product-image .wishlist-top {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px
}

.product-box-5 .product-image .wishlist-top svg {
    width: 18px
}

.product-box-5 .product-image .product-option {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    bottom: -5px;
    background-color: #fff;
    width: 70%;
    padding: 10px 5px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.12);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    max-width: 320px
}

@media (max-width: 480px) {
    .product-box-5 .product-image .product-option {
        display: none
    }
}

.product-box-5 .product-image .product-option li {
    position: relative;
    width: 33.33%;
    text-align: center
}

.product-box-5 .product-image .product-option li a {
    color: #222
}

.product-box-5 .product-image .product-option li a .feather {
    width: 17px;
    height: auto;
    color: #4a5568
}

.product-box-5 .product-image .product-option li+li:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #4a5568;
    opacity: 0.3
}

[dir="rtl"] .product-box-5 .product-image .product-option li+li:after {
    left: unset;
    right: 0
}

.product-box-5 .product-image img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 10px
}

.product-box-5:hover .product-image a img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.product-box-5:hover .product-image .product-option {
    bottom: 15px;
    opacity: 1
}

.product-box-5 .product-detail {
    padding-top: 10px;
    text-align: center
}

.product-box-5 .product-detail a {
    color: #222;
    display: block
}

.product-box-5 .product-detail a:hover {
    color: #222
}

.product-box-5 .product-detail a h5 {
    margin-bottom: 6px
}

.product-box-5 .product-detail a h5.name {
    font-weight: 600;
    line-height: 22px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-transform: capitalize
}

.product-box-5 .product-detail .product-rating {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(4px + (7 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.product-box-5 .product-detail .product-rating h6 {
    font-weight: 500
}

.product-box-5+.product-box-5 {
    margin-top: 30px !important
}

.product-section-2 .product-box-4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    padding: 0
}

.product-section-2 .product-box-4:hover .product-details .option {
    opacity: 1
}

.product-section-2 .product-box-4:hover .product-details .option li {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.product-section-2 .product-box-4:hover .product-details .option li:nth-child(1) {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s
}

.product-section-2 .product-box-4:hover .product-details .option li:nth-child(2) {
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s
}

.product-section-2 .product-box-4:hover .product-details .option li:nth-child(3) {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s
}

.product-section-2 .product-box-4:hover .product-details .option li:nth-child(4) {
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s
}

.product-section-2 .product-box-4 .product-image {
    width: calc(84px + (116 - 84) * ((100vw - 320px) / (1920 - 320)));
    height: calc(84px + (116 - 84) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid #ececec;
    border-radius: 12px;
    padding: 15px
}

.product-section-2 .product-box-4 .product-image-2 {
    width: 116px;
    height: 151px;
    border-radius: 8px
}

.product-section-2 .product-box-4 .product-image img {
    width: 100%;
    height: 100%;
    margin: 0;
    -o-object-fit: contain;
    object-fit: contain
}

.product-section-2 .product-box-4 .product-details {
    text-align: left;
    padding-left: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

[dir="rtl"] .product-section-2 .product-box-4 .product-details {
    padding-left: unset;
    padding-right: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.product-section-2 .product-box-4 .product-details a {
    color: #222
}

.product-section-2 .product-box-4 .product-details a .name {
    font-weight: 500;
    margin: 5px 0;
    text-align: left
}

[dir="rtl"] .product-section-2 .product-box-4 .product-details a .name {
    text-align: right
}

.product-section-2 .product-box-4 .product-details .price {
    color: var(--theme-color);
    font-weight: 500
}

.product-section-2 .product-box-4 .product-details .price del {
    color: #bfbfbf;
    font-weight: 400;
    margin-left: 5px
}

.product-section-2 .product-box-4 .product-details .option {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    position: absolute;
    left: 25px;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin: 0 -4px
}

[dir="rtl"] .product-section-2 .product-box-4 .product-details .option {
    left: unset;
    right: 10px
}

@media (max-width: 480px) {
    .product-section-2 .product-box-4 .product-details .option {
        left: 10px;
        margin: 0 -2px
    }
}

.product-section-2 .product-box-4 .product-details .option li {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--theme-color);
    margin: 0 4px;
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative;
    overflow: hidden
}

@media (max-width: 480px) {
    .product-section-2 .product-box-4 .product-details .option li {
        width: 30px;
        height: 30px;
        margin: 0 2px
    }
}

.product-section-2 .product-box-4 .product-details .option li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0.1;
    z-index: -1
}

.product-section-2 .product-box-4 .product-details .option li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1
}

.product-section-2 .product-box-4 .product-details .option li a .icli {
    font-size: 20px;
    color: #000
}

@media (max-width: 480px) {
    .product-section-2 .product-box-4 .product-details .option li a .icli {
        font-size: 15px
    }
}

.product-section-2 .product-box-4 .product-details .option li a .icli.iconly-Swap {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.review-box:hover .review-profile .review-image {
    border-radius: 6px
}

.review-box .review-contain {
    margin-bottom: 40px
}

.review-box .review-contain h5 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 15px
}

.review-box .review-contain p {
    color: #4a5568;
    line-height: 27px;
    margin-bottom: 0
}

.review-box .review-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.review-box .review-profile .review-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.review-box .review-profile .review-detail {
    padding-left: 12px
}

[dir="rtl"] .review-box .review-profile .review-detail {
    padding-left: unset;
    padding-right: 12px
}

.review-box .review-profile .review-detail h5 {
    font-size: 18px;
    margin-bottom: 7px;
    font-weight: 500;
    color: #222
}

.review-box .review-profile .review-detail h6 {
    font-size: 16px;
    font-weight: 500;
    color: #4a5568
}

.service-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 767px) {
    .service-contain {
        display: none
    }
}

.service-contain .service-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

@media (max-width: 1366px) {
    .service-contain .service-box {
        width: 50%
    }
}

@media (max-width: 1366px) {
    .service-contain .service-box:nth-child(-n+2) {
        margin-bottom: 15px
    }
}

@media (max-width: 1366px) {
    .service-contain .service-box:nth-child(3)::before {
        display: none
    }
}

@media (max-width: 767px) {
    .service-contain .service-box::before {
        display: none
    }
}

.service-contain .service-box+.service-box::before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 50%;
    left: -104px;
    border: 1px dashed #c1c1c1
}

[dir="rtl"] .service-contain .service-box+.service-box::before {
    left: unset;
    right: -104px
}

@media (max-width: 1660px) {
    .service-contain .service-box+.service-box::before {
        left: -70px
    }

    [dir="rtl"] .service-contain .service-box+.service-box::before {
        left: unset;
        right: -70px
    }
}

@media (max-width: 1460px) {
    .service-contain .service-box+.service-box::before {
        left: -36px
    }

    [dir="rtl"] .service-contain .service-box+.service-box::before {
        left: unset;
        right: -36px
    }
}

@media (max-width: 1366px) {
    .service-contain .service-box+.service-box::before {
        left: -31px
    }

    [dir="rtl"] .service-contain .service-box+.service-box::before {
        left: unset;
        right: -31px
    }
}

.service-contain .service-box .service-image img {
    width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain
}

.service-contain .service-box .service-detail {
    margin-left: 20px
}

[dir="rtl"] .service-contain .service-box .service-detail {
    margin-left: unset;
    margin-right: 20px
}

.service-contain .service-box .service-detail h5 {
    font-weight: 500;
    color: #222
}

.service-contain-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(191, 191, 191, 0.2);
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}
.f-14{
    font-size: 14px;
}
.f-10{
    font-size: 10px
}
.discounted-product{
    background:#fbe24c;
    padding: 2px 16px 6px 16px;
    border-radius: 0px 30px 30px 30px;
}
.service-contain-2:hover {
    background-color: var(--theme-color)
}

.service-contain-2:hover svg {
    fill: #fff
}

.service-contain-2:hover .service-detail h3,
.service-contain-2:hover .service-detail h6 {
    color: #fff
}

.service-contain-2 svg {
    width: 42px;
    height: 42px;
    fill: var(--theme-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.service-contain-2 .service-detail {
    margin-left: 25px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .service-contain-2 .service-detail {
    margin-left: unset;
    margin-right: 25px
}

.service-contain-2 .service-detail h3 {
    letter-spacing: 0.8px;
    margin-bottom: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-weight: 600
}

.service-contain-2 .service-detail h6 {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.coming-soon-section {
    width: 100vw;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-image: url(../../../public/assets/images/inner-page/coming-soon.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative
}

.coming-soon-section .coming-box {
    text-align: center;
    z-index: 1
}

.coming-soon-section .coming-box .coming-text {
    width: 90%;
    margin: 12px auto 0;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6;
    color: #4a5568
}

@media (max-width: 480px) {
    .coming-soon-section .coming-box .coming-text {
        width: 100%
    }
}

.coming-soon-section .coming-box .coming-title h2 {
    font-size: calc(27px + (38 - 27) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    margin-top: -5px
}

.coming-soon-section .coming-box .coming-timer {
    margin-top: 24px
}

.coming-soon-section .coming-box .coming-timer ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.coming-soon-section .coming-box .coming-timer ul li {
    width: calc(62px + (90 - 62) * ((100vw - 320px) / (1920 - 320)));
    height: calc(62px + (90 - 62) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    background-color: #fff;
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.coming-soon-section .coming-box .coming-timer ul li+li::after {
    content: ":";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(-10px + (-17 - -10) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(24px + (30 - 24) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color)
}

[dir="rtl"] .coming-soon-section .coming-box .coming-timer ul li+li::after {
    left: unset;
    right: -17px
}

.coming-soon-section .coming-box .coming-timer ul li .counter {
    display: block;
    text-align: center
}

.coming-soon-section .coming-box .coming-timer ul li .counter>div {
    font-size: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: var(--theme-color);
    line-height: 1
}

.coming-soon-section .coming-box .coming-timer ul li .counter p {
    margin-bottom: 0;
    margin-top: 5px;
    color: #4a5568;
    font-size: calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.coming-soon-section .coming-box .coming-contain {
    margin-top: calc(19px + (38 - 19) * ((100vw - 320px) / (1920 - 320)))
}

.coming-soon-section .coming-box .coming-contain .coming-form {
    position: relative;
    width: 90%;
    margin: 22px auto 0;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--theme-color)
}

.coming-soon-section .coming-box .coming-contain .coming-form .form-control {
    border: none;
    border-radius: 0
}

[dir="rtl"] .coming-soon-section .coming-box .coming-contain .coming-form .form-control {
    text-align: left
}

.coming-soon-section .coming-box .coming-contain .coming-form .form-control:focus {
    border-color: transparent;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #4a5568;
    z-index: 0
}

@media (max-width: 480px) {
    .coming-soon-section .coming-box .coming-contain .coming-form {
        width: 100%
    }
}

.coming-soon-section .coming-box .coming-contain .coming-form .coming-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: none;
    padding: 0 calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    background: var(--theme-color2);
    color: #fff;
    font-weight: 700
}

>>.table:not(:last-child):last-child>* {
    border-bottom: none
}

.inner-title {
    margin-bottom: calc(32px + (55 - 32) * ((100vw - 320px) / (1920 - 320)));
    text-align: center
}

.inner-title h2 {
    font-size: calc(21px + (35 - 21) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #222;
    padding-bottom: 20px;
    display: inline-block;
    text-transform: none;
    position: relative
}

.inner-title h2::after {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 70px;
    height: 1px;
    bottom: 0;
    background-color: var(--theme-color)
}

.inner-title h2 img {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -11px;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    padding: 0 10px
}

.inner-title-2 {
    margin-bottom: calc(18px + (38 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.inner-title-2 h3 {
    font-size: calc(24px + (30 - 24) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.section-404 .image-404 {
    text-align: center
}

.section-404 .image-404 img {
    width: 25%
}

@media (max-width: 1460px) {
    .section-404 .image-404 img {
        width: 40%
    }
}

@media (max-width: 991px) {
    .section-404 .image-404 img {
        width: 50%
    }
}

@media (max-width: 575px) {
    .section-404 .image-404 img {
        width: 70%
    }
}

.section-404 .contain-404 {
    width: 50%;
    text-align: center;
    margin: calc(20px + (45 - 20) * ((100vw - 320px) / (1920 - 320))) auto 0;
    font-family: "Public Sans", sans-serif
}

@media (max-width: 1660px) {
    .section-404 .contain-404 {
        width: 60%
    }
}

@media (max-width: 1199px) {
    .section-404 .contain-404 {
        width: 80%
    }
}

@media (max-width: 767px) {
    .section-404 .contain-404 {
        width: 100%
    }
}

.section-404 .contain-404 h3 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.5;
    font-weight: 400
}

.section-404 .contain-404 button {
    margin-top: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.cart-section .deliver-box {
    padding: 22px 18px;
    background-color: #f8f8f8;
    border-radius: 6px;
    position: relative;
    margin-bottom: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.cart-section .deliver-box .btn-close {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: 18px
}

.cart-section .deliver-box h5:first-child {
    margin-bottom: 8px
}

.cart-section .deliver-box h5:last-child {
    font-weight: 500;
    line-height: 1.4
}

.cart-section .deliver-box h5:last-child a {
    text-decoration: underline;
    margin-left: 5px
}

.cart-section .cart-title {
    margin-bottom: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.cart-section .promo-code-box {
    margin-bottom: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.cart-section .promo-code-box .form-label {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.cart-section .total-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 22px 28px;
    border: 1px solid #ececec
}

.cart-section .member-button {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    letter-spacing: 1.4px
}

.cart-section .special-seller-box {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 6px;
    border-radius: 5px;
    border: 1px dashed #bfbfbf
}

.cart-section .special-seller-box h5 {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0;
    padding-bottom: 18px;
    border-bottom: 1px solid #4a5568;
    margin-bottom: 25px
}

.cart-section .special-seller-box .sub-total-table tbody tr td:last-child {
    font-weight: 500
}

.cart-section .special-seller-box .sub-total-table tbody tr.sub-totle td {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 26px
}

.cart-section .special-seller-box .sub-total-table tbody tr.sub-totle td:last-child {
    text-align: right
}

.cart-section .special-seller-box .sub-total-table tbody tr.shipping-title td {
    border: none;
    padding-top: 20px;
    padding-bottom: 10px
}

.cart-section .special-seller-box .sub-total-table tbody tr.shipping-list td {
    border: none
}

.cart-section .special-seller-box .sub-total-table tbody tr.shipping-list td .form-check {
    margin-bottom: 0
}

.cart-section .special-seller-box .sub-total-table tbody tr.shipping-list td .form-check .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 10px
}

.cart-section .special-seller-box .sub-total-table tbody tr.shipping-list td .form-check .form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.cart-section .special-seller-box .sub-total-table tbody tr.shipping-list td .form-check .form-check-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.cart-section .cart-total-box {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 6px;
    text-align: center
}

.cart-section .cart-total-box h3 {
    font-weight: bold;
    color: #222;
    margin-bottom: 15px
}

.cart-section .cart-total-box h6 {
    width: 100%;
    margin: 20px 0 10px;
    color: #222;
    font-weight: 500;
    font-size: 16px
}

.cart-section .cart-total-box p {
    margin: 0;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.4
}

.cart-section .cart-total-box .checkout-button {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 12px
}

.cart-table {
    background-color: #f8f8f8;
    padding: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320))) calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.cart-table.cart-table-2 {
    background-color: transparent;
    padding: 0
}

.cart-table.cart-table-2 table {
    margin-bottom: 0
}

.cart-table.cart-table-2 table thead tr th {
    font-size: 20px;
    font-weight: 500;
    color: #4a5568;
    padding: 0 22px 16px
}

.cart-table.cart-table-2 table tbody {
    border-top: 1px solid #ececec
}

.cart-table.cart-table-2 table tbody tr td {
    vertical-align: middle
}

.cart-table.cart-table-2 table tbody tr:first-child td {
    padding: 22px 16px
}

.cart-table table {
    margin-bottom: 0
}

.cart-table table tbody tr:last-child td {
    border: none;
    padding-bottom: 0
}

.cart-table table tbody tr:first-child td {
    padding-top: 0
}

.cart-table table tbody tr td {
    padding: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320))) 16px;
    min-width: calc(135px + (170 - 135) * ((100vw - 320px) / (1920 - 320)))
}

.cart-table table tbody tr td .table-title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500
}

.cart-table table tbody tr td.product-detail:hover .product .product-image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.cart-table table tbody tr td.product-detail ul li+li {
    margin-bottom: 2px
}

.cart-table table tbody tr td.product-detail .product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden
}

@media (max-width: 1366px) {
    .cart-table table tbody tr td.product-detail .product {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

.cart-table table tbody tr td.product-detail .product .product-image {
    width: 70px
}

@media (max-width: 1366px) {
    .cart-table table tbody tr td.product-detail .product .product-image {
        width: 80px
    }
}

@media (max-width: 575px) {
    .cart-table table tbody tr td.product-detail .product .product-image {
        width: 90px
    }
}

@media (max-width: 480px) {
    .cart-table table tbody tr td.product-detail .product .product-image {
        width: 100px
    }
}

@media (max-width: 360px) {
    .cart-table table tbody tr td.product-detail .product .product-image {
        width: 70px
    }
}

.cart-table table tbody tr td.product-detail .product .product-image img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.cart-table table tbody tr td.product-detail .product .product-detail {
    margin-left: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 0
}

[dir="rtl"] .cart-table table tbody tr td.product-detail .product .product-detail {
    margin-left: unset;
    margin-right: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .cart-table table tbody tr td.product-detail .product .product-detail ul {
    padding-right: 0
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li {
    display: block;
    white-space: nowrap;
    margin-bottom: 8px;
    font-size: 14px
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li+li {
    margin-bottom: calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)))
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li .saving {
    font-weight: 500
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li span {
    font-weight: 500
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.name a {
    font-weight: 500;
    font-size: 16px;
    color: #222
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.name a:hover {
    color: #222
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li:nth-child(n+4) {
    display: none
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li .price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li .price h6 {
    margin-left: 8px;
    text-decoration: line-through
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity-price-box {
    border: 1px solid #ececec;
    border-radius: 5px;
    width: 62%
}

@media (max-width: 575px) {
    .cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity-price-box {
        width: 80%
    }
}

@media (max-width: 360px) {
    .cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity-price-box {
        width: 90%
    }
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity-price-box .cart_qty button {
    padding: calc(3px + (10 - 3) * ((100vw - 320px) / (1920 - 320))) calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
    border: none
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity-price-box .cart_qty button input {
    padding: 10px 18px;
    border: none;
    text-align: center
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity {
    margin: 6px 0
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity .quantity-price {
    width: 50%
}

@media (max-width: 480px) {
    .cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity .quantity-price {
        width: 60%
    }
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity .quantity-price .cart_qty button {
    width: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320)));
    height: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    background-color: #e3e0e0;
    border-radius: 100% !important;
    padding: 0
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity .quantity-price .cart_qty button i {
    margin-top: 2px
}

.cart-table table tbody tr td.product-detail .product .product-detail ul li.quantity .quantity-price .cart_qty input {
    padding: 0;
    border: none;
    text-align: center;
    background-color: transparent
}

.cart-table table tbody tr td.price h5 {
    font-weight: 500;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.cart-table table tbody tr td.price h5 del {
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin-left: 4px
}

[dir="rtl"] .cart-table table tbody tr td.price h5 del {
    margin-left: unset;
    margin-right: 4px
}

.cart-table table tbody tr td.price h6 {
    margin-top: 5px;
    white-space: nowrap;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.cart-table table tbody tr td.saving {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-color)
}

.cart-table table tbody tr td.quantity {
    width: 20%
}

.cart-table table tbody tr td .quantity-price {
    width: 62%
}

@media (max-width: 1460px) {
    .cart-table table tbody tr td .quantity-price {
        width: 80%
    }
}

@media (max-width: 1199px) {
    .cart-table table tbody tr td .quantity-price {
        width: 90%
    }
}

@media (max-width: 991px) {
    .cart-table table tbody tr td .quantity-price {
        width: 100%
    }
}

.cart-table table tbody tr td .quantity-price .cart_qty button {
    width: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320)));
    height: calc(29px + (35 - 29) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    background-color: #e3e0e0;
    border-radius: 100% !important;
    padding: 0
}

.cart-table table tbody tr td .quantity-price .cart_qty button i {
    font-size: 14px;
    padding-top: 3px
}

.cart-table table tbody tr td .quantity-price .cart_qty input {
    padding: 0;
    border: none;
    text-align: center;
    background-color: transparent
}

.cart-table table tbody tr td.subtotal h5 {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    display: inline-block
}

.cart-table table tbody tr td.subtotal .btn-close {
    margin-left: 50px
}

.cart-table table tbody tr td.save-remove .save {
    font-weight: 400;
    white-space: nowrap;
    font-size: 16px;
    display: block;
    text-decoration: underline
}

.cart-table table tbody tr td.save-remove .save:hover {
    color: var(--theme-color)
}

.cart-table table tbody tr td.save-remove .remove {
    font-weight: 400;
    font-size: 15px;
    color: #bf2020;
    text-decoration: underline
}

.cart-table table tfoot {
    border-top: 1px solid #ececec
}

.cart-table table tfoot tr td:first-child {
    border: none
}

.cart-table table tfoot tr td:nth-child(2) {
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    border-left: 1px solid #ececec
}

.cart-table table tfoot tr td:last-child {
    font-size: 16px;
    font-weight: 500;
    border-right: 1px solid #ececec
}

.summery-box {
    border-radius: 5px;
    background-color: #f8f8f8
}

.summery-box .summery-header {
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #ececec
}

.summery-box .summery-header h3 {
    font-weight: 600
}

.summery-box .summery-header a {
    font-weight: 500;
    margin-left: auto;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.summery-box .summery-header a:hover {
    color: var(--theme-color)
}

[dir="rtl"] .summery-box .summery-header a {
    margin-left: unset;
    margin-right: auto
}



.summery-contain::-webkit-scrollbar-track {
    background-color: #4a5568
}

.summery-contain::-webkit-scrollbar {
    width: 3px
}

.summery-contain::-webkit-scrollbar-thumb {
    background-color: var(--theme-color);
    border-radius: 4px
}

.summery-contain .coupon-cart .coupon-box {
    position: relative;
    overflow: hidden;
    border-radius: 4px
}

.summery-contain .coupon-cart .coupon-box .btn-apply {
    background: var(--theme-color);
    color: #fff;
    padding: 0 calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    border: none
}

.summery-contain .coupon-cart .coupon-box .form-control {
    border: 2px solid var(--theme-color);
    padding: 10px
}

[dir="rtl"] .summery-contain ul {
    padding-right: 0
}

.summery-contain ul li {
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.summery-contain ul li:last-child {
    border-bottom: none
}

.summery-contain ul li h4 {
    font-size: 15px;
    color: #4a5568
}

.summery-contain ul li h4.price {
    color: #4a5568;
    margin-left: auto
}

[dir="rtl"] .summery-contain ul li h4.price {
    margin-left: unset;
    margin-right: auto
}

.summery-contain ul li h4.price .shpping-contain {
    display: block;
    font-weight: 400;
    font-size: 13px;
    margin-top: 3px
}

.summery-contain ul li h4 span {
    font-weight: 600
}


.summery-contain::-webkit-scrollbar-track {
    background-color: transparent
}

.summery-contain::-webkit-scrollbar {
    width: 3px
}

.summery-contain::-webkit-scrollbar-thumb {
    background-color: var(--theme-color);
    border-radius: 4px
}

.summery-contain li {
    padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.summery-contain li:last-child {
    border-bottom: none
}

.summery-contain li p {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6;
    margin: 0
}

.summery-contain li h4 {
    font-size: 15px;
    color: #4a5568
}

.summery-contain li h4.price {
    color: #4a5568;
    margin-left: auto
}

[dir="rtl"] .summery-contain li h4.price {
    margin-left: unset;
    margin-right: auto
}

.summery-contain li h4.price .shpping-contain {
    display: block;
    font-weight: 400;
    font-size: 13px;
    margin-top: 3px
}

.summery-contain li h4 span {
    font-weight: 600
}

.summery-box .summery-total {
    padding: 0 calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.summery-box .summery-total li {
    padding-top: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.summery-box .summery-total li h4 {
    font-size: 17px;
    color: #222
}

.summery-box .summery-total li h4.price {
    margin-left: auto
}

[dir="rtl"] .summery-box .summery-total li h4.price {
    margin-left: unset;
    margin-right: auto
}

.summery-box .summery-total li h4 span {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.summery-box .summery-total li:nth-child(4) {
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) 0
}

.summery-box .summery-total li:nth-child(4) h4 {
    font-weight: 600;
    color: var(--theme-color)
}

.summery-box .summery-total li:last-child {
    border-top: 1px solid #ececec;
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) 0
}

.summery-box .summery-total li:last-child h4 {
    font-weight: 600;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.button-group {
    text-align: right;
    margin-top: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.button-group.cart-button {
    margin-top: 0;
    padding: 0 calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320))) calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .button-group.cart-button ul {
    padding-right: 0
}

.button-group.cart-button ul li {
    width: 100%
}

.button-group ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(7px + (16 - 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .button-group ul {
    padding-right: 0
}

.button-group ul.button-group-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: calc(26px + (45 - 26) * ((100vw - 320px) / (1920 - 320)))
}

.button-group ul li button {
    width: 100%;
    font: inherit;
    letter-spacing: 0.04em;
    padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.button-group ul li button.shopping-button {
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ececec;
    color: #000
}

.button-group ul li button.shopping-button i {
    margin-right: 8px
}

[dir="rtl"] .button-group ul li button.shopping-button i {
    margin-right: unset;
    margin-left: 8px !important
}

.checkout-section .custom-accordion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section .custom-accordion .accordion-item {
    border: none;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    width: 100%
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button {
    color: #4a5568;
    position: relative;
    padding: 0
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button::after {
    content: none
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 30px;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out
}

[dir="rtl"] .checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button::before {
    right: unset;
    left: 30px
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    color: #222;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::before {
    top: 20%;
    -webkit-transform: rotate(-180deg) translateY(-50%);
    transform: rotate(-180deg) translateY(-50%)
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label {
    font-weight: 500;
    color: #222;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 16px 20px
}

[dir="rtl"] .checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label {
    padding-right: 0
}

@media (max-width: 480px) {
    .checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label {
        margin-top: 5px
    }
}

.checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label .form-check-input {
    margin-right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .checkout-section .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label .form-check-input {
    margin-left: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    margin-right: unset
}

.checkout-section .custom-accordion .accordion-item .accordion-collapse .accordion-body .cod-review a:hover {
    color: var(--theme-color)
}

.checkout-section .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check {
    margin-bottom: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .checkout-section .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check {
    padding-left: unset;
    padding-right: 0
}

.checkout-section .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check input {
    margin-right: 10px
}

[dir="rtl"] .checkout-section .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check input {
    margin-right: unset;
    margin-left: 10px
}

.checkout-section .custom-navtab {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(0px + (24 - 0) * ((100vw - 320px) / (1920 - 320)));
    overflow: auto;
    position: sticky;
    top: 0
}

@media (max-width: 991px) {
    .checkout-section .custom-navtab {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.checkout-section .custom-navtab .nav-item {
    width: 100%;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

@media (max-width: 991px) {
    .checkout-section .custom-navtab .nav-item {
        display: inline-block
    }

    .checkout-section .custom-navtab .nav-item+.nav-item {
        margin-left: 15px
    }
}

.checkout-section .custom-navtab .nav-item .nav-link {
    position: relative;
    text-align: left;
    border: 2px solid #ececec;
    padding: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
    cursor: pointer
}

@media (max-width: 991px) {
    .checkout-section .custom-navtab .nav-item .nav-link {
        width: calc(195px + (220 - 195) * ((100vw - 320px) / (1920 - 320)))
    }
}

.checkout-section .custom-navtab .nav-item .nav-link::before {
    content: "";
    position: absolute;
    width: 0px;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: var(--theme-color);
    background-color: transparent;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    border-radius: 4px
}

.checkout-section .custom-navtab .nav-item .nav-link.active {
    color: #222;
    background-color: transparent;
    border: 2px solid var(--theme-color)
}

.checkout-section .custom-navtab .nav-item .nav-link.active::before {
    -webkit-box-shadow: 0 4px 0px var(--theme-color);
    box-shadow: 0 4px 0px var(--theme-color);
    width: 100%
}

.checkout-section .custom-navtab .nav-item .nav-link.active .nav-item-box h4,
.checkout-section .custom-navtab .nav-item .nav-link.active .nav-item-box i {
    color: var(--theme-color);
    z-index: 1;
    -webkit-text-stroke: 1px transparent
}

.checkout-section .custom-navtab .nav-item .nav-link .nav-item-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section .custom-navtab .nav-item .nav-link .nav-item-box span {
    color: #4a5568;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: calc(0px + (5 - 0) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section .custom-navtab .nav-item .nav-link .nav-item-box h4 {
    color: #222;
    font-weight: 500;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section .custom-navtab .nav-item .nav-link .nav-item-box .lord-icon {
    width: calc(38px + (58 - 38) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin-left: auto
}

[dir="rtl"] .checkout-section .custom-navtab .nav-item .nav-link .nav-item-box .lord-icon {
    margin-left: unset;
    margin-right: auto
}

.checkout-section .tab-content {
    background-color: #f8f8f8;
    height: 100%;
    padding: calc(26px + (35 - 26) * ((100vw - 320px) / (1920 - 320))) calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section .tab-content .tab-pane .tab-title {
    margin-bottom: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #222
}

.checkout-section .delivery-address-box {
    border-radius: 8px;
    padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.07)
}

.checkout-section .delivery-address-box>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.checkout-section .delivery-address-box>div .label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--theme-color);
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.8px
}

[dir="rtl"] .checkout-section .delivery-address-box>div .label {
    right: unset;
    left: 0
}

.checkout-section .delivery-address-box>div .form-check .form-check-input {
    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 2px
}

.checkout-section .delivery-address-box>div .form-check .form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.checkout-section .delivery-address-box>div .form-check .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.checkout-section .delivery-address-box>div .form-check .form-check-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.checkout-section .delivery-address-box>div .delivery-address-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 10px;
    width: calc(85% + (75 - 85) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .checkout-section .delivery-address-box>div .delivery-address-detail {
    margin-left: unset;
    margin-right: 10px;
    padding-right: 0
}

.checkout-section .delivery-address-box>div .delivery-address-detail li {
    display: block;
    width: 100%
}

.checkout-section .delivery-address-box>div .delivery-address-detail p,
.checkout-section .delivery-address-box>div .delivery-address-detail h6 {
    line-height: 1.4;
    margin-bottom: 0;
    font-weight: 400;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section .delivery-address-box>div .delivery-address-detail p span,
.checkout-section .delivery-address-box>div .delivery-address-detail h6 span {
    margin-right: 4px
}

.checkout-section .add-address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 30px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    border-radius: 8px
}

.checkout-section .delivery-option {
    padding: calc(17px + (26 - 17) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    border-radius: 5px
}

@media (max-width: 1460px) {
    .checkout-section .delivery-option .select-option {
        margin-top: 6px
    }
}

.checkout-section .delivery-option .date-box {
    position: relative
}

.checkout-section .delivery-option .date-box i {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: 17px;
    font-size: 18px;
    color: #4a5568
}

.checkout-section .delivery-option .delivery-category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

@media (max-width: 767px) {
    .checkout-section .delivery-option .delivery-category {
        display: block
    }
}

.checkout-section .delivery-option .custom-form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    min-height: auto
}

[dir="rtl"] .checkout-section .delivery-option .custom-form-check {
    padding-left: unset;
    padding-right: 1.5rem
}

.checkout-section .delivery-option .custom-form-check .form-check-label {
    font-size: calc(15px + (19 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-left: 12px;
    font-weight: 500
}

[dir="rtl"] .checkout-section .delivery-option .custom-form-check .form-check-label {
    padding-left: unset;
    padding-right: 12px
}

.checkout-section .delivery-option .delivery-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

@media (max-width: 575px) {
    .checkout-section .delivery-option .delivery-items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (max-width: 480px) {
    .checkout-section .delivery-option .delivery-items {
        display: block
    }
}

.checkout-section .delivery-option .delivery-items h5 {
    letter-spacing: 0.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section .delivery-option .delivery-items h5.items {
    margin-bottom: 5px
}

@media (max-width: 575px) {
    .checkout-section .delivery-option .delivery-items h5.items {
        margin-bottom: 0
    }
}

@media (max-width: 480px) {
    .checkout-section .delivery-option .delivery-items h5.items {
        margin-bottom: 5px
    }
}

.checkout-section .delivery-option .delivery-items h5.items span {
    text-decoration: underline
}

@media (max-width: 575px) {
    .checkout-section .delivery-option .delivery-items h5.charge {
        margin-top: 8px
    }
}

.checkout-section .delivery-option .delivery-items h5 i {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-left: 7px;
    color: rgba(0, 0, 0, 0.25)
}

[dir="rtl"] .checkout-section .delivery-option .delivery-items h5 i {
    margin-left: unset;
    margin-right: 7px
}

.checkout-section .delivery-option .delivery-date {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    border: 1px solid #ececec;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568;
    width: 100%;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section .delivery-option .delivery-date input {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    border: none;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568;
    width: 100%
}

.checkout-section .delivery-option .delivery-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

.checkout-section .delivery-option .delivery-time .dropdown-toggle {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    border: 1px solid #ececec;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

.checkout-section .delivery-option .delivery-time .dropdown-toggle i {
    -webkit-text-stroke: 1px #4a5568;
    color: transparent;
    font-size: 18px;
    margin-right: 10px
}

.checkout-section .delivery-option .delivery-time .dropdown-toggle::after {
    content: none
}

.checkout-section .delivery-option .delivery-time .dropdown-toggle::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px
}

.checkout-section .delivery-option .delivery-time .dropdown-menu {
    border: 1px solid transparent;
    -webkit-box-shadow: 0 6px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.1)
}

.checkout-section .delivery-option .delivery-time .dropdown-menu li a:active {
    color: #000;
    text-decoration: none;
    background-color: #ececec
}

.checkout-section .delivery-option .delivery-time .dropdown-menu li+li {
    margin-top: 5px
}

.checkout-section .payment-button {
    padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.checkout-section .payment-method .custom-form-check label {
    font-weight: 500;
    font-size: 17px
}

.checkout-section .credit-card-box .credit-detail {
    position: relative
}

.checkout-section .credit-info label {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.checkout-section .payment-option {
    padding: 20px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.17);
    border-radius: 8px;
    position: relative
}

.checkout-section .payment-option .form-check .form-check-input {
    margin-top: 4px
}

.checkout-section .payment-option .form-check label {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px
}

.checkout-section .payment-option img {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: 20px;
    width: 60px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(17px + (45 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li {
    position: relative;
    width: 100%
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li:last-child .checkout-box::before {
    content: none
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 6px;
    background-color: #f8f8f8
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-icon {
    left: unset;
    right: 0
}

@media (max-width: 575px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-icon {
        display: none
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-icon .lord-icon {
    width: 100%;
    height: 100%
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box {
    padding: calc(14px + (29 - 14) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 8px #eee;
    box-shadow: 0 0 8px #eee;
    margin-left: 66px;
    position: relative
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box {
    margin-left: unset;
    margin-right: 66px
}

@media (max-width: 575px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box {
        margin-left: 0
    }

    [dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box {
        margin-left: unset;
        margin-right: 0
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box::before {
    content: "";
    position: absolute;
    top: 25px;
    left: -42px;
    width: 0;
    height: 115%;
    border-left: 1px dashed rgba(34, 34, 34, 0.18);
    z-index: -1
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box::before {
    left: unset;
    right: -42px
}

@media (max-width: 575px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box::before {
        content: none
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-title {
    margin-bottom: calc(9px + (17 - 9) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-title h4 {
    font-weight: 600;
    font-size: calc(16px + (19 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box {
    border-radius: 8px;
    padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    height: 100%;
    -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.07)
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--theme-color);
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.8px
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .label {
    right: unset;
    left: 0
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .form-check .form-check-input {
    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 2px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .form-check .form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .form-check .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .form-check .form-check-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .delivery-address-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 10px;
    width: calc(85% + (75 - 85) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .delivery-address-detail {
    margin-left: unset;
    margin-right: 10px;
    padding-right: 0
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .delivery-address-detail li {
    display: block;
    width: 100%
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .delivery-address-detail p,
.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .delivery-address-detail h6 {
    line-height: 1.4;
    margin-bottom: 0;
    font-weight: 400;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .delivery-address-detail p span,
.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-address-box>div .delivery-address-detail h6 span {
    margin-right: 4px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .add-address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 30px;
    height: 100%;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    border-radius: 8px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option {
    padding: calc(17px + (26 - 17) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    border-radius: 5px
}

@media (max-width: 1460px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .select-option {
        margin-top: 6px
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .date-box {
    position: relative
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .date-box i {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: 17px;
    font-size: 18px;
    color: #4a5568
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

@media (max-width: 767px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-category {
        display: block
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .custom-form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    min-height: auto
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .custom-form-check {
    padding-left: unset;
    padding-right: 1.5rem
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .custom-form-check .form-check-label {
    font-size: calc(15px + (19 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-left: 12px;
    font-weight: 500
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .custom-form-check .form-check-label {
    padding-left: unset;
    padding-right: 12px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-date {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    border: 1px solid #ececec;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568;
    width: 100%;
    padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-date input {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    border: none;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568;
    width: 100%
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time .dropdown-toggle {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    border: 1px solid #ececec;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time .dropdown-toggle i {
    -webkit-text-stroke: 1px #4a5568;
    color: transparent;
    font-size: 18px;
    margin-right: 10px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time .dropdown-toggle::after {
    content: none
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time .dropdown-toggle::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time .dropdown-menu {
    border: 1px solid transparent;
    -webkit-box-shadow: 0 6px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.1)
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time .dropdown-menu li a:active {
    color: #000;
    text-decoration: none;
    background-color: #ececec
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .delivery-option .delivery-time .dropdown-menu li+li {
    margin-top: 5px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box {
    display: none
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box.show {
    display: block
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option {
    padding: calc(17px + (26 - 17) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    border-radius: 5px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

@media (max-width: 575px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (max-width: 480px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items {
        display: block
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5 {
    letter-spacing: 0.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5.items {
    margin-bottom: 5px
}

@media (max-width: 575px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5.items {
        margin-bottom: 0
    }
}

@media (max-width: 480px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5.items {
        margin-bottom: 5px
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5.items span {
    text-decoration: underline
}

@media (max-width: 575px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5.charge {
        margin-top: 8px
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5 i {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-left: 7px;
    color: rgba(0, 0, 0, 0.25)
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .future-box .future-option .delivery-items h5 i {
    margin-left: unset;
    margin-right: 7px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .payment-button {
    padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .payment-method .custom-form-check label {
    font-weight: 500;
    font-size: 17px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .credit-card-box .credit-detail {
    position: relative
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .credit-info label {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .payment-option {
    padding: 20px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.17);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.17);
    border-radius: 8px;
    position: relative
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .payment-option .form-check .form-check-input {
    margin-top: 4px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .payment-option .form-check label {
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .payment-option img {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: 20px;
    width: 60px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item {
    border: none;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    width: 100%
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button {
    color: #4a5568;
    position: relative;
    padding: 0
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button::after {
    content: none
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 30px;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button::before {
    right: unset;
    left: 30px
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    color: #222;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::before {
    top: 20%;
    -webkit-transform: rotate(-180deg) translateY(-50%);
    transform: rotate(-180deg) translateY(-50%)
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label {
    font-weight: 500;
    color: #222;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: 16px 20px
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label {
    padding-right: 0
}

@media (max-width: 480px) {
    .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label {
        margin-top: 5px
    }
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label .form-check-input {
    margin-right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-header .accordion-button .form-check .form-check-label .form-check-input {
    margin-left: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    margin-right: unset
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body {
    padding-top: 0
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body .cod-review {
    margin: 0;
    line-height: 1.5;
    color: #4a5568
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body .cod-review a:hover {
    color: var(--theme-color)
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check {
    margin-bottom: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check {
    padding-left: unset;
    padding-right: 0
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check input {
    margin-right: 10px
}

[dir="rtl"] .checkout-section-2 .left-sidebar-checkout .checkout-detail-box>ul>li .checkout-box .checkout-detail .custom-accordion .accordion-item .accordion-collapse .accordion-body .custom-form-check input {
    margin-right: unset;
    margin-left: 10px
}

.checkout-section-2 .right-side-summery-box {
    position: sticky;
    top: 110px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 {
    border-radius: 7px;
    background-color: #f8f8f8;
    padding: calc(14px + (29 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-header {
    padding-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-header h3 {
    font-weight: 600
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-header a {
    font-weight: 500;
    margin-left: auto;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-header a:hover {
    color: var(--theme-color)
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-header a {
    margin-left: unset;
    margin-right: auto
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain {
    border-bottom: 1px solid #ececec
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain::-webkit-scrollbar-track {
    background-color: #4a5568
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain::-webkit-scrollbar {
    width: 3px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain::-webkit-scrollbar-thumb {
    background-color: var(--theme-color);
    border-radius: 4px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain .checkout-image {
    width: calc(41px + (52 - 41) * ((100vw - 320px) / (1920 - 320)));
    height: calc(41px + (52 - 41) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 10px
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain .checkout-image {
    margin-right: unset;
    margin-left: 10px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain .coupon-cart .coupon-box {
    position: relative;
    overflow: hidden;
    border-radius: 4px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain .coupon-cart .coupon-box .btn-apply {
    background: var(--theme-color);
    color: #fff;
    padding: 0 calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    border: none
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain .coupon-cart .coupon-box .form-control {
    border: 2px solid var(--theme-color);
    padding: 10px
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul {
    padding-right: 0
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul li {
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul li:last-child {
    border-bottom: none
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul li h4 {
    font-size: 15px;
    color: #4a5568
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul li h4.price {
    color: #4a5568;
    margin-left: auto
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul li h4.price {
    margin-left: unset;
    margin-right: auto
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul li h4.price .shpping-contain {
    display: block;
    font-weight: 400;
    font-size: 13px;
    margin-top: 3px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain ul li h4 span {
    font-weight: 600
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain {
    border-bottom: 1px solid #ececec;
    padding-bottom: 10px
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain {
    padding-bottom: 10px;
    padding: 0
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain::-webkit-scrollbar-track {
    background-color: transparent
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain::-webkit-scrollbar {
    width: 3px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain::-webkit-scrollbar-thumb {
    background-color: var(--theme-color);
    border-radius: 4px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li {
    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320))) 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li:last-child {
    border-bottom: none
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li p {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6;
    margin: 0
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li h4 {
    font-size: 15px;
    color: #4a5568
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li h4.price {
    color: #4a5568;
    margin-left: auto
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li h4.price {
    margin-left: unset;
    margin-right: auto
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li h4.price .shpping-contain {
    display: block;
    font-weight: 400;
    font-size: 13px;
    margin-top: 3px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-contain li h4 span {
    font-weight: 600
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total {
    margin-top: 0px;
    padding-top: 5px
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total {
    padding: 0;
    padding-top: 5px
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li {
    padding-top: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li h4 {
    font-size: 17px;
    color: #222
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li h4.price {
    margin-left: auto
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li h4.price {
    margin-left: unset;
    margin-right: auto
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li h4 span {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li:nth-child(4) {
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) 0
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li:nth-child(4) h4 {
    font-weight: 600;
    color: var(--theme-color)
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li:last-child {
    border-top: 1px solid #ececec;
    padding-top: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li:last-child h4 {
    font-weight: 600;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .right-side-summery-box .checkout-offer {
    margin-top: 24px;
    border-radius: 7px;
    background-color: #f8f8f8;
    padding: calc(14px + (29 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-title .offer-icon {
    width: 20px
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-title .offer-name h6 {
    font-weight: 600;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
    margin-bottom: -2px
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(11px + (16 - 11) * ((100vw - 320px) / (1920 - 320)));
    height: 100%;
    overflow: hidden auto;
    max-height: 104px
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail {
    padding: 0
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(85, 85, 85, 0.14)
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
    border-radius: 50px
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(85, 85, 85, 0.5)
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail li {
    display: block
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail li p {
    color: #4a5568;
    line-height: 1.5;
    position: relative;
    padding-left: 23px;
    font-size: calc(13px + (13 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin: 0
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail li p {
    padding-left: unset;
    padding-right: 23px
}

.checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail li p::before {
    content: "";
    position: absolute;
    top: 7px;
    left: 7px;
    width: 5px;
    height: 5px;
    background-color: #4a5568;
    border-radius: 100%
}

[dir="rtl"] .checkout-section-2 .right-side-summery-box .checkout-offer .offer-detail li p::before {
    left: unset;
    right: 7px
}

.contact-box-section .left-sidebar-box .contact-image {
    text-align: center;
    margin-bottom: calc(25px + (64 - 25) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 1199px) {
    .contact-box-section .left-sidebar-box .contact-image {
        margin-bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        height: 100%
    }
}

@media (max-width: 991px) {
    .contact-box-section .left-sidebar-box .contact-image {
        margin-bottom: calc(25px + (64 - 25) * ((100vw - 320px) / (1920 - 320)))
    }
}

.contact-box-section .left-sidebar-box .contact-image img {
    width: 60%
}

@media (max-width: 1199px) {
    .contact-box-section .left-sidebar-box .contact-image img {
        width: 77%
    }
}

@media (max-width: 991px) {
    .contact-box-section .left-sidebar-box .contact-image img {
        width: 60%;
        margin-bottom: calc(25px + (64 - 25) * ((100vw - 320px) / (1920 - 320)))
    }
}

.contact-box-section .left-sidebar-box .contact-title {
    margin-bottom: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.contact-box-section .left-sidebar-box .contact-title h3 {
    position: relative;
    display: inline-block;
    font-size: calc(23px + (28 - 23) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.contact-box-section .left-sidebar-box .contact-title h3::after {
    content: "";
    position: absolute;
    width: 70%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background: var(--theme-color2)
}

[dir="rtl"] .contact-box-section .left-sidebar-box .contact-title h3::after {
    left: unset;
    right: 0
}

.contact-box-section .left-sidebar-box .contact-detail .contact-detail-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(2px + (5 - 2) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f8f8f8;
    padding: calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (27 - 15) * ((100vw - 320px) / (1920 - 320))) calc(30px + (41 - 30) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    border-radius: 10px;
    margin-left: 22px
}

.contact-box-section .left-sidebar-box .contact-detail .contact-detail-box .contact-icon {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: -21px;
    background-color: var(--theme-color);
    padding: 13px;
    border-radius: 7px;
    display: inherit;
    color: #fff
}

.contact-box-section .left-sidebar-box .contact-detail .contact-detail-box .contact-detail-title,
.contact-box-section .left-sidebar-box .contact-detail .contact-detail-box .contact-detail-contain {
    width: 100%
}

.contact-box-section .left-sidebar-box .contact-detail .contact-detail-box .contact-detail-title h4 {
    font-weight: 600;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-top: -2px
}

.contact-box-section .left-sidebar-box .contact-detail .contact-detail-box .contact-detail-contain p {
    margin: 0;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.5;
    color: #4a5568;
    margin-bottom: -5px
}

.contact-box-section .right-sidebar-box {
    padding: calc(21px + (60 - 21) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    background-color: #f8f8f8
}

.contact-box-section .right-sidebar-box .custom-form label {
    color: #4a5568;
    display: block;
    font-size: 16px;
    margin-bottom: calc(3px + (8 - 3) * ((100vw - 320px) / (1920 - 320)))
}

.contact-box-section .right-sidebar-box .custom-form .custom-input {
    position: relative
}

.contact-box-section .right-sidebar-box .custom-form .custom-input .form-control {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(41px + (52 - 41) * ((100vw - 320px) / (1920 - 320)));
    border: none
}

[dir="rtl"] .contact-box-section .right-sidebar-box .custom-form .custom-input .form-control {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(41px + (52 - 41) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.contact-box-section .right-sidebar-box .custom-form .custom-input .form-control:focus {
    background-color: #fff;
    border-color: transparent
}

.contact-box-section .right-sidebar-box .custom-form .custom-input i {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568
}

[dir="rtl"] .contact-box-section .right-sidebar-box .custom-form .custom-input i {
    left: unset;
    right: 0
}

.contact-box-section .right-sidebar-box .custom-form .custom-textarea {
    position: relative
}

.contact-box-section .right-sidebar-box .custom-form .custom-textarea .form-control {
    padding-left: 52px;
    border: none
}

[dir="rtl"] .contact-box-section .right-sidebar-box .custom-form .custom-textarea .form-control {
    padding-left: unset;
    padding-right: 52px
}

.contact-box-section .right-sidebar-box .custom-form .custom-textarea .form-control:focus {
    background-color: #fff;
    border-color: transparent
}

.contact-box-section .right-sidebar-box .custom-form .custom-textarea i {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 18px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 18px;
    color: #4a5568
}

[dir="rtl"] .contact-box-section .right-sidebar-box .custom-form .custom-textarea i {
    left: unset;
    right: 0
}

.map-section .map-box {
    margin-bottom: -6px;
    display: block
}

.map-section .map-box iframe {
    width: 100%;
    height: 350px
}

.log-in-section {
    overflow-x: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 0
}

.log-in-section::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../../../public/assets/images/inner-page/log-in-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1
}

.log-in-section.otp-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.log-in-section .image-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
}

.log-in-section .log-in-form .form-control {
    border: none
}

.log-in-section .inputs {
    margin: calc(-2px + (-8 - 2) * ((100vw - 320px) / (1920 - 320)))
}

.log-in-section .inputs input {
    margin: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
    border: none
}

.log-in-section .log-in-box {
    background-color: #f8f8f8;
    padding: calc(16px + (50 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)))
}

.log-in-section .log-in-box .logo-name {
    margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-top: -10px
}

.log-in-section .log-in-box .logo-name a {
    font-size: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
    font-weight: bold;
    color: #222;
    font-family: "Public Sans", sans-serif;
    display: block
}

.log-in-section .log-in-box .logo-name a img {
    width: calc(150px + (225 - 150) * ((100vw - 320px) / (1920 - 320)))
}

.log-in-section .log-in-box .log-in-title {
    margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.log-in-section .log-in-box .log-in-title h3 {
    margin-bottom: calc(1px + (6 - 1) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (21 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.log-in-section .log-in-box .log-in-title h4,
.log-in-section .log-in-box .log-in-title h5 {
    color: #4a5568;
    margin-top: 8px
}

.log-in-section .log-in-box .log-in-button {
    margin-top: 20px;
    padding: 1px 0
}

.log-in-section .log-in-box .log-in-button ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .log-in-section .log-in-box .log-in-button ul {
    padding-right: 0
}

.log-in-section .log-in-box .log-in-button ul li {
    display: block;
    width: 100%
}

.log-in-section .log-in-box .log-in-button ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #222;
    border-radius: 5px;
    padding: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320))) calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff
}

.log-in-section .log-in-box .log-in-button ul li a img {
    width: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)))
}

.log-in-section .log-in-box .forgot-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 360px) {
    .log-in-section .log-in-box .forgot-box {
        display: block
    }
}

.log-in-section .log-in-box .remember-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.log-in-section .log-in-box .remember-box .check-box {
    display: block;
    margin-top: -6px
}

.log-in-section .log-in-box .remember-box label {
    font-size: 16px
}

.log-in-section .log-in-box .remember-box label span {
    font-weight: 500;
    color: var(--theme-color)
}

.log-in-section .log-in-box .forgot-password {
    font-size: 16px;
    font-weight: 500;
    display: block
}

.log-in-section .log-in-box .forgot-password:hover {
    color: var(--theme-color)
}

.log-in-section .log-in-box .other-log-in {
    margin-top: 15px;
    text-align: center;
    position: relative
}

.log-in-section .log-in-box .other-log-in::before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    left: 0;
    background-color: #ddd;
    z-index: 0
}

.log-in-section .log-in-box .other-log-in h6 {
    color: #4a5568;
    position: relative;
    background-color: #f9f9f9;
    padding: 0 14px;
    display: inline-block;
    text-transform: uppercase
}

.log-in-section .log-in-box .sign-up-box {
    margin-top: 20px;
    text-align: center
}

.log-in-section .log-in-box .sign-up-box h4 {
    color: #4a5568;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(0px + (3 - 0) * ((100vw - 320px) / (1920 - 320)))
}

.log-in-section .log-in-box .sign-up-box a {
    font-weight: 500;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: -4px;
    display: block
}

.log-in-section .log-in-box .sign-up-box a:hover {
    color: var(--theme-color)
}

.log-in-section .log-in-box .contact-title {
    margin-bottom: 30px
}

.log-in-section .log-in-box .contact-title h2 {
    margin-bottom: 15px
}

.log-in-section .log-in-box .contact-title h5 {
    width: 53%;
    font-size: 18px;
    line-height: 1.3;
    color: #4a5568
}

.faq-contain {
    margin-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)))
}

.faq-contain .faq-top-box {
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 9px;
    padding: 28px 18px
}

.faq-contain .faq-top-box .faq-box-icon {
    width: 65px;
    height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 7px;
    margin: 0 auto 16px
}

.faq-contain .faq-top-box .faq-box-icon img {
    width: calc(36px + (45 - 36) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

.faq-contain .faq-top-box .faq-box-contain h3 {
    font-weight: 700
}

.faq-contain .faq-top-box .faq-box-contain p {
    margin: 17px 0 0;
    line-height: 1.6;
    color: #4a5568;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: -4px
}

.faq-box-contain .faq-contain {
    margin-bottom: 0;
    position: sticky;
    top: 92px
}

@media (max-width: 1199px) {
    .faq-box-contain .faq-contain {
        margin-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)))
    }
}

.faq-box-contain .faq-contain h2 {
    font-weight: 700;
    font-size: calc(28px + (56 - 28) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.4
}

.faq-box-contain .faq-contain p {
    margin: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320))) 0 0;
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.5;
    color: #4a5568
}

@media (max-width: 1199px) {
    .faq-box-contain .faq-contain p {
        width: 70%
    }
}

@media (max-width: 991px) {
    .faq-box-contain .faq-contain p {
        width: 90%
    }
}

@media (max-width: 767px) {
    .faq-box-contain .faq-contain p {
        width: 100%;
        text-align: justify
    }
}

.faq-box-contain .faq-accordion .accordion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.faq-box-contain .faq-accordion .accordion .accordion-item {
    width: 100%;
    border-radius: 7px;
    background-color: #f8f8f8;
    border: none;
    overflow: hidden
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-header .accordion-button {
    background-color: #f8f8f8;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    line-height: 1.5;
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-header .accordion-button i {
    margin-left: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .faq-box-contain .faq-accordion .accordion .accordion-item .accordion-header .accordion-button i {
    margin-left: unset;
    margin-right: auto
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-header .accordion-button::after {
    content: unset
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    font-weight: 700;
    color: var(--theme-color);
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-header .accordion-button:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 0 calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-collapse .accordion-body p {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6;
    color: #4a5568;
    margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.faq-box-contain .faq-accordion .accordion .accordion-item .accordion-collapse .accordion-body p:last-child {
    margin-bottom: -4px
}

.compare-section .compare-table {
    border: 1px solid #ddd;
    margin-bottom: 0
}

.compare-section .compare-table tr td,
.compare-section .compare-table tr th {
    min-width: 200px;
    border-right: 1px solid #ddd;
    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320))) calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    text-align: center
}

.compare-section .compare-table tr td:first-child,
.compare-section .compare-table tr th:first-child {
    min-width: auto
}

.compare-section .compare-table tr th {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-color);
    background-color: #f8f8f8
}

.compare-section .compare-table tr td {
    font-size: 15px
}

.compare-section .compare-table tr td.title a {
    font-weight: 700;
    color: #222
}

.compare-section .compare-table tr td a {
    font-weight: 600
}

.compare-section .compare-table tr td a:hover {
    color: #222
}

.compare-section .compare-table tr td .compare-image {
    width: calc(105px + (130 - 105) * ((100vw - 320px) / (1920 - 320)));
    height: calc(105px + (130 - 105) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    background-color: #f1f1f1;
    margin: 0 auto
}

.compare-section .compare-table tr td .compare-image img {
    width: 100%;
    height: calc(65px + (90 - 65) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain
}

.compare-section .compare-table tr td .price h5 {
    font-weight: 600;
    color: #222
}

.compare-section .compare-table tr td .price h5 del {
    font-weight: 400;
    font-size: 15px;
    margin-left: 4px;
    color: #777
}

.compare-section .compare-table tr td .price h5 span {
    margin-left: 4px;
    color: var(--theme-color)
}

.compare-section .compare-table tr td .compare-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 5px
}

.compare-section .compare-table tr td .compare-rating span {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .compare-section .compare-table tr td .compare-rating span {
    margin-left: unset;
    padding-right: 5px
}

.compare-section .compare-table tr td .summary {
    color: #777;
    line-height: 1.5;
    margin-bottom: 0;
    font-size: 15px
}

.blog-section .left-sidebar-box {
    position: sticky;
    top: 20px
}

.blog-section .left-sidebar-box .left-search-box .search-box {
    position: relative
}

.blog-section .left-sidebar-box .left-search-box .search-box::before {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 20px;
    color: #4a5568
}

.blog-section .left-sidebar-box .left-search-box .search-box::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 60%;
    right: 51px;
    opacity: .3;
    background-color: #4a5568
}

.blog-section .left-sidebar-box .left-search-box .search-box input {
    padding: 8px 68px 8px 20px;
    background-color: #f8f8f8;
    border: none;
    height: 53px;
    border-radius: 7px
}

.blog-section .left-sidebar-box .left-search-box .search-box input:focus {
    border-color: #ced4da
}

.blog-section .left-sidebar-box .left-accordion-box {
    margin-top: 20px
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item {
    border: none;
    padding: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item+.accordion-item {
    margin-top: 20px
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-header .accordion-button {
    background-color: #f8f8f8;
    color: #222;
    font-weight: 700;
    padding: 0
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-header .accordion-button::after {
    width: unset;
    height: unset;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    background-image: none;
    content: "\f107"
}

[dir="rtl"] .blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-header .accordion-button::after {
    margin-left: unset;
    margin-right: auto
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-header .accordion-button:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    box-shadow: none
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse {
    background-color: #f8f8f8
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body {
    padding: 0;
    margin-top: 20px
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box+.recent-box {
    margin-top: 20px
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box:hover .recent-image img {
    -webkit-transform: scale(1.1) rotate(4deg);
    transform: scale(1.1) rotate(4deg)
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-image {
    width: 110px;
    border-radius: 5px;
    overflow: hidden
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-image img {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-detail {
    padding-left: 15px;
    width: calc(100% - 110px)
}

[dir="rtl"] .blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-detail {
    padding-left: unset;
    padding-right: 15px
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-detail a {
    color: #222
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-detail a:hover {
    color: #222
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-detail a h5 {
    font-weight: 600;
    line-height: 1.45;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-detail h6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 8px;
    color: #4a5568
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .recent-post-box .recent-box .recent-detail h6 .feather {
    width: 18px;
    height: 18px;
    stroke-width: 1
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20px
}

[dir="rtl"] .blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul {
    padding-left: unset;
    padding-right: 0
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li {
    display: block;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    overflow: hidden;
    width: 100%
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li:first-child {
    padding-top: 0
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li a {
    display: block;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #222
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li a .category-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li a .category-name h5 {
    color: #4a5568;
    font-weight: 600
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li a .category-name h5,
.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li a .category-name span {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .category-list-box ul li a .category-name span {
    width: 22px;
    height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;
    color: #fff;
    background-color: var(--theme-color);
    padding: 4px 5px 3px;
    border-radius: 100%
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px
}

[dir="rtl"] .blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul {
    padding-left: unset;
    padding-right: 0
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul li {
    z-index: 0;
    padding: 4px 9px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    background-color: #fff
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: var(--theme-color);
    opacity: 0
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul li:hover {
    border-color: transparent
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul li:hover::after {
    opacity: 1
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul li:hover a {
    color: #fff
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-tags-box ul li a {
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    text-transform: capitalize
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-list-2 li+li {
    margin-top: 30px
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-list-2 li+li::after {
    top: -16px;
    width: calc(100% - 80px - calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320))))
}

.blog-section .left-sidebar-box .left-accordion-box .accordion-item .accordion-collapse .accordion-body .product-list-2 li .offer-product .offer-detail a {
    color: #222
}

.blog-section .custom-border {
    border-bottom: 1px solid #ececec;
    padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(35px + (60 - 35) * ((100vw - 320px) / (1920 - 320)))
}

.blog-section .blog-box:hover .blog-image a {
    -webkit-transform: scale(1.1) rotate(4deg);
    transform: scale(1.1) rotate(4deg)
}

.blog-section .blog-box .blog-image {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 0
}

.blog-section .blog-box .blog-image a {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .blog-box .blog-image label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: unset;
    left: 0;
    border-radius: 0;
    background-color: #febc5c;
    color: #fff;
    padding: 10px 13px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600
}

.blog-section .blog-box .blog-image label i {
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 3px;
    color: #fff
}

.blog-section .blog-box .blog-contain {
    padding: 0 20px 20px
}

.blog-section .blog-box .blog-contain-2 {
    padding: 0
}

.blog-section .blog-box .blog-contain .blog-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.blog-section .blog-box .blog-contain .blog-label .time,
.blog-section .blog-box .blog-contain .blog-label .super {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568;
    text-transform: uppercase;
    font-size: 13px
}

.blog-section .blog-box .blog-contain .blog-label .time .feather,
.blog-section .blog-box .blog-contain .blog-label .super .feather {
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 4px
}

[dir="rtl"] .blog-section .blog-box .blog-contain .blog-label .time .feather,
[dir="rtl"] .blog-section .blog-box .blog-contain .blog-label .super .feather {
    margin-right: unset;
    margin-left: 4px
}

.blog-section .blog-box .blog-contain .blog-label .time span,
.blog-section .blog-box .blog-contain .blog-label .super span {
    margin-top: 1px
}

.blog-section .blog-box .blog-contain a {
    color: #222
}

.blog-section .blog-box .blog-contain a:hover {
    color: #222
}

.blog-section .blog-box .blog-contain a h3 {
    margin: 10px 0 6px;
    font-weight: 600;
    line-height: 1.5;
    text-transform: capitalize;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.blog-section .blog-box .blog-contain p {
    line-height: 1.8;
    color: #4a5568;
    margin-bottom: 0
}

@media (max-width: 1366px) {
    .blog-section .blog-box .blog-contain p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden
    }
}

.blog-section .blog-box .blog-contain .blog-button {
    border: none;
    background-color: rgba(var(--theme-color-rgb), 0.1);
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 0;
    overflow: hidden;
    border-radius: 5px;
    padding: 10px 22px;
    color: var(--theme-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin-top: calc(8px + (22 - 8) * ((100vw - 320px) / (1920 - 320)));
    font-size: 14px
}

.blog-section .blog-box .blog-contain .blog-button:hover {
    background-color: rgba(var(--theme-color-rgb), 1);
    color: #fff
}

.blog-section .blog-box .blog-contain .blog-button i {
    margin-left: 10px;
    margin-top: 1px
}

[dir="rtl"] .blog-section .blog-box .blog-contain .blog-button i {
    margin-left: unset;
    margin-right: 10px
}

.blog-section .blog-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 1199px) {
    .blog-section .blog-list {
        display: block
    }
}

@media (max-width: 991px) {
    .blog-section .blog-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (max-width: 767px) {
    .blog-section .blog-list {
        display: block
    }
}

.blog-section .blog-list:hover {
    -webkit-box-shadow: 0 4px 9px rgba(34, 34, 34, 0.1);
    box-shadow: 0 4px 9px rgba(34, 34, 34, 0.1)
}

.blog-section .blog-list:hover .blog-image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.blog-section .blog-list:hover .blog-contain a h3 {
    color: var(--theme-color)
}

.blog-section .blog-list:hover .blog-contain .blog-button {
    background-color: var(--theme-color);
    color: #fff
}

.blog-section .blog-list .blog-image {
    margin-bottom: -1px;
    width: 38%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 1399px) {
    .blog-section .blog-list .blog-image {
        width: 45%
    }
}

@media (max-width: 1199px) {
    .blog-section .blog-list .blog-image {
        width: 100%
    }
}

@media (max-width: 991px) {
    .blog-section .blog-list .blog-image {
        width: 45%
    }
}

@media (max-width: 767px) {
    .blog-section .blog-list .blog-image {
        width: 100%;
        height: auto;
        margin-bottom: 8px;
        text-align: center
    }
}

.blog-section .blog-list .blog-image img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

@media (max-width: 1199px) {
    .blog-section .blog-list .blog-image img {
        width: 100%
    }
}

@media (max-width: 991px) {
    .blog-section .blog-list .blog-image img {
        width: auto
    }
}

@media (max-width: 767px) {
    .blog-section .blog-list .blog-image img {
        width: 100%
    }
}

.blog-section .blog-list .blog-contain {
    padding: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    width: 62%
}

@media (max-width: 1399px) {
    .blog-section .blog-list .blog-contain {
        width: 55%
    }
}

@media (max-width: 1199px) {
    .blog-section .blog-list .blog-contain {
        width: 100%
    }
}

@media (max-width: 991px) {
    .blog-section .blog-list .blog-contain {
        width: 55%
    }
}

@media (max-width: 767px) {
    .blog-section .blog-list .blog-contain {
        width: 100%
    }
}

.blog-section .blog-detail-image {
    position: relative
}

.blog-section .blog-detail-image .blog-image-contain {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 55px 15px 40px;
    text-align: center;
    background: #fff;
    background: -webkit-gradient(linear, left bottom, left top, from(#fff), to(rgba(246, 246, 246, 0)));
    background: linear-gradient(0deg, #fff 0%, rgba(246, 246, 246, 0) 100%);
    color: #222
}

@media (max-width: 480px) {
    .blog-section .blog-detail-image .blog-image-contain {
        position: relative
    }
}

.blog-section .blog-detail-image .blog-image-contain .contain-list li {
    text-transform: capitalize;
    position: relative
}

.blog-section .blog-detail-image .blog-image-contain .contain-list li+li {
    margin-left: 30px
}

[dir="rtl"] .blog-section .blog-detail-image .blog-image-contain .contain-list li+li {
    margin-left: unset;
    margin-right: 30px
}

.blog-section .blog-detail-image .blog-image-contain .contain-list li+li::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 1px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #222;
    left: -24px
}

[dir="rtl"] .blog-section .blog-detail-image .blog-image-contain .contain-list li+li::before {
    right: -24px;
    left: unset
}

.blog-section .blog-detail-image .blog-image-contain h2 {
    margin: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
    font-weight: 700;
    font-size: calc(22px + (34 - 22) * ((100vw - 320px) / (1920 - 320)))
}

.blog-section .blog-detail-image .blog-image-contain .contain-comment-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(10px + (23 - 10) * ((100vw - 320px) / (1920 - 320)));
    color: #777
}

.blog-section .blog-detail-image .blog-image-contain .contain-comment-list li .user-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.blog-section .blog-detail-image .blog-image-contain .contain-comment-list li .user-list .feather {
    width: 18px;
    height: 18px;
    margin-right: 3px
}

[dir="rtl"] .blog-section .blog-detail-image .blog-image-contain .contain-comment-list li .user-list .feather {
    margin-right: unset;
    margin-left: 3px
}

.blog-section .blog-detail-contain p {
    color: #4a5568;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.blog-section .blog-detail-contain p .first {
    width: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320)));
    height: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(55px + (70 - 55) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    float: left;
    margin-right: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 8px
}

.blog-section .blog-detail-contain .blog-details-quote {
    padding: calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320))) calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320))) calc(18px + (60 - 18) * ((100vw - 320px) / (1920 - 320))) calc(62px + (180 - 62) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: #4a5568;
    position: relative;
    color: #fff
}

.blog-section .blog-detail-contain .blog-details-quote h3 {
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(24px + (35 - 24) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)))
}

.blog-section .blog-detail-contain .blog-details-quote h5 {
    letter-spacing: 3px;
    color: #ddd;
    text-transform: uppercase
}

.blog-section .blog-detail-contain .blog-details-quote::after {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    font-size: calc(38px + (70 - 38) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color);
    top: calc(-6px + (26 - -6) * ((100vw - 320px) / (1920 - 320)));
    left: calc(15px + (87 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.blog-section .leave-title {
    margin: 40px 0 9px
}

.blog-section .leave-title h3 {
    position: relative;
    font-weight: 700;
    z-index: 0;
    color: #4a5568;
    font-size: 22px
}

.blog-section .user-comment-box {
    margin-top: 24px
}

.blog-section .user-comment-box ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

[dir="rtl"] .blog-section .user-comment-box ul {
    padding-left: unset;
    padding-right: 0
}

.blog-section .user-comment-box ul li {
    padding: 0px 0 50px;
    width: 100%;
    position: relative
}

.blog-section .user-comment-box ul li.li-padding {
    padding-left: calc(28px + (80 - 28) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 480px) {
    .blog-section .user-comment-box ul li {
        padding: 0
    }
}

.blog-section .user-comment-box ul li .user-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (max-width: 480px) {
    .blog-section .user-comment-box ul li .user-box {
        display: block
    }
}

.blog-section .user-comment-box ul li .user-box .reply-button {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .blog-section .user-comment-box ul li .user-box .reply-button {
    right: unset;
    left: 0
}

.blog-section .user-comment-box ul li .user-box .reply-button i {
    margin: -3px 9px 0 0;
    color: #777
}

[dir="rtl"] .blog-section .user-comment-box ul li .user-box .reply-button i {
    margin: -3px 0 0 9px
}

.blog-section .user-comment-box ul li .user-box .user-iamge {
    position: relative
}

.blog-section .user-comment-box ul li .user-box .user-iamge img {
    width: 60px;
    height: 60px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 100%;
    overflow: hidden
}

.blog-section .user-comment-box ul li .user-box .user-iamge .user-name {
    position: absolute;
    top: 0;
    left: 80px;
    width: 220px
}

[dir="rtl"] .blog-section .user-comment-box ul li .user-box .user-iamge .user-name {
    right: 80px;
    left: unset
}

@media (max-width: 480px) {
    .blog-section .user-comment-box ul li .user-box .user-iamge .user-name {
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

.blog-section .user-comment-box ul li .user-box .user-iamge .user-name h5 {
    font-weight: 600
}

.blog-section .user-comment-box ul li .user-box .user-iamge .user-name h6 {
    color: #4a5568;
    margin-bottom: 6px
}

.blog-section .user-comment-box ul li .user-box .user-contain {
    width: calc(100% - 70px);
    padding-left: 20px
}

[dir="rtl"] .blog-section .user-comment-box ul li .user-box .user-contain {
    padding-left: unset;
    padding-right: 20px
}

@media (max-width: 480px) {
    .blog-section .user-comment-box ul li .user-box .user-contain {
        width: 100%;
        padding: 0;
        margin-top: 14px
    }
}

.blog-section .user-comment-box ul li .user-box .user-contain p {
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6;
    color: #4a5568;
    margin-bottom: 0;
    margin-top: 50px;
    width: 85%
}

@media (max-width: 575px) {
    .blog-section .user-comment-box ul li .user-box .user-contain p {
        width: 100%
    }
}

@media (max-width: 480px) {
    .blog-section .user-comment-box ul li .user-box .user-contain p {
        margin: 0 0 35px
    }
}

.blog-section .leave-box {
    padding: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
    border-radius: 6px
}

.blog-section .leave-box .leave-comment .comment-notes p {
    line-height: 1.6
}

.blog-section .leave-box .leave-comment .save-comment-box {
    margin: 20px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.blog-section .leave-box .leave-comment .save-comment-box .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.blog-section .leave-box .leave-comment .save-comment-box .form-check input {
    margin-top: -5px
}

@media (max-width: 659px) {
    .blog-section .leave-box .leave-comment .save-comment-box .form-check input {
        margin-top: -21px
    }
}

@media (max-width: 341px) {
    .blog-section .leave-box .leave-comment .save-comment-box .form-check input {
        margin-top: -44px
    }
}

.blog-section .leave-box .leave-comment .save-comment-box .form-check .form-check-label {
    font-size: 15px;
    font-weight: 400;
    color: #4a5568
}

.blog-section .leave-box .leave-comment .blog-input .form-control {
    border: none
}

.blog-section .leave-box .leave-comment .blog-input .form-control:focus {
    background-color: #fff
}

.review-title h4 {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(7px + (13 - 7) * ((100vw - 320px) / (1920 - 320)));
    color: #222;
    font-weight: 400
}

.review-title h2 {
    font-size: calc(26px + (40 - 26) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
    width: 80%;
    line-height: 1.3;
    position: relative
}

@media (max-width: 480px) {
    .review-title h2 {
        width: 100%
    }
}

.review-title h2.center::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    color: var(--theme-color)
}

.about-us-title h4 {
    font-size: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(7px + (13 - 7) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color);
    font-family: "Pacifico", cursive
}

.about-us-title h2 {
    font-size: calc(23px + (32 - 23) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(28px + (41 - 28) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.3;
    position: relative
}

.about-us-title h2::before {
    content: "";
    position: absolute;
    width: calc(106px + (175 - 106) * ((100vw - 320px) / (1920 - 320)));
    height: 3px;
    bottom: calc(-7px + (6 - 7) * ((100vw - 320px) / (1920 - 320)));
    left: 0;
    background: #ffa53b
}

.about-us-title h2.center::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.fresh-vegetable-section .fresh-image {
    margin-top: 30px;
    border-radius: calc(12px + (50 - 12) * ((100vw - 320px) / (1920 - 320))) 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.fresh-vegetable-section .fresh-image>div {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden
}

.fresh-vegetable-section .fresh-image:hover>div {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
}

.fresh-vegetable-section .fresh-image-2 {
    border-radius: 0 calc(12px + (50 - 12) * ((100vw - 320px) / (1920 - 320)));
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.fresh-vegetable-section .fresh-image-2>div {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden
}

.fresh-vegetable-section .fresh-image-2:hover>div {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
}

.fresh-vegetable-section .fresh-contain {
    height: 100%
}

.fresh-vegetable-section .fresh-contain p {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.7;
    color: #4a5568;
    margin-bottom: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)))
}

.fresh-vegetable-section .delivery-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .fresh-vegetable-section .delivery-list ul {
    padding-right: 0
}

.fresh-vegetable-section .delivery-list ul li {
    width: 100%
}

.fresh-vegetable-section .delivery-list ul li .deliver-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    color: #4a5568
}

.fresh-vegetable-section .delivery-list ul li .deliver-box .feather {
    width: 17px;
    height: auto;
    margin-top: 3px
}

.fresh-vegetable-section .delivery-list ul li .deliver-box h6 {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    width: calc(100% - 17px)
}

.fresh-vegetable-section .delivery-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.fresh-vegetable-section .delivery-box li .delivery-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0
}

.fresh-vegetable-section .delivery-box li .delivery-box .delivery-icon img {
    width: 30px;
    height: auto
}

.client-section {
    background-color: #f8f8f8
}

.client-section .clint-contain {
    background: #fff;
    padding: calc(27px + (50 - 27) * ((100vw - 320px) / (1920 - 320)));
    border-radius: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    position: relative
}

.client-section .clint-contain:hover .client-icon img {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.client-section .clint-contain:hover h2 {
    opacity: .09;
    margin-right: 9px
}

.client-section .clint-contain:hover h4 {
    color: var(--theme-color)
}

.client-section .clint-contain .client-icon {
    width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
    height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 14px;
    margin-top: -11px
}

.client-section .clint-contain .client-icon img {
    width: 74%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.client-section .clint-contain h2 {
    position: absolute;
    top: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
    right: calc(24px + (34 - 24) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    opacity: .05;
    font-size: calc(52px + (70 - 52) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 800;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .client-section .clint-contain h2 {
    right: unset;
    left: calc(24px + (34 - 24) * ((100vw - 320px) / (1920 - 320)))
}

.client-section .clint-contain h4 {
    font-size: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 7px;
    font-weight: 600;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.client-section .clint-contain p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    margin: 0 auto;
    line-height: 1.7;
    margin-bottom: -5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.client-section .client-box .client-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.client-section .client-box .client-list li {
    width: 460px;
    position: relative
}

.team-section .team-box:hover .team-iamge img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-filter: grayscale(0);
    filter: grayscale(0)
}

.team-section .team-box:hover .team-iamge .team-media {
    opacity: 1;
    right: 10px
}

.team-section .team-box .team-iamge {
    width: 50%;
    height: auto;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto
}

.team-section .team-box .team-iamge img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

.team-section .team-box .team-name {
    text-align: center;
    margin-top: 20px
}

.team-section .team-box .team-name h3 {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.7px;
    font-weight: 700
}

.team-section .team-box .team-name h5 {
    margin-top: 6px;
    letter-spacing: 0.5px;
    color: #4a5568
}

.team-section .team-box .team-name p {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6;
    color: #4a5568;
    margin: 9px auto 14px;
    width: 80%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

@media (max-width: 360px) {
    .team-section .team-box .team-name p {
        width: 100%
    }
}

.team-section .team-box .team-name .team-media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 14px;
    position: relative;
    margin-top: 15px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.team-section .team-box .team-name .team-media li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    background-color: #ededed
}

.team-section .team-box .team-name .team-media li a.fb-bg {
    color: #4267B2;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.team-section .team-box .team-name .team-media li a.fb-bg:hover {
    background-color: #4267B2;
    color: #fff
}

.team-section .team-box .team-name .team-media li a.twitter-bg {
    color: #00acee;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.team-section .team-box .team-name .team-media li a.twitter-bg:hover {
    background-color: #00acee;
    color: #fff
}

.team-section .team-box .team-name .team-media li a.pint-bg {
    color: #c8232c;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.team-section .team-box .team-name .team-media li a.pint-bg:hover {
    background-color: #c8232c;
    color: #fff
}

.team-section .team-box .team-name .team-media li a.insta-bg {
    color: #F56040;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.team-section .team-box .team-name .team-media li a.insta-bg:hover {
    background-color: #F56040;
    color: #fff
}

.review-section {
    background-color: #f8f8f8
}

.review-section .reviewer-box {
    background-color: #fff;
    padding: calc(18px + (34 - 18) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 10px;
    z-index: 0;
    position: relative;
    overflow: hidden
}

.review-section .reviewer-box:hover i {
    color: var(--theme-color);
    font-size: 143px;
    opacity: .18;
    -webkit-transform: rotate(7deg);
    transform: rotate(7deg);
    bottom: -29px;
    right: -7px
}

.review-section .reviewer-box i {
    position: absolute;
    font-size: 106px;
    opacity: 0.05;
    z-index: -1;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    bottom: -30px;
    right: -8px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .review-section .reviewer-box i {
    -webkit-transform: rotate(-15deg) rotateY(183deg);
    transform: rotate(-15deg) rotateY(183deg);
    left: -8px;
    right: unset
}

.review-section .reviewer-box h3 {
    font-weight: 400;
    margin: 10px 0 13px;
    font-size: 20px;
    line-height: 1.5
}

.review-section .reviewer-box p {
    color: #4a5568;
    line-height: 1.7;
    margin-bottom: 23px;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.review-section .reviewer-box .reviewer-profile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(12px + (19 - 12) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.review-section .reviewer-box .reviewer-profile .reviewer-image {
    width: calc(65px + (75 - 65) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    border-radius: 8px;
    overflow: hidden
}

.review-section .reviewer-box .reviewer-profile .reviewer-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.review-section .reviewer-box .reviewer-profile .reviewer-name h4 {
    font-weight: 700;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color)
}

.review-section .reviewer-box .reviewer-profile .reviewer-name h6 {
    color: #4a5568;
    margin-top: 6px
}

.chart-padding {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px
}

.chart-title {
    margin-bottom: 20px
}

.chart-title h3 {
    font-size: 24px;
    font-weight: 600
}

.dashboard-title {
    margin-bottom: 22px
}

.dashboard-title h3 {
    font-size: 20px;
    line-height: 1.3;
    position: relative;
    font-weight: 600
}

.dashboard-title.dashboard-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.dashboard-title.dashboard-flex button {
    margin-left: auto
}

.user-dashboard-section .dashboard-left-sidebar {
    background: #f8f8f8;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.14);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.14);
    border-radius: 10px;
    overflow: hidden;
    position: sticky;
    top: 107px;
    z-index: 0
}

@media (max-width: 991px) {
    .user-dashboard-section .dashboard-left-sidebar {
        width: 300px;
        height: 100vh;
        position: fixed;
        top: 0;
        left: -320px;
        border-radius: 0;
        z-index: 6;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out
    }

    .user-dashboard-section .dashboard-left-sidebar.show {
        left: 0
    }
}

.user-dashboard-section .dashboard-left-sidebar .close-button {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background: #f8f8f8;
    border-radius: 100%
}

.user-dashboard-section .dashboard-left-sidebar .close-button .close-sidebar {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: #4a5568;
    padding: 2px 0 0
}

.user-dashboard-section .dashboard-left-sidebar .profile-box {
    position: relative
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .cover-image {
    position: relative;
    overflow: hidden
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .cover-image img {
    width: 100%;
    height: 150px;
    -o-object-fit: cover;
    object-fit: cover
}

@media (max-width: 991px) {
    .user-dashboard-section .dashboard-left-sidebar .profile-box .cover-image img {
        height: 120px
    }
}


.user-dashboard-section .dashboard-left-sidebar .profile-box .cover-image .cover-icon {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    background-color: #fff;
    border-radius: 100%;
    color: #4a5568
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain {
    padding: 0 15px
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-image {
    margin: 0 auto;
    text-align: center;
    margin-top: -50px;
    z-index: 1;
    position: relative
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-image img {
    width: calc(93px + (108 - 93) * ((100vw - 320px) / (1920 - 320)));
    height: calc(93px + (108 - 93) * ((100vw - 320px) / (1920 - 320)));
    -o-object-fit: contain;
    object-fit: contain;
    background-color: #f8f8f8;
    border-radius: 100%;
    padding: 5px;
    border: 1px solid #ececec;
    -webkit-box-shadow: 2px 3px 8px rgba(34, 34, 34, 0.32);
    box-shadow: 2px 3px 8px rgba(34, 34, 34, 0.32)
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-image .cover-icon {
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 55%;
    z-index: 1;
    background-color: #fff;
    border-radius: 100%;
    color: #4a5568
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-image .cover-icon i {
    position: relative
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-image .cover-icon i input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    width: 35px
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-name {
    margin-top: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    padding-bottom: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid #ddd
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-name h3 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    letter-spacing: 0.7px
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-name h6 {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 4px
}

.user-dashboard-section .dashboard-left-sidebar .profile-box .profile-contain .profile-name h6 span {
    margin: 0 8px
}

.user-dashboard-section .dashboard-left-sidebar .user-nav-pills {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(2px + (7 - 2) * ((100vw - 320px) / (1920 - 320)));
    margin: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) 0 6px
}

.user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item {
    width: 100%;
    display: block
}

.user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item .nav-link {
    font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    color: #4a5568;
    width: 100%;
    text-align: left;
    padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    z-index: 0;
    overflow: hidden;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item .nav-link.active,
.user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item .nav-link .show>.nav-link {
    border-left: 3px solid var(--theme-color);
    font-weight: 600;
    background-color: transparent;
    color: var(--theme-color)
}

.user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item .nav-link.active::before,
.user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item .nav-link .show>.nav-link::before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: var(--theme-color2);
    z-index: -1;
    opacity: 0.1
}

.user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item .nav-link .feather {
    width: calc(18px + (19 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin-right: 10px
}

[dir="rtl"] .user-dashboard-section .dashboard-left-sidebar .user-nav-pills .nav-item .nav-link .feather {
    margin-right: unset;
    margin-left: 10px
}

.user-dashboard-section .dashboard-right-sidebar {
    background-color: #fff;
    padding: 0;
    height: 100%
}

@media (max-width: 991px) {
    .user-dashboard-section .dashboard-right-sidebar {
        height: auto
    }
}



.user-dashboard-section .dashboard-right-sidebar .dashboard-bg-box+.dashboard-bg-box {
    margin-top: 24px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-user-name h6 {
    font-size: 16px;
    margin-bottom: 10px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-user-name p {
    margin: 0;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.5;
    letter-spacing: 0.3px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box {
    margin: 30px 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box .totle-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 15px;
    background-color: #fff;
    padding: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 9px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.08);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.08);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box .totle-contain:hover .img-1 {
    width: calc(84px + (96 - 84) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transform: translateY(-50%) rotate(-7deg);
    transform: translateY(-50%) rotate(-7deg);
    opacity: 0.2;
    right: -19px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box .totle-contain img {
    width: calc(54px + (60 - 54) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box .totle-contain .img-1 {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: calc(78px + (90 - 78) * ((100vw - 320px) / (1920 - 320)));
    position: absolute;
    opacity: 0.12;
    right: -30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: grayscale(1);
    filter: grayscale(1)
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box .totle-contain .img-1 {
    right: unset;
    left: -30px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box .totle-contain .totle-detail h5 {
    margin-bottom: calc(3px + (7 - 3) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .total-box .totle-contain .totle-detail h3 {
    font-weight: 600
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-contant-title {
    border-bottom: 1px solid #ddd;
    padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-contant-title h4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-contant-title h4 a {
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-contant-title h4 a:hover {
    color: var(--theme-color)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-detail h6 {
    margin-bottom: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-home .dashboard-detail a:hover {
    color: var(--theme-color)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container .order-icon {
    position: relative;
    z-index: 0;
    color: var(--theme-color);
    padding: 9px;
    border-radius: 100%;
    overflow: hidden
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container .order-icon::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--theme-color);
    opacity: 0.1;
    z-index: -1
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container .order-detail h4 {
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.6px;
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container .order-detail h4 span {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background: -webkit-gradient(linear, right top, left top, from(#ff6b6b), to(#ff4f4f));
    background: linear-gradient(-90deg, #ff6b6b 0%, #ff4f4f 100%);
    padding: 4px 6px;
    border-radius: 4px;
    margin-left: calc(9px + (20 - 9) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container .order-detail h4 span {
    margin-left: unset;
    margin-right: calc(9px + (20 - 9) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container .order-detail h4 span.success-bg {
    background: var(--theme-color2)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .order-container .order-detail h6 {
    margin-top: 6px;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: .6px;
    font-weight: 300;
    line-height: 1.5
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 20px;
    background-color: #f8f8f8;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 30px;
    border-radius: 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 1199px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail {
        display: block
    }
}

@media (max-width: 991px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (max-width: 767px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail {
        display: block
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail:hover .order-image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-image {
    display: block
}

@media (max-width: 1199px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-image {
        text-align: center;
        margin-bottom: calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)))
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-image img {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap a {
    display: block;
    color: #222
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap a:hover {
    color: #222
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap a h3 {
    font-weight: 600;
    margin-bottom: 6px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap p {
    line-height: 1.5;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap .product-size {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap .product-size {
    padding-right: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap .product-size li {
    width: 100%
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap .product-size li .size-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box .product-order-detail .order-wrap .product-size li .size-box h5 {
    font-weight: 600;
    margin-left: 8px;
    font-size: 14px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box {
    border-radius: 8px;
    padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.07);
    position: relative;
    height: 100%;
    overflow: hidden
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--theme-color);
    padding: 2px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.8px
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .label {
    right: unset;
    left: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .form-check {
    margin-top: 3px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .form-check .form-check-input {
    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 2px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .form-check .form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .form-check .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .form-check .form-check-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table {
    margin-bottom: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table tr:first-child td {
    padding-top: 0;
    font-weight: 600;
    font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
    color: #222
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table tr td {
    border: none;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: 4px 0;
    color: #4a5568
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table tr td:first-child {
    min-width: 90px;
    padding-left: 8px
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table tr td:first-child {
    padding-left: unset;
    padding-right: 8px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table tr td:last-child p {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
    line-height: 1.5
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table tr td h4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    color: #222
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box>div .address-table table tr td h4 span {
    background-color: var(--theme-color);
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.8px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box .button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box .button-group button {
    background-color: #f8f8f8;
    font-weight: 600
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box .button-group button:hover {
    background-color: var(--theme-color);
    color: #fff
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box .button-group button .feather {
    width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin-right: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-address .address-box .button-group button .feather {
    margin-right: unset;
    margin-left: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-address .add-address .address-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    color: #222
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail {
    position: relative
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail:hover .edit-card {
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail.add-card {
    background-color: #f9f9f9;
    cursor: pointer;
    padding: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail.add-card .card-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 130px;
    background: #d2d2d2;
    color: #222;
    text-align: center
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail.add-card .card-details h5 {
    margin-bottom: 0;
    text-transform: capitalize
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details {
    height: 100%;
    color: #fff;
    padding: 30px 20px;
    border-radius: 5px;
    background-color: #4b77a0;
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0) 1px, rgba(255, 255, 255, 0.03) 2px, rgba(255, 255, 255, 0.04) 3px, rgba(255, 255, 255, 0.05) 4px), -webkit-linear-gradient(-245deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.2) 70%, rgba(255, 255, 255, 0) 90%)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details.card-visa {
    background-color: #777876
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details.dabit-card {
    background-color: #86b8cf
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .card-number {
    margin-bottom: 10px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .card-number h4 {
    color: #fff;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail .title {
    margin-bottom: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail .title span {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.3
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail .date h3 {
    margin-bottom: 0;
    color: #fff;
    margin-left: 15px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: normal
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail .date h3 {
    margin-left: 0;
    margin-right: 15px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail .primary {
    margin-left: auto
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail .primary {
    margin-left: 0;
    margin-right: auto
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .valid-detail .primary span {
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding: 0;
    font-weight: 400;
    text-transform: capitalize
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .name-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .name-detail .name h5 {
    text-transform: uppercase;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
    color: #fff
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .card-details .name-detail .card-img {
    width: auto
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .edit-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    background-color: rgba(34, 34, 34, 0.8);
    border-radius: 5px;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

@media (max-width: 767px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .edit-card {
        display: none
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .payment-card-detail .edit-card a {
    padding: 0 10px;
    text-transform: capitalize;
    color: #fff
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-card .edit-card-mobile {
    display: none
}

@media (max-width: 767px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-card .edit-card-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 10px
    }

    .user-dashboard-section .dashboard-right-sidebar .dashboard-card .edit-card-mobile a {
        padding: 0 10px;
        text-transform: capitalize;
        color: #222
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 575px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail {
        display: block
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail h3 {
    font-weight: 400;
    font-size: 16px;
    margin-right: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail h3 {
    margin-right: unset;
    margin-left: 10px
}

@media (max-width: 360px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail h3 {
        margin-right: 0
    }

    [dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail h3 {
        margin-right: unset;
        margin-left: 0
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail h3 span {
    background: var(--theme-color2);
    color: #fff;
    padding: 3px 8px;
    margin-left: 10px;
    border-radius: 5px;
    font-size: 13px
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail h3 span {
    margin-left: unset;
    margin-right: 10px
}

@media (max-width: 575px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-name-detail .profile-rating {
        margin-top: 3px
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail a {
    margin-left: auto;
    padding: 8px 14px;
    position: relative;
    z-index: 0;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail a {
    margin-left: unset;
    margin-right: auto
}

@media (max-width: 575px) {
    .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail a {
        margin-top: 10px
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--theme-color2);
    opacity: .08;
    z-index: -1
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail a:hover {
    color: var(--theme-color)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .location-profile {
    margin-top: 20px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .location-profile ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(10px + (26 - 10) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .location-profile ul {
    padding-right: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .location-profile ul li .location-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 7px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .location-profile ul li .location-box .feather {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .location-profile ul li .location-box h6 {
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-detail .profile-description p {
    margin: 20px 0 0;
    color: #4a5568;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about {
    margin-top: 20px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table {
    margin-bottom: 36px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table tbody tr td {
    vertical-align: middle;
    border: none;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    white-space: nowrap
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table tbody tr td:first-child {
    color: #4a5568
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table tbody tr td a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table tbody tr td a:hover {
    color: var(--theme-color)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table tbody tr td a span {
    margin-left: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    position: relative;
    font-weight: 600;
    overflow: hidden;
    z-index: 0
}

[dir="rtl"] .user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table tbody tr td a span {
    margin-left: unset;
    margin-right: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)))
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-profile .profile-about .table tbody tr td a span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--theme-color2);
    opacity: 0.08;
    z-index: -1
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box+.privacy-box {
    margin-top: 15px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box h6 {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 6px;
    line-height: 1.5
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box p {
    margin: 0;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .switch {
    position: absolute;
    -webkit-clip-path: circle(0%);
    clip-path: circle(0%);
    opacity: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .switch+label {
    width: 62px;
    height: 25px;
    position: relative;
    border-radius: 50px;
    cursor: pointer;
    background-color: #0e997e26;
    border: 1px solid #ddd
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .switch+label:before,
.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .switch+label:after {
    display: inline-block;
    position: absolute
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .switch+label:before {
    width: 19px;
    height: 19px;
    content: "";
    border-radius: 100%;
    left: 2px;
    top: 2px;
    background: var(--theme-color2);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .switch:checked+label:before {
    left: 39px
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .form-check-input {
    position: absolute;
    -webkit-clip-path: circle(0%);
    clip-path: circle(0%);
    opacity: 0
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .form-check-input+label {
    width: 62px;
    height: 25px;
    position: relative;
    border-radius: 50px;
    cursor: pointer;
    background-color: rgba(var(--theme-color-rgb), 0.149);
    border: 1px solid rgba(var(--theme-color-rgb), 0.149)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .form-check-input+label:before,
.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .form-check-input+label:after {
    display: inline-block;
    position: absolute
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .form-check-input+label:before {
    width: 19px;
    height: 19px;
    content: "";
    border-radius: 100%;
    left: 2px;
    top: 2px;
    background-color: var(--theme-color);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .form-check-input:checked+label {
    background-color: var(--theme-color)
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-privacy .privacy-box .switch-radio .form-check-input:checked+label:before {
    left: 39px;
    background: #fff
}

.order-detail .order-image {
    background-color: #f8f8f8;
    padding: 40px;
    border-radius: 8px;
    text-align: center
}

.order-detail .order-image img {
    width: 360px;
    margin: 0 auto
}

.order-detail .order-details-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8;
    padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 8px;
    height: 100%
}

.order-detail .order-details-contain .order-tracking-icon .feather {
    width: 25px;
    height: auto;
    stroke-width: 1.4px
}

.order-detail .order-details-contain .order-details-name h2 {
    font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)))
}

.order-detail .order-details-contain .order-details-name h4 {
    line-height: 1.4;
    font-weight: 500;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.order-detail .order-details-contain .order-details-name h5 {
    margin-bottom: 7px
}

.order-detail .order-details-contain .order-details-name img {
    margin-top: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
    width: calc(160px + (200 - 160) * ((100vw - 320px) / (1920 - 320)))
}

.progtrckr {
    margin: 15px 0 0;
    padding: 15px 0 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

@media (max-width: 575px) {
    .progtrckr {
        display: inline;
        margin: 30px 0;
        overflow: hidden
    }
}

.progtrckr li {
    display: inline-block;
    text-align: center;
    margin: 10px 0;
    position: relative
}

@media (max-width: 575px) {
    .progtrckr li {
        margin: 0;
        width: 50% !important
    }
}

.progtrckr li h5 {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 500
}

@media (max-width: 575px) {
    .progtrckr li h5 {
        display: block;
        text-align: left;
        margin-top: 0
    }

    [dir="rtl"] .progtrckr li h5 {
        text-align: right
    }
}

.progtrckr li:before {
    position: relative;
    top: -2px;
    float: left;
    left: 50% !important;
    line-height: 1;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important
}

@media (max-width: 575px) {
    .progtrckr li:before {
        position: relative;
        top: 10px;
        float: left;
        left: -2px !important;
        line-height: 1;
        -webkit-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important
    }

    [dir="rtl"] .progtrckr li:before {
        float: right;
        right: -32px !important;
        left: unset !important
    }
}

.progtrckr li.progtrckr-todo {
    color: #4a5568;
    border-top: 4px solid #959595;
    width: 100% !important
}

@media (max-width: 575px) {
    .progtrckr li.progtrckr-todo {
        position: relative;
        text-align: left;
        margin-left: 30px;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
        padding-left: 30px;
        border-top: unset;
        border-left: 4px solid #959595
    }

    [dir="rtl"] .progtrckr li.progtrckr-todo {
        border-left: none;
        border-right: 4px solid #4a5568;
        margin-left: 0;
        margin-right: 30px;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        padding-left: 0;
        padding-right: 30px;
        text-align: right
    }
}

.progtrckr li.progtrckr-todo:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding-top: 2px;
    content: "";
    color: #fff;
    background: #959595;
    line-height: 35px;
    border: none;
    border-radius: 35px;
    font-size: 16px
}

@media (max-width: 575px) {
    .progtrckr li.progtrckr-todo:before {
        position: absolute;
        top: 10px;
        left: -17px;
        line-height: 1;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    [dir="rtl"] .progtrckr li.progtrckr-todo:before {
        left: unset;
        right: -17px
    }
}

.progtrckr li.progtrckr-todo h6 {
    font-size: 13px;
    margin-top: 8px
}

.progtrckr li.progtrckr-done {
    color: #222;
    border-top: 4px solid var(--theme-color);
    width: 100% !important
}

@media (max-width: 575px) {
    .progtrckr li.progtrckr-done:first-of-type {
        padding-top: 5px
    }
}

@media (max-width: 575px) {
    .progtrckr li.progtrckr-done {
        position: relative;
        text-align: left;
        margin-left: 30px;
        -webkit-box-align: self-start;
        -ms-flex-align: self-start;
        align-items: self-start;
        padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
        border-top: unset;
        border-left: 4px solid var(--theme-color)
    }

    [dir="rtl"] .progtrckr li.progtrckr-done {
        margin-left: 0;
        margin-right: 30px;
        text-align: right;
        border-left: none;
        border-right: 4px solid var(--theme-color);
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }
}

.progtrckr li.progtrckr-done:before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding-top: 2px;
    content: "\f00c";
    color: #fff;
    background-color: var(--theme-color);
    line-height: 35px;
    border: none;
    border-radius: 35px;
    font-size: 16px
}

.progtrckr li.progtrckr-done h6 {
    font-size: 13px;
    margin-top: 8px;
    color: #4a5568
}

.order-tab-table {
    margin-bottom: 0
}

.order-tab-table thead tr th {
    text-align: center;
    background-color: #e7e7e7;
    color: #222;
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400
}

.order-tab-table tbody {
    border-top: none !important
}

.order-tab-table tbody tr td {
    font-weight: 300;
    text-align: center;
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    min-width: 160px;
    color: #777
}

.order-table-section .order-navpills {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));
    overflow: auto hidden
}

.order-table-section .order-navpills .nav-item .nav-link {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(11px + (14 - 11) * ((100vw - 320px) / (1920 - 320))) calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    font-weight: 600;
    white-space: nowrap
}

.order-table-section .order-navpills .nav-item .nav-link.active {
    background: var(--theme-color2);
    color: #fff
}

.order-table-section .order-tab {
    margin-top: 22px
}

.order-table-section .order-tab .order-tab-table {
    margin-bottom: 0
}

.order-table-section .order-tab .order-tab-table thead tr th {
    text-align: center;
    background-color: #e7e7e7;
    color: #222;
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400
}

.order-table-section .order-tab .order-tab-table tbody {
    border-top: none
}

.order-table-section .order-tab .order-tab-table tbody tr td {
    font-weight: 300;
    text-align: center;
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    min-width: 160px;
    color: #777
}

.order-table table tbody tr td {
    vertical-align: middle
}

.order-success-2 tbody {
    border-top: none
}

.search-section .search-box {
    position: relative
}

.search-section .search-box .input-group .form-control:focus {
    z-index: 0
}

.search-section .search-box .input-group button {
    z-index: 0
}

.vendor-bottom {
    padding-bottom: 18px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 16px
}

.vendor-detail-box {
    padding: calc(20px + (28 - 20) * ((100vw - 992px) / (1920 - 992)));
    margin-bottom: 29px;
    border-radius: 9px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.13);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.13);
    background: #fff
}

@media (max-width: 991px) {
    .vendor-detail-box {
        padding: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin-right: 0
    }
}

.vendor-detail-box .vendor-name .vendor-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-detail-box .vendor-name .vendor-logo img {
    width: calc(80px + (89 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

.vendor-detail-box .vendor-name .vendor-logo h3 {
    font-weight: 600;
    text-transform: uppercase
}

.vendor-detail-box .vendor-name .vendor-logo .vendor-rating {
    margin-top: 4px
}

.vendor-detail-box .vendor-name .vendor-logo .vendor-rating .rating li .feather {
    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

.vendor-detail-box .vendor-name .vendor-logo .vendor-rating span {
    font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-detail-box .vendor-name p {
    margin-bottom: -6px;
    line-height: 1.5;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568;
    margin-top: 15px
}

.vendor-detail-box .vendor-tag h4 {
    text-transform: none;
    font-weight: 500;
    margin-bottom: 17px
}

.vendor-detail-box .vendor-tag ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .vendor-detail-box .vendor-tag ul {
    padding: 0
}

.vendor-detail-box .vendor-tag ul li {
    background-color: #ececec;
    letter-spacing: 0.5px;
    padding: 4px 11px;
    border-radius: 3px;
    color: #222;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-detail-box .vendor-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.vendor-detail-box .vendor-share h5 {
    font-weight: 600
}

.vendor-detail-box .vendor-share ul {
    margin-left: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 12px
}

[dir="rtl"] .vendor-detail-box .vendor-share ul {
    padding: 0;
    margin-left: unset;
    margin-right: 15px
}

.vendor-detail-box .vendor-share ul li a {
    color: #4a5568;
    display: block;
    font-size: 16px;
    line-height: 1
}

.vendor-detail-box-2 {
    padding: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 50px;
    border-radius: 9px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.13);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.13);
    background: var(--theme-color);
    color: #fff
}

.vendor-detail-box-2 .vendor-logo {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.vendor-detail-box-2 .vendor-logo img {
    width: 85%;
    height: auto;
    -webkit-filter: invert(1) brightness(100);
    filter: invert(1) brightness(100)
}

@media (max-width: 767px) {
    .vendor-detail-box-2 .vendor-logo img {
        width: 26%
    }
}

@media (max-width: 480px) {
    .vendor-detail-box-2 .vendor-logo img {
        width: 35%
    }
}

@media (max-width: 360px) {
    .vendor-detail-box-2 .vendor-logo img {
        width: 50%
    }
}

.vendor-detail-box-2 .vendor-name {
    height: 100%
}

.vendor-detail-box-2 .vendor-name .vendor-list-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-detail-box-2 .vendor-name h3 {
    font-weight: 600;
    text-transform: uppercase;
    display: block
}

.vendor-detail-box-2 .vendor-name .vendor-rating .rating li .feather {
    width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    height: auto
}

.vendor-detail-box-2 .vendor-name .vendor-rating span {
    font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (1920 - 320)));
    color: #f1f1f1
}

.vendor-detail-box-2 .vendor-name p {
    margin-bottom: -6px;
    line-height: 1.5;
    font-size: 14px;
    color: #f1f1f1;
    margin-top: 11px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.vendor-detail-box-2 .vendor-tag h4 {
    text-transform: none;
    font-weight: 500;
    margin-bottom: 17px
}

.vendor-detail-box-2 .vendor-tag ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(7px + (12 - 7) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-detail-box-2 .vendor-tag ul li {
    background-color: #ececec;
    letter-spacing: 0.5px;
    padding: 4px 11px;
    border-radius: 3px;
    color: #222;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-detail-box-2 .share-contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    text-align: center
}

@media (max-width: 767px) {
    .vendor-detail-box-2 .share-contact {
        display: block
    }
}

@media (max-width: 767px) {
    .vendor-detail-box-2 .share-contact>div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 16px
    }
}

.vendor-detail-box-2 .share-contact .vendor-share h5 {
    display: block;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.vendor-detail-box-2 .share-contact .vendor-share ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 8px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px
}

[dir="rtl"] .vendor-detail-box-2 .share-contact .vendor-share ul {
    padding: 0
}

.vendor-detail-box-2 .share-contact .vendor-share ul li {
    position: relative
}

.vendor-detail-box-2 .share-contact .vendor-share ul li a {
    color: #f1f1f1;
    font-size: 16px;
    width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.vendor-detail-box-2 .share-contact .vendor-contect {
    text-align: center;
    margin-top: 17px
}

@media (max-width: 767px) {
    .vendor-detail-box-2 .share-contact .vendor-contect {
        margin-top: 0
    }
}

.vendor-detail-box-2 .share-contact .vendor-contect h5 {
    text-align: center;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.vendor-detail-box-2 .share-contact .vendor-contect button {
    margin: 8px auto 0
}

.saller-poster-section .poster-box .poster-image {
    margin-bottom: 52px
}

.saller-poster-section .saller-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 15px
}

.become-service .service-box {
    text-align: center;
    background: #f8f8f8;
    padding: 45px;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.05);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.05);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.become-service .service-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px)
}

.become-service .service-box:hover .service-svg svg {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    fill: var(--theme-color)
}

.become-service .service-box:hover .service-detail h4 {
    color: var(--theme-color)
}

.become-service .service-box:hover .service-detail h4::after {
    width: 68%
}

.become-service .service-box .service-svg {
    width: 90px;
    height: 90px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto 18px;
    border-radius: 5px
}

.become-service .service-box .service-svg svg {
    width: 60px;
    height: auto;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.become-service .service-box .service-detail h4 {
    font-weight: 600;
    display: inline-block;
    font-size: 19px;
    margin-bottom: 7px;
    text-transform: capitalize;
    position: relative;
    z-index: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.become-service .service-box .service-detail h4::after {
    content: "";
    position: absolute;
    bottom: 2px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
    height: 4px;
    background-color: var(--theme-color);
    opacity: 0.3;
    z-index: -1;
    border-radius: 50px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.become-service .service-box .service-detail p {
    margin: 0;
    line-height: 1.5;
    color: #4a5568;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.business-section {
    background-color: #f8f8f8
}

.business-section .business-contain {
    position: relative;
    z-index: 0
}

.business-section .business-contain::after {
    content: "";
    position: absolute;
    background-image: url("../../../public/assets/images/vendor-page/arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    z-index: -1
}

.business-section .business-contain>.row {
    margin-left: -50px;
    margin-right: -50px
}

.business-section .business-contain>.row>div {
    padding-left: 50px;
    padding-right: 50px
}

.business-section .business-contain>.row>div:last-child,
.business-section .business-contain>.row>div:first-child {
    margin-bottom: 40px
}

.business-section .business-contain>.row>div:nth-child(2) {
    margin-top: 40px
}

.business-section .business-contain .business-box {
    text-align: center;
    background-color: #fff;
    border-radius: 9px;
    padding: 35px;
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.05);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.05);
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.business-section .business-contain .business-box .business-number {
    width: 65px;
    height: 65px;
    background: #f8f8f8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto 28px;
    border-radius: 5px
}

.business-section .business-contain .business-box .business-number h2 {
    color: var(--theme-color);
    font-size: 35px;
    font-weight: 500
}

.business-section .business-contain .business-box .business-detail h4 {
    margin-bottom: 10px;
    font-weight: 600
}

.business-section .business-contain .business-box .business-detail p {
    margin: 0;
    color: #4a5568;
    line-height: 1.6
}

.seller-grid-box {
    background-color: #f8f8f8;
    padding: calc(12px + (25 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 14px;
    position: relative;
    -webkit-box-shadow: 0 0 10px rgba(34, 34, 34, 0.05);
    box-shadow: 0 0 10px rgba(34, 34, 34, 0.05);
    display: block
}

.seller-grid-box .grid-contain .seller-contact-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 7px;
    margin-bottom: 25px
}

.seller-grid-box .grid-contain .seller-contact-details .saller-contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 5px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%
}

.seller-grid-box .grid-contain .seller-contact-details .saller-contact .seller-icon i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    font-size: 16px;
    color: var(--theme-color)
}

.seller-grid-box .grid-contain .seller-contact-details .saller-contact .contact-detail h5 {
    line-height: 1.6;
    color: #222
}

.seller-grid-box .grid-contain .seller-contact-details .saller-contact .contact-detail h5 span {
    color: #4a5568
}

.seller-grid-box .grid-contain .contain-name {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.seller-grid-box .grid-contain .contain-name h6 {
    color: #4a5568;
    margin-bottom: 1px
}

.seller-grid-box .grid-contain .contain-name h3 {
    font-size: calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    margin-bottom: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
    color: #222
}

.seller-grid-box .grid-contain .contain-name .product-rating {
    margin-bottom: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.seller-grid-box .grid-contain .contain-name .product-label {
    padding: 5px 10px;
    background-color: rgba(var(--theme-color-rgb), 0.102);
    font-size: 13px;
    letter-spacing: .5px;
    border-radius: 4px;
    color: var(--theme-color)
}

.seller-grid-box .grid-contain .contain-name .grid-image {
    width: 100px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fff;
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-radius: calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-shadow: 0 0 8px rgba(34, 34, 34, 0.07);
    box-shadow: 0 0 8px rgba(34, 34, 34, 0.07)
}

.seller-grid-box-1 .grid-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 18px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px
}

.seller-grid-box-1 .grid-image .image {
    width: 66px;
    height: 66px;
    border-radius: 11px
}

.seller-grid-box-1 .grid-image .contain-name {
    width: calc(100% - 66px - 18px)
}

.seller-grid-box-1 .grid-image .contain-name .since-number {
    margin-bottom: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 480px) {
    .seller-grid-box-1 .grid-image .contain-name .since-number {
        display: block
    }
}

.seller-grid-box-1 .grid-image .contain-name .since-number h6 {
    color: #4a5568
}

.seller-grid-box-1 .grid-image .contain-name .since-number .product-rating {
    margin-left: auto
}

[dir="rtl"] .seller-grid-box-1 .grid-image .contain-name .since-number .product-rating {
    margin-left: unset;
    margin-right: auto
}

@media (max-width: 480px) {
    .seller-grid-box-1 .grid-image .contain-name .since-number .product-rating {
        margin-top: 2px
    }
}

.seller-grid-box-1 .grid-image .contain-name h3 {
    font-size: calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    margin-bottom: 10px
}

.seller-grid-box-1 .grid-image .contain-name .product-label {
    padding: 5px 10px;
    background-color: rgba(var(--theme-color-rgb), 0.1);
    font-size: 13px;
    letter-spacing: .5px;
    border-radius: 4px;
    color: var(--theme-color)
}

.seller-grid-box-1 .grid-contain .seller-category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.seller-grid-box-1 .grid-contain .seller-category .product-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.seller-grid-box-1 .grid-contain .seller-category .product-image li {
    width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fff;
    border-radius: 100%;
    padding: 4px;
    -webkit-box-shadow: 0 0 2px rgba(34, 34, 34, 0.2);
    box-shadow: 0 0 2px rgba(34, 34, 34, 0.2);
    color: #4a5568;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.seller-grid-box-1 .grid-contain .seller-category .product-image li:hover {
    -webkit-transform: scale(1.1) translateY(-7px);
    transform: scale(1.1) translateY(-7px)
}

.seller-grid-box-1 .grid-contain .seller-category .product-image li:last-child {
    padding-top: 5px;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.seller-grid-box-1 .grid-contain .seller-category .product-image li+li {
    margin-left: calc(-19px + (-14 - -19) * ((100vw - 320px) / (1920 - 320)))
}

.product-table {
    margin-bottom: 0
}

.product-table thead tr th {
    text-align: center;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    white-space: nowrap;
    min-width: 90px
}

.product-table tbody {
    border-top: 1px solid #ececec !important
}

.product-table tbody tr:last-child td {
    border-bottom: none;
    padding-bottom: 0
}

.product-table tbody tr td {
    text-align: center;
    padding: 12px 15px;
    vertical-align: middle;
    white-space: nowrap;
    max-width: 190px
}

.product-table tbody tr td:nth-child(2) {
    max-width: 150px
}

.product-table tbody tr td:nth-child(2) h6 {
    color: #4a5568;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.product-table tbody tr td.product-image {
    width: 90px
}

.product-table tbody tr td.efit-delete .feather {
    width: 19px;
    stroke-width: 1.6px
}

.product-table tbody tr td.efit-delete .feather.edit {
    color: var(--theme-color)
}

.product-table tbody tr td.efit-delete .feather.delete {
    color: #ff7272;
    margin-left: 10px
}

[dir="rtl"] .product-table tbody tr td.efit-delete .feather.delete {
    margin-left: unset;
    margin-right: 10px
}

.order-table {
    margin-bottom: 0
}

.order-table-2 tbody {
    border-top: none !important
}

.order-table-2 tbody tr td {
    text-align: left
}

.order-table thead tr th {
    text-align: center;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    white-space: nowrap;
    min-width: 90px
}

.order-table tbody {
    border-top: 1px solid #ececec
}

.order-table tbody tr:last-child td {
    border-bottom: none;
    padding-bottom: 0
}

.order-table tbody tr td {
    text-align: center;
    padding: 12px 15px;
    vertical-align: middle;
    white-space: nowrap
}

.order-table tbody tr td:nth-child(2) {
    color: #4a5568
}

.order-table tbody tr td label {
    padding: 3px 12px;
    font-size: 12px;
    border-radius: 50px
}

.order-table tbody tr td label.success {
    background-color: rgba(var(--theme-color-rgb), 0.1);
    color: var(--theme-color)
}

.order-table tbody tr td label.danger {
    background-color: rgba(255, 114, 114, 0.1);
    color: #ff7272
}

.order-table tbody tr td:first-child {
    font-weight: 600
}

.profile-tab ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(15px + (21 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.profile-tab ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%
}

@media (max-width: 575px) {
    .profile-tab ul li {
        display: block
    }
}

.profile-tab ul li h5 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.profile-tab ul li h5:first-child {
    width: 220px
}

.profile-tab ul li h5:last-child {
    color: #4a5568
}

@media (max-width: 575px) {
    .profile-tab ul li h5:last-child {
        margin-top: 8px
    }
}

.deactivate-box {
    padding: 15px 22px;
    background: rgba(255, 165, 59, 0.1);
    border: 1px dashed #ffa53b;
    border-radius: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 17px;
    margin-bottom: 21px
}

.deactivate-box .deactivate-icon {
    width: 30px;
    height: 30px;
    background: rgba(254, 163, 72, 0.3);
    color: #fea348;
    border-radius: 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px
}

.deactivate-box .deactivate-contain h3 {
    font-weight: 600;
    margin-bottom: 5px
}

.deactivate-box .deactivate-contain h5 {
    color: #4a5568;
    margin-bottom: 10px
}

.deactivate-box .deactivate-contain a {
    font-size: 16px;
    font-weight: 600
}

.dashboard-privacy .privacy-box .custom-form-check-2 label {
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.apexcharts-tooltip * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.apexcharts-toolbar {
    z-index: 0
}

.custom-padding {
    padding-right: 10px
}

.custom-height {
    max-height: 190px;
    overflow-y: auto;
    height: 100%
}

.custom-height::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(85, 85, 85, 0.14)
}

.custom-height::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
    border-radius: 50px
}

.custom-height::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(85, 85, 85, 0.5)
}

.shop-banner-contain {
    position: relative;
    border-radius: 8px
}

.shop-banner-contain .shop-banner-details {
    padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))) calc(25px + (80 - 25) * ((100vw - 320px) / (1920 - 320)));
    width: 50%
}

@media (max-width: 1460px) {
    .shop-banner-contain .shop-banner-details {
        width: 60%
    }
}

@media (max-width: 991px) {
    .shop-banner-contain .shop-banner-details {
        width: 70%
    }
}

@media (max-width: 767px) {
    .shop-banner-contain .shop-banner-details {
        width: 80%;
        background: #fff;
        background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.4)), to(rgba(246, 246, 246, 0)));
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 0%, rgba(246, 246, 246, 0) 100%)
    }
}

@media (max-width: 480px) {
    .shop-banner-contain .shop-banner-details {
        width: 100%
    }
}

.shop-banner-contain .shop-banner-details h5 {
    font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1.5px
}

.shop-banner-contain .shop-banner-details h2 {
    font-size: calc(24px + (58 - 24) * ((100vw - 320px) / (1920 - 320)));
    margin: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) 0;
    line-height: 1.2;
    font-weight: 700
}

.banner-description {
    margin-top: 28px
}

.banner-description p {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.6
}

.category-title h3 {
    display: inline-block;
    padding-bottom: 4px;
    margin-bottom: 18px;
    font-weight: 600;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color)
}

.category-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-y: scroll;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20px;
    padding-right: 15px;
    max-height: 415px

}
.category-list::-webkit-scrollbar-track
{
	background-color: #fff;
}

.category-list::-webkit-scrollbar
{
	width: 4px;
	background-color: #F5F5F5;
}

.category-list::-webkit-scrollbar-thumb
{
	background-color: #d2d2d2;
    border-radius:4px;
}

[dir="rtl"] .category-list {
    padding-right: 0
}

.category-list li {
    display: block;
    width: 100%
}

.category-list li .category-list-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.category-list li .category-list-box input {
    margin-top: -4px
}

.category-list li .category-list-box .form-check-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin-left: 8px
}

.category-list li .category-list-box .form-check-label .name {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    color: #222;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    width: 80%
}

.category-list li .category-list-box .form-check-label .number {
    color: #777;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)))
}

.irs {
    position: relative;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 55px
}

.irs .irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
    height: 10px;
    top: 33px;
    background-color: #f9f8f6;
    border-radius: 50px
}

.irs .irs-line-left {
    width: 11%;
    height: 8px;
    position: absolute;
    display: block;
    top: 0;
    left: 0
}

.irs .irs-line-mid {
    width: 82%;
    height: 8px;
    position: absolute;
    display: block;
    top: 0;
    left: 9%
}

.irs .irs-line-right {
    width: 11%;
    height: 8px;
    position: absolute;
    display: block;
    top: 0;
    right: 0
}

.irs .irs-bar {
    width: 0;
    height: 10px;
    position: absolute;
    display: block;
    left: 0;
    top: 33px;
    background-color: var(--theme-color)
}

.irs .irs-bar-edge {
    width: 14px;
    height: 10px;
    position: absolute;
    display: block;
    left: 0;
    top: 33px;
    border: 1px solid #428bca;
    border-right: 0;
    background: #428bca;
    background: -webkit-gradient(linear, left bottom, left top, from(#428bca), to(#7fc3e8));
    background: linear-gradient(to top, #428bca 0%, #7fc3e8 100%);
    border-radius: 16px 0 0 16px
}

.irs .irs-shadow {
    width: 0;
    height: 2px;
    position: absolute;
    display: none;
    left: 0;
    top: 38px;
    background: #222;
    opacity: 0.3;
    border-radius: 5px
}

.irs .irs-slider {
    width: 20px;
    height: 20px;
    position: absolute;
    display: block;
    z-index: 1;
    top: 28px;
    border: 3px solid #fff;
    background-color: var(--theme-color);
    border-radius: 100%;
    cursor: pointer
}

.irs .irs-slider .irs-slider.type_last {
    z-index: 2
}

.irs .irs-slider .irs-min {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
    color: #333;
    font-size: 12px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 5px;
    background: rgba(34, 34, 34, 0.1);
    border-radius: 3px
}

.irs .irs-max {
    position: absolute;
    display: block;
    right: 0;
    cursor: default;
    color: #777;
    font-size: 12px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 3px 7px;
    background: rgba(34, 34, 34, 0.1);
    border-radius: 3px;
    font-weight: 500
}

.irs .irs-min {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    cursor: default;
    color: #777;
    font-size: 12px;
    line-height: 1.333;
    text-shadow: none;
    padding: 3px 7px;
    background: rgba(34, 34, 34, 0.1);
    border-radius: 3px;
    font-weight: 500
}

.irs .irs-from {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
    color: #fff;
    font-size: 13px;
    line-height: 1.333;
    text-shadow: none;
    padding: 3px 7px;
    background-color: var(--theme-color);
    border-radius: 3px;
    font-weight: 600
}

.irs .irs-to {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
    color: #fff;
    font-size: 13px;
    line-height: 1.333;
    text-shadow: none;
    padding: 3px 7px;
    background-color: var(--theme-color);
    border-radius: 3px;
    font-weight: 600
}

.irs .irs-single {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
    color: #fff;
    font-size: 14px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: var(--theme-color);
    border-radius: 3px
}

.irs .irs-grid {
    width: 100%;
    height: 20px;
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    height: 27px
}

.irs .irs-with-grid {
    height: 75px
}

.irs .irs-with-grid .irs-grid {
    display: block
}

.irs .irs-grid-pol {
    width: 1px;
    height: 8px;
    position: absolute;
    top: 0;
    left: 0;
    background: #222;
    opacity: 0.5;
    background: #428bca
}

.irs .irs-grid-pol.small {
    height: 4px;
    background: #999
}

.irs .irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: #222;
    bottom: 5px;
    color: #99a4ac
}

.irs .irs-disable-mask {
    width: 102%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    cursor: default;
    z-index: 2
}

.irs .irs-disabled {
    opacity: 0.4
}

.irs .irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important
}

.irs .lt-ie9 .irs-disable-mask {
    background: #222;
    filter: alpha(opacity=0);
    cursor: not-allowed
}

.irs .lt-ie9 .irs-shadow {
    filter: alpha(opacity=30)
}

.irs .lt-ie9 .irs-min {
    background: #ccc
}

.irs .lt-ie9 .irs-max {
    background: #ccc
}

.irs .lt-ie9 .irs-from {
    background: #999
}

.irs .lt-ie9 .irs-to {
    background: #999
}

.irs .lt-ie9 .irs-single {
    background: #999
}

.js-range-slider {
    margin-top: 15px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    color: #222;
    width: 100%
}

.shop-section .left-box {
    position: sticky;
    top: 0;
}

[dir="rtl"] .shop-section .left-box {
    border-right: unset;
    border-left: 1px solid #ddd
}

@media (max-width: 991px) {
    .shop-section .left-box {
        width: 300px;
        height: 100%;
        border-right: unset;
        position: fixed;
        top: 0;
        left: -350px;
        background-color: #fff;
        z-index: 1041;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        overflow-x: scroll;
        overflow-y: auto;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out
    }

    [dir="rtl"] .shop-section .left-box {
        border-right: unset;
        border-left: unset;
        left: unset;
        right: -350px
    }
}

.shop-section .left-box.right-box {
    border-right: unset;
    border-left: 1px solid #ddd
}

[dir="rtl"] .shop-section .left-box.right-box {
    border-left: unset;
    border-right: 1px solid #ddd
}

@media (max-width: 991px) {
    .shop-section .left-box.right-box {
        border-left: unset;
        left: unset;
        right: -350px
    }

    [dir="rtl"] .shop-section .left-box.right-box {
        left: -350px;
        right: unset
    }
}

.shop-section .left-box.right-box.show {
    left: unset;
    right: 0
}

[dir="rtl"] .shop-section .left-box.right-box.show {
    right: unset;
    left: 0
}

.shop-section .left-box.show {
    left: 0
}

[dir="rtl"] .shop-section .left-box.show {
    right: 0;
    left: unset
}

.shop-section .left-box .shop-left-sidebar {
    padding-right: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)))
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar {
    padding-right: unset;
    padding-left: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 991px) {
    .shop-section .left-box .shop-left-sidebar {
        padding: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)))
    }

    [dir="rtl"] .shop-section .left-box .shop-left-sidebar {
        padding: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)))
    }
}

.shop-section .left-box .shop-left-sidebar.shop-right-sidebar {
    padding-left: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
    padding-right: unset
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar.shop-right-sidebar {
    padding-left: unset;
    padding-right: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 991px) {
    .shop-section .left-box .shop-left-sidebar.shop-right-sidebar {
        padding: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)))
    }

    [dir="rtl"] .shop-section .left-box .shop-left-sidebar.shop-right-sidebar {
        padding: calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)))
    }
}

.shop-section .left-box .shop-left-sidebar.shop-right-sidebar .back-button h3 i {
    margin-right: auto;
    margin-left: 0
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar.shop-right-sidebar .back-button h3 i {
    margin-right: 0;
    margin-left: auto
}

.shop-section .left-box .shop-left-sidebar .back-button {
    display: none
}

@media (max-width: 991px) {
    .shop-section .left-box .shop-left-sidebar .back-button {
        display: block;
        margin-bottom: 26px
    }
}

.shop-section .left-box .shop-left-sidebar .back-button h3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 600
}

.shop-section .left-box .shop-left-sidebar .back-button h3 i {
    margin-right: 10px
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar .back-button h3 i {
    margin-left: 10px;
    margin-right: unset
}

.shop-section .left-box .shop-left-sidebar .search-box {
    position: relative;
    margin-bottom: 30px
}

.shop-section .left-box .shop-left-sidebar .search-box::after {
    content: "";
    position: absolute;
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    border-left: 1px solid #ddd;
    font-size: 15px;
    padding: 0 18px;
    height: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #4a5568
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar .search-box::after {
    right: unset;
    left: 0;
    border-left: unset;
    border-right: 1px solid #ddd
}

.shop-section .left-box .shop-left-sidebar .filter-category {
    margin-bottom: 25px
}

.shop-section .left-box .shop-left-sidebar .filter-category .filter-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0 0 8px 0
}

.shop-section .left-box .shop-left-sidebar .filter-category .filter-title h2 {
    font-size: 18px;
    padding: 0 0 8px 0;
    font-weight: 600;
    border-bottom: 1px solid var(--theme-color)
}

.shop-section .left-box .shop-left-sidebar .filter-category .filter-title a {
    display: block
}

.shop-section .left-box .shop-left-sidebar .filter-category .filter-title a:hover {
    color: var(--theme-color)
}

.shop-section .left-box .shop-left-sidebar .filter-category ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 16px
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar .filter-category ul {
    padding-right: 0
}

.shop-section .left-box .shop-left-sidebar .filter-category ul li {
    padding: 9px 34px 9px 11px;
    background-color: #f9f8f6;
    position: relative;
    border-radius: 4px
}

.shop-section .left-box .shop-left-sidebar .filter-category ul li:before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    right: 13px;
    color: #777;
    cursor: pointer
}

.shop-section .left-box .shop-left-sidebar .filter-category ul li a {
    color: #777
}

.shop-section .left-box .shop-left-sidebar .custome-accordion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 25px
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item {
    border: none;
    width: 100%
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
    padding: 0 0 8px 0;
    font-weight: 600;
    margin-bottom: 9px
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button.collapsed::before {
    content: "\f107";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button:focus {
    border-color: unset;
    -webkit-box-shadow: none;
    box-shadow: none
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button::after {
    content: none
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button::before {
    right: unset;
    left: 5px
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    color: #222;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button span {
    position: relative
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-header .accordion-button span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -8px;
    left: 0;
    background-color: var(--theme-color)
}

.shop-section .left-box .shop-left-sidebar .custome-accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 16px 0 0
}

.shop-section .left-box .shop-left-sidebar .left-title {
    margin-bottom: 20px;
    margin-top: 32px
}

.shop-section .left-box .shop-left-sidebar .left-title h3 {
    font-weight: 700
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab {
    gap: calc(11px + (20 - 11) * ((100vw - 320px) / (1920 - 320)))
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item {
    width: 100%;
    height: 60px;
    background-color: #f7f6f3;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    color: #222;
    border-radius: unset;
    position: relative;
    text-align: left
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link {
    text-align: right
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link:hover(not) img {
    width: 75px;
    height: 75px;
    -webkit-transform: translateY(-50%) rotate(-4deg);
    transform: translateY(-50%) rotate(-4deg);
    right: -12px;
    opacity: 0.45
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link:hover img {
    width: 75px;
    height: 75px;
    -webkit-transform: translateY(-50%) rotate(-4deg);
    transform: translateY(-50%) rotate(-4deg);
    right: -12px;
    opacity: 0.29
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link.active {
    color: #fff;
    background: linear-gradient(90.56deg, var(--theme-color1) 8.46%, var(--theme-color) 62.97%);
    font-size: 19px;
    font-weight: 600
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link.active img {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 90px;
    height: 90px;
    position: absolute;
    right: -10px;
    -webkit-filter: invert(1);
    filter: invert(1);
    opacity: 0.4
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link.active img {
    right: unset;
    left: -10px
}

.shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link img {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 70px;
    height: 70px;
    position: absolute;
    right: -10px;
    opacity: 0.1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

[dir="rtl"] .shop-section .left-box .shop-left-sidebar .custom-nav-tab .nav-item .nav-link img {
    right: unset;
    left: -10px
}

.shop-section .show-button {
    margin-bottom: 14px
}

.shop-section .show-button .filter-button {
    display: block;
    color: #fff
}

.shop-section .show-button .filter-button a {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320))) calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
    background-color: var(--theme-color);
    border-radius: 5px;
    display: inline-block
}

.shop-section .show-button .filter-button-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.shop-section .show-button .filter-button-group .sort-by-button {
    color: #fff;
    margin-left: auto
}

.shop-section .show-button .filter-button-group .sort-by-button a {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320))) calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320)));
    background-color: var(--theme-color);
    border-radius: 5px;
    display: inline-block
}

.shop-section .show-button .filter-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 8px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.shop-section .show-button .top-filter-menu-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.shop-section .show-button .top-filter-menu-2 .sidebar-filter-menu a {
    border: none;
    background-color: #f6f5f2;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(9px + (10 - 9) * ((100vw - 320px) / (1920 - 320))) 15px;
    border-radius: 5px;
    color: #777
}

.shop-section .show-button .top-filter-menu-2 .category-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 767px) {
    .shop-section .show-button .top-filter-menu-2 .category-dropdown h5 {
        display: none
    }
}

.shop-section .show-button .top-filter-menu-2 .category-dropdown .dropdown {
    margin-left: 10px
}

[dir="rtl"] .shop-section .show-button .top-filter-menu-2 .category-dropdown .dropdown {
    margin-right: 10px;
    margin-left: unset
}

.shop-section .show-button .top-filter-menu-2 .category-dropdown .dropdown .dropdown-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    background-color: #f6f5f2;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(9px + (10 - 9) * ((100vw - 320px) / (1920 - 320))) 15px;
    border-radius: 5px;
    color: #777;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px
}

.shop-section .show-button .top-filter-menu-2 .category-dropdown .dropdown .dropdown-toggle::after {
    content: none
}

.shop-section .show-button .top-filter-menu-2 .category-dropdown .dropdown .dropdown-toggle i {
    margin-top: 2px
}

.shop-section .show-button .top-filter-menu-2 .category-dropdown .dropdown .dropdown-menu li {
    display: block
}

.shop-section .show-button .top-filter-menu-2 .grid-option {
    margin-left: auto
}

.shop-section .show-button .top-filter-menu-2 .grid-option-2 {
    margin-left: 0
}

@media (max-width: 767px) {
    .shop-section .show-button .top-filter-menu-2 .grid-option-2 {
        display: none
    }
}

.shop-section .show-button .top-filter-menu-2 .grid-option-2 ul li.active {
    background: var(--theme-color)
}

.shop-section .show-button .top-filter-menu-2 .grid-option-2 ul li.active img {
    -webkit-filter: invert(1) brightness(100);
    filter: invert(1) brightness(100)
}

.shop-section .show-button .top-filter-menu-2 .grid-option ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(5px + (12 - 5) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .shop-section .show-button .top-filter-menu-2 .grid-option ul {
    padding-right: 0
}

.shop-section .show-button .top-filter-menu-2 .grid-option ul li {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(149.8deg, #F6F5F2 17.21%, #FBFAF9 79.21%)
}

.shop-section .show-button .top-filter-menu-2 .grid-option ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%
}

.shop-section .show-button .top-filter-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 991px) {
    .shop-section .show-button .top-filter-menu {
        margin-top: 18px
    }
}

.shop-section .show-button .top-filter-menu .category-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 360px) {
    .shop-section .show-button .top-filter-menu .category-dropdown h5 {
        display: none
    }
}

.shop-section .show-button .top-filter-menu .category-dropdown .dropdown {
    margin-left: 10px
}

[dir="rtl"] .shop-section .show-button .top-filter-menu .category-dropdown .dropdown {
    margin-left: unset;
    margin-right: 10px
}

@media (max-width: 360px) {
    .shop-section .show-button .top-filter-menu .category-dropdown .dropdown {
        margin-left: 0
    }
}

.shop-section .show-button .top-filter-menu .category-dropdown .dropdown .dropdown-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    background-color: #f6f5f2;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(9px + (10 - 9) * ((100vw - 320px) / (1920 - 320))) 15px;
    border-radius: 5px;
    color: #777;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px
}

.shop-section .show-button .top-filter-menu .category-dropdown .dropdown .dropdown-toggle::after {
    content: none
}

.shop-section .show-button .top-filter-menu .category-dropdown .dropdown .dropdown-toggle i {
    margin-top: 2px
}

.shop-section .show-button .top-filter-menu .category-dropdown .dropdown .dropdown-menu li {
    display: block
}

.shop-section .show-button .top-filter-menu .category-dropdown .dropdown .dropdown-menu li a:hover,
.shop-section .show-button .top-filter-menu .category-dropdown .dropdown .dropdown-menu li a:focus {
    background-color: transparent
}

.shop-section .show-button .top-filter-menu .grid-option {
    margin-left: auto
}

[dir="rtl"] .shop-section .show-button .top-filter-menu .grid-option {
    margin-left: unset;
    margin-right: auto
}

.shop-section .show-button .top-filter-menu .grid-option ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .shop-section .show-button .top-filter-menu .grid-option ul {
    padding-right: 0
}

.shop-section .show-button .top-filter-menu .grid-option ul li {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(149.8deg, #F6F5F2 17.21%, #FBFAF9 79.21%)
}

.shop-section .show-button .top-filter-menu .grid-option ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%
}

.shop-section .show-button .top-filter-menu .grid-option ul li.active {
    background: var(--theme-color)
}

.shop-section .show-button .top-filter-menu .grid-option ul li.active img {
    -webkit-filter: invert(1) brightness(100);
    filter: invert(1) brightness(100)
}

.shop-section .show-button .filter-category {
    margin-bottom: 25px
}

.shop-section .show-button .filter-category ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 16px
}

.shop-section .show-button .filter-category ul li {
    padding: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))) calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320))) calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))) calc(7px + (11 - 7) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f9f8f6;
    position: relative;
    border-radius: 4px
}

.shop-section .show-button .filter-category ul li:before {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    right: 13px;
    color: #777;
    cursor: pointer
}

.shop-section .show-button .filter-category ul li a {
    color: #777
}

.shop-section .top-filter-category {
    width: 100%;
    height: 0;
    padding: 0 24px;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 9px rgba(34, 34, 34, 0.07);
    box-shadow: 0 2px 9px rgba(34, 34, 34, 0.07);
    border-radius: 6px;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.shop-section .top-filter-category.show {
    padding: 24px;
    height: auto;
    margin-bottom: 22px
}

.shop-section .top-filter-category>.row>div {
    border-right: 1px solid #ececec
}

[dir="rtl"] .shop-section .top-filter-category>.row>div {
    border-right: unset;
    border-left: 1px solid #ececec
}

.shop-section .top-filter-category>.row>div:last-child {
    border: none
}

@media (max-width: 1199px) {
    .shop-section .top-filter-category>.row>div:nth-child(4n-2) {
        border-right: none
    }

    [dir="rtl"] .shop-section .top-filter-category>.row>div:nth-child(4n-2) {
        border-right: unset;
        border-left: none
    }
}

@media (max-width: 767px) {
    .shop-section .top-filter-category>.row>div {
        border: none
    }
}

.category-slider-section .category-contain .category-contain-image {
    background: linear-gradient(149.8deg, #F6F5F2 17.21%, #FBFAF9 79.21%);
    padding: 22px;
    border-radius: 8px
}

.category-slider-section .category-contain .category-contain-image img {
    width: 150px;
    height: 150px;
    -o-object-fit: contain;
    object-fit: contain;
    margin: 0 auto
}

.category-slider-section .category-contain .category-contain-name {
    margin-top: 12px;
    text-align: center
}

.category-slider-section .category-contain .category-contain-name h5 {
    color: #4a5568;
    font-weight: 500
}

.pt-25 {
    padding-top: 25px
}

.product-load-more .col-grid-box {
    display: none
}

.product-title {
    margin-top: 20px
}

.product-title h4 {
    font-weight: 600;
    margin-bottom: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.4px;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-box {
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8
}

.vendor-box .verndor-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    padding: 7px calc(16px + (26 - 16) * ((100vw - 320px) / (1920 - 320)));
    background-color: #fff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.vendor-box .verndor-contain .vendor-image {
    width: 64px;
    height: auto
}

.vendor-box .verndor-contain .vendor-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.vendor-box .vendor-list {
    margin-top: calc(13px + (24 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.vendor-box .vendor-list ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(9px + (18 - 9) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[dir="rtl"] .vendor-box .vendor-list ul {
    padding-right: 0
}

.vendor-box .vendor-list ul li {
    display: block
}

.vendor-box .vendor-list ul li .address-contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 8px
}

.vendor-box .vendor-list ul li .address-contact .feather {
    width: 20px;
    height: 20px;
    color: var(--theme-color)
}

.vendor-box .vendor-list ul li .address-contact h5 {
    font-weight: 600
}

.vendor-box .vendor-list ul li .address-contact h5 span {
    font-weight: 400;
    margin-left: 7px
}

.vendor-box .vendor-detail {
    margin: calc(13px + (28 - 13) * ((100vw - 320px) / (1920 - 320))) 0 0;
    line-height: 1.7;
    color: #4a5568;
    padding-bottom: calc(10px + (18 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-bottom: 1px solid #ececec
}

.hot-line-number {
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f8f8f8
}

.hot-line-number h5 {
    font-weight: 600;
    margin-bottom: 4px
}

.hot-line-number h6 {
    color: #4a5568;
    margin-bottom: 17px
}

.hot-line-number h2 {
    font-weight: 600;
    font-size: 24px
}

.product-category {
    gap: 25px;
    padding: calc(12px + (33 - 12) * ((100vw - 320px) / (1920 - 320)))
}

.product-section .product-left-box {
    position: sticky;
    top: 0
}

.product-section .left-slider-image .sidebar-image {
    border-radius: 8px;
    overflow: hidden
}

.product-section .left-slider-image .sidebar-image img {
    width: auto;
    height: auto;
    cursor: pointer;
    -o-object-fit: contain;
    object-fit: contain
}

.product-section .right-box-contain {
    position: sticky;
    top: 10px;
    left: 0
}

@media (max-width: 767px) {
    .product-section .right-box-contain {
        text-align: center
    }
}

.product-section .right-box-contain .offer-top {
    font-weight: 500;
    padding: 7px 20px;
    background-color: #fbe24c;
    border-radius: 0 5px 5px 0;
    color: #333;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 20px;
    margin-left: -22px;
}

.product-section .right-box-contain .name {
    font-weight: 700;
    margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.product-section .right-box-contain .price-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 1460px) {
    .product-section .right-box-contain .price-rating-2 {
        display: block
    }
}

@media (max-width: 1460px) {
    .product-section .right-box-contain .price-rating-2 .custom-rate {
        margin-top: 5px
    }
}

@media (max-width: 767px) {
    .product-section .right-box-contain .price-rating {
        display: block
    }
}

@media (max-width: 1460px) {
    .product-section .right-box-contain .price-rating-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

@media (max-width: 480px) {
    .product-section .right-box-contain .price-rating-box {
        display: block
    }
}

@media (max-width: 1460px) {
    .product-section .right-box-contain .price-rating-box .custom-rate {
        margin-top: 0 !important
    }
}

@media (max-width: 480px) {
    .product-section .right-box-contain .price-rating-box .custom-rate {
        margin-top: 10px !important
    }
}

@media (max-width: 767px) {
    .product-section .right-box-contain .price-rating .custom-rate {
        margin-top: 10px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.product-section .right-box-contain .price-rating .price {
    font-weight: 600;
    font-size: calc(19px + (22 - 19) * ((100vw - 320px) / (1920 - 320)))
}

.product-section .right-box-contain .price-rating .price del {
    font-weight: 400;
    font-size: 12px;
    margin: 0 calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)))
}

.product-section .right-box-contain .price-rating .price span {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700
}

.product-section .right-box-contain .price-rating .custom-rate ul li .feather {
    width: 16px;
    height: 16px
}

.product-section .right-box-contain .price-rating .custom-rate .review {
    font-size: 13px;
    margin-left: 12px
}

[dir="rtl"] .product-section .right-box-contain .price-rating .custom-rate .review {
    margin-left: unset;
    margin-right: 12px
}

.product-section .right-box-contain .procuct-contain {
    border-bottom: 1px solid #ececec;
    padding-bottom: 16px
}

.product-section .right-box-contain .procuct-contain p {
    color: #4a5568;
    line-height: 1.7;
    margin: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) 0 0;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    width: 80%
}

@media (max-width: 767px) {
    .product-section .right-box-contain .procuct-contain p {
        width: 100%
    }
}

.product-section .right-box-contain .product-packege .select-packege {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(5px + (13 - 5) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 767px) {
    .product-section .right-box-contain .product-packege .select-packege {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

[dir="rtl"] .product-section .right-box-contain .product-packege .select-packege {
    padding-right: 0
}

.product-section .right-box-contain .product-packege .select-packege li a {
    padding: 6px 11px;
    border: 1px solid #ececec;
    border-radius: 4px;
    display: block;
    color: #4a5568;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}

.product-section .right-box-contain .product-packege .select-packege li a.active {
    border: 1px solid var(--theme-color);
    background: var(--theme-color2);
    color: #fff
}

.product-section .right-box-contain .note-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px
}

@media (max-width: 767px) {
    .product-section .right-box-contain .note-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.product-section .right-box-contain .note-box .compare-button {
    background-color: #f8f8f8
}

.product-section .right-box-contain .note-box .cart-button {
    -webkit-animation: shake 150ms 2 linear;
    animation: shake 150ms 2 linear
}

.product-section .right-box-contain .note-box .product-qty {
    width: auto;
    margin-top: 0
}

@media (max-width: 360px) {
    .product-section .right-box-contain .note-box .product-qty {
        width: 160px
    }
}

.product-section .right-box-contain .note-box .product-qty .input-group {
    background: linear-gradient(187.77deg, #fafafa 5.52%, #f8f8f8 94%)
}

.product-section .right-box-contain .buy-now-button {
    margin-top: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ececec
}

.product-section .right-box-contain .buy-now-button button {
    background-color: #FF7272
}

.product-section .right-box-contain .buy-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 11px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-section .right-box-contain .buy-box .team-box {
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-section .right-box-contain .buy-box .team-box input {
    margin-top: -7px;
    display: block
}

.product-section .right-box-contain .buy-box .team-box .form-check-label span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-section .right-box-contain .buy-box .team-box .form-check-label span a {
    text-decoration: underline;
    margin-left: 3px;
    color: #222
}

@media (max-width: 767px) {
    .product-section .right-box-contain .buy-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.product-section .right-box-contain .buy-box button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #777;
    border: 0;
    background: transparent;
}

.product-section .right-box-contain .buy-box button .feather {
    width: 17px;
    height: auto
}

.product-section .right-box-contain .pickup-box {
    padding-bottom: 20px;
    border-bottom: 1px solid #ececec
}

.product-section .right-box-contain .pickup-box .product-info {
    margin-top: 20px
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(8px + (17 - 8) * ((100vw - 320px) / (1920 - 320)));
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
    display: inline-block;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 12px;
    width: 80%
}

@media (max-width: 480px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        padding: 15px
    }
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media (max-width: 1500px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-2 {
        width: 100%
    }
}

@media (max-width: 1450px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-2 {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        padding: 15px
    }
}

@media (max-width: 1199px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-2 {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        display: block;
        padding-bottom: 4px
    }

    .product-section .right-box-contain .pickup-box .product-info .product-info-list-2 li {
        margin-bottom: 8px
    }
}

@media (max-width: 480px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-bottom: 15px
    }

    .product-section .right-box-contain .pickup-box .product-info .product-info-list-2 li {
        margin-bottom: 0
    }
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list-3 {
    width: 50%
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list-3 li {
    margin-bottom: 7px
}

@media (max-width: 360px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-3 li {
        margin-bottom: 0
    }
}

@media (max-width: 1366px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-3 {
        width: 80%
    }
}

@media (max-width: 767px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-3 {
        width: 100%
    }
}

@media (max-width: 360px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list-3 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list li {
    padding-left: 13px;
    width: 100%;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    color: #777
}

@media (max-width: 575px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list li {
        width: auto;
        margin-left: 18px
    }
}

@media (max-width: 480px) {
    .product-section .right-box-contain .pickup-box .product-info .product-info-list li {
        margin-left: unset;
        width: 100%;
        padding-left: unset
    }

    .product-section .right-box-contain .pickup-box .product-info .product-info-list li::after {
        content: none !important
    }
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list li::after {
    content: "";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    left: 0;
    background-color: #4a5568;
    border-radius: 100%
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list li a {
    margin-left: 5px;
    color: #222;
    font-weight: 500
}

.product-section .right-box-contain .pickup-box .product-info .product-info-list li a+a {
    margin-left: 0
}

.product-section .right-box-contain .pickup-box .pickup-icon i {
    font-size: 20px;
    color: var(--theme-color)
}

.product-section .right-box-contain .pickup-box .pickup-detail h4 {
    width: 90%;
    font-weight: 400;
    margin-bottom: 5px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6
}

@media (max-width: 767px) {
    .product-section .right-box-contain .pickup-box .pickup-detail h4 {
        width: 100%
    }
}

.product-section .right-box-contain .pickup-box .pickup-detail h6 {
    margin-top: 4px
}

.product-section .right-box-contain .pickup-box .pickup-detail a {
    margin-top: 0;
    color: var(--theme-color);
    display: inline-block
}

.product-section .right-box-contain .paymnet-option ul {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: calc(3px + (8 - 3) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 767px) {
    .product-section .right-box-contain .paymnet-option ul {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

[dir="rtl"] .product-section .right-box-contain .paymnet-option ul {
    padding-right: 0
}

.product-section .right-box-contain .share-option {
    padding-bottom: 20px
}

.product-section .right-box-contain .share-option ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 20px
}

.product-section .right-box-contain .share-option ul li {
    display: block
}

.product-section .right-box-contain .share-option ul li a {
    display: block;
    text-align: center;
    font-size: 17px;
    color: #4a5568
}

.product-section .product-main .slider-image img {
    width: 100%
}

.product-section .right-sidebar-box {
    position: sticky;
    top: 10px
}

.product-section .right-sidebar-box .product-right-sidebar li+li {
    margin-top: 0
}

.product-section .right-sidebar-box .product-right-sidebar li+li::after {
    top: -14px;
    width: calc(100% - 103px)
}

.related-product-2 .related-product .product-title-2 {
    margin-top: 0
}

.related-product-2 .related-product .product-title-2 h4 {
    font-size: calc(21px + (25 - 21) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    margin-bottom: calc(16px + (21 - 16) * ((100vw - 320px) / (1920 - 320)))
}

.related-product-2 .related-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: 15px
}

@media (max-width: 1577px) {
    .related-product-2 .related-box {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
}

.related-product-2 .related-box .related-image {
    -ms-touch-action: pan-x;
    touch-action: pan-x;
    overflow: auto;
    padding-bottom: 13px
}

.related-product-2 .related-box .related-image>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)))
}

.related-product-2 .related-box .related-image>ul>li {
    width: 225px;
    min-width: 225px;
    position: relative
}

.related-product-2 .related-box .related-image>ul>li+li::before {
    content: "+";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(-18px + (-26 - -18) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    color: #4a5568
}

.related-product-2 .related-box .related-image>ul>li a {
    display: block;
    overflow: hidden
}

.related-product-2 .related-box .related-image>ul>li a .image-box {
    background-color: #f8f8f8;
    width: calc(136px + (170 - 136) * ((100vw - 320px) / (1920 - 320)));
    height: calc(136px + (170 - 136) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    border-radius: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320)))
}

.related-product-2 .related-box .related-image>ul>li a .image-box .form-check {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 0
}

.related-product-2 .related-box .related-image>ul>li a .image-box .form-check .checkbox_animated::after {
    border: 1px solid #4a5567;
    border-radius: 3px
}

.related-product-2 .related-box .related-image>ul>li a .related-content {
    margin-top: 12px;
    padding: 0 calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.related-product-2 .related-box .related-image>ul>li a .related-content h5 {
    font-size: 15px;
    line-height: 1.2;
    color: #222;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.related-product-2 .related-box .related-image>ul>li a .related-content h6 {
    margin-top: 5px;
    color: var(--theme-color)
}

.related-product-2 .related-box .related-image>ul>li a .related-content h6 del {
    color: #4a5568;
    margin-left: 4px;
    font-size: 13px
}

.related-product-2 .related-box .budle-list {
    gap: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.related-product-2 .related-box .budle-list>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 15px
}

.related-product-2 .related-box .budle-list>ul>li {
    width: 100%;
    display: block
}

.related-product-2 .related-box .budle-list>ul>li .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin-bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.related-product-2 .related-box .budle-list>ul>li .form-check .checkbox_animated {
    margin-top: -4px
}

.related-product-2 .related-box .budle-list>ul>li .form-check .form-check-label span {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    color: #4a5568
}

.related-product-2 .related-box .budle-list>ul>li .form-check .form-check-label span span {
    color: #222;
    font-weight: 600
}

.related-product-2 .related-box .budle-list>ul>li.contant {
    margin-top: calc(0px + (15 - 0) * ((100vw - 320px) / (1920 - 320)))
}

.related-product-2 .related-box .budle-list>ul>li.contant h5 {
    font-size: 17px;
    font-weight: 600;
    color: #4a5568
}

.related-product-2 .related-box .budle-list>ul>li.contant h4 {
    margin-top: 8px;
    font-weight: 700
}

.related-product-2 .related-box .budle-list>ul>li.contant h4 del {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-left: 5px;
    font-weight: 400
}

.sticky-bottom-cart {
    position: fixed;
    bottom: -160px;
    width: 100%;
    z-index: 9;
    background-color: #fff;
    padding: calc(11px + (6 - 11) * ((100vw - 320px) / (1920 - 320))) 0;
    -webkit-box-shadow: 0 0 3px 1px rgba(34, 34, 34, 0.06);
    box-shadow: 0 0 3px 1px rgba(34, 34, 34, 0.06);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.sticky-bottom-cart .cart-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 575px) {
    .sticky-bottom-cart .cart-content {
        display: block
    }
}

.sticky-bottom-cart .product-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media (max-width: 575px) {
    .sticky-bottom-cart .product-image {
        display: none
    }
}

.sticky-bottom-cart .product-image img {
    width: 60px;
    height: 70px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top
}

.sticky-bottom-cart .product-image .content {
    margin-left: 12px;
    color: #222;
    margin-top: 0
}

[dir="rtl"] .sticky-bottom-cart .product-image .content {
    margin-left: 0;
    margin-right: 12px
}

@media (max-width: 991px) {
    .sticky-bottom-cart .product-image .content {
        display: none
    }
}

@media (max-width: 480px) {
    .sticky-bottom-cart .product-image .content {
        display: block
    }
}

.sticky-bottom-cart .product-image .content h5,
.sticky-bottom-cart .product-image .content h6 {
    margin-bottom: 0
}

.sticky-bottom-cart .product-image .content h5 {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize
}

.sticky-bottom-cart .product-image .content h6 {
    font-size: 15px;
    margin-top: 6px
}

.sticky-bottom-cart .product-image .content h6 del {
    margin-left: 7px;
    color: #4a5568
}

[dir="rtl"] .sticky-bottom-cart .product-image .content h6 del {
    margin-left: unset;
    margin-right: 7px
}

.sticky-bottom-cart .product-image .content h6 span {
    color: var(--theme-color);
    margin-left: 5px
}

[dir="rtl"] .sticky-bottom-cart .product-image .content h6 span {
    margin-left: 0;
    margin-right: 5px
}

.sticky-bottom-cart .selection-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 25px 0 auto
}

@media (max-width: 575px) {
    .sticky-bottom-cart .selection-section {
        display: none
    }
}

.sticky-bottom-cart .selection-section .form-control {
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    text-transform: capitalize
}

.sticky-bottom-cart .selection-section .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.sticky-bottom-cart .selection-section .product-qty {
    height: 51px
}

.sticky-bottom-cart .selection-section .product-qty .input-group button {
    height: 100%
}

.sticky-bottom-cart .selection-section .form-group {
    width: 300px;
    margin-left: 10px;
    margin-right: 10px
}

@media (max-width: 1199px) {
    .sticky-bottom-cart .selection-section .form-group {
        width: 190px
    }
}

@media (max-width: 767px) {
    .sticky-bottom-cart .selection-section .form-group {
        margin-right: 0;
        width: unset
    }
}

@media (max-width: 575px) {
    .sticky-bottom-cart .add-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.sticky-bottom-cart .add-btn a {
    padding: 12px 40px
}

@media (max-width: 575px) {
    .sticky-bottom-cart .add-btn a {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.sticky-bottom-cart .add-btn a.wishlist-btn {
    display: none;
    background-color: #f8f8f8;
    color: #222
}

.sticky-bottom-cart .add-btn a.wishlist-btn:hover {
    background-color: var(--theme-color);
    border-color: var(--theme-color);
    color: #fff
}

@media (max-width: 575px) {
    .sticky-bottom-cart .add-btn a.wishlist-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

@media (max-width: 575px) {
    .sticky-bottom-cart .add-btn a+a {
        margin-left: 8px
    }

    [dir="rtl"] .sticky-bottom-cart .add-btn a+a {
        margin-right: 8px;
        margin-left: unset
    }
}

.sticky-bottom-cart .add-btn a i {
    margin-right: 7px
}

[dir="rtl"] .sticky-bottom-cart .add-btn a i {
    margin-right: unset;
    margin-left: 7px
}

.stickyCart {
    padding-bottom: 94px
}

.stickyCart .theme-option-2 {
    bottom: calc(74px + (90 - 74) * ((100vw - 320px) / (1920 - 320)))
}

@media (max-width: 575px) {
    .stickyCart {
        padding-bottom: 0
    }
}

.stickyCart .sticky-bottom-cart {
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.stickyCart .recently-purchase.show {
    bottom: 110px
}

.stickyCart .tap-top {
    bottom: 110px
}

[dir="rtl"] .slick-slider {
    direction: ltr
}

[dir="rtl"] .slick-slide {
    float: left
}

[dir="rtl"] ul {
    padding-right: 0
}

.setting-box.buy-now {
    background-color: #ff6b6b;
    position: fixed;
    left: 20px;
    bottom: 20px
}
.f-12{
    font-size: 12px;
}
.f-22{
    font-size: 22px;
}
.hr{
    position:relative;
    margin: 30px 0
}
.hr span{
    position:absolute;
    left: calc(50% - 40px);
    display: inline-block;
    top: -10px;
    background:#fff;
    padding: 0 15px;
    width: 80px;
    text-align: center
}
.locat-name-active{
    text-align:left !important;
    font-size: 12px !important;
}
.locat-name-active b{
    display: block;font-size:11px;
}
a{
    text-decoration: none !important
}
.placeholder-bg{
    border-radius: 7px !important;
    overflow: hidden;
    background-image: linear-gradient(to left, rgba(0,0,0, .01), rgba(0,0,0, .025), rgba(0,0,0, .05), rgba(0,0,0, .025), rgba(0,0,0, .01));
    background-image: -moz-linear-gradient(to left, rgba(0,0,0, .01), rgba(0,0,0, .025), rgba(0,0,0, .05), rgba(0,0,0, .025), rgba(0,0,0, .01));
    background-image: -webkit-linear-gradient(to left, rgba(0,0,0, .01), rgba(0,0,0, .025), rgba(0,0,0, .05), rgba(0,0,0, .025), rgba(0,0,0, .01));
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0%{
        left: -45%;
    }
    100%{
        left: 100%;
    }
}
.discount-price{
    display: block;
    font-size: 11px;
    border-radius: 0 0 5px 0;
    background: #fbe24c;
    text-align: center;
    position: absolute;
    line-height: 10px;
    font-weight: 700;
    top: 0px;
    left: 0px;
    padding: 4px 12px;
}
.hr-b{
    border-bottom: 1px solid rgba(0,0,0,0.09) !important;
    margin-left: -16px;
    width: calc(100% + 32px);
}

.product-contain * {
    color: #777777 !important
}
.card .card-header{
    padding-bottom: 0 !important
}
.card .card-footer, .card .card-header{
    background:#fff !important;
    padding: 22px;
    font-size:14px;
}
.breadcrumb>li>a{
    color:#333;
    font-weight: 600;
}
.breadcrumb>li:last-child>a{
    color:#d0d0d0 !important;
}
.breadcrumb-item+.breadcrumb-item::before{
    content: "\f054" !important;
    font-family: "Font Awesome 6 Pro";
    font-size: 9px;
    margin: 5px 7px
}
.breactcrumb-section{
    margin-bottom:-10px;
    padding-top:12px !important;
}
.add-to-cart-button .qty-box .input-group {
    background-color: #f8f8f8;
    border-radius: 6px;
    padding: 7px;
    text-align: center;
    z-index: 0;
    bottom: 0;
    top: 0;
    left: -5px;
    border: 1px solid #dedede;
}
.cursor-pointer{
    cursor:pointer
}
.otp-inputs>div{
    align-items: normal !important;
    justify-contents: center;
}
.otp-inputs input{
    font-size: 25px;
    width: 40px !important;
    border: 1px solid #dedede;
    border-radius: 12px;
    padding: 4px 10px;
}
.otp-inputs span{
    display: inline-block;
    margin: 12px 5px;
}

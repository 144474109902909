.login-section{
    position:relative;
    padding-top: 100px !important;
}
.login-section .login-bg{
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 200px;
    background: var(--theme-color)
}
.user-profile-img{
    margin-top: -14px;
    margin-right: 6px;
    border: 1px solid var(--theme-color);
    padding: 0;
    border-radius: 50%;
}
li.seperator{
    display: block;
    height: 1px;
    width: 100%;
    background:#dedede;
    margin: 4px 0
}
.delivery-icon{
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding-top: 7px;
    margin-top:-13px;
    margin-right: 10px;
    border: 1px solid var(--theme-color)
}
.img-radius{
    border-radius:4px;
    border: 1px solid #dedede;
}
.all-item-middle td{
    vertical-align: middle !important;
}
.cart-add-button-table{
    top:33px;
    position:relative;
}
.bg-theme-light.card-footer{
    background:rgba(255,127,0,0.16) !important;
    background-color:rgba(255,127,0,0.16) !important;
}
.lh-10{
    line-height: 8px !important;
}
.alert-success{
    color: rgba(2, 182, 29, 1) !important;
    background: #e3fde6 !important;
    border-color: rgba(2, 182, 29, 0.1) !important;
}
.alert-mini{
    padding: 5px !important;
    font-size: 12px;
    font-weight: 600
}
.custome-accordion .accordion-button:not(.collapsed){
    color:#444 !important;
background: transparent !important;
}
.accordion-button:not(.collapsed){
    background-color:rgba(255,127,0,0.16) !important;
    color: var(--theme-color) !important
}
.w-22{
    width: 22px;
    display: block;
}
.cover-image{
    background: var(--theme-color);
    height: 20px;
    width:100%;
}
.user-card{
    border-radius: 0 0 6px 6px !important;
    border-color: var(--bs-border-color) !important;
}
.fill-tab .nav-link{
    font-size: 18px;
    font-weight: 600;
    color:#444;
}
.fill-tab .nav-link.active,.fill-tab .nav-link:hover{
    color: var(--theme-color) !important;
}
.order-header{
    position:relative;
}
.img-items{
    position:absolute;
    left: -10px;
    top: 0px;
}
.img-item{
    position:absolute;
    left: 0;
    top: 0;
}
.img-item:hover{
    z-index: 10
}
.img-item:nth-child(2){
    left: 17px;
}
.img-item:nth-child(3){
    left: 35px;
}
.img-item.img-count{
    width: 35px;
    height: 35px;
    background:#f5f5f5;
    color:#888;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #dedede;
    font-weight: 600;
    padding-top: 7px
}
.img-item img{
    width:35px;
    max-width: 35px !important;
    border-radius: 50%;
    border: 1px solid #dedede;
    background:#fff;
}
.order-header{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.order-header .order-title{
    font-weight: 600
}
.order-header .order-info{
    flex: 1;
    margin-left: 70px;
}
.order-header .order-info span{
    display: block;
    color:#666;
    font-size: 14px;
}
.order-header .order-info span{
    display: block;
    color:#666;
    font-size: 14px;
    font-weight: 600;
    margin-bottom:3px
}
.order-header .order-info span.date{
    font-size: 12px;
    font-weight: 400;
}
.order-header .order-price{
    margin-right: 30px
}
.order-header .order-price span:first-child{
    display: block;
    color: #308f22 !important;
    font-weight: 600;
    font-size: 15px
}
.order-header .order-price span:last-child{
    font-size:12px;
    color:#666;
}
.order-header .order-status{
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    color:#444;
    margin-top: 12px
}
#order-accord .accordion-button:not(.collapsed){
    background: #fff !important;
}
.order-list-summary-contain .checkout-image{
    width: 80px;
}
.credit-card-icon{
    right: 14px;
    top: 14px;
    position: absolute;
    font-size: 22px;
    opacity: 0.4
}
.bg-light-green{

}
.order-complete-card i.text-success{
    font-size: 80px
}
.small-hr{
    width: 60%;
    height: 1px;
    background:rgba(0,0,0,0.1);
    display: block;
    margin: 20px auto;
}
.bg-success-subtle{
    background-color : #eafbed !important;
}
.text-success{
    color: #48a04d !important;
}
.btn-success{
    background-color: #48a04d !important;
}
.btn-outline-success{
    color: #48a04d !important;
    border-color: #48a04d !important;
}
.btn-outline-success:hover{
    background-color: #48a04d;
    color: #fff !important;
}
.swiper-button{
    position:absolute;
    top: calc(50% - 30px);
    background: rgba(255,255,255,.5);
    border: 1px solid #dedede;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    z-index: 11;
    padding-top: 14px;
    font-size: 20px;
    cursor: pointer;
    color: rgba(0,0,0,0.6);
}
.swiper-button:hover{
    background: rgba(255,255,255,.9);
    color: rgba(0,0,0,.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.1)
}
.swiper-button-prev{
    left: -30px;
    text-align: right;
    padding-right: 10px
}
.swiper-button-next{
    right: -30px;
    text-align: left;
    padding-left: 10px
}
.mt--2{
    margin-top: -10px
}
.close-x{
    width: 20px;
    height: 20px;
    padding: 0 3px;
    position:absolute;
    right: 10px;
    top: -10px;
}
.passive-address{
    opacity: 0.8
}
.active-address{
    border-color: rgba(2, 182, 29, 1) !important;
}
.active-address .card-header{
    background-color: #e3fde6 !important;
    color: rgba(2, 182, 29, 1) !important;
}

footer ul, footer ul li{
    list-style-type: none !important;
}
footer .footer-social a{
    font-size: 35px;
}
footer .footer-social a:hover i{
    color: var(--theme-color) !important;
}
.share-product a{
    color: #888;
    font-size: 18px;
    margin: 0 6px
}
.share-product a:hover{
    color: var(--theme-color)
}
.share-product span{
    font-size: 17px;
    color: #888;
    display: inline-block;
    margin-top: -3px;
    margin-right: 10px;
    font-weight: bold
}
.lh-20{
    line-height: 25px;
}
.category-box-list{
    border: 1px solid transparent;
}
.category-box-list:hover, .active-campaign{
    border-color: var(--theme-color)
}
.category-box-list:hover .hover-area, .active-campaign .hover-area{
    opacity: 1
}
.hover-area{
    opacity: 0;
    position:absolute;
    top: 0;
    right: 0;
    bottom:0;
    left: 0;
    background: rgba(255,255,255,.7);
    backdrop-filter: blur(10px);
}
